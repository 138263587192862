import Entity from '_entities/base/EntityNew'

export const RoomLinkSchema = {
    name: 'RoomLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}
class RoomLink extends Entity {
    get schema() {
        return RoomLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Наименование',
    }
}

export default RoomLink
