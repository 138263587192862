import { map as _map } from 'lodash'

import Entity from '_entities/base/EntityNew'

import PipeLink from '_entities/pipe/PipeLink'
import PremiseLink from '_entities/premise/PremiseLink'
import EntranceLink from '_entities/entrance/EntranceLink'

export const PushMessageLocationSchema = {
    name: 'PushMessageLocation',
    properties: {
        entrances: { type: 'EntranceLink[]', entity: EntranceLink, default: [] },
        floors: { type: 'integer[]', default: [] },
        pipes: { type: 'PipeLink[]', entity: PipeLink, default: [] },
        premises: { type: 'PremiseLink[]', entity: PremiseLink, default: [] },
    },
}

class PushMessageLocation extends Entity {
    get schema() {
        return PushMessageLocationSchema
    }

    static fieldLabels = {
        entrances: 'Подъезды',
        floors: 'Этажи',
        pipes: 'Стояки',
        premises: 'Помещения',
    }

    toArray() {
        return {
            entrances: this.entrances ? _map(this.entrances, (entrance) => entrance.id) : [],
            floors: this.floors ? this.floors : [],
            pipes: this.pipes ? _map(this.pipes, (pipe) => pipe.id) : [],
            premises: this.premises ? _map(this.premises, (premise) => premise.id) : [],
        }
    }
}

export default PushMessageLocation
