const WorkShiftScheduleNavigator = () => import('_navigations/Home/WorkShiftSchedule/WorkShiftScheduleNavigator')

const WorkShiftScheduleScreen = () => import('_screens/Home/WorkShiftSchedule/WorkShiftScheduleScreen')

const workShiftScheduleRoutes = [
    {
        path: 'work-shift-schedule',
        component: WorkShiftScheduleNavigator,
        children: [
            {
                path: 'list',
                name: 'WorkShiftSchedule',
                component: WorkShiftScheduleScreen,
                meta: {
                    screenOptions: {
                        title: 'Смены сотрудников',
                    },
                },
            },
        ],
    },
]

export default workShiftScheduleRoutes
