import Entity from '_entities/base/EntityNew'

export const TicketSCAcceptanceTypeLinkSchema = {
    name: 'TicketSCAcceptanceTypeLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class TicketSCAcceptanceTypeLink extends Entity {
    get schema() {
        return TicketSCAcceptanceTypeLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Наименование',
    }
}

export default TicketSCAcceptanceTypeLink
