import {
    compact as _compact,
    includes as _includes,
    intersection as _intersection,
    map as _map,
    orderBy as _orderBy,
    uniq as _uniq,
} from 'lodash'
import { checkConditions } from '_utils/conditions'

const getters = {
    getPermissions: (state) => {
        return _orderBy(state.items)
    },

    getPermissionModules: (state, getters) => {
        const modules = _map(getters.getPermissions, (permission) => {
            const permissionParts = permission.split('.')

            return permissionParts[0]
        })

        return _compact(_uniq(modules))
    },

    hasPermission: (state, getters) => (checkPermissions) => {
        if (!Array.isArray(checkPermissions) || !checkPermissions?.length) {
            return false
        }

        if (!getters.getPermissions?.length) {
            return true
        }

        const conditions = _map(checkPermissions, (permissionValues) => {
            if (Array.isArray(permissionValues)) {
                return _intersection(getters.getPermissions, permissionValues)?.length > 0
            } else {
                return _includes(getters.getPermissions, permissionValues)
            }
        })

        return checkConditions('or', conditions)
    },

    hasModulePermission: (state, getters) => (checkPermissionModules) => {
        if (!Array.isArray(checkPermissionModules) || !checkPermissionModules?.length) {
            return false
        }

        if (!getters.getPermissionModules?.length) {
            return true
        }

        const intersection = _intersection(getters.getPermissionModules, checkPermissionModules)

        return Boolean(intersection?.length)
    },
}

export default getters
