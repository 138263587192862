import { EMAIL_REGEXP, MOBILE_PHONE_REGEXP, OFFICE_PHONE_REGEXP } from '_utils/constants'

export const requiredRule = (value) => (!!value && !!value.length) || 'Обязательное поле.'

export const requiredObjectRule = (value) => (value && typeof value === 'object') || 'Обязательное поле.'

export const requiredArrayRule = (value) => (value.length > 0) || 'Обязательное поле'

export const maxLengthRule = (maxLength) => (value) => (!!value && value.length <= maxLength) || `Максимальное количество символов: ${maxLength}.`

export const emailRule = (value) => !value || value.match(EMAIL_REGEXP)?.length === 1 || 'Некорректный адресс электронной почты'

export const mobilePhoneRule = (value) => !value || value.match(MOBILE_PHONE_REGEXP)?.length === 1 || 'Номер телефона должен содержать 11 цифр'

export const officePhoneRule = (value) => !value || value.match(OFFICE_PHONE_REGEXP)?.length === 1 || 'Номер телефона должен содержать 6 цифр'
