<template>
    <v-text-field
        ref="phoneInput"
        v-mask="phoneFieldMask"
        v-bind="$attrs"
        :label="label"
        :rules="rules"
        masked="true"
        clearable
        outlined
        @click:clear="clearPhone"
        @change="setPhone"
    />
</template>

<script>
    import { mask } from 'vue-the-mask'

    export default {
        name: 'UIPhoneInput',

        directives: {
            mask,
        },

        props: {
            label: {
                type: String,
                default: 'Номер телефона',
            },
            rules: {
                type: Array,
                default: () => [
                    (value) => (value && value.match(/^\+?[0-9]{11}$/)?.length === 1) || 'Номер телефона должен содержать 11 цифр',
                ],
            },
        },

        data: () => ({
            phoneFieldMask: '+7##########',
        }),

        methods: {
            clearPhone() {
                this.$emit('input', '')
            },
            setPhone(evt) {
                this.$emit('input', evt)
            },
        },
    }
</script>
