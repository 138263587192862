import Vue from 'vue'

const mutations = {
    fetchMessagesRequest(state) {
        state.isFetching = true
    },

    fetchMessagesSuccess(state, { items }) {
        state.errors = []
        state.isFetching = false

        items.forEach((item) => {
            Vue.set(state.messages, item.id, item)
        })
    },

    fetchMessagesError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },

    addedMessage(state, item) {
        Vue.set(state.messages, item.id, item)
    },

    deleteMessageRequest(state) {
        state.isDeleting = true
    },

    deleteMessageSuccess(state, messageId) {
        state.errors = []
        state.isDeleting = false
        Vue.delete(state.messages, messageId)
    },

    deleteMessageError(state, errors) {
        state.errors = errors
        state.isDeleting = false
    },

    sendMessageRequest(state) {
        state.isSending = true
    },

    sendMessageSuccess(state, message) {
        state.errors = []
        state.isSending = false
        Vue.set(state.messages, message.id, message)
    },

    sendMessageError(state, errors) {
        state.errors = errors
        state.isSending = false
    },

    editMessageRequest(state) {
        state.isEditing = true
    },

    editMessageSuccess(state, message) {
        state.errors = []
        state.isEditing = false
        Vue.set(state.messages, message.id, message)
    },

    editMessageError(state, errors) {
        state.errors = errors
        state.isEditing = false
    },
}

export default mutations
