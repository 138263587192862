const QueueNavigator = () => import('_navigations/Home/Queue/QueueNavigator')

const OnQueueScreen = () => import('_screens/Home/Queues/OnQueueScreen')

const queueRoutes = [
    {
        path: 'queues',
        component: QueueNavigator,
        children: [
            {
                path: 'list',
                name: 'Queues',
                component: OnQueueScreen,
                meta: {
                    screenOptions: {
                        title: 'На линии',
                    },
                },
            },
        ],
    },
]

export default queueRoutes
