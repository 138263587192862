<template>
    <UIPage>
        <UIPageHeader />
        <v-card>
            <div class="horizontal-scroll">
                <div ref="slot">
                    <slot />
                </div>
                <div class="horizontal-scroll__left" @mouseenter="setShift('left')" @mouseleave="setShift('none')">
                    <div class="horizontal-scroll__button__left" @mouseenter="setShift('left')" @mouseleave="setShift('none')">
                        <v-icon v-text="'$arrowLeft'" />
                    </div>
                </div>
                <div class="horizontal-scroll__right" @mouseenter="setShift('right')" @mouseleave="setShift('none')">
                    <div class="horizontal-scroll__button__right" @mouseenter="setShift('right')" @mouseleave="setShift('none')">
                        <v-icon v-text="'$arrowRight'" />
                    </div>
                </div>
            </div>
        </v-card>
    </UIPage>
</template>

<script>
    export default {
        name: 'UIHorizontalScroll',

        props: {
            elementType: { type: String, default: 'dataTable' },
            shiftSpeed: { type: Number, default: 5 },
        },

        data: () => ({
            shift: 0,
            onScroll: null,
            element: null,
        }),

        destroyed() {
            clearInterval(this.onScroll)
        },

        methods: {
            setShift(shift) {
                if (shift === 'none') {
                    this.shift = 0
                    clearInterval(this.onScroll)
                    this.onScroll = null
                } else if (shift === 'left') {
                    this.shift = -this.shiftSpeed
                    if (!this.onScroll) {
                        this.onScroll = setInterval(() => {
                            this.scrollTo()
                        }, 10)
                    }
                } else if (shift === 'right') {
                    this.shift = this.shiftSpeed
                    if (!this.onScroll) {
                        this.onScroll = setInterval(() => {
                            this.scrollTo()
                        }, 10)
                    }
                }
            },

            scrollTo() {
                if (!this.element) {
                    if (this.elementType === 'dataTable') {
                        this.element = this.$refs?.slot?.getElementsByClassName('v-data-table__wrapper')[0]
                    }
                }

                if (this.element && this.element?.scrollLeft !== undefined) {
                    this.element.scrollLeft += this.shift
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .horizontal-scroll {
        position: relative;

        &__left {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 40px;

            &:hover .horizontal-scroll__button__left {
                opacity: 1;
            }
        }

        &__right {
            position: absolute;
            top: 0;
            bottom: 50px;
            right: 0;
            width: 40px;

            &:hover .horizontal-scroll__button__right {
                opacity: 1;
            }
        }

        &__button__left {
            display: flex;
            justify-content: center;
            position: absolute;
            top: 40%;
            height:80px;
            width: 40px;
            background: rgba(0, 0, 0, .1);
            left: 0;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            opacity: 0;
            transition: .5s;
            cursor: pointer;
        }

        &__button__right {
            display: flex;
            justify-content: center;
            position: absolute;
            top: 40%;
            height:80px;
            width: 40px;
            background: rgba(0, 0, 0, .1);
            right: 0;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            opacity: 0;
            transition: .5s;
            cursor: pointer;
        }
    }
</style>
