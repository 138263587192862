const mutations = {
    getTimeEntryRequest(state) {
        state.isFetching = true
    },

    getTimeEntrySuccess(state, timeEntry) {
        state.errors = []
        state.isFetching = false
        state.timeEntry = timeEntry
    },

    getTimeEntryError(state, errors) {
        state.errors = errors
        state.isFetching = false
        state.timeEntry = null
    },
}

export default mutations
