import { API } from '_api'

import MaintenanceStatus from '_entities/maintenance/MaintenanceStatus'

const actions = {
    async fetchMaintenanceStatus({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchMantenanceStatusRequest')

            try {
                const result = await API.v1.services.maintenance.getServiceStatus(params)

                let maintenanceStatus = null
                let isInfoShown = false
                let isMaintenance = false
                let needRestartApplication = false

                if (result) {
                    maintenanceStatus = new MaintenanceStatus(result)
                }

                const currentMaintenanceCode = state.status?.statusCode
                const maintenanceCode = maintenanceStatus?.statusCode

                if (maintenanceStatus) {
                    if (maintenanceCode === 'coming_soon') {
                        isInfoShown = true
                        isMaintenance = false
                    }

                    if (maintenanceCode === 'in_progress') {
                        isInfoShown = false
                        isMaintenance = true
                    }

                    if (maintenanceCode === 'over') {
                        isInfoShown = false
                        isMaintenance = false
                    }
                }

                const isCurrentProgressOrOver =
                    currentMaintenanceCode === 'over' || currentMaintenanceCode === 'in_progress'

                needRestartApplication = isCurrentProgressOrOver && maintenanceCode !== 'in_progress'

                commit('fetchMantenanceStatusSuccess', {
                    maintenanceStatus,
                    isInfoShown,
                    isMaintenance,
                    needRestartApplication,
                })
            } catch (e) {
                commit('fetchMantenanceStatusError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
