import Entity from '_entities/base/EntityNew'

import TicketDepartment from '_entities/ticket/TicketDepartment'
import TicketKind from '_entities/ticket/TicketKind'
import TicketType from '_entities/ticket/TicketType'

export const TicketTypingItemSchema = {
    name: 'TicketTypingItem',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        ticketDepartment: { type: 'TicketDepartmentLink', entity: TicketDepartment },
        ticketKind: { type: 'TicketKindLink', entity: TicketKind },
        ticketType: { type: 'TicketTypeLink', entity: TicketType },
    },
}

class TicketTypingItem extends Entity {
    get schema() {
        return TicketTypingItemSchema
    }

    get dataMap() {
        return {
            ticket_department: 'ticketDepartment',
            ticket_kind: 'ticketKind',
            ticket_type: 'ticketType',
        }
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
        ticketDepartment: 'Отдел заявки',
        ticketKind: 'Вид заявки',
        ticketType: 'Тип заявки',
    }
}

export default TicketTypingItem
