const indicationRoutes = [
    {
        path: 'indications',
        children: [
            {
                path: '',
                name: 'MyCountryCompany',
                beforeEnter() {
                    window.open('https://indications.service-company.biz/#/?companyId=8ed0852d-514d-4d70-86b9-8132842f5954&ckc=true', '_blank')
                },
            },
            {
                path: '',
                name: 'HorizonCompany',
                beforeEnter() {
                    window.open('https://indications.service-company.biz/#/?companyId=84ee1cf7-8725-4a85-b5db-f0f250562163&ckc=true', '_blank')
                },
            },
        ],
    },
]

export default indicationRoutes
