import Entity from '_entities/base/EntityNew'

export const TimeEntryLinkSchema = {
    name: 'TimeEntryLink',
    properties: {
        dateFinish: { type: 'string' },
        dateStart: { type: 'string' },
        duration: { type: 'int' },
    },
}

class TimeEntryLink extends Entity {
    get schema() {
        return TimeEntryLinkSchema
    }

    get dataMap() {
        return {
            date_finish: 'dateFinish',
            date_start: 'dateStart',
        }
    }

    static fieldLabels = {
        dateFinish: 'Время завершения',
        dateStart: 'Время начала',
        duration: 'Длительность',
    }
}

export default TimeEntryLink
