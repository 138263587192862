import Entity from '_entities/base/EntityNew'

export const WorkerPositionLinkSchema = {
    name: 'WorkerPositionLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}
class WorkerPositionLink extends Entity {
    get schema() {
        return WorkerPositionLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Наименование',
    }
}

export default WorkerPositionLink
