<!-- eslint-disable vue/no-v-html -->
<template>
    <v-dialog
        v-model="show"
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
    >
        <v-card>
            <v-toolbar flat :loading="okLoading || cancelLoading" :color="options.color" dark dense>
                <v-toolbar-title class="white--text">
                    {{ title }}
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text v-show="!!message" class="pa-4">
                <span v-if="isHtmlMessage" v-html="message" />
                <span v-else>{{ message }}</span>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn :disabled="okLoading || cancelLoading" text @click.native="cancel">
                    Отмена
                </v-btn>
                <v-btn :disabled="okLoading || cancelLoading" color="primary" text @click.native="agree">
                    Да
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'UIConfirmDialog',

        data: () => ({
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            okLoading: false,
            cancelLoading: false,
            options: {
                color: 'primary',
                width: 290,
                zIndex: 200,
                html: false,
            },
        }),

        computed: {
            isHtmlMessage() {
                return this.options?.html
            },

            show: {
                get() {
                    return this.dialog
                },
                set(value) {
                    this.dialog = value
                    if (value === false) {
                        this.cancel()
                    }
                },
            },
        },

        methods: {
            open(title, message, options) {
                this.title = title
                this.message = message
                this.options = Object.assign(this.options, options)
                this.dialog = true
            },

            async agree() {
                try {
                    if (typeof this.options.onOk === 'function') {
                        this.okLoading = true
                        await this.options.onOk()
                    }

                    this.dialog = false
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось провести операцию подтверждения', e)
                    this.$logger.logException(e)
                } finally {
                    this.okLoading = false
                }
            },

            async cancel() {
                try {
                    if (typeof this.options.onCancel === 'function') {
                        this.cancelLoading = true
                        await this.options.onCancel()
                    }

                    this.dialog = false
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось провести операцию отмены', e)
                    this.$logger.logException(e)
                } finally {
                    this.cancelLoading = false
                }
            },
        },

        /*
        methods: {
            open(title, message, options) {
                this.dialog = true
                this.title = title
                this.message = message
                this.options = Object.assign(this.options, options)
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },

            agree() {
                this.resolve(true)
                this.dialog = false
            },

            cancel() {
                this.resolve(false)
                this.dialog = false
            },
        },
        */
    }
</script>
