import { PERMISSIONS } from '_features/permissions/constants'

const RouterNavigator = () => import('_screens/Home/Tickets/Router/RouterNavigator')
const RoutesScreen = () => import('_screens/Home/Tickets/Router/RoutesScreen')
const RouteEditScreen = () => import('_screens/Home/Tickets/Router/RouteEditScreen')

const routerRoutes = [
    {
        path: 'router',
        component: RouterNavigator,
        children: [
            {
                path: '',
                name: 'TicketRoutes',
                component: RoutesScreen,
                meta: {
                    screenOptions: {
                        title: 'Маршрутизатор',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_ROUTER_TICKET_ROUTE],
                },
            },
            {
                path: 'edit/:ticketRouteId?',
                name: 'TicketRouteEdit',
                component: RouteEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Редактирование маршрута',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_ROUTER_TICKET_ROUTE],
                },
            },
        ],
    },
]

export default routerRoutes
