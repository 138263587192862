import Vue from 'vue'

const mutations = {
    fetchNewsCategoriesRequest(state) {
        state.isFetching = true
    },

    fetchNewsCategoriesSuccess(state, { items }) {
        state.errors = []
        state.isFetching = false

        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchNewsCategoriesError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },

    deleteNewsCategoriesRequest(state) {
        state.isFetching = true
    },

    deleteNewsCategoriesSuccess(state, { newsCategoryId }) {
        state.errors = []
        state.isFetching = false
        Vue.delete(state.items, newsCategoryId)
    },

    deleteNewsCategoriesError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },
}

export default mutations
