import { forEach as _forEach } from 'lodash'

import ServiceElement from '_entities/service/ServiceElement'

import Product from '_entities/service/element/Product'

export const ServiceElementCatalogSchema = {
    elementType: { type: 'string', default: 'catalog' },
    products: { type: 'Product[]', entity: Product, default: () => [] },
}

class ServiceElementCatalog extends ServiceElement {
    get schema() {
        return {
            ...super.schema.name,
            ...super.schema.primaryKey,
            properties: {
                ...super.schema.properties,
                ...ServiceElementCatalogSchema,
            },
        }
    }

    get dataMap() {
        return {
            ...super.dataMap,
            element_type: 'elementType',
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
        products: 'Товары',
    }

    toServer(data) {
        const products = []

        _forEach(data?.products, (product) => {
            products.push(product.toServer(product))
        })

        return {
            ...super.toServer(data),
            products: products,
        }
    }
}

export default ServiceElementCatalog
