const ExperimentalFeaturesNavigator = () => import('_navigations/Home/ExperimentalFeatures/ExperimentalFeaturesNavigator')

const NotificationCenter = () => import('_screens/Home/ExperimentalFeatures/NotificationCenter')
const FilterTestScreen = () => import('_screens/Home/ExperimentalFeatures/FilterTestScreen')
const ScrollTableTestScreen = () => import('_screens/Home/ExperimentalFeatures/ScrollTableTestScreen')
const UITestScreen = () => import('_screens/Home/ExperimentalFeatures/UITestScreen')
const PhoneTestScreen = () => import('_screens/Home/ExperimentalFeatures/PhoneTestScreen')

const experimentalFeaturesRoutes = []

experimentalFeaturesRoutes.push({
    path: 'experimentalFeatures',
    name: 'ExperimentalFeatures',
    component: ExperimentalFeaturesNavigator,
    children: [
        {
            path: 'notification-center-test',
            name: 'notificationCenterTest',
            component: NotificationCenter,
            meta: {
                screenOptions: {
                    title: 'Центр уведомлений',
                },
            },
        },
        {
            path: 'filter-test',
            name: 'FilterTest',
            component: FilterTestScreen,
            meta: {
                screenOptions: {
                    title: 'Filter',
                },
            },
        },
        {
            path: 'scroll-table-test',
            name: 'ScrollTableTestScreen',
            component: ScrollTableTestScreen,
            meta: {
                screenOptions: {
                    title: 'BI',
                },
            },
        },
        {
            path: 'ui-test',
            name: 'UITest',
            component: UITestScreen,
            meta: {
                screenOptions: {
                    title: 'UI тест',
                },
            },
        },
        {
            path: 'phone-test',
            name: 'PhoneTest',
            component: PhoneTestScreen,
            meta: {
                screenOptions: {
                    title: 'Тест телефонии',
                },
            },
        },
    ],
})

export default experimentalFeaturesRoutes
