import Entity from '_entities/base/EntityNew'

import ServiceTagType from '_entities/service/ServiceTagType'

export const ServiceTagSchema = {
    name: 'ServiceTag',
    primaryKey: 'id',
    properties: {
        dateExpiration: { type: 'int' },
        id: { type: 'string' },
        label: { type: 'string' },
        type: { type: 'ServiceTagType', entity: ServiceTagType },
        value: { type: 'int' },
    },
}

class ServiceTag extends Entity {
    get schema() {
        return ServiceTagSchema
    }

    get dataMap() {
        return {
            date_expiration: 'dateExpiration',
        }
    }

    static fieldLabels = {
        dateExporation: 'Дата окончания действия',
        label: 'Наименование',
        type: 'Тип',
        value: 'Значение',
    }

    toServer() {
        return {
            date_expiration: this.dateExpiration,
            label: this.label || this.type?.name || '',
            type: this.type?.id || null,
            value: this.value ? Math.round(this.value) : null,
        }
    }
}

export default ServiceTag
