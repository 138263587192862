import { API } from '_api'

import ServicePartnerCompany from '_entities/service/ServicePartnerCompany'

const actions = {
    async fetchServicePartnerCompanies({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchServicePartnerCompaniesRequest')

            try {
                const response = await API.v1.services.service.getServicePartnerCompanies(params)

                const { results } = response

                const items = []

                if (results) {
                    results?.forEach((result) => {
                        items.push(new ServicePartnerCompany(result))
                    })
                }

                commit('fetchServicePartnerCompaniesSuccess', { items })
            } catch (e) {
                commit('fetchServicePartnerCompaniesError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
