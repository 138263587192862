import Entity from '_entities/base/EntityNew'

export const TicketKindLinkSchema = {
    name: 'TicketKindLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class TicketKindLink extends Entity {
    get schema() {
        return TicketKindLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
    }
}

export default TicketKindLink
