import { PERMISSIONS } from '_features/permissions/constants'

import routerRoutes from '@/router/Home/Tickets/Router'

const TicketsSetupNavigator = () => import('_navigations/Home/TicketsSetup/TicketsSetupNavigator')

const TicketAdditionalCheckScreen = () => import('_screens/Home/Tickets/TicketAdditionalCheckScreen')
const TicketAdditionalCheckEditScreen = () => import('_screens/Home/Tickets/TicketAdditionalCheckEditScreen')
const TicketTypingScreen = () => import('_screens/Home/Tickets/TicketTypingScreen')

const ticketsSetupRoutes = [
    {
        path: 'tickets-setup',
        name: 'TicketsSetupNavigator',
        component: TicketsSetupNavigator,
        redirect: { name: 'ticketsRouter' },
        children: [
            ...routerRoutes,
            {
                path: 'typing',
                name: 'TicketTyping',
                component: TicketTypingScreen,
                meta: {
                    screenOptions: {
                        title: 'Типизация',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_TICKET_KIND,
                        PERMISSIONS.CAN_VIEW_TICKET_TYPE,
                    ],
                },
            },
            {
                path: 'additional-check/',
                name: 'TicketAdditionalCheck',
                component: TicketAdditionalCheckScreen,
                meta: {
                    screenOptions: {
                        title: 'Установка проверки',
                    },
                },
            },
            {
                path: 'additional-check/edit',
                name: 'TicketAdditionalCheckEdit',
                component: TicketAdditionalCheckEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Редактирование проверки',
                    },
                },
            },
        ],
    },
]

export default ticketsSetupRoutes
