import moment from 'moment'

import Entity from '_entities/base/EntityNew'

import Photo from '_entities/media/Photo'

export const UserOldSchema = {
    name: 'UserOld',
    primaryKey: 'id',
    properties: {
        createdDate: { type: 'int' }, // UNIX
        email: { type: 'string' },
        firstName: { type: 'string' },
        isUsesMobileApp: { type: 'bool' },
        id: { type: 'string' },
        lastActivity: { type: 'int' },
        lastName: { type: 'string' },
        middleName: { type: 'string' },
        photo: { type: 'Photo', entity: Photo },
        phone: { type: 'string' },
        phoneNumber: { type: 'string' },
    },
}

class UserOld extends Entity {
    get schema() {
        return UserOldSchema
    }

    get dataMap() {
        return {
            created_date: 'createdDate',
            first_name: 'firstName',
            is_uses_mobile_app: 'isUsesMobileApp',
            last_activity: 'lastActivity',
            last_name: 'lastName',
            middle_name: 'middleName',
            phone_number: 'phoneNumber',
        }
    }

    static fieldLabels = {
        createdDate: 'Дата создания',
        email: 'Email',
        firstName: 'Имя',
        isUsesMobileApp: 'Использует мобильное приложение',
        id: 'ID',
        lastActivity: 'Последняя активность',
        lastName: 'Фамилия',
        middleName: 'Отчество',
        photo: 'Аватар',
        phone: 'Телефон',
    }

    get fullName() {
        const names = []

        const lastName = this.lastName ? this.lastName.trim() : null
        const firstName = this.firstName ? this.firstName.trim() : null
        const middleName = this.middleName ? this.middleName.trim() : null

        if (lastName) {
            names.push(lastName)
        }
        if (firstName) {
            names.push(firstName)
        }
        if (middleName) {
            names.push(middleName)
        }

        return names.join(' ')
    }

    get lastActivityCaption() {
        if (this.lastActivity) {
            const now = moment()
            const last = moment.unix(this.lastActivity)

            return moment.duration(-now.diff(last), 'milliseconds').locale('ru').humanize(true)
        }

        return ''
    }
}

export default UserOld
