import Entity from '_entities/base/EntityNew'

export const TicketSourceLinkSchema = {
    name: 'TicketSourceLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class TicketSourceLink extends Entity {
    get schema() {
        return TicketSourceLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
    }
}

export default TicketSourceLink
