<template>
    <v-footer app color="white" inset>
        <slot />
    </v-footer>
</template>

<script>
    export default {
        name: 'CFooter',
    }
</script>
