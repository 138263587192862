export const sipUsers = {
    1131: 'ee239d5998cef36094603daabbde3485', // Залесова
    1157: '3fb6a7255ea38021e1cb5ae8c0b37161', // Идиятуллина
    943: '33a0a273ebfc438384e872c154c3e54f', // Чураков

    9000: 'ddd15493cbdea114d16196c6b9e0cf92', // пользователь голосования
    9001: '011ad53fcad5e3032b52ecbd29771db4', // пользователь голосования
    9002: 'e339ea5a939c45c55796d393ba22c7a5', // пользователь голосования
    9003: '18646503860d64b22f38cdf34356b5e3', // пользователь голосования
    9004: '369956aacac0069dcd290ae360164b7c', // пользователь голосования
    9005: '9302a82c20c049def7da734f424910be', // пользователь голосования
    9006: '49039dfcaa22d8f6007bd019fc1b8963', // пользователь голосования
    9007: 'ebda905507dd2c4e978200dc8b116d5e', // пользователь голосования
    9008: 'df2cc6214d60b86dd8a55fd7fb2a3c92', // пользователь голосования
    9009: 'd0a77e172ce614266c952dca2fb30c90', // пользователь голосования
    9010: 'dd372622702bf2da1279af7737019dd0', // пользователь голосования
    9011: 'a7e0303d6c15f3911447c7bbed0a4c8a', // пользователь голосования
    9012: '314b56ee794986f983d273fa7cde1cac', // пользователь голосования
    9013: '7a6941c462a55e81c7752a5ed353b226', // пользователь голосования
    9014: '859f821d827163690da01eb5560d1ce0', // пользователь голосования
    9015: 'd54ff357794546e1d294f463dde9a74a', // пользователь голосования
    9016: '9570e912480db3ff955a92683f93107d', // пользователь голосования
    9017: '7dfcc8c1a6777fdd991f88d16fbe923c', // пользователь голосования
    9018: 'e6ff1598a342bd4220c03241c0824364', // пользователь голосования
    9019: '89b83af27fd9c531889bedb04346bd60', // пользователь голосования
    9998: '5a6f2e4509b90e7fe6d2995e8617b25e', // Проведение опросов

    9999: '8d3d6402c6da6b6a5ccd1a305a3ff986', // Зиновьев

    1201: '0487240295d8288cf3aa54ede7af35e5', // Тест
    1202: '0487240295d8288cf3aa54ede7af35e5', // Тест
    1124: '0487240295d8288cf3aa54ede7af35e5', // Тест
    1129: '0487240295d8288cf3aa54ede7af35e5', // Тест
}
