import { API } from '_api'

import UserOld from '_entities/user/UserOld'

const actions = {
    async fetchCallInfo({ commit, state }, phoneNumber) {
        let resident

        if (phoneNumber) {
            commit('setPhoneNumber', phoneNumber)
        }

        try {
            const params = {
                search: state.phoneNumber,
            }

            const response = await API.v1.services.user.getResidents(params)

            if (response?.results?.length) {
                resident = new UserOld(response.results[0])
            }

            commit('fetchResident', { resident })
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

export default actions
