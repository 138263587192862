import moment from 'moment'

export const localeMoment = (params) => {
    const date = params?.date || null
    const unix = params?.unix || null

    if (date) {
        return moment(date).locale('ru')
    }

    if (unix) {
        return moment.unix(unix).locale('ru')
    }

    return moment().locale('ru')
}

export const formatDate = (unix, format) => {
    if (!unix) {
        return
    }

    let result = moment.unix(unix)

    // i18n
    result = result.locale('ru')

    if (format) {
        result = result.format(format)
    }

    return result
}

export const toUnix = (date) => {
    if (!date) {
        return
    }

    return moment(date).unix()
}
