import actions from './actions'
import mutations from './mutations'

import PhoneSettings from '_entities/call/PhoneSettings'

const state = {
    handlerTimeAtLine: null,
    handlerTimeAtIncoming: null,
    isAnsweredPhoneCall: false,
    isAllowPhoneCopy: true,
    isCallRefered: false,
    isPhoneConnecting: false,
    isIncomingPhoneCall: false,
    isMuted: false,
    isPaused: false,
    isOutgoingPhoneCall: false,
    isPhoneConnected: false,
    isPhoneStopping: false,
    connectionStartTime: 0, // UNIX, момент когда оператор вышел на линию
    phoneNumber: null,
    phoneError: null,
    phoneStatus: null,
    remoteAudio: null, // Ссылка на <audio> html элемент
    resident: null, // данные клиента входящего звонка

    ref: null, // ref ссылка на компонент переключателя, для внешнего управления

    realm: null,
    sipCall: null,
    userAgent: null,
    RTCSession: null,

    phoneSettings: new PhoneSettings({
        displayName: '1202',
        privateIdentity: '1202',
        publicIdentity: 'sip:1202@pbx.service-company.biz',
        password: '0487240295d8288cf3aa54ede7af35e5',
        realm: 'pbx.service-company.biz',
        websocketServerURL: 'wss://pbx.service-company.biz:8089/asterisk/ws',
    }),
    timeAtLine: 0,
    timeAtIncoming: 0,
    timeAtIncomingCount: 15, // Время ожидания входящего звонка до сброса
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}
