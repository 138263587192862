<template>
    <div>
        <CAttachment class="c-attachment-photo" :class="{ required: required && !photos.length }">
            <v-row align="center" justify="space-between" no-gutters>
                <v-col cols="auto">
                    {{ label }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="auto">
                    <div class="picker__item" :style="style">
                        <v-responsive aspect-ratio="1">
                            <div class="picker__placeholder" @click="selectColor" />
                        </v-responsive>
                        <div class="actions">
                            <v-btn
                                class="actions__action"
                                icon
                                @click="clearColor"
                            >
                                <v-icon v-text="`$delete`" />
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </CAttachment>

        <UIDialog ref="dialog" valid>
            <v-card flat>
                <v-card-text>
                    <v-color-picker
                        v-model="selectedColor"
                        v-bind="$attrs"
                    />
                </v-card-text>
            </v-card>
        </UIDialog>
    </div>
</template>

<script>
    import CAttachment from '_common/components/Attachments/CAttachment'

    import { UIDialog } from '_ui'

    export default {
        name: 'UIColorPicker',

        components: {
            CAttachment,
            UIDialog,
        },

        props: {
            // TODO: disabled
            disabled: { type: Boolean, default: false },
            required: { type: Boolean },
            hint: { type: String, default: null },
            label: { type: String, default: null },
            value: { type: String, default: null },
        },

        data: () => ({
            selectedColor: null,
        }),

        computed: {
            style() {
                return {
                    backgroundColor: this.model,
                }
            },

            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },
        },

        methods: {
            async selectColor() {
                this.selectedColor = this.value

                if (await this.$refs.dialog.open('Выбор цвета')) {
                    this.model = this.selectedColor?.hex || this.selectedColor
                }
            },

            clearColor() {
                this.model = null
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    .picker {
        &__item {
            border-radius:    $border-radius-root;
            border-width:     1px;
            border-style:     solid;
            border-color:     rgb(219, 219, 219);

            width:            10rem;

            display:          flex;

            overflow:         hidden;
            position:         relative;
            z-index:          1;
        }

        &__placeholder {
            cursor:          pointer;

            width:           100%;
            height:          100%;

            display:         flex;
            align-items:     center;
            justify-content: center;
        }
    }

    .actions {
        position: absolute;
        top:      map-get($spacers, 2);
        right:    map-get($spacers, 2);
        z-index:  2;
    }
</style>
