import Entity from '_entities/base/EntityNew'

export const CalendarSchema = {
    name: 'Calendar',
    primaryKey: 'id',
    properties: {
        color: { type: 'string', default: '#000000' },
        id: { type: 'string' },
        name: { type: 'string' },
        // Локальные поля (не хранятся на сервере) для работы интерфейса
        visible: { type: 'bool', default: true },
        deleted: { type: 'bool', default: false },
    },
}

class Calendar extends Entity {
    get schema() {
        return CalendarSchema
    }

    static fieldLabels = {
        color: 'Цвет',
        id: 'ID календаря',
        name: 'Наименование',
    }

    toArray() {
        return {
            color: this.color,
            name: this.name,
        }
    }
}

export default Calendar
