<template>
    <v-dialog
        v-model="isShownDialog"
        max-width="500px"
        persistent
        scrollable="false"
    >
        <v-card flat :color="bgColor">
            <v-card-text>
                <v-row dense align="center">
                    <v-col cols="9">
                        <v-card-title class="wrap-balance">
                            {{ cardTitleModel }}
                        </v-card-title>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn icon @click="hideDialog">
                            <v-icon v-text="'$minus'" />
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn icon @click="closeDialog">
                            <v-icon v-text="'$close'" />
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mb-10" />

                <v-row>
                    <v-col class="d-flex justify-center">
                        <v-avatar>
                            <img
                                v-if="applicantPhotoUrl"
                                alt="Avatar"
                                :src="applicantPhotoUrl"
                            >
                            <v-icon v-else v-text="'$userCircle'" />
                        </v-avatar>
                    </v-col>
                </v-row>

                <v-list-item
                    target="_blank"
                    :to="link"
                >
                    <v-list-item-content>
                        <FUserEditForm
                            v-if="isEditing"
                            ref="userEditForm"
                            :is-valid.sync="isValid"
                            :resident="resident"
                        />
                        <v-row v-else>
                            <v-col class="d-flex justify-center" cols="12">
                                {{ residentName }}
                            </v-col>
                            <v-col class="d-flex justify-center" cols="12">
                                {{ residentPhone }}
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    @click="createTicket"
                >
                    Создать заявку
                </v-btn>
                <v-btn
                    v-if="!isEditing && resident"
                    color="primary"
                    class="ml-5"
                    text
                    @click="editResident"
                >
                    Редактировать
                </v-btn>
                <v-btn
                    v-if="isEditing"
                    color="primary"
                    class="ml-5"
                    :disabled="!isValid"
                    text
                    @click="saveResident"
                >
                    Сохранить
                </v-btn>
                <v-btn
                    v-if="isEditing"
                    color="primary"
                    class="ml-5"
                    text
                    @click="closeResident"
                >
                    Не сохранять
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapState } from 'vuex'

    import FUserEditForm from '_features/user/user/components/FUserEditForm'

    export default {
        name: 'FIncomingCallDialog',

        components: {
            FUserEditForm,
        },

        data: () => ({
            isEditing: false,
            isValid: false,
        }),

        computed: {
            ...mapState('incomingCall', {
                resident: (state) => state.resident,
                callerId: (state) => state.callerId,
                callerPhone: (state) => state.callerPhone,
                cardColor: (state) => state.cardColor,
                cardTitle: (state) => state.cardTitle,
                isShownDialog: (state) => state.isShownDialog,
                showPhonePanel: (state) => state.showPhonePanel,
            }),

            incomingPhoneNumber() {
                return this.$store.state.sipCall.incomingPhoneNumber
            },

            residentName() {
                return this.resident?.fullName || 'Клиент не определен'
            },

            residentPhone() {
                return this.resident?.phone || this.callerPhone || this.incomingPhoneNumber || 'Телефонный номер не определен'
            },

            cardTitleModel() {
                return this.cardTitle || 'Входящий звонок'
            },

            bgColor() {
                return this.cardColor || 'white'
            },

            link() {
                if (this.resident && !this.isEditing) {
                    return { name: 'ApplicantView', params: { applicantId: this.resident.id } }
                }

                return null
            },
        },

        methods: {
            closeDialog() {
                this.$store.commit('incomingCall/resetData')
            },

            createTicket() {
                this.hideDialog()
                this.$router.push({ name: 'TicketCreate', params: { isFromCall: true } })
            },

            editResident() {
                this.isEditing = true
            },

            async saveResident() {
                await this.$refs.userEditForm.saveResident()
                this.isEditing = false
            },

            closeResident() {
                this.isEditing = false
            },

            hideDialog() {
                this.$store.commit('incomingCall/hideDialog')
            },
        },
    }
</script>

<style scoped>
    .wrap-balance {
        text-wrap: balance;
    }
</style>
