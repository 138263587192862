import { request } from '_api/v1/base/behemoth/client'

export const getIntercoms = async (params) => {
    return await request('GET', 'intercom/', params)
}
export const getIntercomById = async (intercomId) => {
    return await request('GET', `intercom/${intercomId}/`)
}

export const createIntercom = async (params) => {
    return await request('POST', 'intercom/', params)
}

export const updateIntercom = async (intercomId, params) => {
    return await request('PUT', `intercom/${intercomId}/`, params)
}

export const deleteIntercom = async (intercomId) => {
    return await request('DELETE', `intercom/${intercomId}/`)
}
