import Entity from '_entities/base/EntityNew'

import TicketSCAcceptanceChecklistItemLink from '_entities/ticket/TicketSCAcceptanceChecklistItemLink'
import TicketSCAcceptanceTypeLink from '_entities/ticket/TicketSCAcceptanceTypeLink'

export const TicketSCAcceptanceInfoSchema = {
    name: 'TicketSCAcceptanceInfo',
    properties: {
        ticketSCAcceptanceChecklistItem: { type: 'TicketSCAcceptanceChecklistItemLink', entity: TicketSCAcceptanceChecklistItemLink },
        ticketSCAcceptanceType: { type: 'TicketSCAcceptanceTypeLink', entity: TicketSCAcceptanceTypeLink },
    },
}

class TicketSCAcceptanceInfo extends Entity {
    get schema() {
        return TicketSCAcceptanceInfoSchema
    }

    get dataMap() {
        return {
            sc_acceptance_checklist_item: 'ticketSCAcceptanceChecklistItem',
            sc_acceptance_type: 'ticketSCAcceptanceType',
        }
    }

    static fieldLabels = {
        ticketSCAcceptanceChecklistItem: 'Пункт чеклиста приемки',
        ticketSCAcceptanceType: 'Тип приемки',
    }
}

export default TicketSCAcceptanceInfo
