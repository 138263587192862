import { compact as _compact, map as _map } from 'lodash'

import moment from 'moment'

import Building from '_entities/building/Building'
import Calendar from '_entities/calendar/Calendar'
import CalendarEvent from '_entities/calendar/CalendarEvent'
import Department from '_entities/department/Department'
import Entity from '_entities/base/EntityNew'
import ResidentialComplex from '_entities/residentialComplex/ResidentialComplex'
import TicketDepartment from '_entities/ticket/TicketDepartment'
import TicketKind from '_entities/ticket/TicketKind'
import TicketType from '_entities/ticket/TicketType'
import User from '_entities/user/User'

export const CalendarSlotSchema = {
    name: 'CalendarSlot',
    primaryKey: 'id',
    properties: {
        buildings: { type: 'Building[]', entity: Building, default: [] }, // с бэка приходит массив UUID
        calendar: { type: 'Calendar', entity: Calendar }, // с бэка приходит UUID
        createdBy: { type: 'User', entity: User }, // с бэка приходит UUID
        edited: { type: 'bool', default: false },
        executor: { type: 'User', entity: User }, // с бэка приходит UUID
        executorDepartament: { type: 'Department', entity: Department }, // с бэка приходит UUID
        events: { type: 'CalendarEvent[]', entity: CalendarEvent, default: [] },
        id: { type: 'string' }, // UUID
        residentialComplexes: { type: 'ResidentialComplex[]', entity: ResidentialComplex, default: [] },
        ticketDepartments: { type: 'TicketDepartment[]', entity: TicketDepartment, default: [] }, // с бэка приходит массив UUID
        ticketKinds: { type: 'TicketKind[]', entity: TicketKind, default: [] }, // с бэка приходит массив UUID
        ticketTypes: { type: 'TicketType[]', entity: TicketType, default: [] }, // с бэка приходит массив UUID
        timeEnd: { type: 'datetimeUnix' }, // UNIX
        timeStart: { type: 'datetimeUnix' }, // UNIX
    },
}

class CalendarSlot extends Entity {
    get schema() {
        return CalendarSlotSchema
    }

    get dataMap() {
        return {
            created_by: 'createdBy',
            executor_departament: 'executorDepartament',
            // TODO: Переименование сущности Project > ResidentialComplex (API)
            projects: 'residentialComplexes',
            ticket_departments: 'ticketDepartments',
            ticket_kinds: 'ticketKinds',
            ticket_types: 'ticketTypes',
            time_end: 'timeEnd',
            time_start: 'timeStart',
        }
    }

    static fieldLabels = {
        buildings: 'Дома',
        calendar: 'Календарь',
        executor: 'Исполнитель',
        executorDepartament: 'Подразделение исполнителя',
        id: 'ID слота',
        residentialComplexes: 'ЖК',
        ticketDepartments: 'Отделы',
        ticketKinds: 'Виды',
        ticketTypes: 'Типы',
        timeEnd: 'Время окончания',
        timeStart: 'Время начала',
    }

    getIdsArray() {
        const idsArray = [
            ..._map(this.buildings, (building) => building?.id),
            this.executor?.id,
            ..._map(this.residentialComplexes, (residentialComplex) => residentialComplex?.id),
            ..._map(this.ticketDepartments, (ticketDepartment) => ticketDepartment?.id),
            ..._map(this.ticketKinds, (ticketKind) => ticketKind?.id),
            ..._map(this.ticketTypes, (ticketType) => ticketType?.id),
        ]
        return _compact(idsArray)
    }

    toArray() {
        return {
            buildings: _map(this.buildings, (building) => building?.id),
            calendar: this.calendar?.id,
            executor: this.executor?.id || null,
            executor_department: this.executorDepartament?.id || this.executor?.worker?.department?.id || null,
            ticket_departments: _map(this.ticketDepartments, (ticketDepartment) => ticketDepartment.id),
            ticket_kinds: _map(this.ticketKinds, (ticketKind) => ticketKind.id),
            ticket_types: _map(this.ticketTypes, (ticketType) => ticketType.id),
            time_end: moment.utc(this.timeEnd).unix(),
            time_start: moment.utc(this.timeStart).unix(),
        }
    }
}

export default CalendarSlot
