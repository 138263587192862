<template>
    <v-card v-if="currentWorker" flat>
        <v-row>
            <v-col>
                <v-btn
                    v-if="timeEntry"
                    color="primary"
                    :disabled="isProsessing"
                    :loading="isProsessing"
                    text
                    @click="changeWorkStatus('finish')"
                >
                    Завершить работу
                </v-btn>
                <v-btn
                    v-else
                    color="primary"
                    :disabled="isProsessing"
                    :loading="isProsessing"
                    text
                    @click="changeWorkStatus('start')"
                >
                    Начать работу
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import moment from 'moment'

    import { API } from '_api'

    export default {
        name: 'UIWorkStatus',

        data: () => ({
            isProsessing: false,
        }),

        computed: {
            currentWorker() {
                return this.$store.state.auth.currentWorker
            },

            timeEntry() {
                return this.$store.state.timeManagement.timeEntry
            },
        },

        methods: {
            async changeWorkStatus(state) {
                this.isProsessing = true
                try {
                    if (state === 'start') {
                        await API.v1.services.timeManagement.startWork({
                            user: this.currentWorker?.id,
                            date_start: moment().unix(),
                        })
                    } else if (state === 'finish') {
                        await API.v1.services.timeManagement.finishWork({
                            id: this.timeEntry?.id,
                            date_finish: moment().unix(),
                        })
                    }

                    await this.$store.dispatch('timeManagement/getCurrentTimeEntry', this.currentWorker?.id)
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось изменить статус работы', e)
                    this.$logger.logException(e)
                }
                this.isProsessing = false
            },
        },
    }
</script>
