import Entity from '_entities/base/EntityNew'

export const RangeSchema = {
    name: 'Range',
    properties: {
        from: { type: 'string', default: '' },
        to: { type: 'string', default: '' },
    },
}

class Range extends Entity {
    get schema() {
        return RangeSchema
    }

    static fieldLabels = {
        from: 'с',
        to: 'по',
    }
}

export default Range
