import Entity from '_entities/base/EntityNew'

import ApprovalType from '_entities/approval/ApprovalType'
import UserLink from '_entities/user/UserLink'

export const ApprovalSchema = {
    name: 'Approval',
    primaryKey: 'id',
    properties: {
        action: { type: 'string' }, // stringed object
        applicant: { type: 'UserLink', entity: UserLink },
        approvalType: { type: 'ApprovalType', entity: ApprovalType },
        comment: { type: 'string' },
        id: { type: 'string' }, // UUID
        entityId: { type: 'string' },
        entityInfo: { type: 'string' },
        entityTitle: { type: 'string' },
        entityType: { type: 'string' },
        rejectionComment: { type: 'string' },
        responsible: { type: 'UserLink', entity: UserLink },
        status: { type: 'string' },
        title: { type: 'string' },
    },
}

class Approval extends Entity {
    get schema() {
        return ApprovalSchema
    }

    get dataMap() {
        return {
            approval_type: 'approvalType',
            entity_id: 'entityId',
            entity_info: 'entityInfo',
            entity_title: 'entityTitle',
            entity_type: 'entityType',
            rejection_comment: 'rejectionComment',
        }
    }

    static fieldLabels = {
        action: 'Изменения',
        applicant: 'Заявитель',
        approvalType: 'Тип согласования',
        comment: 'Комментарий',
        entityId: 'ID сущности',
        entityInfo: 'Информация',
        entityType: 'Тип сущности',
        rejectionComment: 'Комментарий отказа',
        responsible: 'Ответственный',
        status: 'Статус',
        title: 'Заголовок',
    }
}
export default Approval
