import Entity from '_entities/base/EntityNew'

export const CreatedByDocumentSchema = {
    name: 'CreatedByDocument',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        lastLogin: { type: 'string' },
        phone: { type: 'string' },
        userId: { type: 'string' },
    },
}

class CreatedByDocument extends Entity {
    get schema() {
        return CreatedByDocumentSchema
    }

    get dataMap() {
        return {
            last_login: 'lastLogin',
            user_id: 'userId',
        }
    }

    get fullName() {
        const names = []

        const lastName = this.lastName ? this.lastName.trim() : null
        const firstName = this.firstName ? this.firstName.trim() : null
        const middleName = this.middleName ? this.middleName.trim() : null

        if (lastName) {
            names.push(lastName)
        }
        if (firstName) {
            names.push(firstName)
        }
        if (middleName) {
            names.push(middleName)
        }

        return names.join(' ')
    }
}

export default CreatedByDocument
