import { request } from '_api/v1/base/behemoth/client'

export const getKeys = async (params) => {
    return await request('GET', 'key/', params)
}

export const getKeyById = async (keyId) => {
    return await request('GET', `key/${keyId}/`)
}

export const getArchiveKeys = async (params) => {
    return await request('GET', 'key/archive/', params)
}

export const deleteKey = async (keyId) => {
    return await request('DELETE', `key/${keyId}/`)
}

export const updateKey = async (keyId, params) => {
    return await request('PUT', `key/${keyId}/`, params)
}

export const addKey = async (params) => {
    return await request('POST', 'key/', params)
}

export const returnKeyToOwner = async (keyId, params) => {
    return await request('PUT', `key/return_to_owner/${keyId}/`, params)
}

export const lostKey = async (keyId, params) => {
    return await request('PUT', `key/lost/${keyId}/`, params)
}

export const getKeyHistory = async (params) => {
    return await request('GET', 'storage_history/', params)
}
