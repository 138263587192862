import { map as _map } from 'lodash'

import Entity from '_entities/base/EntityNew'

import Photo from '_entities/media/Photo'
import UserLink from '_entities/user/UserLink'

export const ServicePartnerCompanySchema = {
    name: 'ServicePartnerCompany',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        logo: { type: 'Photo', entity: Photo },
        name: { type: 'string' },
        users: { type: 'UserLink[]', entity: UserLink, default: () => [] },
    },
}

class ServicePartnerCompany extends Entity {
    get schema() {
        return ServicePartnerCompanySchema
    }

    static fieldLabels = {
        id: 'ID',
        logo: 'Логотип',
        name: 'Название',
        users: 'Ползователи',
    }

    toServer() {
        return {
            logo: this.logo?.id,
            name: this.name,
            users: _map(this.users, (user) => {
                return user.id
            }),
        }
    }
}

export default ServicePartnerCompany
