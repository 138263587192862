import ServiceElement from '_entities/service/ServiceElement'

export const ServiceElementSendMessageSchema = {
    elementType: { type: 'text', default: 'send_message' },
    deliveryMethod: { type: 'string' }, // String: 'push' || 'chat'
    sendSms: { type: 'bool', default: false },
    smsText: { type: 'text' },
    text: { type: 'text' },
}

class ServiceElementSendMessage extends ServiceElement {
    get schema() {
        return {
            ...super.schema.name,
            ...super.schema.primaryKey,
            properties: {
                ...super.schema.properties,
                ...ServiceElementSendMessageSchema,
            },
        }
    }

    get dataMap() {
        return {
            ...super.dataMap,
            element_type: 'elementType',
            delivery_method: 'deliveryMethod',
            send_sms: 'sendSms',
            sms_text: 'smsText',
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
        deliveryMethod: 'Метод доставки',
        sendSms: 'Отправлять СМС при отсутсвии МПК',
        smsText: 'Текст СМС-сообщения',
        text: 'Текст сообщения',
    }

    toServer() {
        return {
            ...super.toServer(this),
            delivery_method: this.deliveryMethod,
            send_sms: this.sendSms,
            sms_text: this.smsText,
            text: this.text,
        }
    }
}

export default ServiceElementSendMessage
