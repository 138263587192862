const mutations = {
    show(state) {
        state.isShown = true
        state.hasNew = false
    },

    hide(state) {
        state.isShown = false
    },

    hasNewMessages(state, value) {
        state.hasNew = value
    },
}

export default mutations
