import Entity from '_entities/base/EntityNew'

export const BuildingSectionLinkSchema = {
    name: 'BuildingSectionLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' }, // UUID
        name: { type: 'string' },
        number: { type: 'string' },
    },
}

class BuildingSectionLink extends Entity {
    get schema() {
        return BuildingSectionLinkSchema
    }

    static fieldLabels = {
        name: 'Наименование',
        number: 'Номер',
    }
}

export default BuildingSectionLink
