import { API } from '_api'

import Building from '_entities/building/Building'

const actions = {
    async fetchBuildings({ commit, state }, options) {
        if (!state.isFetching) {
            commit('fetchBuildingsRequest')

            try {
                const response = await API.v1.services.building.getBuildings(options)

                const { results } = response

                const items = []

                if (results) {
                    results?.forEach((result) => {
                        items.push(new Building(result))
                    })
                }

                commit('fetchBuildingsSuccess', { items })
            } catch (e) {
                commit('fetchBuildingsError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
