import Entity from '_entities/base/EntityNew'

export const DocSchema = {
    name: 'Doc',
    primaryKey: 'id',
    properties: {
        extension: { type: 'string' },
        id: { type: 'string' },
        mimeType: { type: 'string' },
        name: { type: 'string' },
        url: { type: 'string' },
    },
}

class Doc extends Entity {
    get schema() {
        return DocSchema
    }

    get dataMap() {
        return {
            file_name: 'name',
        }
    }

    static fieldLabels = {
        extension: 'Расширение',
        id: 'ID',
        mimeType: 'MIME тип',
        name: 'Название',
        url: 'URL',
    }

    fileType() {
        switch (this.mimeType) {
            case 'image/jpeg':
            case 'image/png':
                return 'photo'
            case 'application/pdf':
                return 'doc'
            default:
                return 'file'
        }
    }
}

export default Doc
