<template>
    <v-autocomplete
        v-model="model"
        v-bind="$attrs"
        clearable
        :disabled="$attrs.disabled || false"
        :hide-details="$attrs.hideDetails || false"
        :items="ticketKinds"
        item-text="name"
        item-value="id"
        :label="label"
        :loading="isFetching"
        :multiple="multiple"
        outlined
        placeholder="Начните ввод.."
        :readonly="$attrs.readonly"
        return-object
        :rules="$attrs.rules || false"
        :search-input.sync="search"
        @focus.once="handleFetchData"
        @keydown="fetchData"
        @click:clear="fetchData"
    />
</template>

<script>
    import { debounce as _debounce, filter as _filter, forEach as _forEach } from 'lodash'
    import { mapGetters, mapState } from 'vuex'

    export default {
        name: 'FTicketKindsSelect',

        props: {
            filter: { type: Object, default: null },
            label: { type: String, default: 'Вид заявки' },
            multiple: { type: Boolean },
            value: { type: [Array, Object], default: null },
        },

        data: () => ({
            search: null,
        }),

        computed: {
            ...mapState('ticketKinds', {
                isFetching: (state) => state.isFetching,
            }),

            ...mapGetters({
                getTicketKinds: 'ticketKinds/getTicketKinds',
            }),

            model: {
                get() {
                    return this.value
                },
                set(value) {
                    if (value) {
                        if (this.multiple) {
                            const ticketKindIds = []

                            _forEach(value, (item) => {
                                if (item.name) {
                                    this.$store.commit('ticketKinds/addedTicketKind', item)
                                } else {
                                    ticketKindIds.push(item.id)
                                }
                            })

                            if (ticketKindIds.length) {
                                this.handleFetchData({ id__in: ticketKindIds })
                            }
                        } else {
                            if (value.name) {
                                this.$store.commit('ticketKinds/addedTicketKind', value)
                            } else {
                                this.handleFetchData({ id__in: [value.id] })
                            }
                        }
                    }

                    this.$emit('input', value)
                },
            },

            ticketKinds() {
                let ticketKinds = this.getTicketKinds

                if (this.filter) {
                    if (typeof this.filter === 'function') {
                        //
                    } else {
                        _forEach(this.filter, (filterItem) => {
                            ticketKinds = _filter(ticketKinds, filterItem)
                        })
                    }
                }

                if (this.multiple && this.value?.length) {
                    ticketKinds = [...ticketKinds, ...this.value]
                } else if (!this.multiple && this.value) {
                    ticketKinds = [...ticketKinds, this.value]
                }

                return ticketKinds
            },
        },

        watch: {
            value: {
                handler() {
                    this.search = null
                },
            },
        },

        created() {
            this.fetchData = _debounce(() => {
                const search = this.search

                if (!this.isFetching && search) {
                    const filters = {
                        search,
                    }

                    this.handleFetchData(filters)
                }
            }, 800)
        },

        methods: {
            handleFetchData(filters) {
                const params = {
                    page: 1,
                    page_size: Number.MAX_SAFE_INTEGER,
                }

                this.$store.dispatch('ticketKinds/fetchTicketKinds', { ...filters, ...params })
            },
        },
    }
</script>
