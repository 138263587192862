import router from '@/router'
import * as Sentry from '@sentry/vue'

export default {
    install: (Vue, options) => {
        const dsn = localStorage.getItem('dsn') || 'https://4008b32ee527a1cdbe8764ddba8843e0@sentry-new.itstrana.site/76'

        Sentry.init({
            Vue,
            dsn: dsn,
            environment: process.env.NODE_ENV === 'production' && process.env.VUE_APP_ENV === 'production' ? 'production' : 'development',
            integrations: [
                new Sentry.BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                }),
                new Sentry.Replay(),
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            // tracesSampleRate: 1.0,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', process.env.VUE_APP_API_BEHEMOTH_HOST],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            // replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,

            beforeSend(event, hint) {
                const error = hint?.originalException

                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.log('Sentry > event', error?.message)

                    return null
                }

                // Не отправлять в Sentry
                if (error?.message?.match(/Network Error/i)) {
                    return null
                }

                return event
            },
        })

        Vue.prototype.$logger = {
            logException(error) {
                Sentry.captureException(error)
            },

            logEvent(data) {
                if (process.env.NODE_ENV === 'production') {
                    const id = data?.id || '85778472'
                    const goal = data?.goal
                    const params = data?.params

                    if (params) {
                        const strParams = JSON.stringify(params)

                        // eslint-disable-next-line no-eval
                        eval(`ym(${id},'reachGoal','${goal}',${strParams})`)
                    } else {
                        // eslint-disable-next-line no-eval
                        eval(`ym(${id},'reachGoal','${goal}')`)
                    }
                } else {
                    // eslint-disable-next-line no-console
                    console.log('Metrics >', data?.goal)
                }
            },
        }
    },
}
