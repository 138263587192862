<template>
    <div class="l-section">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'CSection',
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/variables';
</style>
