import { request } from '_api/v1/base/behemoth/client'

export const getDutySchedules = async (params) => {
    return await request('GET', 'department/duty_schedule/', params)
}

export const getDutyScheduleById = async (dutyId) => {
    return await request('GET', `department/duty_schedule/${dutyId}/`)
}

export const updateDutySchedule = async (dutyId, data) => {
    return await request('PUT', `department/duty_schedule/${dutyId}/`, data)
}

export const createDutySchedule = async (data) => {
    return await request('POST', 'department/duty_schedule/', data)
}

export const deleteDutySchedule = async (dutyId) => {
    return await request('DELETE', `department/duty_schedule/${dutyId}/`)
}
