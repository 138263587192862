import { API } from '_api'

const actions = {
    async getSentryDSN({ commit }) {
        try {
            const response = await API.v1.services.sync.getSentryDSN({
                app_id: 'd507ca01-1514-43e2-86ee-e2993aaf624b', // SCW
                platform: 'vue',
            })

            const { dsn } = response

            if (dsn) {
                await localStorage.setItem('dsn', dsn)
            }
        } catch (e) {
        }
    },
}

export default actions
