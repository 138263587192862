import { PERMISSIONS } from '_features/permissions/constants'

const TicketsNavigator = () => import('_navigations/Home/Tickets/TicketsNavigator')

const TicketAlmostOverdueScreen = () => import('_screens/Home/Tickets/TicketAlmostOverdueScreen')
const TicketApprovalScreen = () => import('_screens/Home/Tickets/TicketApprovalScreen')
const TicketCreatedByMeScreen = () => import('_screens/Home/Tickets/TicketCreatedByMeScreen')
const TicketCreateScreen = () => import('_screens/Home/Tickets/TicketCreateScreen')
const TicketOnAuditorScreen = () => import('_screens/Home/Tickets/TicketOnAuditorScreen')
const TicketOnCheckScreen = () => import('_screens/Home/Tickets/TicketOnCheckScreen')
const TicketOnContractScreen = () => import('_screens/Home/Tickets/TicketOnContractScreen')
const TicketOnInWorkScreen = () => import('_screens/Home/Tickets/TicketOnInWorkScreen')
const TicketOnNewScreen = () => import('_screens/Home/Tickets/TicketOnNewScreen')
const TicketOnNeedExecutorScreen = () => import('_screens/Home/Tickets/TicketOnNeedExecutorScreen')
const TicketOverdueScreen = () => import('_screens/Home/Tickets/TicketOverdueScreen')
const TicketServiceCalendarScreen = () => import('_screens/Home/Tickets/Service/TicketServiceCalendarScreen')
const TicketsScreen = () => import('_screens/Home/Tickets/TicketsScreen')
const TicketViewScreen = () => import('_screens/Home/Tickets/TicketViewScreen')

const ApplicantViewScreen = () => import('_screens/Home/Tickets/ApplicantViewScreen')
const ApplicantEditScreen = () => import('_screens/Home/Tickets/ApplicantEditScreen')

const ticketsRoutes = [
    {
        path: 'tickets',
        name: 'TicketsNavigator',
        redirect: { name: 'Tickets' },
        component: TicketsNavigator,
        children: [
            {
                path: 'list',
                name: 'Tickets',
                component: TicketsScreen,
                meta: {
                    screenOptions: {
                        title: 'Заявки',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
            {
                path: 'create/',
                name: 'TicketCreate',
                component: TicketCreateScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Новая заявка',
                    },
                    permissions: [PERMISSIONS.CAN_ADD_TICKET],
                },
            },
            {
                path: 'view/:ticketId/',
                name: 'TicketView',
                component: TicketViewScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Заявка',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
            {
                path: 'service-calendar/',
                component: TicketServiceCalendarScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Заявка из сервиса',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
            {
                path: 'on-new',
                name: 'TicketOnNew',
                component: TicketOnNewScreen,
                meta: {
                    screenOptions: {
                        title: 'Новые',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
            {
                path: 'need-executor',
                name: 'TicketOnNeedExecutor',
                component: TicketOnNeedExecutorScreen,
                meta: {
                    screenOptions: {
                        title: 'Назначить исполнителя',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
            {
                path: 'contract',
                name: 'TicketOnContract',
                component: TicketOnContractScreen,
                meta: {
                    screenOptions: {
                        title: 'Договор',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
            {
                path: 'in-work',
                name: 'TicketOnInWork',
                component: TicketOnInWorkScreen,
                meta: {
                    screenOptions: {
                        title: 'В работе',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
            {
                path: 'on-check',
                name: 'TicketOnCheck',
                component: TicketOnCheckScreen,
                meta: {
                    screenOptions: {
                        title: 'На проверке',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
            {
                path: 'approval',
                name: 'TicketApproval',
                component: TicketApprovalScreen,
                meta: {
                    screenOptions: {
                        title: 'Согласование',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
            {
                path: 'created-by-me',
                name: 'TicketCreatedByMe',
                component: TicketCreatedByMeScreen,
                meta: {
                    screenOptions: {
                        title: 'Я заявитель',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
            {
                path: 'almost-overdue',
                name: 'TicketAlmostOverdue',
                component: TicketAlmostOverdueScreen,
                meta: {
                    screenOptions: {
                        title: 'Почти просроченные',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
            {
                path: 'overdue',
                name: 'TicketOverdue',
                component: TicketOverdueScreen,
                meta: {
                    screenOptions: {
                        title: 'Просроченные',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
            {
                path: 'auditor',
                name: 'TicketAuditor',
                component: TicketOnAuditorScreen,
                meta: {
                    screenOptions: {
                        title: 'Наблюдаю',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_TICKET,
                        PERMISSIONS.CAN_VIEW_OWN_DEPARMENT_TICKET,
                    ],
                },
            },
        ],
    },

    {
        path: 'applicants',
        component: TicketsNavigator,
        children: [
            {
                path: 'view/:applicantId/',
                name: 'ApplicantView',
                component: ApplicantViewScreen,
                meta: {
                    screenOptions: {
                        title: 'Заявитель',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_USER],
                },
            },
            {
                path: 'edit/:applicantId/',
                name: 'ApplicantEdit',
                component: ApplicantEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Редактирование заявителя',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_USER],
                },
            },
        ],
    },
]

export default ticketsRoutes
