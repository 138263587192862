import { request } from '_api/v1/base/behemoth/client'

export const getLegalDocuments = async (params) => {
    return await request('GET', 'document/', params)
}

export const getLegalDocumentById = async (docId) => {
    return await request('GET', `document/${docId}/`)
}

export const updateLegalDocument = async ({ docId, data }) => {
    return await request('PUT', `document/${docId}/`, data)
}
