<template>
    <v-select
        v-model="model"
        v-bind="$attrs"
        clearable
        outlined
    />
</template>

<script>
    export default {
        name: 'UISelect',

        props: {
            value: { type: String, default: null },
        },

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },
        },
    }
</script>
