export const declension = (value, declensions) => {
    declensions = declensions || ['элемент', 'элемента', 'элементов']

    if (declensions.length === 3) {
        const condition1 = value % 10 === 1 && value % 100 !== 11 // Элемент
        const condition2 = value % 10 >= 2 && value % 10 <= 4 // Элемента
        const condition3 = value % 100 < 10 || value % 100 >= 20 // Элементов

        return value + ' ' + declensions[condition1 ? 0 : condition2 && condition3 ? 1 : 2]
    }

    return false
}

export const clearSpaces = (value) => {
    if (!value) {
        return
    }

    // чистка от двойных пробелов
    value = value?.replace(/\s{2}/g, ' ') || ''

    return value?.trim() || ''
}
