import Entity from '_entities/base/EntityNew'

import FileImage from '_entities/media/file/FileImage'

export const ServiceSectionSchema = {
    name: 'ServiceSection',
    primaryKey: 'id',
    properties: {
        backgroundColor: { type: 'string' },
        icon: { type: 'FileImage', entity: FileImage },
        isVisibleOnMainPage: { type: 'bool', default: false },
        id: { type: 'string' },
        name: { type: 'string' },
        order: { type: 'int' },
    },
}

class ServiceSection extends Entity {
    get schema() {
        return ServiceSectionSchema
    }

    get dataMap() {
        return {
            background_color: 'backgroundColor',
            is_visible_on_main_page: 'isVisibleOnMainPage',
        }
    }

    static fieldLabels = {
        backgroundColor: 'Цвет фона',
        icon: 'Иконка',
        id: 'ID',
        isVisibleOnMainPage: 'Видимость на главной странице',
        name: 'Название',
        order: 'Порядок',
    }

    toServer() {
        return {
            background_color: this.backgroundColor,
            is_visible_on_main_page: this.isVisibleOnMainPage,
            icon: this.icon?.id || null,
            name: this.name,
            order: this.order,
        }
    }
}

export default ServiceSection
