<template>
    <div>
        <v-card v-if="isView">
            <v-list v-if="documents && documents.length > 0" class="px-4">
                <v-list-item-content
                    v-for="document in documents"
                    :key="`UIAttachmentResponseDocsView_${document.id}`"
                    class="py-2"
                >
                    <v-card class="pa-2">
                        <v-card-subtitle class="subtitle-2 pa-0 mb-2">
                            {{ document.number }}
                            {{ document.dateOfDocument && ` - ${formatDate(document.dateOfDocument, 'DD.MM.YYYY')}` }}
                        </v-card-subtitle>
                        <a
                            v-if="document.file && document.file.url"
                            download="download"
                            :href="document.file.url"
                            :target="/(jpe?g|gif|png)/.test(document.file.name) ?'_blank' : ''"
                        >
                            {{ document.file.name }}
                        </a>
                        <v-card-subtitle v-else class="pa-0">
                            Файл отсутствует
                        </v-card-subtitle>
                    </v-card>
                </v-list-item-content>
            </v-list>
            <v-card-subtitle v-else class="d-flex justify-center pa-4">
                Ответы на обращение отсутствуют
            </v-card-subtitle>
        </v-card>
        <CAttachment v-else>
            <v-row align="center" justify="space-between" no-gutters>
                <v-col cols="auto">
                    {{ label }}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col
                    v-for="document in documents"
                    :key="`UIAttachmentResponseDocs_${document.id}`"
                    cols="12"
                >
                    <v-card class="pa-4">
                        <v-card-subtitle class="pa-0 mb-2">
                            {{ document.number }}
                        </v-card-subtitle>
                        <div class="mb-2">
                            <UIDatePicker
                                v-model="document.dateOfDocument"
                                label="Дата ответа на обращение"
                                hide-details
                                @change="updateDateDock(document.id, document.dateOfDocument)"
                            />
                        </div>
                        <div v-if="document.file">
                            <UIAttachmentDocItem
                                class="c-attachment-doc__item"
                                :value="document.file"
                                @click-remove="removeDock(document)"
                            />
                        </div>
                        <v-btn
                            v-else
                            color="primary"
                            :disabled="document.isLoading"
                            :loading="document.isLoading"
                            text
                            @click="addDock(document)"
                        >
                            Добавить документ
                        </v-btn>
                        <v-btn class="delete" icon title="Удалить бронь" @click="bookRemove(document.id)">
                            <v-icon v-text="'$delete'" />
                        </v-btn>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-btn
                        color="primary"
                        :disabled="isUploading"
                        :loading="isUploading"
                        text
                        @click="bookDocument"
                    >
                        Сформировать номер исходящего ответа
                    </v-btn>
                </v-col>
            </v-row>
        </CAttachment>

        <UIConfirmDialog ref="confirm" />
    </div>
</template>

<script>
    import { map as _map, filter as _filter } from 'lodash'
    import { formatDate, toUnix } from '@/plugins/moment'

    import { API } from '_api'

    import DocumentAppealResponse from '_entities/document/DocumentAppealResponse'

    import CAttachment from '_common/components/Attachments/CAttachment'

    import UIAttachmentDocItem from '_ui/attachments/UIAttachmentDocItem'
    import UIConfirmDialog from '_ui/UIConfirmDialog'
    import UIDatePicker from '_ui/forms/UIDatePicker'

    import { selectFiles } from '_utils/file'

    export default {
        name: 'UIAttachmentResponseDocs',

        components: {
            CAttachment,
            UIAttachmentDocItem,
            UIConfirmDialog,
            UIDatePicker,
        },

        props: {
            appealDocumentId: { type: String, required: true },
            label: {
                type: String,
                required: true,
            },
            isView: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            documents: null,
            isUploading: false,
        }),

        computed: {
            params() {
                return {
                    date_of_document: formatDate(toUnix(new Date()), 'YYYY-MM-DD'),
                    appeal_document_id__in: [this.appealDocumentId],
                    ordering: 'id',
                }
            },
        },

        async mounted() {
            await this.fetchDock()
        },

        methods: {
            formatDate,

            async addDock(document) {
                try {
                    const selectedFile = await selectFiles({
                        multiple: false,
                    })
                    document.isLoading = true
                    const file = new FormData()
                    file.append('files', selectedFile[0])

                    let newFile = await API.v1.services.documentFile.uploadDocumentFile(file)

                    if (newFile.length > 0) {
                        newFile = newFile[0]
                        await API.v1.services.appealDocument.updateDocumentAppealResponse(document.id, { file: newFile.id })
                        document.file = newFile
                    }
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось загрузить файл', e)
                    this.$logger.logException(e)
                } finally {
                    document.isLoading = false
                }
            },

            async bookDocument() {
                try {
                    this.isUploading = true
                    await API.v1.services.appealDocument.createDocumentAppealResponse(this.params)
                    await this.fetchDock()
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось создать забронированное поле', e)
                    this.$logger.logException(e)
                } finally {
                    this.isUploading = false
                }
            },

            bookRemove(id) {
                this.$refs.confirm.open(
                    'Удалить забронированное поле',
                    'Вы уверены, что хотите удалить забронированное поле?',
                    {
                        onOk: async () => {
                            try {
                                await API.v1.services.appealDocument.deleteDocumentAppealResponse(id)
                                this.documents = _filter(this.documents, (document) => document.id !== id)
                            } catch (e) {
                                this.$root.$pushRequestNotifications('Не удалось удалить забронированное поле', e)
                                this.$logger.logException(e)
                            }
                        },
                    },
                )
            },

            async fetchDock() {
                try {
                    const { results } = await API.v1.services.appealDocument.getDocumentAppealResponses(this.params)
                    this.documents = _map(results, (result) => {
                        const document = new DocumentAppealResponse(result)
                        document.isLoading = false
                        return document
                    })
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить список документов', e)
                    this.$logger.logException(e)
                }
            },

            removeDock(document) {
                this.$refs.confirm.open(
                    'Удалить документ',
                    'Вы уверены, что хотите удалить документ?',
                    {
                        onOk: async () => {
                            try {
                                await API.v1.services.appealDocument.updateDocumentAppealResponse(document.id, { file: null })
                                document.file = null
                            } catch (e) {
                                this.$root.$pushRequestNotifications('Не удалось удалить документ', e)
                                this.$logger.logException(e)
                            }
                        },
                    },
                )
            },

            async updateDateDock(id, date) {
                try {
                    await API.v1.services.appealDocument.updateDocumentAppealResponse(id, { date_of_document: formatDate(date, 'YYYY-MM-DD') })
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось обновить дату документа', e)
                    this.$logger.logException(e)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .delete {
        position: absolute;
        top: 5px;
        right: 5px;
    }
</style>
