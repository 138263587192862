<template>
    <div>
        <v-select
            v-model="calendarVacancyDate"
            clearable
            :items="calendarVacancyDates"
            item-text="text"
            item-value="value"
            label="Дата работ"
            outlined
            return-object
        />
        <v-select
            v-model="model"
            v-bind="$attrs"
            clearable
            :items="calendarVacanciesByDate"
            item-text="text"
            item-value="value"
            label="Время работ"
            outlined
            :rules="[isRequiredEvent]"
            return-object
        />
    </div>
</template>

<script>
    import {
        filter as _filter,
        map as _map,
        orderBy as _orderBy,
        uniq as _uniq,
    } from 'lodash'

    import { API } from '_api'

    import Building from '_entities/building/Building'
    import Calendar from '_entities/calendar/Calendar'
    import TicketDepartment from '_entities/ticket/TicketDepartment'
    import TicketKind from '_entities/ticket/TicketKind'
    import TicketType from '_entities/ticket/TicketType'

    export default {
        name: 'FCalendarVacancyTimeSelect',

        props: {
            value: { type: Object, default: null },
            building: { type: Building, required: true },
            required: { type: Boolean, default: false },
            ticketDepartment: { type: TicketDepartment, required: true },
            ticketKind: { type: TicketKind, required: true },
            ticketType: { type: TicketType, required: true },
        },

        data: () => ({
            calendarVacancies: [],
            calendarVacancyDate: null,
        }),

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            calendarVacancyDates() {
                const allDates = _map(this.calendarVacancies, (vacansy) => vacansy.date)
                const dates = _uniq(allDates)
                return _orderBy(dates)
            },

            calendarVacanciesByDate() {
                return this.calendarVacancyDate
                    ? _filter(this.calendarVacancies, (vacancy) => vacancy.date === this.calendarVacancyDate)
                    : this.calendarVacancies
            },

            isRequiredEvent() {
                const isEventSelected = this.model !== null && this.model !== undefined

                return this.required === false || (this.required && isEventSelected) || 'Обязательное поле'
            },
        },

        watch: {
            ticketType: {
                handler(ticketType) {
                    if (ticketType) {
                        this.model = null
                        this.calendarVacancies = []

                        this.fetchCalendarSlots()
                    }
                },
                deep: true,
            },

            building: {
                handler() {
                    this.model = null
                    this.calendarVacancies = []

                    this.fetchCalendarSlots()
                },
                deep: true,
            },
        },

        mounted() {
            this.fetchCalendarSlots()
        },

        methods: {
            async fetchCalendarSlots() {
                if (!this.ticketType?.id) {
                    return
                }

                const response = await API.v1.services.ticketType.getTicketTypeById(this.ticketType?.id)
                const ticketType = new TicketType(response)

                const hasTyping = this.ticketDepartment?.id && this.ticketKind?.id && this.ticketType?.id
                const hasLocation = this.building?.id
                const assignmentType = ticketType.assignmentType

                let calendar = null

                if (hasTyping && hasLocation && assignmentType) {
                    try {
                        const calendars = await API.v1.services.calendar.getCalendars()

                        if (calendars?.results?.length > 0) {
                            calendar = new Calendar(calendars.results[0])
                        }

                        if (calendar) {
                            const params = {
                                assignment_type: assignmentType,
                                building: this.building.id,
                                ticket_department: this.ticketDepartment.id,
                                ticket_kind: this.ticketKind.id,
                                ticket_type: this.ticketType.id,
                            }

                            const response = await API.v1.services.calendar.getCalendarSlotVacancies(calendar?.id, params)

                            if (response) {
                                this.calendarVacancies = response || []
                            }
                        }
                    } catch (e) {
                        this.$root.$pushRequestNotifications('Не удалось получить доступное время работ', e)
                        this.$logger.logException(e)
                    }
                }
            },
        },
    }
</script>
