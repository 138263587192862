<template>
    <v-row dense>
        <v-col v-for="doc in docs" :key="`doc_${doc.id}`" cols="12">
            <UIMediaDoc :value="doc" />
        </v-col>
    </v-row>
</template>

<script>
    import UIMediaDoc from '_ui/media/UIMediaDoc'

    export default {
        name: 'UIMediaDocs',

        components: {
            UIMediaDoc,
        },

        props: {
            value: { type: Array, default: () => [] },
        },

        computed: {
            docs() {
                return this.value
            },
        },
    }
</script>
