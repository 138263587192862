import Entity from '_entities/base/EntityNew'

import TicketInspectionChecklistItemLink from '_entities/ticket/TicketInspectionChecklistItemLink'
import TicketInspectionTypeLink from '_entities/ticket/TicketInspectionTypeLink'

export const TicketInspectionInfoSchema = {
    name: 'TicketInspectionInfo',
    properties: {
        ticketInspectionChecklistItem: { type: 'TicketInspectionChecklistItemLink', entity: TicketInspectionChecklistItemLink },
        ticketInspectionType: { type: 'TicketInspectionTypeLink', entity: TicketInspectionTypeLink },
    },
}

class TicketInspectionInfo extends Entity {
    get schema() {
        return TicketInspectionInfoSchema
    }

    get dataMap() {
        return {
            inspection_checklist_item: 'ticketInspectionChecklistItem',
            inspection_type: 'ticketInspectionType',
        }
    }

    static fieldLabels = {
        ticketInspectionChecklistItem: 'Пункт чеклиста обхода',
        ticketInspectionType: 'Тип обхода',
    }
}

export default TicketInspectionInfo
