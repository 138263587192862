import { request } from '_api/v1/base/behemoth/client'

// Фотоотчеты

export const getPhotoReports = async (params) => {
    return await request('GET', 'sc_report/photo_report/', params)
}

export const getPhotoReportById = async (photoReportId) => {
    return await request('GET', `sc_report/photo_report/${photoReportId}/`)
}

export const updatePhotoReport = async (photoReportId, params) => {
    return await request('PUT', `sc_report/photo_report/${photoReportId}/`, params)
}

// Отчеты

export const getMonthReports = async (params) => {
    return await request('GET', 'sc_report/', params)
}

export const getMonthReportById = async (monthReportId) => {
    return await request('GET', `sc_report/${monthReportId}/`)
}

export const publishMonthReport = async (monthReportId) => {
    return await request('GET', `sc_report/${monthReportId}/publish/`)
}
