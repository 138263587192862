import { API } from '_api'

import ServiceDiscountCard from '_entities/service/ServiceDiscountCard'

const actions = {
    async fetchServiceDiscountCard({ commit, state }, { serviceDiscountCardId }) {
        if (!state.isFetching) {
            commit('fetchServiceDiscountCardRequest')

            try {
                const response = await API.v1.services.service.getServiceDiscountCardById(serviceDiscountCardId)

                const item = response ? new ServiceDiscountCard(response) : null

                commit('fetchServiceDiscountCardSuccess', { item })
            } catch (e) {
                commit('fetchServiceDiscountCardError', e)

                return Promise.reject(e)
            }
        }
    },

    async saveServiceDiscountCard({ commit, state }, { serviceDiscountCard }) {
        if (!state.isSaving) {
            commit('saveServiceDiscountCardRequest')

            try {
                const serviceDiscountCardId = serviceDiscountCard.id
                const data = serviceDiscountCard.toServer()

                if (serviceDiscountCardId) {
                    await API.v1.services.service.updateServiceDiscountCard({ serviceDiscountCardId, data })
                } else {
                    await API.v1.services.service.createServiceDiscountCard({ data })
                }

                commit('saveServiceDiscountCardSuccess')
            } catch (e) {
                commit('saveServiceDiscountCardError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
