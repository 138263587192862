import Entity from '_entities/base/EntityNew'

import CreatedByDocument from '_entities/document/CreatedByDocument'
import Doc from '_entities/media/Doc'

export const DocumentAppealResponseSchema = {
    name: 'DocumentAppealResponse',
    primaryKey: 'id',
    properties: {
        appealDocumentId: { type: 'int' },
        createdAt: { type: 'string' },
        createdBy: { type: 'CreatedByDocument', entity: CreatedByDocument },
        dateOfDocument: { type: 'int' },
        file: { type: 'Doc', entity: Doc },
        id: { type: 'int' },
        number: { type: 'string' },
        updatedAt: { type: 'string' },
    },
}

class DocumentAppealResponse extends Entity {
    get schema() {
        return DocumentAppealResponseSchema
    }

    get dataMap() {
        return {
            appeal_document_id: 'appealDocumentId',
            created_at: 'createdAt',
            created_by: 'createdBy',
            date_of_document: 'dateOfDocument',
            updated_at: 'updatedAt',
        }
    }

    static fieldLabels = {}

    toServer() {
        return {}
    }
}

export default DocumentAppealResponse
