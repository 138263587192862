import Entity from '_entities/base/EntityNew'

export const TicketCancelReasonSchema = {
    name: 'TicketCancelReason',
    properties: {
        code: { type: 'string' },
        name: { type: 'string' },
        order: { type: 'int' },
    },
}

class TicketCancelReason extends Entity {
    get schema() {
        return TicketCancelReasonSchema
    }

    static fieldLabels = {
        code: 'Код',
        name: 'Наименование',
    }
}

export default TicketCancelReason
