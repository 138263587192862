import { request } from '_api/v1/base/behemoth/client'

export const getDepartments = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'department/post/departments/', filters, { params })
}

export const getDepartmentById = async (departmentId) => {
    return await request('GET', `department/${departmentId}`)
}

export const updateDepartment = async ({ departmentId, data }) => {
    return await request('PUT', `department/${departmentId}/`, data)
}

export const createDepartment = async ({ data }) => {
    return await request('POST', 'department/', data)
}

export const deleteDepartment = async (departmentId) => {
    return await request('DELETE', `department/${departmentId}/`)
}

export const getDepartmentOffices = async () => {
    return await request('GET', 'department/office/')
}
