import Entity from '_entities/base/EntityNew'

export const CameraGroupLinkSchema = {
    name: 'CameraGroupLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'int' },
        name: { type: 'string' },
    },
}

class CameraGroupLink extends Entity {
    get schema() {
        return CameraGroupLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
    }
}

export default CameraGroupLink
