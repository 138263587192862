<template>
    <v-dialog
        v-model="show"
        dark
        :max-width="options.width"
        scrollable
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
    >
        <v-card>
            <v-toolbar dark dense flat>
                <v-toolbar-title class="white--text">
                    Звонок
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-row dense>
                    <v-col cols="5">
                        <v-card flat width="200">
                            <v-row dense>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="search"
                                        autofocus
                                        clearable
                                        class="my-5"
                                        hide-details
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="4">
                                    <v-btn @click="addSymbol('1')">
                                        1
                                    </v-btn>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn @click="addSymbol('2')">
                                        2
                                    </v-btn>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn @click="addSymbol('3')">
                                        3
                                    </v-btn>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn @click="addSymbol('4')">
                                        4
                                    </v-btn>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn @click="addSymbol('5')">
                                        5
                                    </v-btn>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn @click="addSymbol('6')">
                                        6
                                    </v-btn>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn @click="addSymbol('7')">
                                        7
                                    </v-btn>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn @click="addSymbol('8')">
                                        8
                                    </v-btn>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn @click="addSymbol('9')">
                                        9
                                    </v-btn>
                                </v-col>
                                <v-col cols="4">
                                    <v-btn @click="addSymbol('0')">
                                        0
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="7">
                        <div v-if="filteredResidents.length">
                            <v-list>
                                <v-list-item-group v-model="residentIndex">
                                    <v-list-item
                                        v-for="client in filteredResidents"
                                        :key="client.id"
                                    >
                                        <v-list-item-avatar>
                                            <v-img :src="getResidentAvatar(client)" />
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ getResidentName(client) }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <div v-if="restCount" style="text-align: center;">
                                еще {{ restCount }} соответствий
                            </div>
                        </div>
                        <div v-else class="d-flex justify-center align-center" style="height: 100%;">
                            <div style="width: 60%; font-size: 1.2em;">
                                Введите часть номера или имени для поиска клиента
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn
                    text
                    @click.native="cancel"
                >
                    Отмена
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="!resident && !search"
                    @click.native="agree"
                >
                    Позвонить
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { debounce as _debounce, map as _map } from 'lodash'

    import { API } from '_api'

    import UserOld from '_entities/user/UserOld'

    export default {
        name: 'FMakeCallDialog',

        props: {
            valid: { type: Boolean },
            value: { type: Object, default: null },
        },

        data: () => ({
            dialog: false,
            resolve: null,
            reject: null,
            options: {
                color: 'primary',
                width: 600,
                zIndex: 200,
            },
            search: null,
            residents: [],
            resident: null,
            residentIndex: null,
        }),

        computed: {
            filteredResidents() {
                return this.residents?.slice(0, 4) || []
            },

            show: {
                get() {
                    return this.dialog
                },
                set(value) {
                    this.dialog = value
                    if (value === false) {
                        this.cancel()
                    }
                },
            },

            restCount() {
                return this.residents.length > 4 ? this.residents.length - 4 : 0
            },
        },

        watch: {
            search: {
                handler() {
                    this.fetchData()
                },
            },

            residentIndex: {
                handler(residentIndex) {
                    this.resident = this.filteredResidents[residentIndex]
                    this.search = this.resident.phone || this.search.phoneNumber || this.search
                },
            },
        },

        created() {
            this.fetchData = _debounce(() => {
                this.handleFetchData()
            }, 1000)
        },

        methods: {
            addSymbol(symbol) {
                if (!this.search) {
                    this.search = ''
                }

                this.search = this.search + symbol
            },

            async handleFetchData() {
                this.resident = null
                this.residentIndex = null

                if (!this.search) {
                    this.residents = []
                    return
                }

                try {
                    const params = {
                        page: 1,
                        page_size: 100,
                        search: this.search,
                    }

                    const response = await API.v1.services.user.getResidents(params)

                    const { results } = response

                    this.residents = _map(results, (result) => new UserOld(result))
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить список сотрудников', e)
                    this.$logger.logException(e)
                }
            },

            getResidentAvatar(resident) {
                return resident?.user?.photo?.getSmall()?.url || null
            },

            getResidentName(resident) {
                return resident?.fullName || resident?.phoneNumber || 'Без имени'
            },

            open(options) {
                this.dialog = true
                this.options = Object.assign(this.options, options)
                this.resident = null

                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },

            agree() {
                this.resolve({ resident: this.resident, phone: this.search })
                this.dialog = false
                this.search = null
            },

            cancel() {
                this.resolve()
                this.dialog = false
                this.search = null
            },
        },
    }
</script>
