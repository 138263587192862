import { cloneDeep as _cloneDeep, find as _find, forEach as _forEach, map as _map } from 'lodash'

import Filter from '_entities/filter/Filter'

import { getFilterLocalStorageKey, getFilterLocalStorageKeyNew } from '_features/filter/helpers'

const getFullFilterFields = (originalFields, savedFields) => {
    return _map(originalFields, (originalField) => {
        const savedField = _find(savedFields, (savedField) => {
            return savedField.code === originalField.code
        })

        const savedFieldValue = savedField?.value

        if (savedFieldValue !== null && savedFieldValue !== undefined) {
            if (savedFieldValue?.type === 'date' && savedFieldValue?.date) {
                originalField.value = savedFieldValue
            } else if (savedFieldValue?.multiple === true && savedFieldValue?.length) {
                originalField.value = savedFieldValue
            } else {
                originalField.value = savedFieldValue
            }
        }

        return originalField
    })
}

const actions = {
    initFilter({ commit }, payload) {
        const { filter } = payload

        let savedFilter = null

        // Restore from JSON
        const newKey = getFilterLocalStorageKeyNew(filter.code)
        let json = localStorage.getItem(newKey)

        if (!json) {
            const oldKey = getFilterLocalStorageKey(filter.code)
            json = localStorage.getItem(oldKey)

            localStorage.setItem(newKey, json)
        }

        if (json) {
            savedFilter = JSON.parse(json)
        }

        if (savedFilter) {
            const fields = getFullFilterFields(filter.fields, savedFilter?.fields)

            filter.search = savedFilter?.search
            filter.fields = fields
            filter.presets = [
                ...filter.presets,
                ...savedFilter?.presets,
            ]
            filter.activePresetId = savedFilter?.activePresetId
        }

        commit('setFilter', { filter: new Filter(filter) })
    },

    // Применить пресет
    // - Сбросить значения полей
    // - Установить значения полей из пресета
    // - Установить идентификатор активного пресета
    applyFilterPreset({ dispatch }, payload) {
        const { filter, filterPreset } = payload

        // Сбросить значения полей
        _forEach(filter.fields, (filterField) => {
            filterField.value = filterField.default
        })

        // Установить значения полей из пресета
        _forEach(filterPreset?.fields, (presetFilterField) => {
            const filterField = _find(filter?.fields, (o) => o.code === presetFilterField?.code)

            if (filterField) {
                filterField.value = presetFilterField.value
            }
        })

        // Установить идентификатор активного пресета
        filter.activePresetId = filterPreset.id

        dispatch('applyFilter', { filter })
    },

    // Применить фильтр
    applyFilter({ commit }, payload) {
        const { filter } = payload

        // Save to JSON
        const key = getFilterLocalStorageKeyNew(filter.code)
        localStorage.setItem(key, JSON.stringify(filter.simplified))

        commit('setFilter', { filter })
    },

    // Сбросить активный пресет
    // - Сбросить значения полей (только тех, которые в пресете)
    // - Сбросить идентификатор активного пресета
    resetActiveFilterPreset({ dispatch }, payload) {
        const { filter } = payload

        const activeFilterPreset = _find(filter?.presets, (o) => o.id === filter?.activePresetId)

        _forEach(activeFilterPreset?.fields, (presetFilterField) => {
            const filterField = _find(filter?.fields, (o) => {
                return o.code === presetFilterField?.code
            })

            if (filterField) {
                filterField.value = filterField.default
            }
        })

        filter.activePresetId = null

        dispatch('applyFilter', { filter })
    },

    // Сбросить фильтр
    // - Сбросить поле поиска
    // - Сбросить значения полей
    // - Сбросить идентификатор активного пресета
    resetFilter({ dispatch }, payload) {
        const { filter } = payload

        // Сбросить поле поиска
        filter.search = null

        // Сбросить значения полей
        _forEach(filter.fields, (filterField) => {
            filterField.value = _cloneDeep(filterField.default)
        })

        // Сбросить идентификатор активного пресета
        filter.activePresetId = null

        dispatch('applyFilter', { filter })
    },

    setFilterValueByCode({ state }, payload) {
        const { filter, code, value } = payload

        if (state.entities[filter]) {
            state.entities[filter].fields = _map(state.entities[filter].fields, (field) => {
                if (field.code === code) {
                    field.value = value
                }

                return field
            })
        }
    },
}

export default actions
