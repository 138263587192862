import { map as _map } from 'lodash'
import Entity from '_entities/base/EntityNew'

import CompanyLink from '_entities/company/CompanyLink'
import BuildingLink from '_entities/building/BuildingLink'
import PremiseLink from '_entities/premise/PremiseLink'
import Photo from '_entities/media/Photo'
import NewsCategoryLink from '_entities/news/NewsCategoryLink'

export const QuizSchema = {
    name: 'Quiz',
    primaryKey: 'id',
    properties: {
        buildings: { type: 'BuildingLink[]', entity: BuildingLink, default: [] },
        category: { type: 'NewsCategoryLink', entity: NewsCategoryLink },
        company: { type: 'CompanyLink', entity: CompanyLink },
        description: { type: 'string' },
        endDate: { type: 'int' },
        finishProgress: { type: 'int' },
        gallery: { type: 'Photo[]', entity: Photo, default: [] },
        id: { type: 'int' },
        image: { type: 'Photo', entity: Photo },
        isActive: { type: 'bool' },
        isSendToEmail: { type: 'bool' },
        premises: { type: 'PremiseLink[]', entity: PremiseLink, default: () => [] },
        startDate: { type: 'int' },
        status: { type: 'string' },
        title: { type: 'string' },
    },
}

class Quiz extends Entity {
    get schema() {
        return QuizSchema
    }

    get dataMap() {
        return {
            end_date: 'endDate',
            finish_progress: 'finishProgress',
            is_active: 'isActive',
            is_send_to_email: 'isSendToEmail',
            start_date: 'startDate',
        }
    }

    static fieldLabels = {
        id: 'ID',
        isActive: 'Статус активности',
        startDate: 'Дата публикации',
        endDate: 'Дата окончания',
        status: 'Статус',
        title: 'Заголовок',
        description: 'Описание',
        category: 'Категория',
        image: 'Изображение',
        buildings: 'Дома',
        company: 'Организация',
        gallery: 'Фотогалерея',
        finishProgress: 'Автозавершение',
        isSendToEmail: 'Отправка ответа на email',
        premises: 'Помещения',
    }

    toServer() {
        return {
            is_active: this.isActive,
            start_date: this.startDate,
            end_date: this.endDate,
            status: this.status,
            title: this.title,
            description: this.description,
            category: this.category?.id,
            image: this.image?.id,
            buildings: _map(this.buildings, (item) => item.id),
            company: this.company?.id,
            gallery: _map(this.gallery, (item) => item.id),
            finish_progress: this.finishProgress,
            is_send_to_email: this.isSendToEmail,
            premises: _map(this.premises, (item) => item.id),
        }
    }
}

export default Quiz
