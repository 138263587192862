<template>
    <div>
        <v-subheader>{{ label }}</v-subheader>
        <v-row dense>
            <v-col cols="12" sm="6">
                <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateFormatted"
                            v-bind="attrs"
                            clearable
                            label="Дата"
                            outlined
                            prepend-inner-icon="$calendarDay"
                            readonly
                            :rules="$attrs.rules"
                            v-on="on"
                            @click:clear="dateModel = null"
                        />
                    </template>
                    <v-date-picker
                        v-model="dateModel"
                        v-bind="$attrs"
                        :first-day-of-week="1"
                        :max="max"
                        :min="min"
                        @input="dateMenu = false"
                    />
                </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
                <v-menu
                    ref="menu"
                    v-model="timeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="time"
                            :disabled="!date"
                            label="Время"
                            outlined
                            prepend-inner-icon="$calendarDay"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        />
                    </template>
                    <v-time-picker
                        v-if="timeMenu"
                        v-model="timeModel"
                        format="24hr"
                        full-width
                        :max="maxTime"
                        :min="minTime"
                        @click:minute="$refs.menu.save(timeModel)"
                    />
                </v-menu>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { formatDate, toUnix } from '@/plugins/moment'

    export default {
        name: 'UIDateTimePicker',

        props: {
            label: { type: String, default: null },
            max: { type: String, default: null },
            min: { type: String, default: null },
            value: { type: Number, default: null },
        },

        data: () => ({
            dateMenu: false,
            timeMenu: false,
            originalDate: null,
            originalTime: null,
        }),

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            dateTime() {
                return formatDate(this.model, 'YYYY-MM-DD HH:mm')
            },

            dateParts() {
                return this.splitDateTime(this.dateTime)
            },

            date() {
                return this.dateParts.date
            },

            dateFormatted() {
                return formatDate(toUnix(this.date), 'LL')
            },

            dateModel: {
                get() {
                    if (this.dateParts) {
                        return this.dateParts.date
                    }

                    return null
                },
                set(date) {
                    if (date) {
                        const time = (this.time || '00:00') + ':00'

                        const dateTime = `${date} ${time}`

                        this.model = toUnix(dateTime)
                    } else {
                        this.model = null
                    }
                },
            },

            time() {
                return this.dateParts.time
            },

            timeModel: {
                get() {
                    return this.time
                },
                set(value) {
                    const time = value ? value + ':00' : null

                    if (time) {
                        const date = this.date

                        const dateTime = `${date} ${time}`

                        this.model = toUnix(dateTime)
                    }
                },
            },

            splittedMax() {
                return this.splitDateTime(this.max)
            },

            splittedMin() {
                return this.splitDateTime(this.min)
            },

            minTime() {
                if (this.splittedMin.date) {
                    if (this.splittedMin.date === this.dateModel) {
                        return this.splittedMin.time
                    }
                }

                return null
            },

            maxTime() {
                if (this.splittedMax.date) {
                    if (this.splittedMax.date === this.dateModel) {
                        return this.splittedMax.time
                    }
                }

                return null
            },
        },

        watch: {
            dateModel: {
                handler(value) {
                    if (!this.originalDate) {
                        this.originalDate = this.dateModel
                    }
                    if (!this.originalTime) {
                        this.originalTime = this.timeModel
                    }

                    if ((this.originalDate === this.dateModel) && (this.originalTime > this.timeModel)) {
                        this.timeModel = this.originalTime
                    }
                },
                immediate: true,
            },
        },

        methods: {
            splitDateTime(datetime) {
                const split = {
                    date: null,
                    time: '00:00',
                }

                const parts = datetime?.split(' ') || []

                // date
                if (parts[0]) {
                    split.date = parts[0]
                }

                // time
                if (parts[1]) {
                    split.time = parts[1]
                }

                return split
            },
        },
    }
</script>
