<template>
    <div class="c-attachment">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'CAttachment',
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/variables";

    .c-attachment {
        background:    transparent;
        border:        1px solid map_get($grey, 'base');
        border-radius: $border-radius-root;

        padding:       map_get($spacers, 2) map_get($spacers, 3);
    }

    .c-attachment.required {
        border:        1px solid map_get($red, 'base');
    }
</style>
