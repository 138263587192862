import { v4 as uuidV4 } from 'uuid'

import Entity from '_entities/base/EntityNew'

import FilterField from '_entities/filter/FilterField'

export const FilterPresetSchema = {
    name: 'FilterPreset',
    primaryKey: 'id',
    properties: {
        code: { type: 'string' },
        fields: { type: 'FilterField[]', entity: FilterField, default: [] },
        id: { type: 'string', default: () => uuidV4() },
        isSystem: { type: 'bool' },
        name: { type: 'string' },
    },
}

class FilterPreset extends Entity {
    get schema() {
        return FilterPresetSchema
    }

    static fieldLabels = {
        name: 'Название',
        code: 'Символьный код',
        fields: 'Поля',
        isSystem: 'Системный',
    }

    get simplified() {
        if (!this.isSystem) {
            return {
                id: this.id,
                code: this.code,
                name: this.name,
                fields: this.fields,
            }
        }
    }
}

export default FilterPreset
