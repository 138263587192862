import Vue from 'vue'

const mutations = {
    fetchSectorsRequest(state) {
        state.isFetching = true
    },

    fetchSectorsSuccess(state, { items }) {
        state.isFetching = false
        state.errors = []

        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchSectorsError(state, errors) {
        state.isFetching = false
        state.errors = errors
    },

    addedSector(state, item) {
        Vue.set(state.items, item.id, item)
    },
}

export default mutations
