import { forEach as _forEach } from 'lodash'

import { API } from '_api'

import NeedAnswerTimeCounter from '_entities/timeCounters/NeedAnswerTimeCounter'

const actions = {
    async getNeedAnswerTimeCounters({ commit }, params) {
        commit('getNeedAnswerTimeCountersRequest')

        try {
            const response = await API.v1.services.timeManagement.getNeedAnswerTimeCounters(params)

            const timeCounters = []

            if (response.results) {
                _forEach(response.results, (timeCounter) => {
                    timeCounters.push(new NeedAnswerTimeCounter(timeCounter))
                })
            }

            commit('getNeedAnswerTimeCountersSuccess', timeCounters)
        } catch (e) {
            commit('getNeedAnswerTimeCountersError', e)
        }
    },
}

export default actions
