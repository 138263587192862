import Entity from '_entities/base/EntityNew'

export const AddressSchema = {
    name: 'Address',
    properties: {
        full: { type: 'string' },
        short: { type: 'string' },
    },
}
class Address extends Entity {
    get schema() {
        return AddressSchema
    }

    static fieldLabels = {
        full: 'Полный адрес',
        short: 'Короткий адрес',
    }
}

export default Address
