export const downloadFiles = (files) => {
    try {
        files.forEach((file, i) => {
            setTimeout(() => {
                const aEl = document.createElement('a')
                aEl.setAttribute('href', file.url)
                aEl.setAttribute('download', 'download')
                if (/(jpe?g|gif|png)/.test(file.name)) {
                    aEl.setAttribute('target', '_blank')
                }
                aEl.click()
            }, i * 800)
        })
    } catch (e) {
        this.$root.$pushRequestNotifications('Не удалось загрузить файл', e)
        this.$logger.logException(e)
    }
}

export const selectFiles = (params) => {
    return new Promise((resolve) => {
        const fileInput = document.createElement('input')
        fileInput.type = 'file'
        fileInput.multiple = params?.multiple
        fileInput.accept = params?.contentType

        fileInput.onchange = () => {
            const files = Array.from(fileInput.files)

            resolve(files)
        }

        fileInput.click()
    })
}
