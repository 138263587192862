<template>
    <v-row dense align="end">
        <v-col>
            <UIDateTimeStringPicker
                v-model="model.from"
                dense
                hide-details
                label="c"
                :max="maxFromDate"
                :min="minDate"
            />
        </v-col>
        <v-col>
            <UIDateTimeStringPicker
                v-model="model.to"
                hide-details
                dense
                label="по"
                :max="maxDate"
                :min="minToDate"
            />
        </v-col>
        <v-col v-if="allowDelete" cols="auto">
            <v-btn
                text
                color="primary"
                @click="deleteRange"
            >
                удалить
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    import moment from 'moment'
    import Range from '_entities/range/Range'
    import UIDateTimeStringPicker from '_ui/forms/UIDateTimeStringPicker'

    export default {
        name: 'UIDateTimeRange',

        components: {
            UIDateTimeStringPicker,
        },

        props: {
            allowDelete: { type: Boolean, default: true },
            max: { type: String, default: null },
            min: { type: String, default: null },
            type: { type: String, default: null },
            value: { type: Object, default: () => new Range() },
        },

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            minDate() {
                return (this.min && this.min !== '0') ? this.min : moment().add(-1, 'year').format('YYYY-MM-DD')
            },

            maxFromDate() {
                if (this.model.to !== '') {
                    return this.model.to
                } else if (this.max && this.max !== '0') {
                    return this.max
                } else {
                    return moment().add(3, 'year').format('YYYY-MM-DD')
                }
            },

            minToDate() {
                if (this.model.from !== '') {
                    return this.model.from
                } else if (this.min && this.min !== '0') {
                    return this.min
                } else {
                    return moment().add(-1, 'year').format('YYYY-MM-DD')
                }
            },

            maxDate() {
                return (this.max && this.max !== '0') ? this.max : moment().add(3, 'year').format('YYYY-MM-DD')
            },
        },

        methods: {
            deleteRange() {
                this.$emit('delete')
            },
        },
    }
</script>
