import { PERMISSIONS } from '_features/permissions/constants'

const AbsenceScheduleNavigator = () => import('_navigations/Home/AbsenceSchedule/AbsenceScheduleNavigator')

const AbsenceScheduleEditScreen = () => import('_screens/Home/AbsenceSchedule/AbsenceScheduleEditScreen')
const AbsenceScheduleScreen = () => import('_screens/Home/AbsenceSchedule/AbsenceScheduleScreen')

const absenceScheduleRoutes = [
    {
        path: 'absence-schedule',
        component: AbsenceScheduleNavigator,
        children: [
            {
                path: 'list',
                name: 'AbsenceSchedule',
                component: AbsenceScheduleScreen,
                meta: {
                    screenOptions: {
                        title: 'График отсутствий',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_ABSENT_SCHEDULE],
                },
            },
            {
                path: 'edit',
                name: 'AbsenceScheduleEdit',
                component: AbsenceScheduleEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Редактирование отсутствия',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_ABSENT_SCHEDULE],
                },
            },
            {
                path: 'create',
                name: 'AbsenceScheduleCreate',
                component: AbsenceScheduleEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Добавление отсутствия',
                    },
                    permissions: [PERMISSIONS.CAN_ADD_ABSENT_SCHEDULE],
                },
            },
        ],
    },
]

export default absenceScheduleRoutes
