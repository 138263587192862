import { request } from '_api/v1/base/behemoth/client'

export const getCallQueues = async (params) => {
    return await request('GET', 'queue/', params)
}

export const addUser = async () => {
    return await request('GET', 'queue/add/')
}

export const removeUser = async (params) => {
    return await request('GET', 'queue/remove/', params)
}

export const getUserQueue = async (workerId) => {
    return await request('GET', `queue/user/${workerId}/`)
}
