import '@/plugins/jquery'
import Logger from '@/plugins/logger'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify/'
import './scss/main.scss'

import CDebug from './common/components/CDebug'

Vue.config.productionTip = false

// TODO: Vuetify bug
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <svg>.'
Vue.config.warnHandler = function (msg, vm, trace) {
    // `trace` is the component hierarchy trace
    if (msg === ignoreWarnMessage) {
        msg = null
        vm = null
        trace = null
    }
}

// For debug
Vue.component('CDebug', CDebug)

// Логирование ошибок и метрик
Vue.use(Logger)

Vue.prototype.$hasPermission = function (checkPermissions) {
    return store.getters['permissions/hasPermission'](checkPermissions)
}

Vue.prototype.$pushRequestNotifications = function (caption, messages, color = 'red') {
    const _messages = Array.isArray(messages) ? messages : [messages]

    _messages?.forEach((message) => {
        const text = message?.detail || message?.message

        this.$root.pushNotification({
            text: `${caption}: ${text}`,
            color: color,
        })
    })
}

new Vue({
    router,
    store,
    vuetify,
    methods: {
        async pushNotification(data) {
            await store.dispatch('notifications/pushNotification', data)
        },
    },
    render: (h) => h(App),
}).$mount('#app')
