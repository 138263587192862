import { PERMISSIONS } from '_features/permissions/constants'

const WorkersNavigator = () => import('_navigations/Home/Workers/WorkersNavigator')

const WorkersScreen = () => import('_screens/Home/Workers/WorkersScreen')
const WorkerEditScreen = () => import('_screens/Home/Workers/WorkerEditScreen')

const workersRoutes = [
    {
        path: 'workers',
        component: WorkersNavigator,
        children: [
            {
                path: '',
                name: 'Workers',
                component: WorkersScreen,
                meta: {
                    screenOptions: {
                        title: 'Список сотрудников',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_DEPARTMENT_WORKER],
                },
            },
            {
                path: 'edit/:workerId?',
                name: 'WorkerEdit',
                component: WorkerEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Сотрудник',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_DEPARTMENT_WORKER],
                },
            },
        ],
    },
]

export default workersRoutes
