const mutations = {
    fetchDepartmentsRequest(state) {
        state.isFetching = true
    },

    fetchDepartmentsSuccess(state, { items }) {
        state.errors = []
        state.isFetching = false

        state.items = items
    },

    fetchDepartmentsError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },
}

export default mutations
