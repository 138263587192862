<template>
    <UIAutocomplete
        v-model="model"
        v-bind="$attrs"
        :api-method="method"
        item-text="name"
        item-value="id"
        :label="label"
        :sort-by="['name']"
        :sort-orders="['asc']"
    />
</template>

<script>
    import { API } from '_api'

    import { UIAutocomplete } from '_ui'

    export default {
        name: 'FPipePicker',

        components: {
            UIAutocomplete,
        },

        props: {
            label: { type: String, default: 'Стояк' },
            value: { type: [Array, Object], default: null },
        },

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            method() {
                return API.v1.services.pipe.getPipes
            },
        },
    }
</script>
