<template>
    <v-card flat>
        <v-row align="center">
            <v-card-title>{{ label }}</v-card-title>
            <v-chip color="primary" class="white--text">
                {{ itemsCount }}
            </v-chip>
            <v-btn v-if="itemsCount > 0" icon @click="changeExpand">
                <v-icon v-text="`$${icon}`" />
            </v-btn>
        </v-row>
        <template v-if="isExpanded && items.length">
            <v-list-item v-for="item in items" :key="item">
                <v-list-item-title v-if="item.id">
                    <a :href="`#${item.id}`">
                        {{ item.text }}
                    </a>
                </v-list-item-title>
                <v-list-item-title v-else>
                    {{ item }}
                </v-list-item-title>
            </v-list-item>
        </template>
    </v-card>
</template>

<script>
    export default {
        name: 'UIExpandList',

        props: {
            items: {
                type: Array,
                default: () => [],
            },
            label: { type: String, default: 'Список' },
        },

        data: () => ({
            isExpanded: true,
        }),

        computed: {
            itemsCount() {
                return this.items?.length || 0
            },

            icon() {
                return this.isExpanded ? 'caretUp' : 'expand'
            },
        },

        methods: {
            changeExpand() {
                this.isExpanded = !this.isExpanded
            },
        },
    }
</script>
