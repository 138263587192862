import Entity from '_entities/base/EntityNew'

import CityLink from '_entities/city/CityLink'

export const CompanyPhoneSchema = {
    name: 'CompanyPhone',
    primaryKey: 'id',
    properties: {
        city: { type: 'CityLink', entity: CityLink },
        code: { type: 'string' },
        commentForClient: { type: 'string' },
        id: { type: 'string' }, // UUID
        phone: { type: 'string' },
    },
}

class CompanyPhone extends Entity {
    get schema() {
        return CompanyPhoneSchema
    }

    get dataMap() {
        return {
            comment_for_client: 'commentForClient',
        }
    }

    static fieldLabels = {
        id: 'ID',
        sity: 'Город',
        code: 'Код',
        phone: 'Телефон',
        commentForClient: 'Комментарий для клиента',
    }

    toServer() {
        return {
            id: this.id,
            city: this.city?.id,
            code: this.code,
            phone: this.phone,
            comment_for_client: this.commentForClient,
        }
    }

    static codes = [
        {
            id: 'client_communication',
            name: 'Для связи с клиентами',
        },
    ]

    getPhoneText() {
        return this.phone
    }
}

export default CompanyPhone
