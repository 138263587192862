import { PERMISSIONS } from '_features/permissions/constants'

const SettingsNavigator = () => import('_navigations/Home/Settings/SettingsNavigator')

const MaintenanceWorkScreen = () => import('_screens/Home/MaintenanceWork/MaintenanceWorkScreen')
const MaintenanceWorkEditScreen = () => import('_screens/Home/MaintenanceWork/MaintenanceWorkEditScreen')

const settingsRoutes = []

settingsRoutes.push({
    path: 'settings',
    name: 'Settings',
    component: SettingsNavigator,
    children: [
        {
            path: 'maintenance-work',
            name: 'maintenanceWork',
            component: MaintenanceWorkScreen,
            meta: {
                screenOptions: {
                    title: 'Технические работы',
                },
                permissions: [PERMISSIONS.CAN_VIEW_MAINTENANCE_WORK_EVENT],
            },
        },
        {
            path: 'maintenance-work-edit',
            name: 'maintenanceWorkEdit',
            component: MaintenanceWorkEditScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Редактирование технических работ',
                },
                permissions: [
                    PERMISSIONS.CAN_CHANGE_MAINTENANCE_WORK_EVENT,
                    PERMISSIONS.CAN_ADD_MAINTENANCE_WORK_EVENT,
                ],
            },
        },
    ],
})

export default settingsRoutes
