import FileBase from '_entities/media/file/FileBase'

export const FileImageSchema = {
    name: 'FileImage',
    properties: {
        height: { type: 'int' },
        url50: { type: 'string' },
        url200: { type: 'string' },
        url1000: { type: 'string' },
        width: { type: 'int' },
    },
}

class FileImage extends FileBase {
    get schema() {
        return {
            ...super.schema,
            ...FileImageSchema,
            properties: {
                ...super.schema.properties,
                ...FileImageSchema.properties,
            },
        }
    }
}

export default FileImage
