<template>
    <v-card flat>
        <v-card-text>
            <v-form v-model="isValidModel">
                <v-row>
                    <v-col class="d-flex justify-center" cols="12">
                        <v-text-field
                            v-model="editingResident.lastName"
                            hide-details
                            label="Фамилия"
                            outlined
                            :rules="[rules.isNamePresent]"
                        />
                    </v-col>
                    <v-col class="d-flex justify-center" cols="12">
                        <v-text-field
                            v-model="editingResident.firstName"
                            hide-details
                            label="Имя"
                            outlined
                            :rules="[rules.isNamePresent]"
                        />
                    </v-col>
                    <v-col class="d-flex justify-center" cols="12">
                        <v-text-field
                            v-model="editingResident.middleName"
                            hide-details
                            label="Отчество"
                            outlined
                        />
                    </v-col>
                    <v-col class="d-flex justify-center" cols="12">
                        <v-text-field
                            v-model="editingResident.phone"
                            hide-details
                            label="Телефон"
                            outlined
                            readonly
                            :rules="[rules.isCorrectPhone]"
                        />
                    </v-col>
                    <v-col class="d-flex justify-center" cols="12">
                        <v-text-field
                            v-model="editingResident.email"
                            hide-details
                            label="Email"
                            outlined
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    import { API } from '_api'

    import { cloneDeep as _cloneDeep } from 'lodash'

    import { clearSpaces } from '_utils/text'

    export default {
        name: 'FUserEditForm',

        props: {
            resident: { type: Object, default: null },
            isValid: { type: Boolean, default: false },
        },

        data: () => ({
            editingResident: null,
            rules: {
                isNamePresent: (value) => (!!value && !!value.length) || 'Значение не может быть пустым',
                isCorrectPhone: (value) => (value && value.match(/^\+?[0-9]{11}$/)?.length === 1) || 'Номер телефона должен содержать 11 цифр',
            },
        }),

        computed: {
            isValidModel: {
                get() {
                    return this.isValid
                },
                set(value) {
                    this.$emit('update:isValid', value)
                },
            },
        },

        watch: {
            resident: {
                handler(resident) {
                    this.editingResident = _cloneDeep(resident)
                },
                deep: true,
                immediate: true,
            },
        },

        methods: {
            async saveResident() {
                const firstName = clearSpaces(this.editingResident.firstName)
                const lastName = clearSpaces(this.editingResident.lastName)

                const params = {
                    user_id: this.resident.id,
                    first_name: firstName,
                    middle_name: this.editingResident.middleName,
                    last_name: lastName,
                    phone_number: this.editingResident.phone,
                    email: this.editingResident.email,
                }

                try {
                    await API.v1.services.user.changeUserData(params)

                    this.$store.dispatch('incomingCall/fetchCallerInfo', {
                        cardColor: this.cardColor,
                        cardTitle: this.cardTitle,
                        callerId: this.resident.id,
                    })
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось обновить данные клиента', e)
                    this.$logger.logException(e)
                }

                this.isEditing = false
            },
        },
    }
</script>
