import { forEach as _forEach } from 'lodash'

import { API } from '_api'

import NotificationMessage from '_entities/notificationMessage/NotificationMessage'

const actions = {
    async fetchNotificationMessages({ commit, state }, { userId, params }) {
        if (!state.isFetching) {
            commit('fetchNotificationMessagesRequest')

            try {
                const response = await API.v1.services.notification.getNotificationsByUser(userId, params)

                const { results } = response

                const entities = {}

                _forEach(results, (result) => {
                    const notificationMessage = new NotificationMessage(result)
                    entities[notificationMessage.id] = notificationMessage
                })

                commit('fetchNotificationMessagesSuccess', { entities })
            } catch (e) {
                commit('fetchNotificationMessagesError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
