const getters = {
    getFilterByCode: (state) => (code) => {
        return state.entities[code] || null
    },

    getActiveFilterPreset: (state) => (code) => {
        return state.entities[code] || null
    },
}

export default getters
