// import moment from 'moment'
import { v4 as uuidV4 } from 'uuid'

import Entity from '_entities/base/EntityNew'

export const ServiceElementCalendarEventItemSchema = {
    name: 'ServiceElementCalendarEventItem',
    primaryKey: 'id',
    properties: {
        count: { type: 'int' },
        date: { type: 'string' },
        id: { type: 'string', default: () => uuidV4() },
        time: { type: 'string' },
    },
}

class ServiceElementCalendarEventItem extends Entity {
    get schema() {
        return ServiceElementCalendarEventItemSchema
    }

    toServer() {
        return {
            id: this.id,
            date: this.date,
            time: this.time,
            count: this.count,
        }
    }
}

export default ServiceElementCalendarEventItem
