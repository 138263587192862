import { forEach as _forEach } from 'lodash'
import { v4 as uuidV4 } from 'uuid'

import Entity from '_entities/base/EntityNew'

import ServiceElementVariant from '_entities/service/ServiceElementVariant'

export const ServiceElementSchema = {
    name: 'ServiceElement',
    primaryKey: 'id',
    properties: {
        default: { type: 'mixed' },
        defaults: { type: 'mixed[]', default: () => [] },
        dependsOnElement: { type: 'string' }, // UUID
        dependsOnVariants: { type: 'string[]', default: () => [] }, // UUID
        elementType: { type: 'string' },
        id: { type: 'string', default: () => uuidV4() },
        isRequired: { type: 'bool' },
        order: { type: 'int' },
        title: { type: 'string' },
        variants: { type: 'ServiceElementVariant[]', entity: ServiceElementVariant, default: () => [] },
    },
}

class ServiceElement extends Entity {
    get schema() {
        return ServiceElementSchema
    }

    get dataMap() {
        return {
            depends_on_element: 'dependsOnElement',
            depends_on_variants: 'dependsOnVariants',
            element_type: 'elementType',
            is_required: 'isRequired',
        }
    }

    static fieldLabels = {
        id: 'ID',
        title: 'Заголовок',
        elementType: 'Тип',
        isRequired: 'Элемент обязателен для заполнения',
        dependsOnElement: 'Элемент показывается, если этот элемент заполнен',
        dependsOnVariants: 'Элемент показывается, если какое-либо значение из списка выбрано',
        variants: 'Варианты',
        // Default
        default: 'Значение по умолчанию',
        defaults: 'Значения по умолчанию',
        timezone: 'Часовой пояс',
        accountStatus: 'Код статуса лицевого счёта для показа',
    }

    static elementTypes = [
        { code: 'dropdown', name: 'Выпадающий список' },
        { code: 'date', name: 'Дата' },
        { code: 'time', name: 'Время' },
        { code: 'datetime', name: 'Дата / Время' },
        { code: 'title_1', name: 'Заголовок 1' },
        { code: 'title_2', name: 'Заголовок 2' },
        { code: 'upload_images', name: 'Загрузка фотографий' },
        { code: 'upload_files', name: 'Загрузка видео' },
        { code: 'catalog', name: 'Каталог' },
        { code: 'textinput', name: 'Поле ввода' },
        { code: 'text', name: 'Текст' },
        { code: 'calendar_event', name: 'Событие календаря' },
        { code: 'files', name: 'Файлы' },
        { code: 'checkbox', name: 'Checkbox' },
        { code: 'checkbox_tag', name: 'Checkbox (Tag)' },
        { code: 'radiobutton', name: 'Radio' },
        { code: 'radiobutton_with_image', name: 'Radio (с изображением)' },
        { code: 'push_message', name: 'PUSH уведомление' },
        { code: 'send_message', name: 'Отправить сообщение' },
    ]

    toServer() {
        const variants = []

        _forEach(this.variants, (variant, index) => {
            variant.order = index + 1
            variants.push(variant.toServer(variant))
        })

        return {
            id: this.id,
            title: this.title,
            element_type: this.elementType,
            is_required: this.isRequired,
            depends_on_element: this.dependsOnElement || null,
            depends_on_variants: this.dependsOnVariants,
            order: this.order,
            variants,
            default: this.default || '',
            defaults: this.defaults,
        }
    }
}

export default ServiceElement
