import Vue from 'vue'
import JsSIP from 'jssip'

/*  Для работы используется бибилиотека jsSIP
    https://www.npmjs.com/package/jssip
    https://jssip.net/documentation/getting_started/
    https://jssip.net/documentation/api/ua/
*/
const mutations = {
    fetchResident(state, { resident }) {
        state.resident = resident
    },

    restorePhoneSettings(state) {
        const storagePhone = localStorage.getItem('phoneSettings')

        if (storagePhone) {
            state.phoneSettings = Vue.assign({}, state.phoneSettings, JSON.parse(storagePhone))
        }
    },

    savePhoneSettings(state) {
        localStorage.setItem('phoneSettings', JSON.stringify(state.phoneSettings))
    },

    setPhoneStatus(state, message) {
        state.phoneStatus = message
    },

    setPhoneError(state, message) {
        state.phoneError = message
    },

    setPhoneNumber(state, phoneNumber) {
        state.phoneNumber = phoneNumber
    },

    setConnectionStartTime(state, connectionStartTime) {
        state.connectionStartTime = connectionStartTime
    },

    connectPhone(state, { settings, remoteAudio }) {
        // JsSIP.debug.enable('JsSIP:*')
        JsSIP.debug.disable('JsSIP:*')

        if (state.userAgent || state.isPhoneConnecting) {
            return
        }

        state.isPhoneConnecting = true

        state.phoneStatus = 'Подключение к линии...'
        state.remoteAudio = remoteAudio

        state.realm = settings?.realm || state.phoneSettings.realm

        const socket = new JsSIP.WebSocketInterface(settings?.websocketServerURL || state.phoneSettings.websocketServerURL)
        const configuration = {
            sockets: [socket],
            uri: settings?.publicIdentity || state.phoneSettings.publicIdentity,
            password: settings?.password || state.phoneSettings.password,
        }
        const userAgent = new JsSIP.UA(configuration)

        // WebSocket connection events
        userAgent.on('connected', function (e) {
            state.phoneStatus = 'Подключение к линии установлено'
            state.isPhoneConnected = true
        })

        userAgent.on('disconnected', function (e) {
            if (e?.error) {
                const errorMessage = e?.reason || 'Неизвестная ошибка'
                state.phoneError = errorMessage + ' (code ' + e?.code + ')'
            }

            state.phoneStatus = 'Соединение разорвано'
            state.isPhoneConnected = false
            state.isPhoneStopping = false
        })

        userAgent.on('newRTCSession', function (e) {
            state.RTCSession = e.session
            const connection = e.session.connection

            // Входящий звонок
            if (e.originator === 'remote') {
                state.phoneNumber = state.RTCSession.remote_identity.display_name || 'Неизвестный'
                state.isOutgoingPhoneCall = false
                state.isIncomingPhoneCall = true
            }

            // Исходящий звонок
            if (e.originator === 'local') {
                state.isOutgoingPhoneCall = true
                state.isIncomingPhoneCall = false
            }

            // Получение аудиопотока абонента
            if (connection) {
                connection.ontrack = function (e) {
                    remoteAudio.srcObject = e.streams[0]
                    remoteAudio.play()
                }
            }

            state.RTCSession.on('progress', function (e) {
                state.phoneStatus = 'Запуск звонка...'
            })

            state.RTCSession.on('failed', function (e) {
                // eslint-disable-next-line no-console
                console.log('call failed: ' + e.cause)

                state.phoneError = 'Не удалось совершить звонок: ' + e.cause
                state.isOutgoingPhoneCall = false
                state.isIncomingPhoneCall = false
                state.isAnsweredPhoneCall = false
            })

            state.RTCSession.on('ended', function (e) {
                // eslint-disable-next-line no-console
                console.log('call ended :' + e.cause)

                state.phoneStatus = 'Звонок завершен'
                state.isOutgoingPhoneCall = false
                state.isIncomingPhoneCall = false
                state.isAnsweredPhoneCall = false
            })

            state.RTCSession.on('confirmed', function (e) {
                state.phoneStatus = 'Звонок принят'
                state.isAnsweredPhoneCall = true
            })
        })

        userAgent.start()

        state.userAgent = userAgent
        state.isPhoneConnecting = false
    },

    startAtLineTimer(state) {
        state.timeAtLine = 0

        if (!state.handlerTimeAtLine) {
            state.handlerTimeAtLine = setInterval(() => {
                state.timeAtLine++
            }, 1000)
        }
    },

    stopAtLineTimer(state) {
        clearInterval(state.handlerTimeAtLine)
        state.handlerTimeAtLine = null
    },

    startAtIncoming(state) {
        state.timeAtIncoming = state.timeAtIncomingCount

        if (!state.handlerTimeAtIncoming) {
            state.handlerTimeAtIncoming = setInterval(() => {
                state.timeAtIncoming--
            }, 1000)
        }
    },

    stopAtIncoming(state) {
        clearInterval(state.handlerTimeAtIncoming)
        state.handlerTimeAtIncoming = null
    },

    disconnectPhone(state) {
        if (state.userAgent) {
            state.isPhoneStopping = true

            state.userAgent?.terminateSessions()

            state.userAgent.stop()
            state.userAgent = null

            state.connectionStartTime = null
        }
    },

    callPhone(state, phoneNumber) {
        if (!state.isOutgoingPhoneCall && !state.isIncomingPhoneCall) {
            const options = {
                mediaConstraints: { audio: true, video: false },
            }

            const sipCall = state.userAgent.call(`sip:${phoneNumber}@${state.realm}`, options)

            state.sipCall = sipCall
        }
    },

    answerCall(state) {
        if (state.RTCSession) {
            state.isAnsweredPhoneCall = true
            state.RTCSession.answer()

            if (state.RTCSession.connection) {
                state.RTCSession.connection.ontrack = function (e) {
                    state.remoteAudio.srcObject = e.streams[0]
                    state.remoteAudio.play()
                }
            }
        }
    },

    redirectCallPhone(state, phone) {
        const eventHandlers = {
            requestSucceeded: function (e) {
                state.phoneStatus = 'Переадесация успешна'
                state.isCallRefered = true
            },
            requestFailed: function (e) {
                state.phoneError = 'Переадесация не удалась'
            },
            progress: function (e) {
                state.phoneStatus = 'Переадесация в процессе'
            },
            accepted: function (e) {
                state.phoneStatus = 'Переадресованый звонок принят'
            },
            failed: function (e) {
                state.phoneError = 'Переадесация не удалась'
            },
        }

        if (phone && state.RTCSession) {
            state.isCallRefered = false
            state.RTCSession.refer(phone, { eventHandlers })
        }
    },

    cancelCallPhone(state) {
        state.userAgent?.terminateSessions()

        state.isOutgoingPhoneCall = false
        state.isIncomingPhoneCall = false
        state.isAnsweredPhoneCall = false
    },

    switchPause(state) {
        if (state.RTCSession) {
            if (state.RTCSession?.isOnHold()?.local) {
                state.RTCSession.unhold()
            } else {
                state.RTCSession.hold()
            }

            state.isPaused = Boolean(state.RTCSession?.isOnHold()?.local)
        }
    },

    switchMute(state) {
        if (state.RTCSession) {
            if (state.RTCSession?.isMuted()?.audio) {
                state.RTCSession.unmute()
            } else {
                state.RTCSession.mute()
            }

            state.isMuted = Boolean(state.RTCSession?.isMuted()?.audio)
        }
    },

    setAllowPhoneCopy(state, isAllowPhoneCopy) {
        state.isAllowPhoneCopy = isAllowPhoneCopy
    },

    setRef(state, ref) {
        state.ref = ref
    },
}

export default mutations
