import ServiceElement from '_entities/service/ServiceElement'

export const ServiceElementDropdownSchema = {
    elementType: { type: 'string', default: 'dropdown' },
}

class ServiceElementDropdown extends ServiceElement {
    get schema() {
        return {
            ...super.schema.name,
            ...super.schema.primaryKey,
            properties: {
                ...super.schema.properties,
                ...ServiceElementDropdownSchema,
            },
        }
    }

    get dataMap() {
        return {
            ...super.dataMap,
            element_type: 'elementType',
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
    }
}

export default ServiceElementDropdown
