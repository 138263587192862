import { API } from '_api'

import TicketDepartment from '_entities/ticket/TicketDepartment'

const actions = {
    async fetchTicketDepartments({ commit }, options) {
        commit('fetchTicketDepartmentsRequest')

        try {
            const response = await API.v1.services.ticketDepartment.getTicketDepartments(options)

            const { results } = response

            const items = []

            if (results) {
                results?.forEach((result) => {
                    items.push(new TicketDepartment(result))
                })
            }

            commit('fetchTicketDepartmentsData', { items })
            commit('fetchTicketDepartmentsSuccess')
        } catch (e) {
            commit('fetchTicketDepartmentsError', e)

            return Promise.reject(e)
        }
    },
}

export default actions
