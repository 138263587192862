<template>
    <div class="main">
        <slot />

        <v-icon
            v-if="isShowModel"
            class="icon"
            color="primary"
            x-small
            v-text="'$question'"
        />

        <v-sheet
            v-if="isShowModel"
            class="hint"
            :class="orientation"
            :min-width="width"
            outlined
        >
            <v-card-text>
                <div v-for="line, index in hintModel" :key="index">
                    {{ line }}
                </div>
            </v-card-text>
        </v-sheet>
    </div>
</template>

<script>
    export default {
        name: 'UIHelpHint',

        props: {
            hint: { type: [String, Array], default: 'Подсказака отсутствует' },
            isShown: { type: Boolean, default: false },
            width: { type: Number, default: 300 },
            orientation: { type: String, default: 'right' }, // 'left' || 'right'
        },

        computed: {
            hintModel() {
                if (!Array.isArray(this.hint)) {
                    return [this.hint]
                }

                return this.hint
            },

            isShowModel() {
                return this.isShown || this.hintModel?.length
            },
        },
    }
</script>

<style scoped>
    .main:hover .hint {
        opacity: 1;
        top: 100%;
        transition: 0.3s;
        visibility: visible;
    }

    .hint {
        position: absolute;
        opacity: 0;
        top: 150%;
        z-index: 2;
        visibility: hidden;
    }

    .left {
        right: 10%;
    }

    .right {
        left: 10%;
    }

    .icon {
        position: absolute;
        right: -5px;
        top: 0;
        z-index: 2;
        background-color: white;
        border-radius: 50%;
        padding: 7px;
        border: 1px solid #8a0a82;
    }

    .main {
        position: relative;
    }
</style>
