<template>
    <v-row v-if="doc" align="center">
        <v-col cols="auto">
            <v-icon size="20" v-text="icon" />
        </v-col>
        <v-col>
            <a :href="doc.url" download target="_blank">{{ doc.name }}</a>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'UIMediaDoc',

        props: {
            value: { type: Object, default: null },
        },

        computed: {
            doc() {
                return this.value
            },

            icon() {
                switch (this.doc.fileType) {
                    case 'photo':
                        return '$camera'
                    default:
                        return '$file__Light'
                }
            },
        },
    }
</script>
