import { API } from '_api'

const actions = {
    async fetchQueueStatus({ commit }, { workerId }) {
        if (workerId) {
            try {
                const response = await API.v1.services.queue.getUserQueue(workerId)

                if (response) {
                    const queueStatus = response?.queue_status || false
                    commit('setQueueStatus', { queueStatus })
                }
            } catch (e) {
                return Promise.reject(e)
            }
        }
    },

    restoreCallQueue({ commit, getters }) {
        const storeData = localStorage?.unrecallCallsQueue ? localStorage.getItem('unrecallCallsQueue') : null

        let unrecallCallsQueue = storeData ? JSON.parse(storeData) : null

        if (!unrecallCallsQueue) {
            const queues = getters['queue/getPublicQueues']
            if (queues?.length) {
                unrecallCallsQueue = queues[0]
            }
        }

        commit('setCallQueue', unrecallCallsQueue)
    },

    setCallQueue({ commit }, callQueue) {
        if (!callQueue) {
            localStorage.removeItem('unrecallCallsQueue')
        } else {
            localStorage.setItem('unrecallCallsQueue', JSON.stringify(callQueue))
        }

        commit('setCallQueue', callQueue)
    },
}

export default actions
