import Entity from '_entities/base/EntityNew'

export const SectorSchema = {
    name: 'Sector',
    primaryKey: 'id',
    properties: {
        color: { type: 'string', optional: true },
        id: { type: 'string' },
        name: { type: 'string' },
        order: { type: 'int' },
    },
}

class Sector extends Entity {
    get schema() {
        return SectorSchema
    }

    static fieldLabels = {
        color: 'Цвет',
        id: 'ID',
        name: 'Название',
        order: 'Порядок',
    }
}

export default Sector
