import { map as _map } from 'lodash'
import Entity from '_entities/base/EntityNew'

import BuildingLink from '_entities/building/BuildingLink'
import CompanyLink from '_entities/company/CompanyLink'
import StoryElement from '_entities/story/StoryElement'
import Photo from '_entities/media/Photo'

export const StorySchema = {
    name: 'Story',
    primaryKey: 'id',
    properties: {
        buildings: { type: 'BuildingLink[]', entity: BuildingLink, default: [] },
        companies: { type: 'CompanyLink[]', entity: CompanyLink, default: [] },
        elements: { type: 'StoryElement[]', entity: StoryElement, default: [] },
        endDate: { type: 'int' },
        icon: { type: 'Photo', entity: Photo },
        id: { type: 'string' },
        isActive: { type: 'bool' },
        order: { type: 'int' },
        startDate: { type: 'int' },
        title: { type: 'string' },
    },
}

class Story extends Entity {
    get schema() {
        return StorySchema
    }

    get dataMap() {
        return {
            end_date: 'endDate',
            is_active: 'isActive',
            start_date: 'startDate',
        }
    }

    static fieldLabels = {
        buildings: 'Дома',
        companies: 'Компании',
        elements: 'Элементы сторис',
        endDate: 'Дата окончания',
        icon: 'Иконка',
        id: 'ID',
        isActive: 'Активность',
        order: 'Сортировка',
        title: 'Заголовок',
        startDate: 'Дата публикации',
    }

    toServer() {
        return {
            buildings: _map(this.buildings, (item) => item.id),
            companies: _map(this.companies, (item) => item.id),
            elements: _map(this.elements, (item) => item.toServer()),
            end_date: this.endDate,
            icon: this.icon?.id,
            is_active: this.isActive,
            order: this.order,
            title: this.title,
            start_date: this.startDate,
        }
    }
}

export default Story
