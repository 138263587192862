<template>
    <v-dialog
        v-model="show"
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
    >
        <v-card>
            <v-card-text>
                <slot />
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn
                    text
                    @click.native="close"
                >
                    Закрыть
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'UIInfoDialog',

        data: () => ({
            dialog: false,
            resolve: null,
            reject: null,
            options: {
                width: 300,
                zIndex: 200,
            },
        }),

        computed: {
            show: {
                get() {
                    return this.dialog
                },
                set(value) {
                    this.dialog = value
                },
            },
        },

        methods: {
            open(options) {
                this.dialog = true
                this.options = Object.assign(this.options, options)
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },

            close() {
                this.resolve()
                this.dialog = false
            },
        },
    }
</script>
