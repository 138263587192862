<template>
    <div class="media-photo" :style="style">
        <v-responsive :aspect-ratio="aspectRatio">
            <div class="media-photo__preview">
                <v-img
                    v-if="imageSrc"
                    :aspect-ratio="aspectRatio"
                    class="media-photo__image"
                    :src="imageSrc"
                    :width="width"
                    @click="$emit('click')"
                />
                <div v-else class="media-photo__placeholder">
                    <v-icon :size="width * 0.5" v-text="'$camera'" />
                </div>
            </div>
        </v-responsive>
    </div>
</template>

<script>
    export default {
        name: 'UIMediaPhoto',

        props: {
            aspectRatio: { type: Number, default: 1 },
            size: { type: String, default: 'm' },
            width: { type: [Number, String], default: 100 },
            value: { type: Object, default: null },
        },

        computed: {
            photo() {
                return this.value
            },

            photoSize() {
                return this.photo?.getSize(this.size)
            },

            imageSrc() {
                return this.photoSize?.url
            },

            style() {
                const style = {}

                style.width = `${this.width}px`

                return style
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    .media-photo {
        &__preview,
        &__placeholder {
            width:           100%;
            height:          100%;

            display:         flex;
            align-items:     center;
            justify-content: center;
        }

        &__preview {
            background-color: map-get($grey, lighten-4);
            border-radius:    $border-radius-root;

            overflow:         hidden;

            z-index:          1;
        }
    }
</style>
