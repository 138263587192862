import { request } from '_api/v1/base/behemoth/client'

import Coordinates from '_entities/coordinates/Coordinates'

const workTimeTemplate = {
    monday: {
        label: 'Понедельник',
        end: null,
        start: null,
    },
    tuesday: {
        label: 'Вторник',
        end: null,
        start: null,
    },
    wednesday: {
        label: 'Среда',
        end: null,
        start: null,
    },
    thursday: {
        label: 'Четверг',
        end: null,
        start: null,
    },
    friday: {
        label: 'Пятница',
        end: null,
        start: null,
    },
    saturday: {
        label: 'Суббота',
        end: null,
        start: null,
    },
    sunday: {
        label: 'Воскресенье',
        end: null,
        start: null,
    },
}

export const getCompanies = async (params) => {
    const response = await request('GET', 'department/company/', params)

    const { results } = response

    if (results) {
        results?.forEach((result) => {
            if (result.coordinates && typeof result.coordinates !== 'object') {
                const coordinates = result.coordinates?.split(':')

                result.coordinates = {
                    lat: coordinates[0],
                    lon: coordinates[1],
                }
            }

            if (result?.work_time) {
                const workTime = {}
                for (const key in result.work_time) {
                    if (result.work_time[key]) {
                        workTime[key] = {
                            ...workTimeTemplate[key],
                            ...result.work_time[key],
                        }
                    }
                }

                result.work_time = workTime
            }
        })
    }

    return response
}

export const getCompanyById = async (companyId) => {
    const response = await request('GET', `department/company/${companyId}/`)

    if (response) {
        if (response.coordinates && typeof response.coordinates !== 'object') {
            const coordinates = response.coordinates?.split(':')

            response.coordinates = {
                lat: coordinates[0],
                lon: coordinates[1],
            }
        }

        if (!response.coordinates) {
            response.coordinates = new Coordinates()
        }

        if (response?.work_time) {
            const workTime = {}
            for (const key in response.work_time) {
                if (response.work_time[key]) {
                    workTime[key] = {
                        ...workTimeTemplate[key],
                        ...response.work_time[key],
                    }
                }
            }

            response.work_time = workTime
        }
    }

    return response
}

export const updateCompany = async ({ companyId, data }) => {
    return await request('PUT', `department/company/${companyId}/`, data)
}

export const getCompanyTypes = async (params) => {
    return await request('GET', 'department/company_type/', params)
}
