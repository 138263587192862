import { PERMISSIONS } from '_features/permissions/constants'

const QuizzesNavigator = () => import('_navigations/Home/UserApp/QuizzesNavigator')

const QuizzesScreen = () => import('_screens/Home/UserApp/Quizzes/QuizzesScreen')
const QuizEditScreen = () => import('_screens/Home/UserApp/Quizzes/QuizEditScreen')
const QuizStatScreen = () => import('_screens/Home/UserApp/Quizzes/QuizStatScreen')

const quizzesRoutes = [
    {
        path: 'quizzes',
        component: QuizzesNavigator,
        children: [
            {
                path: '',
                name: 'UserAppQuizzes',
                component: QuizzesScreen,
                meta: {
                    screenOptions: {
                        title: 'Опросы',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_NEWS_QUIZ],
                },
            },
            {
                path: 'edit/:quizId?',
                name: 'UserAppQuizEdit',
                component: QuizEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Опрос',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_NEWS_QUIZ],
                },
            },
            {
                path: 'stat/:quizId?',
                name: 'UserAppQuizStat',
                component: QuizStatScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Статистика',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_NEWS_QUIZ],
                },
            },
        ],
    },
]

export default quizzesRoutes
