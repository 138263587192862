import { PERMISSIONS } from '_features/permissions/constants'

const NewsNavigator = () => import('_navigations/Home/UserApp/NewsNavigator')
const NewsCategoriesNavigator = () => import('_navigations/Home/UserApp/News/NewsCategoriesNavigator')

const NewsListScreen = () => import('_screens/Home/UserApp/News/NewsListScreen')
const NewsEditScreen = () => import('_screens/Home/UserApp/News/NewsEditScreen')

const NewsCategoriesScreen = () => import('_screens/Home/UserApp/News/Categories/NewsCategoriesScreen')
const NewsCategoryEditScreen = () => import('_screens/Home/UserApp/News/Categories/NewsCategoryEditScreen')

const newsRoutes = [
    {
        path: 'news',
        component: NewsNavigator,
        children: [
            {
                path: '',
                name: 'UserAppNewsList',
                component: NewsListScreen,
                meta: {
                    screenOptions: {
                        title: 'Новости',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_NEWS],
                },
            },
            {
                path: 'edit/:newsId?',
                name: 'UserAppNewsEdit',
                component: NewsEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Новость',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_NEWS],
                },
            },
            {
                path: 'categories',
                component: NewsCategoriesNavigator,
                children: [
                    {
                        path: '',
                        name: 'UserAppNewsCategories',
                        component: NewsCategoriesScreen,
                        meta: {
                            showBack: true,
                            screenOptions: {
                                title: 'Категории новостей',
                            },
                            permissions: [PERMISSIONS.CAN_VIEW_NEWS_CATEGORY],
                        },
                    },
                    {
                        path: 'edit/:newsCategoryId?',
                        name: 'UserAppNewsCategoryEdit',
                        component: NewsCategoryEditScreen,
                        meta: {
                            showBack: true,
                            screenOptions: {
                                title: 'Категория новостей',
                            },
                            permissions: [PERMISSIONS.CAN_CHANGE_NEWS_CATEGORY],
                        },
                    },
                ],
            },
        ],
    },
]

export default newsRoutes
