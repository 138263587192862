import { PERMISSIONS } from '_features/permissions/constants'

const MobileAnalyticsNavigator = () => import('_navigations/Home/MobileAnalytics/MobileAnalyticsNavigator')

const PhotoReportsScreen = () => import('_screens/Home/MobileAnalytics/PhotoReportsScreen')
const PhotoReportViewScreen = () => import('_screens/Home/MobileAnalytics/PhotoReportViewScreen')
const MonthReportsScreen = () => import('_screens/Home/MobileAnalytics/MonthReportsScreen')
const MonthReportViewScreen = () => import('_screens/Home/MobileAnalytics/MonthReportViewScreen')

const mobileAnalyticsRoutes = [
    {
        path: 'mobile-analytics',
        component: MobileAnalyticsNavigator,
        children: [
            {
                path: 'photo-reports',
                name: 'PhotoReports',
                component: PhotoReportsScreen,
                meta: {
                    screenOptions: {
                        title: 'Фотоотчеты',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_PHOTOREPORT],
                },
            },
            {
                path: 'view/:photoReportId?',
                name: 'PhotoReportView',
                component: PhotoReportViewScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Фотоотчет',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_PHOTOREPORT],
                },
            },
            {
                path: 'month-reports',
                name: 'MonthReports',
                component: MonthReportsScreen,
                meta: {
                    screenOptions: {
                        title: 'Отчеты',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_SCREPORT],
                },
            },
            {
                path: 'view/:monthReportId?',
                name: 'MonthReportView',
                component: MonthReportViewScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Отчет',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_SCREPORT],
                },
            },
        ],
    },
]

export default mobileAnalyticsRoutes
