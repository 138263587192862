import Vue from 'vue'

const mutations = {
    fetchServicesRequest(state) {
        state.isFetching = true
    },

    fetchServicesSuccess(state, { items }) {
        state.errors = []
        state.isFetching = false

        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchServicesError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },

    deleteServiceRequest(state) {
        state.isDeleting = true
    },

    deleteServiceSuccess(state, { serviceId }) {
        state.errors = []
        state.isDeleting = false
        Vue.delete(state.items, serviceId)
    },

    deleteServiceError(state, errors) {
        state.errors = errors
        state.isDeleting = false
    },

    clearServices(state) {
        state.items = {}
    },
}

export default mutations
