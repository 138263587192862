<template>
    <div>
        <v-select
            v-model="categoryModel"
            :disabled="disabled"
            :items="category"
            :label="fieldLabels.sampleCategory"
            outlined
            item-value="id"
            item-text="name"
            @change="(id) => getTemplateType(id, 'category')"
        />
        <v-select
            v-model="viewModel"
            :disabled="!categoryModel || sampleStep === 'category'"
            :items="view"
            :label="fieldLabels.sampleView"
            outlined
            item-value="id"
            item-text="name"
            @change="(id) => getTemplateType(id, 'view')"
        />
        <v-select
            v-model="typeModel"
            :disabled="!viewModel || sampleStep === 'view'"
            :items="type"
            :label="fieldLabels.sampleType"
            outlined
            item-value="id"
            item-text="name"
            @change="(id) => getTemplateItem(id, 'type')"
        />
    </div>
</template>

<script>
    // TODO: Перейти на древовидную структуру вместо category/view/type, т.к. технически, это одна и та же сущность (на back одна таблица с parentId)

    import { API } from '_api'

    import PushMessageTemplate from '_entities/pushMessage/PushMessageTemplate'

    export default {
        name: 'CPushMessagesTemplateSelection',

        props: {
            fieldLabels: {
                type: Object, default: null,
            },
        },

        data: () => ({
            items: [],
            disabled: false,
            categoryModel: null,
            viewModel: null,
            typeModel: null,
            category: [],
            view: [],
            type: [],
            sample: null,
            sampleStep: null,
        }),

        mounted() {
            this.getTemplateType()
        },

        methods: {
            async getTemplateType(parentId, category) {
                try {
                    const response = await API.v1.services.newsletter.getListOfTypeTemplate({
                        parent_id: parentId || null,
                    })

                    if (!parentId) {
                        this.category = response?.results
                    } else if (category === 'category') {
                        this.viewModel = this.typeModel = null
                        this.sample = this.sampleStep = null
                        this.view = response?.results
                    } else {
                        this.type = response?.results
                        this.sample = this.sampleStep = null
                    }

                    if (parentId) {
                        this.getTemplateItem(parentId, category)
                    }
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить список типов', e)
                    this.$logger.logException(e)
                }
            },

            async getTemplateItem(id, category) {
                try {
                    const response = await API.v1.services.newsletter.getTemplate({
                        type_id: id,
                    })

                    if (response?.results?.[0]) {
                        this.sample = new PushMessageTemplate(response?.results?.[0])
                        this.sampleStep = category
                        this.$emit('addSample', this.sample)
                    }
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить список типов', e)
                    this.$logger.logException(e)
                }
            },
        },
    }
</script>

<style scoped lang="scss">

</style>
