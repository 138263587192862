import { PERMISSIONS } from '_features/permissions/constants'

const DepartmentsNavigator = () => import('_navigations/Home/Departments/DepartmentsNavigator')

const DepartmentsScreen = () => import('_screens/Home/Departments/DepartmentsScreen')
const DepartmentEditScreen = () => import('_screens/Home/Departments/DepartmentEditScreen')

const departmentsRoutes = [
    {
        path: 'departments',
        component: DepartmentsNavigator,
        children: [
            {
                path: '',
                name: 'Departments',
                component: DepartmentsScreen,
                meta: {
                    screenOptions: {
                        title: 'Отделы',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_DEPARTMENT],
                },
            },
            {
                path: 'edit/:departmentId?',
                name: 'DepartmentEdit',
                component: DepartmentEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Отдел',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_DEPARTMENT],
                },
            },
        ],
    },
]

export default departmentsRoutes
