const mutations = {
    clearTicket(state) {
        state.errors = []
        state.isFetching = false
        state.item = null
    },

    fetchTicketRequest(state) {
        state.isFetching = true
    },

    fetchTicketSuccess(state, { ticket }) {
        state.errors = []
        state.isFetching = false
        state.item = ticket
    },

    fetchTicketError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },
}

export default mutations
