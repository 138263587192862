import Entity from '_entities/base/EntityNew'

import User from '_entities/user/User'

export const PushMessageTemplateCheckResultSchema = {
    name: 'PushMessageTemplateCheckResult',
    primaryKey: 'id',
    properties: {
        identicalMessageCreatedAt: { type: 'string' }, // UNIX
        identicalMessageCreatedBy: { type: 'User', entity: User },
        isUnique: { type: 'bool' },
        text: { type: 'string' },
    },
}

class PushMessageTemplateCheckResult extends Entity {
    get schema() {
        return PushMessageTemplateCheckResultSchema
    }

    get dataMap() {
        return {
            identical_message_created_at: 'identicalMessageCreatedAt',
            identical_message_created_by: 'identicalMessageCreatedBy',
            is_unique: 'isUnique',
        }
    }
}

export default PushMessageTemplateCheckResult
