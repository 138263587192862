import Vue from 'vue'

const mutations = {
    fetchTicketRoutesRequest(state) {
        state.isFetching = true
    },

    fetchTicketRoutesSuccess(state, { items }) {
        state.isFetching = false
        state.error = null

        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchTicketRoutesError(state, error) {
        state.isFetching = false
        state.error = error
    },
}

export default mutations
