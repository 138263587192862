import { API } from '_api'

import User from '_entities/user/User'
import Worker from '_entities/worker/Worker'

const actions = {
    async restoreAccess({ commit }) {
        commit('restoreAccessRequest')

        try {
            const accessToken = await localStorage.getItem('accessToken')
            const userId = await localStorage.getItem('userId')

            commit('restoreAccessSuccess', { accessToken, userId })
        } catch (e) {
            commit('restoreAccessError', e)
        }
    },

    async signIn({ commit }, { username, password }) {
        commit('signInRequest')

        try {
            const response = await API.v1.services.auth.signIn({ username, password })

            const accessToken = response?.access_token || null
            const userId = response?.user_id || null

            if (accessToken) {
                await localStorage.setItem('accessToken', accessToken)
            }

            if (userId) {
                await localStorage.setItem('userId', userId)
            }

            commit('signInSuccess', { accessToken, userId })
        } catch (e) {
            commit('signInError', e)
        }
    },

    async signOut({ commit }) {
        commit('signOutRequest')

        try {
            await localStorage.removeItem('accessToken')
            await localStorage.removeItem('userId')

            commit('signOutSuccess')
        } catch (e) {
            commit('signOutError', e)
        }
    },

    async fetchCurrentWorker({ commit, state }) {
        commit('fetchCurrentWorkerRequest')
        let response

        try {
            response = await API.v1.services.worker.getCurrentWorker()
            const currentWorker = new Worker(response)

            response = await API.v1.services.user.getUserById(currentWorker.id)
            const currentUser = new User(response)

            commit('fetchCurrentWorkersSuccess', { currentWorker, currentUser })
        } catch (e) {
            commit('fetchCurrentWorkersError', e)
        }
    },

    async changeWorkingStatus({ commit }) {
        commit('saveCurrentWorkerRequest')
        try {
            await API.v1.services.worker.changeWorkStatus()

            commit('saveCurrentWorkerSuccsess')
        } catch (e) {
            commit('saveWorCurrentkerError', e)
        }
    },
}

export default actions
