import { PERMISSIONS } from '_features/permissions/constants'

const ChatBotsNavigator = () => import('_navigations/Home/UserApp/ChatBotsNavigator')

const ChatBotsScreen = () => import('_screens/Home/UserApp/ChatBots/ChatBotsScreen')
const ChatBotEditScreen = () => import('_screens/Home/UserApp/ChatBots/ChatBotEditScreen')

const chatBotRoutes = [
    {
        path: 'chat-bot',
        component: ChatBotsNavigator,
        children: [
            {
                path: '',
                name: 'UserAppChatBots',
                component: ChatBotsScreen,
                meta: {
                    screenOptions: {
                        title: 'Чат-боты',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_CHAT_BOT],
                },
            },
            {
                path: 'edit/:chatBotId?',
                name: 'UserAppChatBotEdit',
                component: ChatBotEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Чат-бот',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_CHAT_BOT],
                },
            },
        ],
    },
]

export default chatBotRoutes
