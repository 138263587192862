<template>
    <div class="black white--text pa-4 my-4">
        <pre><slot /></pre>
    </div>
</template>

<script>
    export default {
        name: 'CDebug',
    }
</script>
