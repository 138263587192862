import { map as _map, filter as _filter, find as _find, forEach as _forEach } from 'lodash'

import Entity from '_entities/base/EntityNew'

import FilterField from '_entities/filter/FilterField'
import FilterPreset from '_entities/filter/FilterPreset'

export const FilterSchema = {
    name: 'Filter',
    properties: {
        activePresetId: { type: 'string' },
        code: { type: 'string' },
        fields: { type: 'FilterField[]', entity: FilterField, default: [] },
        presets: { type: 'FilterPreset[]', entity: FilterPreset, default: [] },
        search: { type: 'string' },
    },
}

class Filter extends Entity {
    get schema() {
        return FilterSchema
    }

    static fieldLabels = {
        code: 'Код',
        search: 'Поиск',
        fields: 'Поля',
        presets: 'Пресеты',
        activePresetId: 'Идентификатор активного пресета',
    }

    get simplified() {
        return {
            code: this.code,
            search: this.search,
            fields: _filter(_map(this.fields, (field) => {
                return field.simplified
            }), (field) => field),
            presets: _filter(_map(this.presets, (preset) => {
                return preset.simplified
            }), (preset) => preset),
            activePresetId: this.activePresetId,
        }
    }

    get activeFilterPreset() {
        return _find(this.presets, (o) => o.id === this.activePresetId)
    }

    get filterValues() {
        const filterValues = []

        _forEach(this.fields, (filterField) => {
            const fieldValues = filterField.getValues()

            _forEach(fieldValues, (fieldValue) => {
                filterValues.push({
                    fieldCode: fieldValue.code,
                    value: fieldValue.value,
                })
            })
        })

        return filterValues
    }
}

export default Filter
