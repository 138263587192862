import Vue from 'vue'

const mutations = {
    clearQuiz(state) {
        state.isSaving = false
        state.isFetching = false
        state.errors = []
        state.item = null
    },

    fetchQuizRequest(state) {
        state.isFetching = true
    },

    fetchQuizSuccess(state, { item }) {
        state.isFetching = false
        state.errors = []
        state.item = item
    },

    fetchQuizError(state, errors) {
        state.isFetching = false
        state.errors = errors
    },

    updateQuizRequest(state) {
        state.isSaving = true
    },

    updateQuizSuccess(state) {
        state.error = []
        state.isSaving = false
    },

    updateQuizError(state, errors) {
        state.errors = errors
        state.isSaving = false
    },

    fetchQuizStatRequest(state) {
        state.isFetching = true
    },

    fetchQuizStatSuccess(state, { items }) {
        state.isFetching = false
        state.errors = []
        items.forEach((item) => {
            Vue.set(state.questions, item.id, item)
        })
    },

    fetchQuizStatError(state, errors) {
        state.isFetching = false
        state.errors = errors
    },

    clearQuizStat(state) {
        state.questions = {}
    },
}

export default mutations
