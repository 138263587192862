import { request } from '_api/v1/base/behemoth/client'

export const getPremises = async (params) => {
    return await request('GET', 'v2/building/premise/', params)
}

export const getPremiseById = async (premiseId) => {
    return await request('GET', `v2/building/premise/${premiseId}/`)
}

export const getPremiseTypes = async (params) => {
    return await request('GET', 'building/premise_type/', params)
}

export const getPremiseTypeById = async (premiseId, params) => {
    return await request('GET', `building/premise_type/${premiseId}/`, params)
}
