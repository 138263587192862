import mutations from './mutations'
import actions from './actions'

const state = {
    errors: [],
    isFetching: false,
    timeEntry: null,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
