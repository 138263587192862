// import { PERMISSIONS } from '_features/permissions/constants'

const SolutionNavigator = () => import('_screens/Home/Tickets/Solution/SolutionNavigator')
const SolutionsScreen = () => import('_screens/Home/Tickets/Solution/SolutionsScreen')
const MySolutionsScreen = () => import('_screens/Home/Tickets/Solution/MySolutionsScreen')

const solutionRoutes = [
    {
        path: 'solutions',
        name: 'SolutionNavigator',
        component: SolutionNavigator,
        children: [
            {
                path: 'list',
                name: 'TicketSolutions',
                component: SolutionsScreen,
                meta: {
                    screenOptions: {
                        title: 'Комментарии',
                    },
                    // permissions: [PERMISSIONS.CAN_VIEW_ROUTER_TICKET_ROUTE]
                },
            },
            {
                path: 'my-list',
                name: 'MyTicketSolutions',
                component: MySolutionsScreen,
                meta: {
                    screenOptions: {
                        title: 'Мои запросы',
                    },
                    // permissions: [PERMISSIONS.CAN_VIEW_ROUTER_TICKET_ROUTE]
                },
            },
        ],
    },
]

export default solutionRoutes
