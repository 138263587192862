import mutations from './mutations'
import actions from './actions'

const state = {
    queueStatus: false,
    callQueue: null,
}

export default {
    namespaced: true,
    actions,
    state,
    mutations,
}
