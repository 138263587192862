import { find as _find, includes as _includes } from 'lodash'

const getters = {
    isAuthorized: (state) => {
        return !!state.accessToken
    },

    getError: (state) => (codes) => {
        return _find(state.errors, (item) => {
            return _includes(codes, item.code)
        })
    },
}

export default getters
