import Entity from '_entities/base/EntityNew'

export const PhoneSettingsSchema = {
    name: 'PhoneSettings',
    properties: {
        displayName: { type: 'string' },
        privateIdentity: { type: 'string' },
        publicIdentity: { type: 'string' },
        password: { type: 'string' },
        realm: { type: 'string' },
        websocketServerURL: { type: 'string' },
    },
}

class PhoneSettings extends Entity {
    get schema() {
        return PhoneSettingsSchema
    }

    get dataMap() {
        return {
            display_name: 'displayName',
            impi: 'privateIdentity',
            impu: 'publicIdentity',
            websocket_proxy_url: 'websocketServerURL',
        }
    }

    static fieldLabels = {
        displayName: 'Имя',
        privateIdentity: 'Номер',
        publicIdentity: 'SIP',
        password: 'Пароль',
        realm: 'Сервер',
        websocketServerURL: 'Websocket сервер',
    }
}

export default PhoneSettings
