<template>
    <v-autocomplete
        v-model="model"
        v-bind="$attrs"
        clearable
        :disabled="$attrs.disabled || false"
        :hide-details="$attrs.hideDetails || false"
        :items="ticketTypesModel"
        item-text="name"
        item-value="id"
        :label="label"
        :loading="isFetching"
        :multiple="multiple"
        outlined
        placeholder="Начните ввод.."
        :readonly="$attrs.readonly"
        return-object
        :rules="$attrs.rules || false"
        :search-input.sync="search"
        @focus.once="handleFetchData"
        @keydown="fetchData"
    />
</template>

<script>
    import { debounce as _debounce } from 'lodash'

    import { API } from '_api'

    import TicketType from '_entities/ticket/TicketType'

    export default {
        name: 'FTicketTypesSelect',

        props: {
            apiFilter: { type: Object, default: null },
            filter: { type: Object, default: null },
            label: { type: String, default: 'Тип заявки' },
            multiple: { type: Boolean },
            value: { type: [Array, Object], default: null },
        },

        data: () => ({
            search: null,
            ticketTypes: [],
        }),

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            ticketTypesModel() {
                if (this.multiple && this.value?.length) {
                    return [...this.ticketTypes, ...this.value]
                } else if (!this.multiple && this.value) {
                    return [...this.ticketTypes, this.value]
                } else {
                    return this.ticketTypes
                }
            },

            params() {
                let params = {}

                if (this.search) {
                    params.search = this.search
                }

                if (this.apiFilter) {
                    params = {
                        ...params,
                        ...this.apiFilter,
                    }
                }

                return params
            },
        },

        watch: {
            value: {
                handler() {
                    this.search = null
                },
            },

            params: {
                handler: 'handleFetchData',
                immediate: true,
                deep: true,
            },
        },

        created() {
            this.fetchData = _debounce(() => {
                this.handleFetchData()
            }, 800)

            this.handleFetchData()
        },

        methods: {
            async handleFetchData() {
                try {
                    const response = await API.v1.services.ticketType.getTicketTypes(this.params)

                    const { results } = response

                    const items = []

                    if (results) {
                        results?.forEach((result) => {
                            items.push(new TicketType(result))
                        })
                    }

                    this.ticketTypes = items
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось загрузить данные типов заявки', e)
                    this.$logger.logException(e)
                }
            },
        },
    }
</script>
