<template>
    <v-autocomplete
        v-model="model"
        v-bind="$attrs"
        clearable
        :hide-details="hideDetails"
        :items="pushMessageTypes"
        item-value="code"
        item-text="name"
        label="Тип сообщения"
        outlined
    />
</template>

<script>
    import PushMessage from '_entities/pushMessage/PushMessage'

    export default {
        name: 'CPushMessageTypesSelect',

        props: {
            hideDetails: { type: Boolean },
            multiple: { type: Boolean },
            value: { type: [Array, String], default: null },
        },

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            pushMessageTypes() {
                return PushMessage.types
            },
        },
    }
</script>
