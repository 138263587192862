import { API } from '_api'

import Story from '_entities/story/Story'

const actions = {
    async fetchStories({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchStoriesRequest')

            try {
                const response = await API.v1.services.story.getStories(params)

                const { results } = response

                const items = []

                if (results) {
                    results?.forEach((result) => {
                        items.push(new Story(result))
                    })
                }

                commit('fetchStoriesSuccess', { items })
            } catch (e) {
                commit('fetchStoriesError', e)
            }
        }
    },

    async deleteStory({ commit }, params) {
        commit('deleteStoryRequest')

        try {
            const storyId = params?.storyId || null
            if (storyId) {
                await API.v1.services.story.deleteStory(storyId)
            }

            commit('deleteStorySuccess', { storyId })
        } catch (e) {
            commit('deleteStoryError', e)
        }
    },
}

export default actions
