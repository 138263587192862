import Entity from '_entities/base/EntityNew'

import moment from 'moment'

export const MaintenanceStatusSchema = {
    name: 'MaintenanceStatus',
    primaryKey: 'id',
    properties: {
        dateFinish: { type: 'int' },
        dateStart: { type: 'int' },
        id: { type: 'string' }, // UUID
    },
}

class MaintenanceStatus extends Entity {
    get schema() {
        return MaintenanceStatusSchema
    }

    get dataMap() {
        return {
            date_finish: 'dateFinish',
            date_start: 'dateStart',
        }
    }

    static fieldLabels = {
        dateFinish: 'Время окончания',
        dateStart: 'Время начала',
    }

    get statusCode() {
        const now = moment().unix()

        if (this.dateStart && this.dateFinish) {
            if (this.dateStart > now) {
                return 'coming_soon'
            } else if (this.dateStart <= now && this.dateFinish > now) {
                return 'in_progress'
            } else if (this.dateFinish <= now) {
                return 'over'
            }
        }

        return null
    }
}

export default MaintenanceStatus
