const mutations = {
    clearTicketRoute(state) {
        state.isFetching = false
        state.error = null
        state.item = null
    },

    fetchTicketRouteRequest(state) {
        state.isFetching = true
    },

    fetchTicketRouteSuccess(state, { item }) {
        state.isFetching = false
        state.error = null
        state.item = item
    },

    fetchTicketRouteError(state, error) {
        state.isFetching = false
        state.error = error
    },
}

export default mutations
