import { request } from '_api/v1/base/behemoth/client'

export const signIn = async ({ username, password }) => {
    return await request('POST', 'user/auth/login/', { username, password })
}

export const sendConfirmCode = async ({ data }) => {
    return await request('POST', 'user/auth/send_confirm_code/', data)
}

export const resetPassword = async ({ data }) => {
    return await request('POST', 'user/auth/reset_password/', data)
}
