import { PERMISSIONS } from '_features/permissions/constants'

const LegalDocumentsNavigator = () => import('_navigations/Home/UserApp/LegalDocumentsNavigator')

const LegalDocumentsScreen = () => import('_screens/Home/UserApp/LegalDocuments/LegalDocumentsScreen')
const LegalDocumentEditScreen = () => import('_screens/Home/UserApp/LegalDocuments/LegalDocumentEditScreen')

const legalDocumentsRoutes = [
    {
        path: 'legal-documents',
        component: LegalDocumentsNavigator,
        children: [
            {
                path: '',
                name: 'UserAppLegalDocuments',
                component: LegalDocumentsScreen,
                meta: {
                    screenOptions: {
                        title: 'Правовые документы',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_LEGAL_DOCUMENT],
                },
            },
            {
                path: 'edit/:docId?',
                name: 'UserAppLegalDocumentEdit',
                component: LegalDocumentEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Правовой документ',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_LEGAL_DOCUMENT],
                },
            },
        ],
    },
]

export default legalDocumentsRoutes
