import Vue from 'vue'

const mutations = {
    fetchCameraGroupsRequest(state) {
        state.isFetching = true
    },

    fetchCameraGroupsSuccess(state, { items }) {
        state.error = []
        state.isFetching = false

        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchCameraGroupsError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },

    clearCameraGroups(state) {
        state.items = {}
    },
}

export default mutations
