<template>
    <v-snackbar
        v-model="isShown"
        :absolute="absolute"
        :bottom="bottom"
        :color="notificationColor"
        :left="left"
        :right="right"
        style="z-index: 999;"
        :timeout="notificationTimeout"
        :top="top"
    >
        <div v-if="notificationTitle">
            <b>{{ notificationTitle }}</b>
        </div>
        {{ notificationText }}
        <template v-slot:action="{ attrs }">
            <v-btn
                v-if="notificationAction"
                color="white"
                text
                v-bind="attrs"
                @click="notificationAction.click()"
            >
                {{ notificationAction.text }}
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'CSnackbar',

        data: () => ({
            isShown: false,
            modes: {
                default: {
                    absolute: false,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    top: 0,
                },
                bottomRight: {
                    absolute: true,
                    bottom: 30,
                    left: 0,
                    right: 30,
                    top: 0,
                },
                topRight: {
                    absolute: true,
                    bottom: 0,
                    left: 0,
                    right: 30,
                    top: 30,
                },
                bottomLeft: {
                    absolute: true,
                    bottom: 30,
                    left: 30,
                    right: 0,
                    top: 0,
                },
                topLeft: {
                    absolute: true,
                    bottom: 0,
                    left: 30,
                    right: 0,
                    top: 30,
                },
            },
            notification: undefined,
            timeout: 5000,
        }),

        computed: {
            ...mapGetters({
                notifications: 'notifications/getLowNotifications',
            }),

            notificationText() {
                return this.notification?.text || null
            },

            notificationTitle() {
                return this.notification?.title || null
            },

            notificationColor() {
                return this.notification?.color || null
            },

            notificationTimeout() {
                return this.notification?.timeout || null
            },

            notificationAction() {
                return this.notification?.action || null
            },

            mode() {
                return this.notification?.mode || 'default'
            },

            absolute() {
                return this.modes[this.mode].absolute
            },

            bottom() {
                return this.modes[this.mode].bottom
            },

            left() {
                return this.modes[this.mode].left
            },

            right() {
                return this.modes[this.mode].right
            },

            top() {
                return this.modes[this.mode].top
            },
        },

        watch: {
            notifications: {
                handler: function (notifications) {
                    if (notifications.length) {
                        this.showNotifications()
                    }
                },
                immediate: true,
            },

            isShown(isShown) {
                if (!isShown) {
                    if (this.notification) {
                        this.$store.commit('notifications/deleteNotification', this.notification.id)
                    }
                }
            },
        },

        methods: {
            showNotifications() {
                if (!this.showNotificationsInterval) {
                    if (this.notifications.length) {
                        this.setNotification(this.notifications[0] || null)

                        this.showNotificationsInterval = window.setInterval(() => {
                            if (this.notifications.length) {
                                this.setNotification(this.notifications[0] || null)
                            } else {
                                window.clearInterval(this.showNotificationsInterval)
                                this.showNotificationsInterval = undefined
                                this.notification = undefined
                            }
                        }, this.timeout + 500)
                    }
                }
            },

            setNotification(payload) {
                const id = payload.id || null
                const text = payload.text || null
                const title = payload.title || null
                const color = payload.color || null
                const action = payload.action || null
                const timeout = payload.timeout || this.timeout
                const mode = payload.mode

                if ((text || title) && id) {
                    this.notification = {
                        id,
                        text,
                        title,
                        color,
                        action,
                        timeout,
                        mode,
                    }

                    this.isShown = true
                }
            },
        },
    }
</script>
