<template>
    <div class="no-copy">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'UINoCopy',
    }
</script>

<style lang="scss" scoped>
    .no-copy {
        user-select: none;
    }
</style>
