<template>
    <div>
        <v-row dense align="baseline">
            <v-col>
                <v-text-field
                    v-model="model.from"
                    dense
                    readonly
                    label="с"
                    outlined
                    @click="dialogFrom = true"
                />
            </v-col>
            <v-col>
                <v-text-field
                    v-model="model.to"
                    dense
                    label="по"
                    outlined
                    readonly
                    @click="dialogTo = true"
                />
            </v-col>
            <v-btn
                v-if="clearable"
                color="primary"
                text
                @click="clearRange"
            >
                очистить
            </v-btn>
            <v-btn
                v-if="allowDelete"
                color="primary"
                text
                @click="deleteRange"
            >
                удалить
            </v-btn>
        </v-row>
        <v-dialog
            v-model="dialogFrom"
            max-width="300"
        >
            <v-time-picker
                v-model="model.from"
                format="24hr"
                :max="maxFrom"
                :min="minFrom"
            />
        </v-dialog>
        <v-dialog
            v-model="dialogTo"
            max-width="300"
        >
            <v-time-picker
                v-model="model.to"
                format="24hr"
                :min="minTo"
                :max="maxTo"
            />
        </v-dialog>
    </div>
</template>

<script>
    import Range from '_entities/range/Range'

    export default {
        name: 'UITimeRange',

        props: {
            allowDelete: { type: Boolean, default: true },
            clearable: { type: Boolean, default: false },
            max: { type: String, default: null },
            min: { type: String, default: null },
            value: { type: Object, default: () => new Range() },
        },

        data: () => ({
            dialogFrom: false,
            dialogTo: false,
        }),

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },
            minFrom() {
                return (this.min !== '0') ? this.min : '00:00'
            },

            maxFrom() {
                let max = '23:59'
                if (this.model?.to !== '') {
                    max = this.model?.to
                } else if (this.max !== '0') {
                    max = this.max
                }
                return max
            },

            minTo() {
                let min = '00:00'
                if (this.model.from !== '') {
                    min = this.model.from
                } else if (this.min !== '0') {
                    min = this.min
                }
                return min
            },

            maxTo() {
                return (this.max !== '0') ? this.max : '23:59'
            },
        },

        methods: {
            clearRange() {
                this.$emit('clear')
            },

            deleteRange() {
                this.$emit('delete')
            },
        },
    }
</script>
