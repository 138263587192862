import { API } from '_api'

import TimeEntry from '_entities/timeEntry/TimeEntry'

const actions = {
    async getCurrentTimeEntry({ commit }, userId) {
        commit('getTimeEntryRequest')

        try {
            const response = await API.v1.services.timeManagement.getCurrentTimeEntry(userId)

            let timeEntry

            if (response?.current_time_entry) {
                timeEntry = new TimeEntry(response.current_time_entry)
            }

            commit('getTimeEntrySuccess', timeEntry)
        } catch (e) {
            commit('getTimeEntryError', e)
        }
    },
}

export default actions
