import Vue from 'vue'

const mutations = {
    fetchServiceSubsectionsRequest(state) {
        state.isFetching = true
    },

    fetchServiceSubsectionsSuccess(state, { items }) {
        state.errors = []
        state.isFetching = false

        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchServiceSubsectionsError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },

    deleteServiceSubsectionRequest(state) {
        state.isDeleting = true
    },

    deleteServiceSubsectionSuccess(state, { serviceSubsectionId }) {
        state.errors = []
        state.isDeleting = false
        Vue.delete(state.items, serviceSubsectionId)
    },

    deleteServiceSubsectionError(state, errors) {
        state.errors = errors
        state.isDeleting = false
    },
}

export default mutations
