import Entity from '_entities/base/EntityNew'

export const TicketRejectReasonSchema = {
    name: 'TicketRejectReason',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        type: { type: 'string' },
    },
}

class TicketRejectReason extends Entity {
    get schema() {
        return TicketRejectReasonSchema
    }

    static fieldLabels = {
        name: 'Наименование',
        type: 'Тип',
    }
}

export default TicketRejectReason
