<template>
    <v-menu
        v-model="showDialog"
        :close-on-content-click="false"
        min-width="290px"
        :nudge-right="40"
        offset-y
        transition="scale-transition"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                v-model="dateFormatted"
                clearable
                :disabled="disabled"
                :label="label"
                outlined
                :prepend-inner-icon="icon"
                readonly
                v-bind="$attrs"
                v-on="on"
                @click:clear="model = null"
            />
        </template>
        <v-date-picker
            v-model="model"
            v-bind="$attrs"
            :first-day-of-week="1"
            @input="onChange"
        />
    </v-menu>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'UIDatePicker',

        props: {
            disabled: { type: Boolean, default: false },
            icon: { type: String, default: '$calendarDay' },
            label: { type: String, default: 'Дата' },
            value: { type: Number, default: null },
        },

        data: () => ({
            showDialog: false,
        }),

        computed: {
            model: {
                get() {
                    const date = this.value || moment().unix()

                    return moment.unix(date).locale('ru').format('YYYY-MM-DD')
                },
                set(value) {
                    this.$emit('input', moment(value).unix())
                },
            },

            dateFormatted() {
                return this.value ? moment.unix(this.value).locale('ru').format('LL') : null
            },
        },

        methods: {
            onChange() {
                this.showDialog = false
                this.$emit('change', this.model)
            },
        },
    }
</script>
