import { orderBy as _orderBy } from 'lodash'

const getters = {
    getStories: (state) => {
        return _orderBy(state.items, ['startDate', 'name'], ['desc', 'asc'])
    },

    getStoryById: (state) => (itemId) => {
        return state.items[itemId] || null
    },
}

export default getters
