import Entity from '_entities/base/EntityNew'

export const ChecklistFieldSchema = {
    name: 'ChecklistField',
    properties: {
        columnName: { type: 'string' },
        cols: { type: 'string' },
        inputType: { type: 'string' },
        possibleValues: { type: 'string[]', defaut: [] },
        value: { type: 'string' },
    },
}

class ChecklistField extends Entity {
    get schema() {
        return ChecklistFieldSchema
    }

    get dataMap() {
        return {
            column_name: 'columnName',
            input_type: 'inputType',
            possible_values: 'possibleValues',
        }
    }

    static fieldLabels = {
        columnName: 'Наименование',
        inputType: 'Тип',
        possibleValues: 'Значения',
        value: 'Значение',
    }

    toServer() {
        return {
            column_name: this.columnName,
            input_type: this.inputType,
            possible_values: this.possibleValues,
            value: this.value,
        }
    }
}

export default ChecklistField
