<template>
    <v-list-item
        :to="route"
        :disabled="!route && !onClick"
        :exact="exact"
        :class="needToHighLight ? 'v-list-item--active' : ''"
        @click="onClick ? onClick() : null"
    >
        <v-list-item-avatar v-if="type === 'groupItem'">
            <template v-if="avatar">
                {{ avatar }}
            </template>
        </v-list-item-avatar>
        <v-list-item-icon v-else>
            <v-badge
                v-if="iconChip && icon"
                :color="chipColor || 'white'"
                :content="iconChip > 99 ? '99+' : iconChip"
                offset-x="12"
                offset-y="12"
                text-color="white"
            >
                <v-icon v-if="icon" size="24" v-text="`$${icon}`" />
            </v-badge>

            <v-icon v-if="!iconChip && icon" size="24" v-text="`$${icon}`" />
        </v-list-item-icon>

        <v-list-item-content>
            <v-list-item-title>
                {{ title }}
                <v-chip
                    v-if="actionChip"
                    :color="chipColor"
                    small
                    text-color="white"
                >
                    {{ actionChip }}
                </v-chip>
            </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
            <v-list-item-action-text>
                {{ actionText }}
            </v-list-item-action-text>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
    export default {
        name: 'CMenuItem',

        props: {
            actionText: { type: String, default: null },
            actionChip: { type: String, default: null },
            avatar: { type: String, default: null },
            chipColor: { type: String, default: 'red' },
            iconChip: { type: String, default: null },
            type: { type: String, default: null },
            exact: { type: Boolean },
            icon: { type: String, default: null },
            route: { type: String, default: null },
            onClick: { type: Function, default: null },
            title: { type: String, default: null },
        },

        computed: {
            sameTicketStage() {
                const ticketStage = this.route?.query?.ticketsSection

                return ticketStage && ticketStage === this.$route?.query?.ticketsSection
            },

            needToHighLight() {
                return this.sameTicketStage
            },
        },
    }
</script>
