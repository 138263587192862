import TimeCounter from '_entities/timeCounters/TimeCounter'

class NeedAnswerTimeCounter extends TimeCounter {
    get schema() {
        return {
            ...super.schema,
        }
    }

    get dataMap() {
        return {
            ...super.dataMap,
        }
    }
}

export default NeedAnswerTimeCounter
