import Entity from '_entities/base/EntityNew'

import ChecklistField from '_entities/checklist/ChecklistField'

export const ChecklistElementSchema = {
    name: 'ChecklistElement',
    properties: {
        fields: { type: 'ChecklistField[]', entity: ChecklistField, defaut: () => [] },
    },
}

class ChecklistElement extends Entity {
    get schema() {
        return ChecklistElementSchema
    }

    static fieldLabels = {
        fields: 'Поля',
    }
}

export default ChecklistElement
