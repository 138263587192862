<template>
    <v-autocomplete
        v-model="model"
        v-bind="$attrs"
        clearable
        :disabled="disabled"
        :hide-details="hideDetails"
        :items="companies"
        item-value="id"
        item-text="name"
        :label="label"
        :loading="isFetching"
        :multiple="multiple"
        outlined
        placeholder="Начните ввод.."
        return-object
    />
</template>

<script>
    import { filter as _filter, forEach as _forEach } from 'lodash'
    import { mapGetters, mapState } from 'vuex'

    export default {
        name: 'FCCompaniesSelect',

        props: {
            disabled: { type: Boolean },
            filter: { type: Object, default: null },
            hideDetails: { type: Boolean },
            label: { type: String, default: 'Компания' },
            multiple: { type: Boolean },
            value: { type: Object, default: null },
        },

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            ...mapState('companies', {
                isFetching: (state) => state.isFetching,
            }),

            ...mapGetters({
                getCompanies: 'companies/getCompanies',
                getCompanyById: 'companies/getCompanyById',
            }),

            companies() {
                let companies = this.getCompanies

                if (this.filter) {
                    if (typeof this.filter === 'function') {
                        //
                    } else {
                        _forEach(this.filter, (filterItem) => {
                            companies = _filter(companies, filterItem)
                        })
                    }
                }

                return companies
            },
        },

        // TODO: переделать
        mounted() {
            this.fetchData()
        },

        methods: {
            fetchData() {
                this.$store.dispatch('companies/fetchCompanies')
            },
        },
    }
</script>
