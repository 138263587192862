import * as app from './app'
import * as absenceSchedule from './absenceSchedule'
import * as accident from './accident'
import * as analytics from './analytics'
import * as appealDocument from './appealDocument'
import * as approval from './approval'
import * as auth from './auth'
import * as building from './building'
import * as calendar from './calendar'
import * as call from './call'
import * as camera from './camera'
import * as chat from './chat'
import * as chatBot from './chatBot'
import * as city from './city'
import * as company from './company'
import * as department from './department'
import * as file from './file'
import * as premiseDocument from './premiseDocument'
import * as incomingDocument from './incomingDocument'
import * as intercom from './intercom'
import * as documentFile from './documentFile'
import * as documentSendingMethod from './documentSendingMethod'
import * as documentUtils from './documentUtils'
import * as dutySchedule from './dutySchedule'
import * as key from './key'
import * as keyIssue from './keyIssue'
import * as legalDocument from './legalDocument'
import * as maintenance from './maintenance'
import * as meter from './meter'
import * as news from './news'
import * as newsletter from './newsletter'
import * as notification from './notification'
import * as commercialAppealDocument from './commercialAppealDocument'
import * as outgoingDocument from './outgoingDocument'
import * as payment from './payment'
import * as permission from './permission'
import * as personalAccount from './personalAccount'
import * as pipe from './pipe'
import * as poll from './poll'
import * as premise from './premise'
import * as proxy from './proxy'
import * as queue from './queue'
import * as quiz from './quiz'
import * as residentialComplex from './residentialComplex'
import * as router from './router'
import * as entrance from './entrance'
import * as sector from './sector'
import * as service from './service'
import * as story from './story'
import * as sync from './sync'
import * as report from './report'
import * as ticket from './ticket'
import * as ticketDepartment from './ticketDepartment'
import * as ticketKind from './ticketKind'
import * as ticketSource from './ticketSource'
import * as ticketStage from './ticketStage'
import * as ticketType from './ticketType'
import * as timeline from './timeline'
import * as timeManagement from './timeManagement'
import * as user from './user'
import * as worker from './worker'

export const services = {
    app,
    absenceSchedule,
    accident,
    analytics,
    appealDocument,
    approval,
    auth,
    building,
    calendar,
    call,
    camera,
    chat,
    chatBot,
    city,
    company,
    department,
    file,
    premiseDocument,
    incomingDocument,
    intercom,
    documentFile,
    documentSendingMethod,
    documentUtils,
    dutySchedule,
    key,
    keyIssue,
    legalDocument,
    maintenance,
    meter,
    news,
    newsletter,
    notification,
    commercialAppealDocument,
    outgoingDocument,
    payment,
    permission,
    personalAccount,
    pipe,
    poll,
    premise,
    proxy,
    queue,
    quiz,
    residentialComplex,
    router,
    entrance,
    report,
    sector,
    service,
    story,
    sync,
    ticket,
    ticketDepartment,
    ticketKind,
    ticketSource,
    ticketStage,
    ticketType,
    timeline,
    timeManagement,
    user,
    worker,
}
