<template>
    <v-container fluid>
        <v-row align="baseline" dense>
            <v-col class="slot" cols="auto">
                <slot name="back" />
            </v-col>
            <v-col cols="auto">
                <slot />
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <slot name="actions" />
            </v-col>
        </v-row>
        <v-row class="slot" dense>
            <slot name="description" />
        </v-row>
        <v-row dense>
            <v-col class="slot">
                <slot name="bottom" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'UIHeader',
    }
</script>

<style scoped>
    .slot:empty{
        display: none;
    }
</style>
