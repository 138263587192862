import ServicePartnerCompany from '_entities/service/ServicePartnerCompany'

const mutations = {
    clearServicePartnerCompany(state) {
        state.item = new ServicePartnerCompany()
        state.isFetching = false
        state.isSaving = false
        state.errors = []

        state.isValidForm = true
    },

    setValidFormState(state, isValidForm) {
        state.isValidForm = !!isValidForm
    },

    // -------------------------------------------------------------------------

    fetchServicePartnerCompanyRequest(state) {
        state.item = new ServicePartnerCompany()
        state.isFetching = true
    },

    fetchServicePartnerCompanySuccess(state, { item }) {
        state.item = item
        state.isFetching = false
        state.errors = []
    },

    fetchServicePartnerCompanyError(state, errors) {
        state.isFetching = false
        state.errors = errors
    },

    // -------------------------------------------------------------------------

    saveServicePartnerCompanyRequest(state) {
        state.isSaving = true
    },

    saveServicePartnerCompanySuccess(state) {
        state.isSaving = false
        state.errors = []
    },

    saveServicePartnerCompanyError(state, errors) {
        state.isSaving = false
        state.errors = errors
    },
}

export default mutations
