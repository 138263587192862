import Vue from 'vue'

const mutations = {
    getNeedAnswerTimeCountersRequest(state) {
        state.isFetching = true
    },

    getNeedAnswerTimeCountersSuccess(state, timeCounters) {
        state.errors = []
        state.isFetching = false

        timeCounters.forEach((item) => {
            Vue.set(state.timeCounters, item.id, item)
        })
    },

    getNeedAnswerTimeCountersError(state, errors) {
        state.errors = errors
        state.isFetching = false
        state.timeCounters = {}
    },

    addedTimeCounter(state, timeCounter) {
        Vue.set(state.timeCounters, timeCounter.id, timeCounter)
    },

    changeTimeCounter(state, timeCounter) {
        Vue.set(state.timeCounters, timeCounter.id, timeCounter)
    },

    deleteTimeCounter(state, timeCounterId) {
        Vue.delete(state.timeCounters, timeCounterId)
    },
}

export default mutations
