import Entity from '_entities/base/EntityNew'

export const CalendarEventSchema = {
    name: 'CalendarEvent',
    primaryKey: 'id',
    properties: {
        entity: { type: 'object' }, // Полная сущность по данным entityType + entityId
        entityId: { type: 'string' }, // UUID
        entityType: { type: 'string' }, // text
        id: { type: 'string' }, // UUID
        slotId: { type: 'string' }, // UUID
        timeEnd: { type: 'int' }, // UNIX
        timeStart: { type: 'int' }, // UNIX
    },
}

class CalendarEvent extends Entity {
    get schema() {
        return CalendarEventSchema
    }

    get dataMap() {
        return {
            entity_id: 'entityId',
            entity_type: 'entityType',
            time_end: 'timeEnd',
            time_start: 'timeStart',
            slot: 'slotId',
        }
    }

    static fieldLabels = {
        entityId: 'ID сущности',
        entityType: 'Тип сущности',
        id: 'ID события',
        slot: 'Слот',
        timeEnd: 'Время окончания',
        timeStart: 'Время начала',
    }

    toArray() {
        return {
            entity_id: this.entityId,
            entity_type: this.entityType,
            slot: this.slot,
            time_end: this.timeEnd,
            time_start: this.timeStart,
        }
    }
}

export default CalendarEvent
