const CalendarNavigator = () => import('_navigations/Home/Calendar/CalendarNavigator')

const CalendarScreen = () => import('_screens/Home/Calendar/CalendarScreen')

const calendarRoutes = []

calendarRoutes.push({
    path: 'calendar',
    component: CalendarNavigator,
    children: [
        {
            path: 'calendar',
            name: 'Calendar',
            component: CalendarScreen,
            meta: {
                screenOptions: {
                    title: 'Календарь',
                },
            },
        },
    ],
})

export default calendarRoutes
