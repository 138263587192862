import { API } from '_api'

import ServiceSubsection from '_entities/service/ServiceSubsection'

const actions = {
    async fetchServiceSubsections({ commit }) {
        commit('fetchServiceSubsectionsRequest')

        try {
            const response = await API.v1.services.service.getServiceSubsections()

            const { results } = response

            const items = []

            if (results) {
                results?.forEach((result) => {
                    items.push(new ServiceSubsection(result))
                })
            }

            commit('fetchServiceSubsectionsSuccess', { items })
        } catch (e) {
            commit('fetchServiceSubsectionsError', e)

            return Promise.reject(e)
        }
    },

    async deleteServiceSubsection({ commit }, params) {
        commit('deleteServiceSubsectionRequest')

        try {
            const serviceSubsectionId = params?.serviceSubsectionId || null
            if (serviceSubsectionId) {
                await API.v1.services.service.deleteServiceSubsection(serviceSubsectionId)
            }

            commit('deleteServiceSubsectionSuccess', { serviceSubsectionId })
        } catch (e) {
            commit('deleteServiceSubsectionError', e)
        }
    },
}

export default actions
