import { orderBy as _orderBy } from 'lodash'

const getters = {
    getPushMessages: (state) => {
        return _orderBy(state.items, ['order', 'name'], ['asc', 'asc'])
    },

    getPushMessageById: (state) => (itemId) => {
        return state.items[itemId] || null
    },
}

export default getters
