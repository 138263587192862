import Entity from '_entities/base/EntityNew'

export const PremiseTypeLinkSchema = {
    name: 'PremiseTypeLink',
    primaryKey: 'id',
    properties: {
        code: { type: 'string' },
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class PremiseTypeLink extends Entity {
    get schema() {
        return PremiseTypeLinkSchema
    }

    static fieldLabels = {
        name: 'Наименование',
    }
}

export default PremiseTypeLink
