import { filter as _filter, orderBy as _orderBy } from 'lodash'

const getters = {
    getQueues: (state) => {
        return _orderBy(state.items, ['name'], ['asc'])
    },

    getPublicQueues: (state) => {
        const publicQueue = _filter(state.items, (item) => item.public === true)
        return _orderBy(publicQueue, ['name'], ['asc'])
    },
}

export default getters
