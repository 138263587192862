import ServicePromotion from '_entities/service/ServicePromotion'

const mutations = {
    clearServicePromotion(state) {
        state.item = new ServicePromotion()
        state.isFetching = false
        state.isSaving = false
        state.errors = []

        state.isValidForm = true
    },

    setValidFormState(state, isValidForm) {
        state.isValidForm = !!isValidForm
    },

    // -------------------------------------------------------------------------

    fetchServicePromotionRequest(state) {
        state.item = new ServicePromotion()
        state.isFetching = true
    },

    fetchServicePromotionSuccess(state, { item }) {
        state.item = item
        state.isFetching = false
        state.errors = []
    },

    fetchServicePromotionError(state, errors) {
        state.isFetching = false
        state.errors = errors
    },

    // -------------------------------------------------------------------------

    saveServicePromotionRequest(state) {
        state.isSaving = true
    },

    saveServicePromotionSuccess(state) {
        state.isSaving = false
        state.errors = []
    },

    saveServicePromotionError(state, errors) {
        state.isSaving = false
        state.errors = errors
    },
}

export default mutations
