import Vue from 'vue'

const mutations = {
    fetchStoriesRequest(state) {
        state.isFetching = true
    },

    fetchStoriesSuccess(state, { items }) {
        state.errors = []
        state.isFetching = false

        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchStoriesError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },

    deleteStoryRequest(state) {
        state.isFetching = true
    },

    deleteStorySuccess(state, { storyId }) {
        state.errors = []
        state.isFetching = false
        Vue.delete(state.items, storyId)
    },

    deleteStoryError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },
}

export default mutations
