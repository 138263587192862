import { request } from '_api/v1/base/behemoth/client'

export const createTicketType = async (params) => {
    return await request('POST', 'ticket/type/', params)
}

export const deleteTicketType = async (ticketTypeId) => {
    return await request('DELETE', `ticket/type/${ticketTypeId}/`)
}

export const getTicketTypes = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'post/ticket/types/', filters, { params })
}

export const getTicketTypeById = async (ticketTypeId) => {
    return await request('GET', `ticket/type/${ticketTypeId}/`)
}

export const updateTicketType = async (ticketTypeId, params) => {
    return await request('PUT', `ticket/type/${ticketTypeId}/`, params)
}
