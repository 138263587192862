import { API } from '_api'

import ServicePromotion from '_entities/service/ServicePromotion'

const actions = {
    async fetchServicePromotion({ commit, state }, { servicePromotionId }) {
        if (!state.isFetching) {
            commit('fetchServicePromotionRequest')

            try {
                const response = await API.v1.services.service.getServicePromotionById(servicePromotionId)

                const item = response ? new ServicePromotion(response) : null

                commit('fetchServicePromotionSuccess', { item })
            } catch (e) {
                commit('fetchServicePromotionError', e)

                return Promise.reject(e)
            }
        }
    },

    async saveServicePromotion({ commit, state }, { servicePromotion }) {
        if (!state.isSaving) {
            commit('saveServicePromotionRequest')

            try {
                const servicePromotionId = servicePromotion.id
                const data = servicePromotion.toServer()

                if (servicePromotionId) {
                    await API.v1.services.service.updateServicePromotion({ servicePromotionId, data })
                } else {
                    await API.v1.services.service.createServicePromotion({ data })
                }

                commit('saveServicePromotionSuccess')
            } catch (e) {
                commit('saveServicePromotionError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
