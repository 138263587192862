import Entity from '_entities/base/EntityNew'

export const NewsLinkSchema = {
    name: 'NewsLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        title: { type: 'string' },
    },
}

class NewsLink extends Entity {
    get schema() {
        return NewsLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        title: 'Заголовок',
    }
}

export default NewsLink
