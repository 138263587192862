import { API } from '_api'

import Sector from '_entities/sector/Sector'

const actions = {
    async fetchSectors({ commit, state }) {
        if (!state.isFetching) {
            commit('fetchSectorsRequest')

            try {
                const response = await API.v1.services.sector.getSectors()

                const { results } = response

                const items = []

                if (results) {
                    results?.forEach((result) => {
                        items.push(new Sector(result))
                    })
                }

                commit('fetchSectorsSuccess', { items })
            } catch (e) {
                commit('fetchSectorsError', e)
            }
        }
    },
}

export default actions
