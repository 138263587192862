import ServiceDiscountCard from '_entities/service/ServiceDiscountCard'

const mutations = {
    clearServiceDiscountCard(state) {
        state.item = new ServiceDiscountCard()
        state.isFetching = false
        state.isSaving = false
        state.errors = []

        state.isValidForm = true
    },

    setValidFormState(state, isValidForm) {
        state.isValidForm = !!isValidForm
    },

    // -------------------------------------------------------------------------

    fetchServiceDiscountCardRequest(state) {
        state.item = new ServiceDiscountCard()
        state.isFetching = true
    },

    fetchServiceDiscountCardSuccess(state, { item }) {
        state.item = item
        state.isFetching = false
        state.errors = []
    },

    fetchServiceDiscountCardError(state, errors) {
        state.isFetching = false
        state.errors = errors
    },

    // -------------------------------------------------------------------------

    saveServiceDiscountCardRequest(state) {
        state.isSaving = true
    },

    saveServiceDiscountCardSuccess(state) {
        state.isSaving = false
        state.errors = []
    },

    saveServiceDiscountCardError(state, errors) {
        state.isSaving = false
        state.errors = errors
    },
}

export default mutations
