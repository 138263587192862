export const PERMISSION_MODULES = {
    ACCEPTANCE: 'acceptance',
    ACCIDENT: 'accident',
    ANALYTICS: 'analytics',
    AUTH: 'auth',
    CALENDAR: 'bh_calendar',
    CALL: 'call',
    CHAT: 'chat',
    CHAT_BOT: 'chatbot',
    DEPARTMENT: 'department',
    DOCUMENT: 'document',
    FEEDBACK: 'feedback',
    FILES: 'files',
    LOCATION: 'location',
    METER: 'meter',
    NEWS: 'news',
    NEWSLETTER: 'newsletter',
    PAYMENT: 'payment',
    ROUTER: 'router',
    SERVICE: 'service',
    STORAGE: 'storage',
    TARIFF: 'tariff',
    TICKET: 'ticket',
    USER: 'user',
}
