import Entity from '_entities/base/EntityNew'

export const TicketSourceSchema = {
    name: 'TicketSource',
    primaryKey: 'id',
    properties: {
        code: { type: 'string' },
        id: { type: 'string' },
        name: { type: 'string' },
        order: { type: 'int' },
    },
}

class TicketSource extends Entity {
    get schema() {
        return TicketSourceSchema
    }

    static fieldLabels = {
        code: 'Код',
        id: 'ID',
        name: 'Название',
        order: 'Порядок',
    }
}

export default TicketSource
