import Entity from '_entities/base/EntityNew'

export const SizeSchema = {
    name: 'Size',
    properties: {
        code: { type: 'string' },
        url: { type: 'string' },
    },
}

class Size extends Entity {
    get schema() {
        return SizeSchema
    }

    static fieldLabels = {
        code: 'Код',
        url: 'URL',
    }
}

export default Size
