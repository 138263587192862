import ServiceElement from '_entities/service/ServiceElement'

export const ServiceElementRadioWithImageSchema = {
    elementType: { type: 'string', default: 'radiobutton_with_image' },
}

class ServiceElementRadioWithImage extends ServiceElement {
    get schema() {
        return {
            ...super.schema.name,
            ...super.schema.primaryKey,
            properties: {
                ...super.schema.properties,
                ...ServiceElementRadioWithImageSchema,
            },
        }
    }

    get dataMap() {
        return {
            ...super.dataMap,
            element_type: 'elementType',
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
    }
}

export default ServiceElementRadioWithImage
