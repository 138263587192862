<template>
    <v-dialog
        v-model="show"
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
    >
        <v-card>
            <v-toolbar :color="options.color" dark dense flat>
                <v-toolbar-title class="white--text">
                    {{ title }}
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <slot />
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn
                    text
                    @click.native="cancel"
                >
                    Отмена
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="!valid"
                    text
                    @click.native="agree"
                >
                    {{ okLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'UIDialog',

        props: {
            valid: { type: Boolean },
            value: { type: Object, default: null },
        },

        data: () => ({
            dialog: false,
            resolve: null,
            reject: null,
            title: null,
            data: null,
            options: {
                color: 'primary',
                width: 290,
                zIndex: 200,
            },
            okLabel: 'Сохранить',
        }),

        computed: {
            show: {
                get() {
                    return this.dialog
                },
                set(value) {
                    this.dialog = value
                    if (value === false) {
                        this.cancel()
                    }
                },
            },
        },

        methods: {
            open(title, options) {
                this.dialog = true
                this.title = title
                this.okLabel = options?.okLabel || this.okLabel
                this.options = Object.assign(this.options, options)
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },

            agree() {
                this.resolve(true)
                this.dialog = false
            },

            cancel() {
                this.resolve(false)
                this.dialog = false
            },
        },
    }
</script>
