const IMNavigator = () => import('_navigations/Home/IM/IMNavigator')

const IMScreen = () => import('_screens/Home/IM/IMScreen')

const imRoutes = [
    {
        path: 'im',
        redirect: { name: 'IM' },
        component: IMNavigator,
        children: [
            {
                path: '',
                name: 'IM',
                component: IMScreen,
                meta: {
                    screenOptions: {
                        title: 'Сообщения',
                    },
                },
            },
        ],
    },
]

export default imRoutes
