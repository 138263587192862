import mutations from './mutations'

import UserCounters from '_entities/user/UserCounters'

const state = {
    counters: new UserCounters(),
}

export default {
    namespaced: true,
    state,
    mutations,
}
