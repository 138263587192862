import Entity from '_entities/base/EntityNew'

import UserLink from '_entities/user/UserLink'

export const DepartmentSchema = {
    name: 'Department',
    primaryKey: 'id',
    properties: {
        head: { type: 'UserLink', entity: UserLink }, // UUID
        id: { type: 'string' },
        name: { type: 'string' },
        parent: { type: 'self' },
    },
}

class Department extends Entity {
    get schema() {
        return DepartmentSchema
    }

    static fieldLabels = {
        head: 'Начальник отдела',
        id: 'ID отдела',
        name: 'Наименование',
        parent: 'Родительский отдел',
    }

    toArray() {
        const data = super.toArray()

        return {
            ...data,
            head: this.head?.id || null,
            parent: this.parent?.id || null,
        }
    }
}

export default Department
