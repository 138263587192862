<template>
    <v-avatar :size="size">
        <v-img
            v-if="photoSizeUrl"
            :aspect-ratio="1"
            :src="photoSizeUrl"
            @error="imgError = true"
        />
        <div v-else>
            <v-icon small v-text="'$userCircle'" />
        </div>
    </v-avatar>
</template>

<script>
    export default {
        name: 'CAvatar',

        props: {
            size: { type: [Number, String], default: 48 },
            value: { type: Object, default: null },
        },

        data: () => ({
            imgError: false,
        }),

        computed: {
            photo() {
                return this.value
            },

            photoSize() {
                return this.photo?.getSize('s')
            },

            photoSizeUrl() {
                return !this.imgError ? this.photoSize?.url : null
            },
        },
    }
</script>
