import Vue from 'vue'

const mutations = {
    fetchTicketsRequest(state) {
        state.isFetching = true
    },

    fetchTicketsSuccess(state, { items }) {
        state.errors = []
        state.isFetching = false

        state.items = {}
        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchTicketsError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },
}

export default mutations
