import moment from 'moment'

import Entity from '_entities/base/EntityNew'

export const DatetimeStringRangeSchema = {
    name: 'DatetimeStringRange',
    properties: {
        from: { type: 'datetimeString', default: '' },
        to: { type: 'datetimeString', default: '' },
    },
}

class DatetimeStringRange extends Entity {
    get schema() {
        return DatetimeStringRangeSchema
    }

    static fieldLabels = {
        from: 'с',
        to: 'по',
    }

    toArray() {
        return {
            from: moment(this.from).format('YYYY-MM-DDTHH:mmZ'),
            to: moment(this.to).format('YYYY-MM-DDTHH:mmZ'),
        }
    }
}

export default DatetimeStringRange
