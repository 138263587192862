import Vue from 'vue'

const mutations = {
    fetchCamerasRequest(state) {
        state.isFetching = true
    },

    fetchCamerasSuccess(state, { items }) {
        state.errors = []
        state.isFetching = false

        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchCamerasError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },

    clearCameras(state) {
        state.items = {}
    },
}

export default mutations
