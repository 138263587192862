import { request } from '_api/v1/base/behemoth/client'

export const getAccidents = async (params) => {
    return await request('GET', 'accident/', params)
}

export const getAccidentById = async (accidentId) => {
    return await request('GET', `accident/${accidentId}/`)
}

export const getAccidentRecordLink = async (accidentId) => {
    return await request('GET', `accident/${accidentId}/file_link/`)
}

export const createAccident = async (data) => {
    return await request('POST', 'accident/', data)
}

export const updateAccident = async (accidentId, data) => {
    return await request('PUT', `accident/${accidentId}/`, data)
}

export const getAccidentTypes = async () => {
    return await request('GET', 'accident/accident_type/')
}

export const getAccidentCompanies = async () => {
    return await request('GET', 'accident/accident_company/')
}

export const getAccidentCreateText = async (params) => {
    return await request('POST', 'accident/create_text/', params)
}

export const getAccidentCreateAudio = async (params) => {
    return await request('GET', 'accident/create_audio/', params)
}
