import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
    errors: [],
    isAtWork: false,
    isFetchingCurrentWorker: false,
    isSavingWorkerData: false,
    isSigningIn: false,
    isSignedOut: false,
    accessToken: null,
    userId: null,
    currentUser: null,
    currentWorker: null,
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
