import Entity from '_entities/base/EntityNew'

import PushMessageTemplateField from '_entities/pushMessage/PushMessageTemplateField'

export const PushMessageTemplateSchema = {
    name: 'PushMessageTemplate',
    primaryKey: 'id',
    properties: {
        fields: { type: 'PushMessageTemplateField[]', entity: PushMessageTemplateField, default: [] },
        id: { type: 'string' }, // UUID
        nextDayDateLimit: { type: 'bool' },
        title: { type: 'string' },
        text: { type: 'string' },
    },
}

class PushMessageTemplate extends Entity {
    get schema() {
        return PushMessageTemplateSchema
    }

    get dataMap() {
        return {
            next_day_date_limit: 'nextDayDateLimit',
        }
    }
}

export default PushMessageTemplate
