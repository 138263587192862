import Entity from '_entities/base/EntityNew'

import CompanyLink from '_entities/company/CompanyLink'
import BuildingLink from '_entities/building/BuildingLink'

export const CameraGroupSchema = {
    name: 'CameraGroup',
    primaryKey: 'id',
    properties: {
        building: { type: 'BuildingLink', entity: BuildingLink },
        company: { type: 'CompanyLink', entity: CompanyLink },
        id: { type: 'int' },
        isActive: { type: 'bool', default: false },
        name: { type: 'string' },
    },
}

class CameraGroup extends Entity {
    get schema() {
        return CameraGroupSchema
    }

    get dataMap() {
        return {
            is_active: 'isActive',
        }
    }

    static fieldLabels = {
        building: 'Дом',
        company: 'Компания',
        id: 'ID',
        isActive: 'Активность',
        name: 'Название',
    }

    toServer() {
        return {
            building: this.building?.id,
            company: this.company?.id,
            is_active: this.isActive,
            name: this.name,
        }
    }
}

export default CameraGroup
