import TimeEntry from '_entities/timeEntry/TimeEntry'

import TimeEntryLink from '_entities/timeEntry/TimeEntryLink'

export const QueueTimeEntrySchema = {
    durationSum: { type: 'int' },
    isOnQueue: { type: 'bool' },
    isBusy: { type: 'bool' },
    onQueueDuration: { type: 'int' },
    parentTimeEntryId: { type: 'string' }, // UUID
    queueEntries: { type: 'TimeEntryLink[]', entity: TimeEntryLink, default: [] }, // Синтетическое, формируется в API
}

class QueueTimeEntry extends TimeEntry {
    get schema() {
        return {
            name: 'QueueTimeEntry',
            ...super.schema.primaryKey,
            properties: {
                ...super.schema.properties,
                ...QueueTimeEntrySchema,
            },
        }
    }

    get dataMap() {
        return {
            ...super.dataMap,
            duration_sum: 'durationSum',
            is_busy: 'isBusy',
            is_on_queue: 'isOnQueue',
            on_queue_duration: 'onQueueDuration',
            time_entry: 'parentTimeEntryId',
            queue_entries: 'queueEntries',
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
        durationSum: 'Время на смене за период',
        isBusy: 'Занят',
        isOnQueue: 'На линии',
        onQueueDuration: 'Время на линии',
    }
}

export default QueueTimeEntry
