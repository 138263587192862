import Vue from 'vue'

import { forEach as _forEach } from 'lodash'

const mutations = {
    fetchNotificationMessagesRequest(state) {
        state.isFetching = true
    },

    fetchNotificationMessagesSuccess(state, { entities }) {
        _forEach(entities, (entity) => {
            Vue.set(state.entities, entity.id, entity)
        })

        state.errors = []
        state.isFetching = false
    },

    fetchNotificationMessagesError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },

    addMessage(state, entity) {
        Vue.set(state.entities, entity.id, entity)
    },
}

export default mutations
