/* eslint-disable camelcase */
import { chunk as _chunk, compact as _compact, map as _map } from 'lodash'

import { API } from '_api'
import { request } from '_api/v1/base/document/client'

import { parseDate } from '_utils/datetime'

export const getIncomingDocuments = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    const response = await request('POST', 'documents/post/IncomingDocument', filters, { params })
    const responseChunks = _chunk(response.results, 25)
    const results = []

    for (const responseChunk of responseChunks) {
        const companyIds = _map(responseChunk, ({ company_id }) => company_id)
        const ticketIds = _map(responseChunk, ({ ticket_id }) => ticket_id)

        const companies = await API.v1.services.company.getCompanies({
            id__in: _compact(companyIds).join(','),
        })

        const tickets = await API.v1.services.ticket.getTickets({
            id__in: _compact(ticketIds),
        })

        for (const response of responseChunk) {
            response.company = companies.results.find((company) => company.id === response.company_id)
            response.ticket = tickets.results.find((ticket) => ticket.id === response.ticket_id)

            response.date_finishing_of_document = parseDate(response.date_finishing_of_document)?.unix()
            response.date_of_document = parseDate(response.date_of_document)?.unix()

            results.push(response)
        }
    }

    response.results = results

    return response
}

export const getIncomingDocumentById = async (incomingDocumentId) => {
    const response = await request('GET', `documents/IncomingDocument/${incomingDocumentId}`)
    const errors = []

    if (response.company_id) {
        response.company = await API.v1.services.company.getCompanyById(response.company_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.ticket_id) {
        response.ticket = await API.v1.services.ticket.getTicketById(response.ticket_id).catch((err) => {
            errors.push(...err)
        })
    }

    response.date_finishing_of_document = parseDate(response.date_finishing_of_document)?.unix()
    response.date_of_document = parseDate(response.date_of_document)?.unix()

    response.errors = errors

    return response
}

export const updateIncomingDocument = async (incomingDocumentId, data) => {
    return await request('PATCH', `documents/IncomingDocument/${incomingDocumentId}`, data)
}

export const createIncomingDocument = async (data) => {
    return await request('POST', 'documents/createIncomingDocument', data)
}

export const deleteIncomingDocument = async (incomingDocumentId) => {
    return await request('DELETE', `documents/IncomingDocument/${incomingDocumentId}`)
}

export const getDocumentIncomingTypes = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'documents/post/DocumentIncomingTypes', filters, { params })
}

export const getIncomingDocumentBlank = async (companyId) => {
    const errors = []
    const response = await request(
        'POST',
        'documents/IncomingDocument/getBlank',
        {
            company_id: companyId,
        },
    )

    if (response.company_id) {
        response.company = await API.v1.services.company.getCompanyById(response.company_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.date_finishing_of_document) {
        response.date_finishing_of_document = parseDate(response.date_finishing_of_document)?.unix()
    }

    if (response.date_of_document) {
        response.date_of_document = parseDate(response.date_of_document)?.unix()
    }

    response.errors = errors

    return response
}
