import { groupBy as _groupBy, orderBy as _orderBy } from 'lodash'

const getters = {
    getTickets: (state) => {
        return _orderBy(state.items, ['number', 'name'], ['desc', 'asc'])
    },

    getTicketById: (state) => (itemId) => {
        return state.items[itemId] || null
    },

    getTicketsGroupedByStage: (state, getters) => {
        return _groupBy(getters.getTickets, (item) => item.ticketStage?.id)
    },
}

export default getters
