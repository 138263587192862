import { filter as _filter } from 'lodash'

import { request } from '_api/v1/base/behemoth/client'

import ServiceElementCalendarEvent from '_entities/service/ServiceElementCalendarEvent'
import ServiceElementCatalog from '_entities/service/ServiceElementCatalog'
import ServiceElementCheckbox from '_entities/service/ServiceElementCheckbox'
import ServiceElementCheckboxTag from '_entities/service/ServiceElementCheckboxTag'
import ServiceElementDate from '_entities/service/ServiceElementDate'
import ServiceElementDateTime from '_entities/service/ServiceElementDateTime'
import ServiceElementDropdown from '_entities/service/ServiceElementDropdown'
import ServiceElementFile from '_entities/service/ServiceElementFile'
import ServiceElementPushMessage from '_entities/service/ServiceElementPushMessage'
import ServiceElementRadio from '_entities/service/ServiceElementRadio'
import ServiceElementRadioWithImage from '_entities/service/ServiceElementRadioWithImage'
import ServiceElementSendMessage from '_entities/service/ServiceElementSendMessage'
import ServiceElementText from '_entities/service/ServiceElementText'
import ServiceElementTextInput from '_entities/service/ServiceElementTextInput'
import ServiceElementTime from '_entities/service/ServiceElementTime'
import ServiceElementTitle1 from '_entities/service/ServiceElementTitle1'
import ServiceElementTitle2 from '_entities/service/ServiceElementTitle2'
import ServiceElementUploadImage from '_entities/service/ServiceElementUploadImage'
import ServiceElementUploadFiles from '_entities/service/ServiceElementUploadFiles'

import moment from 'moment'

export const getAccounts = async (params) => {
    return await request('GET', 'v2/account/', params)
}

export const getAccountDetail = async (accountId) => {
    const response = await request('GET', `v2/account/${accountId}/`)

    // Для корректной инициализации сущности, данные поля должны быть гарантированно массивами
    if (response) {
        response.calls = response.calls || []
        response.meters = response.meters || []
        response.payments = response.payments || []
        response.residents = response.residents || []
        response.storage_history = response.storage_history || []
        response.tickets = response.tickets || []
    }

    return response
}

export const getPersonalAccountDetailOnPremise = async (premise) => {
    const optionsFilter = { sorting: 'created_at', premise }
    const { results } = await getAccounts(optionsFilter)
    const personalAccount = _filter(results, (item) => item.is_active)

    const accountDetail = personalAccount[0]?.id && await getAccountDetail(personalAccount[0].id)

    return accountDetail
}

export const getPersonalAccountById = async (accountId, params) => {
    return await request('GET', `account/${accountId}`, params)
}

export const getAccountsByUserId = async (userId, params) => {
    return await request('GET', `account/${userId}/accounts/`, params)
}

export const addAllowedPerson = async ({ accountId, data }) => {
    return await request('POST', `account/${accountId}/allowed_account_user/`, data)
}

export const removeAllowedPerson = async (accountId, data) => {
    return await request('DELETE', `account/${accountId}/allowed_account_user/`, data)
}

export const getExternalAccount = async (accountExtId) => {
    return await request('GET', `account/external/${accountExtId}/`)
}

export const getExternalAccountServices = async (accountExtId) => {
    const response = await request('GET', `account/${accountExtId}/service/`)

    const { results } = response

    if (results) {
        results.forEach((service) => {
            // Заполнение сущностями
            if (service.elements) {
                for (let el = 0; el < service.elements.length; el++) {
                    const element = service.elements[el]

                    if (element.element_type === 'calendar_event') {
                        element?.events?.forEach((event) => {
                            event.date = moment(event.date).startOf('day').unix()
                        })
                    }

                    switch (element.element_type) {
                        case 'calendar_event':
                            service.elements[el] = new ServiceElementCalendarEvent(element)
                            break
                        case 'catalog':
                            service.elements[el] = new ServiceElementCatalog(element)
                            break
                        case 'checkbox':
                            service.elements[el] = new ServiceElementCheckbox(element)
                            break
                        case 'checkbox_tag':
                            service.elements[el] = new ServiceElementCheckboxTag(element)
                            break
                        case 'date':
                            service.elements[el] = new ServiceElementDate(element)
                            break
                        case 'datetime':
                            service.elements[el] = new ServiceElementDateTime(element)
                            break
                        case 'dropdown':
                            service.elements[el] = new ServiceElementDropdown(element)
                            break
                        case 'files':
                            service.elements[el] = new ServiceElementFile(element)
                            break
                        case 'radiobutton':
                            service.elements[el] = new ServiceElementRadio(element)
                            break
                        case 'radiobutton_with_image':
                            service.elements[el] = new ServiceElementRadioWithImage(element)
                            break
                        case 'text':
                            service.elements[el] = new ServiceElementText(element)
                            break
                        case 'textinput':
                            service.elements[el] = new ServiceElementTextInput(element)
                            break
                        case 'time':
                            service.elements[el] = new ServiceElementTime(element)
                            break
                        case 'title_1':
                            service.elements[el] = new ServiceElementTitle1(element)
                            break
                        case 'title_2':
                            service.elements[el] = new ServiceElementTitle2(element)
                            break
                        case 'upload_images':
                            service.elements[el] = new ServiceElementUploadImage(element)
                            break
                        case 'upload_files':
                            service.elements[el] = new ServiceElementUploadFiles(element)
                            break
                        case 'push_message':
                            service.elements[el] = new ServiceElementPushMessage(element)
                            break
                        case 'send_message':
                            service.elements[el] = new ServiceElementSendMessage(element)
                    }
                }
            }
        })
    }

    return response
}

export const getClientRoles = async (params) => {
    return await request('GET', 'location/client_role/', params)
}
