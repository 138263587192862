import Entity from '_entities/base/EntityNew'

export const CityLinkSchema = {
    name: 'CityLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class CityLink extends Entity {
    get schema() {
        return CityLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
    }
}

export default CityLink
