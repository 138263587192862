import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import ServiceDiscountCard from '_entities/service/ServiceDiscountCard'

const state = {
    item: new ServiceDiscountCard(),
    isFetching: false,
    isSaving: false,
    errors: [],
    // Form
    isValidForm: true,
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
