<template>
    <v-dialog
        v-model="dialog"
        max-width="300"
        persistent
    >
        <v-card>
            <v-card-title>SMS</v-card-title>
            <v-card-text v-if="caption">
                {{ caption }}
            </v-card-text>
            <v-col>
                <v-form v-model="isValidForm">
                    <v-text-field
                        v-model="inPhone"
                        v-mask="phoneFieldMask"
                        masked="true"
                        label="Телефон получателя"
                        outlined
                        :rules="[rules.phone]"
                    />
                    {{ inPhone }}
                    <v-textarea
                        v-if="showText"
                        v-model="text"
                        label="Сообщение"
                        outlined
                        :rules="[rules.required]"
                    />
                </v-form>
            </v-col>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    :disabled="!isValidForm || isSendingSms"
                    :loading="isSendingSms"
                    text
                    @click="agree"
                >
                    Отправить
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="cancel"
                >
                    Отмена
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mask } from 'vue-the-mask'

    export default {
        name: 'UISendSMSDialog',

        directives: {
            mask,
        },

        props: {
            phone: { type: String, default: null },
            showText: { type: Boolean, default: false },
        },

        data: () => ({
            caption: null,
            inPhone: null,
            text: null,
            dialog: false,
            isValidForm: false,
            phoneFieldMask: '+7##########',
            reject: null,
            resolve: null,
            rules: {
                phone: (value) => (value && value.match(/^\+?[0-9]{11}$/)?.length === 1) || 'Номер телефона должен содержать 11 цифр',
                required: (value) => (!!value && !!value.length) || 'Обязательное поле.',
            },
        }),

        watch: {
            phone: {
                handler(value) {
                    this.inPhone = value
                },
                immediate: true,
            },
        },

        methods: {
            open(caption) {
                this.caption = caption
                this.dialog = true
                this.resetFormData()
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },

            agree() {
                this.resolve({ phone: this.inPhone, text: this.text })
                this.dialog = false
            },

            cancel() {
                this.resolve(null)
                this.dialog = false
            },

            resetFormData() {
                this.formData = {
                    phone: null,
                    text: null,
                }
                this.isValidForm = false
            },
        },
    }
</script>
