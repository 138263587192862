import Entity from '_entities/base/EntityNew'

import BuildingLink from '_entities/building/BuildingLink'
import BuildingSectionLink from '_entities/buildingSection/BuildingSectionLink'

export const EntranceSchema = {
    name: 'Entrance',
    primaryKey: 'id',
    properties: {
        building: { type: 'BuildingLink', entity: BuildingLink },
        buildingSection: { type: 'BuildingSectionLink', entity: BuildingSectionLink },
        floors: { type: 'int[]', default: [] },
        id: { type: 'string' }, // UUID
        name: { type: 'string' },
        order: { type: 'int' },
    },
}

class Entrance extends Entity {
    get schema() {
        return EntranceSchema
    }

    get dataMap() {
        return {
            building_section: 'buildingSection',
        }
    }

    static fieldLabels = {
        building: 'Дом',
        buildingSection: 'Секция',
        floor: 'Этажи',
        id: 'ID',
        name: 'Название',
        order: 'Порядок',
    }
}

export default Entrance
