import Entity from '_entities/base/EntityNew'

export const PremiseTypeSchema = {
    name: 'PremiseType',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        code: { type: 'string' },
    },
}

class PremiseType extends Entity {
    get schema() {
        return PremiseTypeSchema
    }

    static fieldLabels = {
        name: 'Наименование',
        code: 'Код',
    }
}

export default PremiseType
