<template>
    <CContainer fluid>
        <slot />
    </CContainer>
</template>

<script>
    import CContainer from '_common/components/CContainer'

    export default {
        name: 'UIPage',

        components: {
            CContainer,
        },
    }
</script>
