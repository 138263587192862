<template>
    <v-card v-if="!isSmall">
        <v-card-subtitle v-if="label" class="pb-0">
            {{ label }}
        </v-card-subtitle>
        <v-list v-if="filesMutation.length > 0" class="px-4">
            <v-list-item-content
                v-for="file in filesMutation"
                :key="`UIAttachmentDownloadDocs_${file.id}`"
                class="py-2"
            >
                <a :href="file.url" target="_blank">
                    {{ file.name }}
                </a>
            </v-list-item-content>
        </v-list>
        <v-card-subtitle v-else class="d-flex justify-center py-4">
            Файлы отсутствуют
        </v-card-subtitle>
    </v-card>
    <v-btn v-else icon @click="download">
        <v-icon v-text="'$sort'" />
    </v-btn>
</template>

<script>
    import { map as _map } from 'lodash'

    import Doc from '_entities/media/Doc'

    import { downloadFiles } from '_utils/file'

    export default {
        name: 'UIAttachmentDownloadDocs',

        props: {
            files: {
                type: Object,
                required: true,
            },
            isSmall: {
                type: Boolean,
                default: false,
            },
            label: {
                type: String,
                default: 'Файл',
            },
        },

        computed: {
            filesMutation() {
                if (this.files && this.files.length > 0) {
                    return _map(this.files, (file) => {
                        return new Doc(file)
                    })
                }

                return []
            },
        },

        methods: {
            download() {
                downloadFiles(this.filesMutation)
            },
        },
    }
</script>
