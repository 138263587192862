import Vue from 'vue'

const mutations = {
    fetchServicePartnerCompaniesRequest(state) {
        state.isFetching = true
    },

    fetchServicePartnerCompaniesSuccess(state, { items }) {
        state.errors = []
        state.isFetching = false

        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchServicePartnerCompaniesError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },

    addedServicePartnerCompany(state, item) {
        Vue.set(state.items, item.id, item)
    },
}

export default mutations
