import { request } from '_api/v1/base/behemoth/client'

export const getQuizzes = async (params) => {
    return await request('GET', 'quiz/', params)
}

export const getQuizById = async (QuizId) => {
    return await request('GET', `quiz/${QuizId}/`)
}

export const createQuiz = async ({ data }) => {
    return await request('POST', 'quiz/', data)
}

export const updateQuiz = async ({ quizId, data }) => {
    return await request('PUT', `quiz/${quizId}/`, data)
}

export const getQuizStat = async (quizId) => {
    return await request('GET', `quiz/${quizId}/statistic/answer/`)
}

export const getQuizQuestionsByQuizId = async (quizId) => {
    return await request('GET', 'quiz/question/', { quiz: quizId })
}

export const getQuizQuestionById = async (questionId) => {
    return await request('GET', `quiz/question/${questionId}/`)
}

export const updateQuizQuestion = async ({ questionId, data }) => {
    return await request('PUT', `quiz/question/${questionId}/`, data)
}

export const createQuizQuestion = async ({ data }) => {
    return await request('POST', 'quiz/question/', data)
}

export const getQuizQuestionAnswersByQuestionId = async (questionId) => {
    return await request('GET', 'quiz/question/answer/', { question: questionId })
}

export const getQuizQuestionAnswerById = async (answerId) => {
    return await request('GET', `quiz/question/answer/${answerId}/`)
}

export const updateQuizQuestionAnswer = async ({ answerId, data }) => {
    return await request('PUT', `quiz/question/answer/${answerId}/`, data)
}

export const createQuizQuestionAnswer = async ({ data }) => {
    return await request('POST', 'quiz/question/answer/', data)
}

export const getStatExcelFile = async (quizId) => {
    return await request('GETFILE', `quiz/${quizId}/statistic/answer/excel/`)
}

export const deleteQuizQuestion = async (questionId) => {
    return await request('DELETE', `quiz/question/${questionId}/`)
}

export const deleteQuizQuestionAnswer = async (answerId) => {
    return await request('DELETE', `quiz/question/answer/${answerId}/`)
}
