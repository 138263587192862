<script>
    import moment from 'moment'

    export default {
        name: 'FMaintenanceRoot',

        data: () => ({
            timerHandler: null,
        }),

        computed: {
            maintenanceStatus() {
                return this.$store.state.maintenanceWork?.status
            },
        },

        watch: {
            maintenanceStatus: {
                handler() {
                    const now = moment().unix()
                    const statusCode = this.maintenanceStatus?.statusCode
                    let time

                    if (statusCode === 'coming_soon') {
                        time = this.maintenanceStatus.dateStart
                    } else if (statusCode === 'in_progress') {
                        time = this.maintenanceStatus.dateFinish
                    }

                    clearTimeout(this.timerHandler)

                    if (time) {
                        const timeout = (time - now) * 1000

                        this.timerHandler = setTimeout(this.refresh, timeout)
                    }
                },
                deep: true,
                immediate: true,
            },
        },

        destroyed() {
            clearTimeout(this.timerHandler)
        },

        methods: {
            refresh() {
                this.$store.dispatch('maintenanceWork/fetchMaintenanceStatus')
            },
        },
    }
</script>
