import Vue from 'vue'

const mutations = {
    fetchServiceSectionsRequest(state) {
        state.isFetching = true
    },

    fetchServiceSectionsSuccess(state, { items }) {
        state.errors = []
        state.isFetching = false

        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchServiceSectionsError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },

    deleteServiceSectionRequest(state) {
        state.isDeleting = true
    },

    deleteServiceSectionSuccess(state, { serviceSectionId }) {
        state.errors = []
        state.isDeleting = false
        Vue.delete(state.items, serviceSectionId)
    },

    deleteServiceSectionError(state, errors) {
        state.errors = errors
        state.isDeleting = false
    },
}

export default mutations
