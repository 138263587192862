<template>
    <v-row dense align="baseline">
        <v-col :cols="headerCols">
            <v-subheader :class="color ? color + '--text' : ''">
                {{ header }}
            </v-subheader>
        </v-col>
        <v-col :cols="textCols">
            <v-row dense align="center">
                <v-col v-if="text" cols="auto">
                    <div :class="color ? color + '--text' : ''">
                        {{ text }}
                    </div>
                </v-col>
                <v-col cols="auto">
                    <slot />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'UIListPairElement',

        props: {
            color: { type: String, default: null },
            header: { type: String, default: null },
            headerCols: { type: String, default: 'auto' },
            text: { type: String, default: null },
            textCols: { type: String, default: null },
        },
    }
</script>
