import { find as _find } from 'lodash'

import Entity from '_entities/base/EntityNew'

import Size from '_entities/media/photo/Size'

export const PhotoSchema = {
    name: 'Photo',
    primaryKey: 'id',
    properties: {
        extension: { type: 'string' },
        id: { type: 'int' },
        mimeType: { type: 'string' },
        name: { type: 'string' },
        sizes: { type: 'Size[]', entity: Size, default: [] },
    },
}

class Photo extends Entity {
    get schema() {
        return PhotoSchema
    }

    static fieldLabels = {
        extension: 'Расширение',
        id: 'ID',
        name: 'Название',
        mimeType: 'MIME тип',
        sizes: 'Размеры',
    }

    getSize(sizeCode) {
        return _find(this.sizes, (size) => size.code === sizeCode)
    }

    getSmall() {
        return this.getSize('s')
    }

    getMedium() {
        return this.getSize('m')
    }
}

export default Photo
