<template>
    <v-container>
        <v-row dense align="center">
            <v-col cols="auto">
                <h3>{{ label }}</h3>
            </v-col>
            <v-btn
                v-if="allowAdd"
                text
                color="primary"
                @click="addRange"
            >
                добавить
            </v-btn>
        </v-row>
        <template v-if="value.length">
            <v-row
                v-for="(range, index) in ranges"
                :key="range"
            >
                <UINumberRange
                    v-if="dataType === 'number'"
                    v-model="ranges[index]"
                    v-bind="$attrs"
                    :max="max"
                    :min="min"
                    @delete="deleteRange(index)"
                />
                <UIDateRange
                    v-if="dataType === 'date'"
                    v-model="ranges[index]"
                    v-bind="$attrs"
                    :max="max"
                    :min="min"
                    @delete="deleteRange(index)"
                />
                <UIDateRange
                    v-if="dataType === 'month'"
                    v-model="ranges[index]"
                    v-bind="$attrs"
                    :max="max"
                    :min="min"
                    type="'month'"
                    @delete="deleteRange(index)"
                />
                <UITimeRange
                    v-if="dataType === 'time'"
                    v-model="ranges[index]"
                    v-bind="$attrs"
                    :max="max"
                    :min="min"
                    @clear="clearRange(index)"
                    @delete="deleteRange(index)"
                />
                <UIDateTimeRange
                    v-if="dataType === 'datetime'"
                    v-model="ranges[index]"
                    v-bind="$attrs"
                    :max="max"
                    :min="min"
                    @delete="deleteRange(index)"
                />
            </v-row>
        </template>
    </v-container>
</template>

<script>
    import UIDateRange from '_ui/forms/UIDateRange'
    import UIDateTimeRange from '_ui/forms/UIDateTimeRange'
    import UINumberRange from '_ui/forms/UINumberRange'
    import UITimeRange from '_ui/forms/UITimeRange'

    import Range from '_entities/range/Range'

    export default {
        name: 'UIRangesPicker',

        components: {
            UIDateRange,
            UIDateTimeRange,
            UINumberRange,
            UITimeRange,
        },

        props: {
            allowAdd: { type: Boolean, default: true },
            dataType: { type: String, default: 'number' }, // 'number' || 'date' || 'month' || 'time' || 'datetime'
            label: { type: String, default: 'Диапазоны' },
            max: { type: String, default: '0' },
            min: { type: String, default: '0' },
            value: { type: Array, default: () => [] },
        },

        data: () => ({
        }),

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            ranges() {
                return this.value?.length ? this.value : [this.value]
            },
        },

        methods: {
            addRange() {
                this.model.push(new Range())
            },

            clearRange(index) {
                if (this.model?.length !== undefined) {
                    this.model[index].from = null
                    this.model[index].to = null
                } else {
                    this.model.from = null
                    this.model.to = null
                }
            },

            deleteRange(index) {
                this.model.splice(index, 1)
            },
        },
    }
</script>
