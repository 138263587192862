import ServiceElement from '_entities/service/ServiceElement'

export const ServiceElementDateSchema = {
    elementType: { type: 'string', default: 'date' },
    timezone: { type: 'string' },
}

class ServiceElementDate extends ServiceElement {
    get schema() {
        return {
            ...super.schema.name,
            ...super.schema.primaryKey,
            properties: {
                ...super.schema.properties,
                ...ServiceElementDateSchema,
            },
        }
    }

    get dataMap() {
        return {
            ...super.dataMap,
            element_type: 'elementType',
        }
    }

    toServer(data) {
        return {
            ...super.toServer(data),
            timezone: this.timezone,
        }
    }
}

export default ServiceElementDate
