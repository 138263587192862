<template>
    <v-row dense align="baseline">
        <v-col>
            <v-autocomplete
                v-if="isShortRange"
                v-model="model.from"
                dense
                :items="rangeFrom"
                label="c"
                outlined
            />
            <v-text-field
                v-else
                v-model="model.from"
                dense
                label="c"
                outlined
                :rules="[rules.notNumber]"
            />
        </v-col>
        <v-col>
            <v-autocomplete
                v-if="isShortRange"
                v-model="model.to"
                dense
                :items="rangeTo"
                label="по"
                outlined
            />
            <v-text-field
                v-else
                v-model="model.to"
                dense
                label="по"
                outlined
                :rules="[rules.notNumber]"
            />
        </v-col>
        <v-col cols="auto">
            <v-btn
                v-if="allowDelete"
                text
                color="primary"
                @click="deleteRange"
            >
                удалить
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
    import Range from '_entities/range/Range'

    export default {
        name: 'UINumberRange',

        props: {
            allowDelete: { type: Boolean, default: true },
            max: { type: String, default: '0' },
            min: { type: String, default: '0' },
            value: { type: Object, default: () => new Range() },
        },

        data: () => ({
            rules: {
                notNumber: (value) => {
                    return isNaN(Number(value)) !== true || 'Требуется ввести число'
                },
            },
        }),

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            isShortRange() {
                return Math.abs(this.max - this.min) <= 100
            },

            rangeFrom() {
                const range = []
                const to = this.value.to || this.max
                for (let index = this.min; index <= to; index++) {
                    range.push(String(index))
                }
                return range
            },

            rangeTo() {
                const range = []
                const from = this.value.from || this.min
                for (let index = from; index <= this.max; index++) {
                    range.push(String(index))
                }
                return range
            },
        },

        methods: {
            deleteRange() {
                this.$emit('delete')
            },
        },
    }
</script>
