import { API } from '_api'

import TicketRoute from '_entities/ticket/TicketRoute'

const actions = {
    async fetchTicketRoute({ commit }, { ticketRouteId }) {
        commit('fetchTicketRouteRequest')

        try {
            const response = await API.v1.services.apiTicket.getTicketRouteById(ticketRouteId)

            const item = response ? new TicketRoute(response) : null

            commit('fetchTicketRouteSuccess', { item })
        } catch (e) {
            commit('fetchTicketRouteError', e)
        }
    },
}

export default actions
