<template>
    <v-dialog
        v-model="show"
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
    >
        <v-card>
            <v-toolbar :color="options.color" dark dense flat>
                <v-toolbar-title class="white--text">
                    Переквалификация заявки
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pa-4">
                <v-form v-model="isValid">
                    <FTicketTypingPicker
                        is-available-ticket-department
                        required
                        :ticket-department.sync="ticketDepartment"
                        :ticket-kind.sync="ticketKind"
                        :ticket-type.sync="ticketType"
                        :is-autofill="isOpening"
                    />
                    <FCalendarVacancyTimeSelect
                        v-show="isNeedSelectCalendarTime"
                        ref="vacancyTimeSelect"
                        v-model="calendarVacancy"
                        :building="building"
                        :ticket-department="ticketDepartment"
                        :ticket-kind="ticketKind"
                        :ticket-type="ticketType"
                        :rules="[requiredVacancy]"
                    />
                </v-form>
                <v-row v-if="!isChangedTyping" align="center" dense>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-icon color="orange" small v-text="'$triangleExclamation'" />
                    </v-col>
                    <v-col cols="auto">
                        Выбрана исходная типизация
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn text @click.native="cancel">
                    Отмена
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="!canRetyping"
                    text
                    @click.native="agree"
                >
                    Переквалифицировать
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import FCalendarVacancyTimeSelect from '_features/calendar/components/FCalendarVacancyTimeSelect'
    import FTicketTypingPicker from '_features/ticket/components/FTicketTypingPicker'

    import { checkConditions } from '_utils/conditions'

    export default {
        name: 'UITypingDialog',

        components: {
            FCalendarVacancyTimeSelect,
            FTicketTypingPicker,
        },

        data: () => ({
            dialog: false,
            isOpening: false,
            isValid: false,
            building: null,
            calendarVacancy: null,
            ticketDepartment: null,
            ticketKind: null,
            ticketType: null,
            resolve: null,
            reject: null,
            options: {
                color: 'primary',
                width: 500,
                zIndex: 200,
            },
            originalTyping: null,
        }),

        computed: {
            isNeedSelectCalendarTime() {
                return (this.ticketType?.assignmentType === 'assigned')
            },

            isChangedTyping() {
                const conditions = []

                conditions.push(this.ticketDepartment?.id !== this.originalTyping?.ticketDepartment?.id)
                conditions.push(this.ticketKind?.id !== this.originalTyping?.ticketKind?.id)
                conditions.push(this.ticketType?.id !== this.originalTyping?.ticketType?.id)

                return checkConditions('or', conditions)
            },

            isChangedTypingHint() {
                const hints = []

                if (!this.isChangedTyping) {
                    hints.push('Типизация не изменена.')
                }

                return hints
            },

            canRetyping() {
                return this.isValid && this.isChangedTyping
            },

            requiredVacancy() {
                const hasVacancy = this.calendarVacancy !== null && this.calendarVacancy !== undefined

                return !this.isNeedSelectCalendarTime || (this.isNeedSelectCalendarTime && hasVacancy) || 'Обязательное поле.'
            },

            show: {
                get() {
                    return this.dialog
                },
                set(value) {
                    this.dialog = value
                    if (value === false) {
                        this.cancel()
                    }
                },
            },

        },

        methods: {
            open(typing) {
                // временная блокировка ватчеров, чтобы не сбросили данные при инициализации
                this.isOpening = true
                setTimeout(() => {
                    this.isOpening = false
                }, 1000)

                this.building = typing?.building
                this.calendarVacancy = typing?.calendarVacancy
                this.ticketDepartment = typing?.ticketDepartment
                this.ticketKind = typing?.ticketKind
                this.ticketType = typing?.ticketType
                this.originalTyping = typing

                this.dialog = true

                this.$refs?.vacancyTimeSelect?.fetchCalendarSlots()

                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },

            agree() {
                const result = {
                    building: this.building,
                    calendarVacancy: this.calendarVacancy,
                    ticketDepartment: this.ticketDepartment,
                    ticketKind: this.ticketKind,
                    ticketType: this.ticketType,
                }
                this.resolve(result)
                this.dialog = false
            },

            cancel() {
                this.resolve()
                this.dialog = false
            },
        },
    }
</script>
