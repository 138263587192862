import Entity from '_entities/base/EntityNew'

import Department from '_entities/department/Department'
import UserOld from '_entities/user/UserOld'

export const TimeEntrySchema = {
    name: 'TimeEntry',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        dateFinish: { type: 'string' },
        dateStart: { type: 'string' },
        department: { type: 'Department', entity: Department },
        duration: { type: 'int' },
        type: { type: 'string' },
        user: { type: 'UserOld', entity: UserOld },
    },
}

class TimeEntry extends Entity {
    get schema() {
        return TimeEntrySchema
    }

    get dataMap() {
        return {
            date_finish: 'dateFinish',
            date_start: 'dateStart',
        }
    }

    static fieldLabels = {
        dateFinish: 'Время завершения',
        dateStart: 'Время начала',
        department: 'Отдел',
        duration: 'Длительность',
        type: 'Тип',
        user: 'Сотрудник',
    }
}

export default TimeEntry
