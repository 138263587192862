import { compact as _compact, map as _map } from 'lodash'

import Building from '_entities/building/Building'
import Entity from '_entities/base/EntityNew'
import ResidentialComplex from '_entities/residentialComplex/ResidentialComplex'
import TicketDepartment from '_entities/ticket/TicketDepartment'
import TicketKind from '_entities/ticket/TicketKind'
import TicketType from '_entities/ticket/TicketType'
import User from '_entities/user/User'

export const CalendarSlotColorSchema = {
    name: 'CalendarSlotColor',
    primaryKey: 'id',
    properties: {
        buildings: { type: 'Building[]', entity: Building, default: [] }, // с бэка приходит массив UUID
        color: { type: 'string' },
        executors: { type: 'User[]', entity: User, default: [] }, // с бэка приходит UUID
        id: { type: 'string' }, // UUID
        name: { type: 'string' },
        residentialComplexes: { type: 'ResidentialComplex[]', entity: ResidentialComplex, default: [] },
        ticketDepartments: { type: 'TicketDepartment[]', entity: TicketDepartment, default: [] }, // с бэка приходит массив UUID
        ticketKinds: { type: 'TicketKind[]', entity: TicketKind, default: [] }, // с бэка приходит массив UUID
        ticketTypes: { type: 'TicketType[]', entity: TicketType, default: [] }, // с бэка приходит массив UUID
    },
}

class CalendarSlotColor extends Entity {
    get schema() {
        return CalendarSlotColorSchema
    }

    get dataMap() {
        return {
            // TODO: Переименование сущности Project > ResidentialComplex (API)
            projects: 'residentialComplexes',
            ticket_departments: 'ticketDepartments',
            ticket_kinds: 'ticketKinds',
            ticket_types: 'ticketTypes',
        }
    }

    static fieldLabels = {
        buildings: 'Дома',
        executors: 'Исполнители',
        residentialComplexes: 'ЖК',
        ticketDepartments: 'Отделы',
        ticketKinds: 'Виды',
        ticketTypes: 'Типы',
    }

    getIdsArray() {
        const idsArray = [
            ..._map(this.buildings, (building) => building?.id),
            ..._map(this.executors, (executor) => executor?.id),
            ..._map(this.residentialComplexes, (residentialComplex) => residentialComplex?.id),
            ..._map(this.ticketDepartments, (ticketDepartment) => ticketDepartment?.id),
            ..._map(this.ticketKinds, (ticketKind) => ticketKind?.id),
            ..._map(this.ticketTypes, (ticketType) => ticketType?.id),
        ]
        return _compact(idsArray)
    }

    toArray() {
        return {
            buildings: _map(this.buildings, (building) => building?.id),
            color: this.color.replace('#', ''),
            executors: _map(this.executors, (executor) => executor?.id),
            name: this.name,
            ticket_departments: _map(this.ticketDepartments, (ticketDepartment) => ticketDepartment.id),
            ticket_kinds: _map(this.ticketKinds, (ticketKind) => ticketKind.id),
            ticket_types: _map(this.ticketTypes, (ticketType) => ticketType.id),
        }
    }
}

export default CalendarSlotColor
