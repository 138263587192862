<template>
    <div>
        <v-select
            v-model="model"
            :items="timezones"
            :label="label"
            item-text="text"
            item-value="code"
            outlined
        />
    </div>
</template>

<script>
    export default {
        name: 'UITimeZonePicker',

        props: {
            label: { type: String, default: 'Часовой пояс' },
            value: { type: String, default: null },
        },

        data: () => ({
            timezones: [
                {
                    code: 'Europe/Kaliningrad',
                    text: 'Калининград (МСК–1)',
                },
                {
                    code: 'Europe/Moscow',
                    text: 'Москва (МСК)',
                },
                {
                    code: 'Europe/Samara',
                    text: 'Самара (МСК+1)',
                },
                {
                    code: 'Asia/Yekaterinburg',
                    text: 'Екатеринбург (МСК+2)',
                },
                {
                    code: 'Asia/Omsk',
                    text: 'Омск (МСК+3)',
                },
                {
                    code: 'Asia/Krasnoyarsk',
                    text: 'Красноярск (МСК+4)',
                },
                {
                    code: 'Asia/Irkutsk',
                    text: 'Иркутск (МСК+5)',
                },
                {
                    code: 'Asia/Yakutsk',
                    text: 'Якутск (МСК+6)',
                },
                {
                    code: 'Asia/Vladivostok',
                    text: 'Владивосток (МСК+7)',
                },
                {
                    code: 'Asia/Magadan',
                    text: 'Магадан (МСК+8)',
                },
                {
                    code: 'Asia/Kamchatka',
                    text: 'Камчатка (МСК+9)',
                },
            ],
        }),

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },
        },
    }
</script>
