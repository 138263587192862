import { request } from '_api/v1/base/behemoth/client'

export const getKeyIssues = async (params) => {
    return await request('GET', 'key_issuance/', params)
}

export const getArchiveKeyIssues = async (params) => {
    return await request('GET', 'key_issuance/archive/', params)
}

export const deleteKeyIssue = async (keyIssueId) => {
    return await request('DELETE', `key_issuance/${keyIssueId}/`)
}

export const updateKeyIssue = async (keyIssueId, params) => {
    return await request('PUT', `key_issuance/${keyIssueId}/`, params)
}

export const addKeyIssue = async (params) => {
    return await request('POST', 'key_issuance/', params)
}

export const returnKeyIssue = async (keyIssueId, params) => {
    return await request('PUT', `key_return/${keyIssueId}/`, params)
}
