import { PERMISSIONS } from '_features/permissions/constants'

const StoriesNavigator = () => import('_navigations/Home/UserApp/StoriesNavigator')

const StoriesScreen = () => import('_screens/Home/UserApp/Stories/StoriesScreen')
const StoryEditScreen = () => import('_screens/Home/UserApp/Stories/StoryEditScreen')

const storiesRoutes = [
    {
        path: 'stories',
        component: StoriesNavigator,
        meta: {
            screenOptions: {
                title: 'Stories',
            },
        },
        children: [
            {
                path: '',
                name: 'UserAppStories',
                component: StoriesScreen,
                meta: {
                    screenOptions: {
                        title: 'Stories',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_NEWS_STORY],
                },
            },
            {
                path: 'edit/:storyId?',
                name: 'UserAppStoryEdit',
                component: StoryEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Stories',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_NEWS_STORY],
                },
            },
        ],
    },
]

export default storiesRoutes
