import {
    compact as _compact,
    filter as _filter,
    find as _find,
    forEach as _forEach,
    map as _map,
    orderBy as _orderBy,
    uniq as _uniq,
} from 'lodash'

import moment from 'moment'

import { request } from '_api/v1/base/calendar/client'
import { getBuildings } from '_api/v1/services/building'
import { getDepartments } from '_api/v1/services/department'
import { getWorkers } from '_api/v1/services/user'
import { getTicketDepartments } from '_api/v1/services/ticketDepartment'
import { getTicketKinds } from '_api/v1/services/ticketKind'
import { getTickets } from '_api/v1/services/ticket'
import { getTicketTypes } from '_api/v1/services/ticketType'

import CalendarSlot from '_entities/calendar/CalendarSlot'
import CalendarSlotColor from '_entities/calendar/CalendarSlotColor'
import Ticket from '_entities/ticket/Ticket'

// Calendar

export const getCalendars = async (params) => {
    // return await request('GET', 'calendar/', params)

    return {
        count: 1,
        next: null,
        previous: null,
        results: [
            {
                id: 'b08745ef-8bc5-47c2-beba-7f7c2adb7e72',
                name: 'Общий',
                color: '#2196F3',
            },
        ],
    }
}

export const getCalendarSlotVacancies = async (calendarId, params) => {
    const vacancies = []
    const response = await request('POST', `calendar/${calendarId}/vacancies/`, params)

    /* структура данных:
        [{'time_start':time_start, 'time_end': time_end,'slot': slot_id}, {...}, ...]
    */

    if (response) {
        _forEach(response, (timeRange) => {
            const date = moment.unix(timeRange.time_start).locale('ru').format('DD MMMM')
            const from = moment.unix(timeRange.time_start).locale('ru').format('HH:mm')
            const to = moment.unix(timeRange.time_end).locale('ru').format('HH:mm')

            vacancies.push({
                date: date,
                text: `${date}: ${from} - ${to}`,
                value: {
                    from: timeRange.time_start,
                    to: timeRange.time_end,
                    slot: timeRange.slot,
                },
            })
        })
    }

    return _orderBy(vacancies, ['from'], ['asc'])
}

// Calendar slot

export const getCalendarSlots = async (params) => {
    let response
    const slots = []
    let ticketIds = []

    const tickets = {}
    const workers = {}
    const buildings = {}
    const departments = {}
    const ticketDepartments = {}
    const ticketKinds = {}
    const ticketTypes = {}

    let calendarSlot
    let isCorrectData

    response = await request('GET', 'calendar_slot/', params)
    const slotResponse = response.results

    // Справочник заявок
    _forEach(slotResponse, (slot) => {
        ticketIds = [...ticketIds, ..._map(slot.events, (event) => event.entity_id)]
    })

    ticketIds = _uniq(_compact(ticketIds))

    if (ticketIds.length) {
        response = await getTickets({
            page: 1,
            page_size: Number.MAX_SAFE_INTEGER,
            id__in: ticketIds,
        })

        _forEach(response?.results, (ticket) => {
            tickets[ticket.id] = new Ticket(ticket)
        })
    }

    // Справочник календарей
    response = await getCalendars()
    const calendars = {}

    _forEach(response?.results, (calendar) => {
        calendars[calendar.id] = calendar
    })

    // Справочник отделов
    response = await getDepartments({
        page: 1,
        page_size: 1000,
    })

    _forEach(response?.results, (department) => {
        departments[department.id] = department
    })

    // Справочник сотрудников
    const executorIds = _map(slotResponse, (slot) => slot.executor)
    const createrIds = _map(slotResponse, (slot) => slot.created_by)

    const workerIds = _uniq(_compact([...executorIds, ...createrIds]))

    if (workerIds.length) {
        response = await getWorkers({
            page: 1,
            page_size: Number.MAX_SAFE_INTEGER,
            id__in: workerIds.join(','),
        })

        _forEach(response?.results, (worker) => {
            workers[worker.id] = worker
        })
    }

    // Справочник домов
    let buildingIds = []

    _forEach(slotResponse, (slot) => {
        buildingIds = [...buildingIds, ...slot.buildings]
    })

    buildingIds = _uniq(_compact(buildingIds))

    if (buildingIds.length) {
        response = await getBuildings({
            page: 1,
            page_size: Number.MAX_SAFE_INTEGER,
            id__in: buildingIds,
        })

        _forEach(response?.results, (building) => {
            buildings[building.id] = building
        })
    }

    // Справочник разделов
    let ticketsDepartmentIds = []

    _forEach(slotResponse, (slot) => {
        ticketsDepartmentIds = [...ticketsDepartmentIds, ...slot.ticket_departments]
    })

    ticketsDepartmentIds = _uniq(_compact(ticketsDepartmentIds))

    if (ticketsDepartmentIds.length) {
        response = await getTicketDepartments({
            page: 1,
            page_size: Number.MAX_SAFE_INTEGER,
            id__in: ticketsDepartmentIds,
        })

        _forEach(response?.results, (ticketsDepartment) => {
            ticketDepartments[ticketsDepartment.id] = ticketsDepartment
        })
    }

    // Справочник видов
    let ticketsKindIds = []

    _forEach(slotResponse, (slot) => {
        ticketsKindIds = [...ticketsKindIds, ...slot.ticket_kinds]
    })

    ticketsKindIds = _uniq(_compact(ticketsKindIds))

    if (ticketsKindIds.length) {
        response = await getTicketKinds({
            page: 1,
            page_size: Number.MAX_SAFE_INTEGER,
            id__in: ticketsKindIds,
        })

        _forEach(response?.results, (ticketsKind) => {
            ticketKinds[ticketsKind.id] = ticketsKind
        })
    }

    // Справочник типов
    let ticketsTypeIds = []

    _forEach(slotResponse, (slot) => {
        ticketsTypeIds = [...ticketsTypeIds, ...slot.ticket_types]
    })

    ticketsTypeIds = _uniq(_compact(ticketsTypeIds))

    if (ticketsTypeIds.length) {
        response = await getTicketTypes({
            page: 1,
            page_size: Number.MAX_SAFE_INTEGER,
            id__in: ticketsTypeIds,
        })

        _forEach(response?.results, (ticketType) => {
            ticketTypes[ticketType.id] = ticketType
        })
    }

    // Вместо объектов в полях приходят их ID.
    // Заполняем их сущностями.
    for (const slot of slotResponse) {
        isCorrectData = true

        // Данные календаря
        if (slot.calendar) {
            slot.calendar = calendars[slot.calendar]

            if (!slot.calendar) {
                isCorrectData = false
            }
        }

        // Данные исполнителя
        if (slot.executor) {
            slot.executor = workers[slot.executor]

            if (!slot.executor) {
                isCorrectData = false
            }
        }

        // Подразделение исполнителя
        if (slot.executor_departament) {
            slot.executor_departament = departments[slot.executor_departament]
        }

        // Данные исполнителя
        if (slot.created_by) {
            slot.created_by = workers[slot.created_by]

            if (!slot.executor) {
                isCorrectData = false
            }
        }

        slot.residentialComplexes = []

        // Данные домов
        if (slot?.buildings?.length) {
            _forEach(slot.buildings, (buildingId, i) => {
                slot.buildings[i] = buildings[buildingId]

                if (slot.buildings[i]) {
                    const residentialComplex = _find(slot.residentialComplexes, (residentialComplex) => {
                        return residentialComplex.id === slot.buildings[i]?.residential_complex?.id
                    })

                    if (!residentialComplex) {
                        slot.residentialComplexes.push(slot.buildings[i]?.residential_complex)
                    }
                } else {
                    isCorrectData = false
                }
            })
        }

        // Данные типизации
        if (slot.ticket_departments?.length) {
            _forEach(slot.ticket_departments, (ticketDepartmentId, i) => {
                slot.ticket_departments[i] = ticketDepartments[ticketDepartmentId]

                if (!slot.ticket_departments[i]) {
                    isCorrectData = false
                }
            })
        }
        if (slot.ticket_kinds?.length) {
            _forEach(slot.ticket_kinds, (ticketKindId, i) => {
                slot.ticket_kinds[i] = ticketKinds[ticketKindId]

                if (!slot.ticket_kinds[i]) {
                    isCorrectData = false
                }
            })
        }

        if (slot.ticket_types?.length) {
            _forEach(slot.ticket_types, (ticketTypeId, i) => {
                slot.ticket_types[i] = ticketTypes[ticketTypeId]

                if (!slot.ticket_types[i]) {
                    isCorrectData = false
                }
            })
        }

        if (slot.events?.length) {
            _forEach(slot.events, (event, i) => {
                slot.events[i].entity = tickets[event.entity_id]
            })

            // Выбрасываем неактуальные события
            slot.events = _filter(slot.events, (event) => {
                return event.entity
            })
        }

        // Создание полноценной сущности слота
        calendarSlot = new CalendarSlot(slot)

        isCorrectData = true

        // Добавление в результатирующий массив
        if (isCorrectData) {
            slots.push({
                id: slot.id,
                data: calendarSlot,
                color: calendarSlot?.calendar?.color || '#2196F3',
                start: calendarSlot.timeStart,
                end: calendarSlot.timeEnd,
                timed: true,
            })
        }
    }

    return _orderBy(slots, ['start'], ['desc'])
}

export const createCalendarSlot = async (params) => {
    return await request('POST', 'calendar_slot/', params)
}

export const updateCalendarSlot = async ({ slotId, params }) => {
    return await request('PUT', `calendar_slot/${slotId}/`, params)
}

export const deleteCalendarSlot = async (slotId) => {
    return await request('DELETE', `calendar_slot/${slotId}/`)
}

// Calendar event

export const getCalendarEvents = async (params) => {
    return await request('GET', 'calendar_event/', params)
}

export const createCalendarEvent = async (params) => {
    return await request('POST', 'calendar_event/', params)
}

export const getCalendarEventById = async (eventId) => {
    return await request('GET', `calendar_event/${eventId}/`)
}

export const updateCalendarEvent = async ({ eventId, params }) => {
    return await request('PUT', `calendar_event/${eventId}/`, params)
}

export const deleteCalendarEvent = async (eventId) => {
    return await request('DELETE', `calendar_event/${eventId}/`)
}

// Calendar slot color

export const getCalendarSlotColors = async (params) => {
    let response
    const calendarSlotColors = []
    let isCorrectData

    let buildingIds = []
    let ticketsDepartmentIds = []
    let ticketsKindIds = []
    let ticketsTypeIds = []
    let workerIds = []

    const buildings = {}
    const ticketDepartments = {}
    const ticketKinds = {}
    const ticketTypes = {}
    const workers = {}

    response = await request('GET', 'calendar_slot_color/', params)
    const colorsResponse = response?.results

    // Справочник сотрудников
    _forEach(colorsResponse, (color) => {
        workerIds = [...workerIds, ...color.executors]
    })

    if (workerIds?.length) {
        response = await getWorkers({
            id__in: _uniq(_compact(workerIds)).join(','),
        })

        _forEach(response?.results, (worker) => {
            workers[worker.id] = worker
        })
    }

    // Справочник домов
    _forEach(colorsResponse, (color) => {
        buildingIds = [...buildingIds, ...color.buildings]
    })

    if (buildingIds?.length) {
        response = await getBuildings({
            id__in: _uniq(_compact(buildingIds)),
        })

        _forEach(response?.results, (building) => {
            buildings[building.id] = building
        })
    }

    // Справочник разделов
    _forEach(colorsResponse, (color) => {
        ticketsDepartmentIds = [...ticketsDepartmentIds, ...color.ticket_departments]
    })

    if (ticketsDepartmentIds?.length) {
        response = await getTicketDepartments({
            id__in: _uniq(_compact(ticketsDepartmentIds)),
        })

        _forEach(response?.results, (ticketsDepartment) => {
            ticketDepartments[ticketsDepartment.id] = ticketsDepartment
        })
    }

    // Справочник видов
    _forEach(colorsResponse, (color) => {
        ticketsKindIds = [...ticketsKindIds, ...color.ticket_kinds]
    })

    if (ticketsKindIds?.length) {
        response = await getTicketKinds({
            id__in: _uniq(_compact(ticketsKindIds)),
        })

        _forEach(response?.results, (ticketsKind) => {
            ticketKinds[ticketsKind.id] = ticketsKind
        })
    }

    // Справочник типов
    _forEach(colorsResponse, (color) => {
        ticketsTypeIds = [...ticketsTypeIds, ...color.ticket_types]
    })

    if (ticketsTypeIds?.length) {
        response = await getTicketTypes({
            id__in: _uniq(_compact(ticketsTypeIds)),
        })

        _forEach(response?.results, (ticketType) => {
            ticketTypes[ticketType.id] = ticketType
        })
    }

    // Вместо объектов в полях приходят их ID.
    // Заполняем их сущностями.
    for (const calendarSlotColor of colorsResponse) {
        isCorrectData = true

        // ЖК нет в исходных данных
        calendarSlotColor.residentialComplexes = []

        // Дома и ЖК
        if (calendarSlotColor?.buildings?.length) {
            _forEach(calendarSlotColor?.buildings, (buildingId, i) => {
                calendarSlotColor.buildings[i] = buildings[buildingId]

                if (calendarSlotColor.buildings[i]) {
                    const residentialComplex = _find(calendarSlotColor.residentialComplexes, (residentialComplex) => {
                        return residentialComplex.id === calendarSlotColor.buildings[i]?.residentialComplex?.id
                    })

                    if (!residentialComplex) {
                        calendarSlotColor.residentialComplexes.push(calendarSlotColor.buildings[i].residential_complex)
                    }
                }

                if (!calendarSlotColor.buildings[i]) {
                    isCorrectData = false
                }
            })
        }

        // Исполнители
        if (calendarSlotColor?.executors?.length) {
            _forEach(calendarSlotColor?.executors, (executorId, i) => {
                calendarSlotColor.executors[i] = workers[executorId]

                if (!calendarSlotColor.executors[i]) {
                    isCorrectData = false
                }
            })
        }

        // Данные типизации
        if (calendarSlotColor.ticket_departments?.length) {
            _forEach(calendarSlotColor.ticket_departments, (ticketDepartmentId, i) => {
                calendarSlotColor.ticket_departments[i] = ticketDepartments[ticketDepartmentId]

                if (!calendarSlotColor.ticket_departments[i]) {
                    isCorrectData = false
                }
            })
        }
        if (calendarSlotColor.ticket_kinds?.length) {
            _forEach(calendarSlotColor.ticket_kinds, (ticketKindId, i) => {
                calendarSlotColor.ticket_kinds[i] = ticketKinds[ticketKindId]

                if (!calendarSlotColor.ticket_kinds[i]) {
                    isCorrectData = false
                }
            })
        }
        if (calendarSlotColor.ticket_types?.length) {
            _forEach(calendarSlotColor.ticket_types, (ticketTypeId, i) => {
                calendarSlotColor.ticket_types[i] = ticketTypes[ticketTypeId]

                if (!calendarSlotColor.ticket_types[i]) {
                    isCorrectData = false
                }
            })
        }

        if (isCorrectData) {
            calendarSlotColor.color = '#' + calendarSlotColor.color
            calendarSlotColors.push(new CalendarSlotColor(calendarSlotColor))
        } else {
            // eslint-disable-next-line no-console
            console.log('incorrect color', calendarSlotColor)
        }
    }

    return calendarSlotColors
}

export const getCalendarSlotColorById = async (calendarColorId) => {
    return await request('GET', `calendar_slot_color/${calendarColorId}/`)
}

export const createCalendarSlotColor = async (params) => {
    return await request('POST', 'calendar_slot_color/', params)
}

export const updateCalendarSlotColor = async ({ calendarColorId, params }) => {
    return await request('PUT', `calendar_slot_color/${calendarColorId}/`, params)
}

export const deleteCalendarSlotColor = async (calendarColorId) => {
    return await request('DELETE', `calendar_slot_color/${calendarColorId}/`)
}
