import { PERMISSIONS } from '_features/permissions/constants'

const AnalyticsNavigator = () => import('_navigations/Home/Analytics/AnalyticsNavigator')

const AnalyticsServiceCompanyScreen = () => import('_screens/Home/Analytics/AnalyticsServiceCompanyScreen')

const analyticsRoutes = []

analyticsRoutes.push({
    path: 'analytics',
    name: 'Analytics',
    component: AnalyticsNavigator,
    children: [
        {
            path: 'service-company',
            name: 'ServiceCompany',
            component: AnalyticsServiceCompanyScreen,
            meta: {
                screenOptions: {
                    title: 'Управляющая компания',
                },
                permissions: [PERMISSIONS.CAN_VIEW_ANALYTICS],
            },
        },
    ],
})

export default analyticsRoutes
