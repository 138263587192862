import { request } from '_api/v1/base/behemoth/client'

export const getResidentialComplexes = async (params) => {
    return await request('GET', 'residential_complex/', params)
}

export const getResidentialComplexById = async (residentialComplexId) => {
    return await request('GET', `residential_complex/${residentialComplexId}/`)
}

export const createResidentialComplex = async ({ data }) => {
    return await request('POST', 'residential_complex/', data)
}

export const updateResidentialComplex = async ({ residentialComplexId, data }) => {
    return await request('PUT', `residential_complex/${residentialComplexId}/`, data)
}
