import { PERMISSIONS } from '_features/permissions/constants'

const IntercomsNavigator = () => import('_navigations/Home/UserApp/IntercomsNavigator')

const IntercomsScreen = () => import('_screens/Home/UserApp/Intercoms/IntercomsScreen')
const IntercomEditScreen = () => import('_screens/Home/UserApp/Intercoms/IntercomEditScreen')

const intercomsRoutes = [
    {
        path: 'intercoms',
        component: IntercomsNavigator,
        children: [
            {
                path: 'list',
                name: 'Intercoms',
                component: IntercomsScreen,
                meta: {
                    screenOptions: {
                        title: 'Домофоны',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_INTERCOM],
                },
            },
            {
                path: 'edit/:intercomId?',
                name: 'IntercomEdit',
                component: IntercomEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Домофон',
                    },
                    permissions: [
                        PERMISSIONS.CAN_ADD_INTERCOM,
                        PERMISSIONS.CAN_CHANGE_INTERCOM,
                    ],
                },
            },
        ],
    },
]

export default intercomsRoutes
