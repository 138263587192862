import mutations from './mutations'

const state = {
    hasNew: false,
    isShown: false,
}

export default {
    namespaced: true,
    state,
    mutations,
}
