const DashboardNavigator = () => import('_navigations/Home/Dashboard/DashboardNavigator')

const DashboardScreen = () => import('_screens/Home/Dashboard/DashboardScreen')
const ChangeLogScreen = () => import('_screens/Home/ChangeLog/ChangeLogScreen')

const ВashboardRoutes = [
    {
        path: 'dashboard',
        component: DashboardNavigator,
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: DashboardScreen,
                meta: {
                    screenOptions: {
                        title: 'Рабочий стол',
                    },
                },
            },
            {
                path: 'change-log',
                name: 'ChangeLog',
                component: ChangeLogScreen,
                meta: {
                    screenOptions: {
                        title: 'История изменений',
                    },
                },
            },
        ],
    },
]

export default ВashboardRoutes
