import { map as _map } from 'lodash'

import Entity from '_entities/base/EntityNew'

import BuildingLink from '_entities/building/BuildingLink'
import CompanyLink from '_entities/company/CompanyLink'
import NewsCategoryLink from '_entities/news/NewsCategoryLink'
import Photo from '_entities/media/Photo'
import Service from '_entities/service/Service'

export const NewsSchema = {
    name: 'News',
    primaryKey: 'id',
    properties: {
        buildings: { type: 'BuildingLink[]', entity: BuildingLink, default: [] },
        category: { type: 'NewsCategoryLink', entity: NewsCategoryLink },
        companies: { type: 'CompanyLink[]', entity: CompanyLink, default: [] },
        date: { type: 'int' },
        extLink: { type: 'string' },
        gallery: { type: 'Photo[]', entity: Photo, default: [] },
        id: { type: 'string' }, // UUID
        image: { type: 'Photo', entity: Photo },
        isActive: { type: 'bool', default: true },
        shortText: { type: 'string' },
        service: { type: 'Service', entity: Service },
        text: { type: 'string' },
        title: { type: 'string' },
    },
}

class News extends Entity {
    get schema() {
        return NewsSchema
    }

    get dataMap() {
        return {
            ext_link: 'extLink',
            is_active: 'isActive',
            short_text: 'shortText',
        }
    }

    static fieldLabels = {
        id: 'ID',
        title: 'Заголовок',
        shortText: 'Короткий текст',
        text: 'Текст',
        isActive: 'Статус активности',
        date: 'Дата публикации',
        image: 'Изображение',
        companies: 'Компания',
        buildings: 'Дома',
        category: 'Категория',
        extLink: 'Внешняя ссылка',
        gallery: 'Галерея',
        service: 'Сервис',
    }

    toServer() {
        return {
            title: this.title,
            short_text: this.shortText,
            text: this.text,
            date: this.date,
            image: this.image?.id,
            is_active: this.isActive,
            ext_link: this.extLink,
            companies: _map(this.companies, (item) => item?.id),
            buildings: _map(this.buildings, (item) => item?.id),
            category: this.category?.id,
            gallery: _map(this.gallery, (item) => item?.id),
            service: this.service?.id,
        }
    }
}

export default News
