import { API } from '_api'

import TicketKind from '_entities/ticket/TicketKind'

const actions = {
    async fetchTicketKinds({ commit }, options) {
        commit('fetchTicketKindsRequest')

        try {
            const response = await API.v1.services.ticketKind.getTicketKinds(options)

            const { results } = response

            const items = []

            if (results) {
                results?.forEach((result) => {
                    items.push(new TicketKind(result))
                })
            }

            commit('fetchTicketKindsData', { items })
            commit('fetchTicketKindsSuccess')
        } catch (e) {
            commit('fetchTicketKindsError', e)

            return Promise.reject(e)
        }
    },
}

export default actions
