<template>
    <v-row dense>
        <v-col v-for="(photo, index) in photos" :key="`photo_${photo.id}`" cols="auto">
            <UIMediaPhoto :value="photo" @click="showPhoto(index)" />
        </v-col>
    </v-row>
</template>

<script>
    import UIMediaPhoto from '_ui/media/UIMediaPhoto'
    import '@fancyapps/fancybox'
    import $ from 'jquery'
    import { map as _map } from 'lodash'

    export default {
        name: 'UIMediaPhotos',

        components: {
            UIMediaPhoto,
        },

        props: {
            value: { type: Array, default: () => [] },
        },

        computed: {
            photos() {
                return this.value
            },
        },

        methods: {
            showPhoto(index) {
                const items = _map(this.value, (item) => {
                    return {
                        src: item.getSize('o').url,
                    }
                })
                $.fancybox.open(items, { loop: true }, index)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.6/dist/jquery.fancybox.min.css';
</style>
