/* eslint-disable camelcase */
import { chunk as _chunk, compact as _compact, map as _map } from 'lodash'

import { API } from '_api'
import { request } from '_api/v1/base/document/client'

import { parseDate } from '_utils/datetime'

export const getPremiseDocuments = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    const response = await request('POST', 'documents/post/PremiseDocuments', filters, { params })
    const responseChunks = _chunk(response.results, 25)
    const results = []

    for (const responseChunk of responseChunks) {
        const primeseIds = _map(responseChunk, ({ premise_id }) => premise_id)
        const userIds = _map(responseChunk, ({ created_by }) => created_by?.user_id)

        const primeses = await API.v1.services.premise.getPremises({
            id__in: _compact(primeseIds).join(','),
        })

        const users = await API.v1.services.user.getUsers({
            id__in: _compact(userIds),
        })

        for (const response of responseChunk) {
            response.premise = primeses.results.find((primese) => primese.id === response.premise_id)
            response.user = users.results.find((user) => user.id === response.created_by?.user_id)

            response.created_at = parseDate(response.created_at)?.unix()
            response.date_of_document = parseDate(response.date_of_document)?.unix()

            results.push(response)
        }
    }

    response.results = results

    return response
}

export const getPremiseDocumentById = async (premiseDocumentId) => {
    const response = await request('GET', `documents/PremiseDocument/${premiseDocumentId}`)
    const errors = []

    if (response.account_id) {
        response.account = await API.v1.services.personalAccount.getPersonalAccountById(response.account_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.premise_id) {
        response.premise = await API.v1.services.premise.getPremiseById(response.premise_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.created_by && response.created_by.user_id) {
        response.user = await API.v1.services.user.getUserById(response.created_by.user_id).catch((err) => {
            errors.push(...err)
        })
    }

    response.created_at = parseDate(response.created_at)?.unix()
    response.date_of_document = parseDate(response.date_of_document)?.unix()

    response.errors = errors

    return response
}

export const updatePremiseDocument = async ({ premiseDocumentId, data }) => {
    return await request('PATCH', `documents/PremiseDocument/${premiseDocumentId}`, data)
}

export const createPremiseDocument = async (data) => {
    return await request('POST', 'documents/createPremiseDocument', data)
}

export const deletePremiseDocument = async (premiseDocumentId) => {
    return await request('DELETE', `documents/PremiseDocument/${premiseDocumentId}`)
}

export const getDocumentPremiseTypes = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'documents/post/DocumentPremiseTypes', filters, { params })
}
