<template>
    <v-dialog
        v-model="show"
        dark
        :max-width="options.width"
        scrollable
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="cancel"
    >
        <v-card>
            <v-toolbar dark dense flat>
                <v-toolbar-title class="white--text">
                    Изменение данных клиента
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <FUserEditForm
                    ref="userEditForm"
                    :is-valid.sync="isValid"
                    :resident="resident"
                />
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer />
                <v-btn
                    text
                    @click.native="cancel"
                >
                    Отмена
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="!isValid"
                    @click.native="agree"
                >
                    Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import FUserEditForm from '_features/user/user/components/FUserEditForm'

    export default {
        name: 'FCallerEditDialog',

        components: {
            FUserEditForm,
        },

        data: () => ({
            isValid: false,
            dialog: false,
            resolve: null,
            reject: null,
            options: {
                color: 'primary',
                width: 600,
                zIndex: 200,
            },
        }),

        computed: {
            show: {
                get() {
                    return this.dialog
                },
                set(value) {
                    this.dialog = value
                    if (value === false) {
                        this.cancel()
                    }
                },
            },

            resident() {
                return this.$store.state.sipCall.resident
            },
        },

        methods: {
            open(options) {
                this.dialog = true
                this.options = Object.assign(this.options, options)

                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },

            async agree() {
                await this.$refs.userEditForm.saveResident()

                this.resolve(true)
                this.dialog = false
            },

            cancel() {
                this.resolve()
                this.dialog = false
            },
        },
    }
</script>
