import DiscountValue from '_entities/service/discountCard/DiscountValue'
import Entity from '_entities/base/EntityNew'
import Photo from '_entities/media/Photo'
import ServiceLink from '_entities/service/ServiceLink'
import ServiceDiscountCardCategory from '_entities/service/ServiceDiscountCardCategory'
import ServicePartnerCompany from '_entities/service/ServicePartnerCompany'

export const ServiceDiscountCardSchema = {
    name: 'ServiceDiscountCard',
    primaryKey: 'id',
    properties: {
        cardColor: { type: 'string' },
        company: { type: 'ServicePartnerCompany', entity: ServicePartnerCompany },
        category: { type: 'ServiceDiscountCardCategory', entity: ServiceDiscountCardCategory },
        currentPromotions: { type: 'string' },
        description: { type: 'string' },
        discountAmount: { type: 'string' },
        discountValue: { type: 'DiscountValue', entity: DiscountValue },
        id: { type: 'string' },
        name: { type: 'string' },
        order: { type: 'int' },
        service: { type: 'ServiceLink', entity: ServiceLink },
        shopLocation: { type: 'Photo', entity: Photo },
    },
}

class ServiceDiscountCard extends Entity {
    get schema() {
        return ServiceDiscountCardSchema
    }

    get dataMap() {
        return {
            card_color: 'cardColor',
            current_promotions: 'currentPromotions',
            discount_amount: 'discountAmount',
            discount_value: 'discountValue',
            shop_location: 'shopLocation',
        }
    }

    static fieldLabels = {
        cardColor: 'Цвет карточки',
        company: 'Компания',
        category: 'Категория',
        currentPromotions: 'Текущие акции',
        description: 'Описание',
        discountAmount: 'Размер скидки',
        discountValue: 'Значение дисконтной карты',
        id: 'ID',
        name: 'Название',
        order: 'Порядок',
        service: 'Сервис',
        shopLocation: 'Расположение магазина',
    }

    toServer() {
        return {
            card_color: this.cardColor,
            company: this.company?.id,
            category: this.category?.id,
            current_promotions: this.currentPromotions,
            description: this.description,
            discount_amount: this.discountAmount,
            discount_value: this.discountValue?.toServer(),
            name: this.name,
            service: this.service?.id,
            shop_location: this.shopLocation?.id,
        }
    }
}

export default ServiceDiscountCard
