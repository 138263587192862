import moment from 'moment'

class Entity {
    constructor(data = {}) {
        this.buildEntity(data, this.schema)
    }

    get schema() {
        throw new Error('Model schema not defined.')
    }

    buildEntity(data = {}, schema = {}) {
        Object.keys(schema).forEach((schemaProp) => {
            const dataProp = schema[schemaProp][0]
            const dataValue = data[dataProp]

            const dataValueType = typeof dataValue

            const isAllowedDataValueType = ['number', 'boolean', 'string', 'object', 'function'].includes(dataValueType)

            if (isAllowedDataValueType) {
                if (this.schema[schemaProp][2] !== undefined) {
                    this[schemaProp] = this.schema[schemaProp][2](dataValue)
                } else {
                    this[schemaProp] = dataValue
                }
            } else {
                this[schemaProp] = this.schema[schemaProp][1]
            }
        })
    }

    getIntegerValue(value) {
        if (!value && value !== '0' && value !== 0) {
            return
        }

        return parseInt(value)
    }

    getFloatValue(value) {
        if (!value && value !== '0' && value !== 0) {
            return
        }

        return parseFloat(value)
    }

    getDatetimeDateValue(value) {
        const _value = this.getIntegerValue(value)

        if (!_value) {
            return
        }

        const momentItem = moment.unix(_value)

        return momentItem.startOf('day').unix()
    }

    getDatetimeTimeValue(value) {
        const _value = this.getIntegerValue(value)

        if (!_value) {
            return
        }

        const dateMomentItem = moment.unix(_value)

        return dateMomentItem.locale('ru').format('HH:mm')
    }

    getCoordinatesValue(value) {
        if (!value) {
            return {
                lat: undefined,
                lon: undefined,
            }
        }

        const coordinates = value.split(':')

        return {
            lat: coordinates[0],
            lon: coordinates[1],
        }
    }

    getEntityValue(EntityClass, value) {
        if (value && typeof value === 'object') {
            return new EntityClass(value)
        }
    }
}

export default Entity
