import Entity from '_entities/base/EntityNew'

import Doc from '_entities/media/Doc'
import Photo from '_entities/media/Photo'
import UserLink from '_entities/user/UserLink'

export const ChatMessageSchema = {
    name: 'ChatMessage',
    primaryKey: 'id',
    properties: {
        answerTime: { type: 'int' }, // UNIX
        answeredBy: { type: 'UserLink', entity: UserLink },
        author: { type: 'UserLink', entity: UserLink },
        chatId: { type: 'string' }, // UUID
        date: { type: 'int' }, // UNIX
        files: { type: 'Doc[]', entity: Doc, default: [] },
        id: { type: 'string' }, // UUID
        images: { type: 'Photo[]', entity: Photo, default: [] },
        isUnread: { type: 'bool' },
        text: { type: 'string' },
    },
}

class ChatMessage extends Entity {
    get schema() {
        return ChatMessageSchema
    }

    get dataMap() {
        return {
            answer_time: 'answerTime',
            answered_by: 'answeredBy',
            chat_id: 'chatId',
            is_unread: 'isUnread',
        }
    }

    get isUnanswered() {
        return !this.answerTime
    }
}

export default ChatMessage
