import { forEach as _forEach } from 'lodash'

import Entity from '_entities/base/EntityNew'
import Photo from '_entities/media/Photo'

export const DiscountValueSchema = {
    name: 'DiscountValue',
    primaryKey: 'id',
    properties: {
        barCode: { type: 'Photo', entity: Photo },
        id: { type: 'string' },
        promoCode: { type: 'string' },
        qrCode: { type: 'Photo', entity: Photo },
        type: { type: 'string' },
    },
}

class DiscountValue extends Entity {
    get schema() {
        return DiscountValueSchema
    }

    get dataMap() {
        return {
            bar_code: 'barCode',
            promo_code: 'promoCode',
            qr_code: 'qrCode',
        }
    }

    static fieldLabels = {
        barCode: 'Штрих-код',
        id: 'ID',
        promoCode: 'Промокод',
        qrCode: 'QR-код',
        type: 'Тип',
    }

    static types = [
        {
            id: 'bar_code',
            name: 'Штрих-код',
        },
        {
            id: 'promo_code',
            name: 'Промокод',
        },
        {
            id: 'qr_code',
            name: 'QR-код',
        },
    ]

    toServer() {
        const result = {
            bar_code: this.barCode?.id,
            id: this.id,
            promo_code: this.promoCode,
            qr_code: this.qrCode?.id,
            type: this.type,
        }

        _forEach(this.constructor.types, (type) => {
            if (type.id !== this.type) {
                result[type.id] = null
            }
        })

        return result
    }
}

export default DiscountValue
