import { PERMISSIONS } from '_features/permissions/constants'

const StorageNavigator = () => import('_navigations/Home/Storage/StorageNavigator')

const StorageKeyIssuanceScreen = () => import('_screens/Home/Storage/StorageKeyIssuanceScreen')
const StorageKeyIssueEditScreen = () => import('_screens/Home/Storage/StorageKeyIssueEditScreen')
const StorageKeyScreen = () => import('_screens/Home/Storage/StorageKeyScreen')
const StorageProxyEditScreen = () => import('_screens/Home/Storage/StorageProxyEditScreen')
const StorageProxyScreen = () => import('_screens/Home/Storage/StorageProxyScreen')

const storageRoutes = []

storageRoutes.push({
    path: 'storage',
    component: StorageNavigator,
    children: [
        {
            path: 'storage-key-issuance',
            name: 'StorageKeyIssuance',
            component: StorageKeyIssuanceScreen,
            meta: {
                screenOptions: {
                    title: 'Выдачи',
                },
                permissions: [PERMISSIONS.CAN_VIEW_STORAGE_KEY_ISSUANCE],
            },
        },
        {
            path: 'storage-key',
            name: 'StorageKey',
            component: StorageKeyScreen,
            meta: {
                screenOptions: {
                    title: 'Ключи',
                },
                permissions: [PERMISSIONS.CAN_VIEW_STORAGE_KEY],
            },
        },
        {
            path: 'storage-proxy',
            name: 'StorageProxy',
            component: StorageProxyScreen,
            meta: {
                screenOptions: {
                    title: 'Доверенности',
                },
                permissions: [PERMISSIONS.CAN_VIEW_STORAGE_PROXY],
            },
        },
        {
            path: 'storage-proxy-edit',
            name: 'StorageProxyEdit',
            component: StorageProxyEditScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Доверенность',
                },
                permissions: [PERMISSIONS.CAN_CHANGE_STORAGE_PROXY],
            },
        },
        {
            path: 'storage-issue-edit',
            name: 'StorageIssueEdit',
            component: StorageKeyIssueEditScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Выдача',
                },
            },
        },
    ],
})

export default storageRoutes
