<template>
    <v-dialog
        v-model="model"
        persistent
        :width="width"
    >
        <v-sheet
            class="d-flex align-center"
            :width="width"
            :height="height"
        >
            <v-container>
                <v-card-text>
                    <v-row justify="space-around">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        />
                    </v-row>
                    <v-row v-if="title" justify="space-around">
                        <v-card-title class="text-center">
                            {{ title }}
                        </v-card-title>
                    </v-row>
                    <v-row v-if="text" dense justify="space-around">
                        <v-col cols="auto">
                            <v-card-text class="text-center">
                                {{ text }}
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-row v-if="progress" dense justify="space-around">
                        <v-col cols="10">
                            <v-progress-linear :value="progress" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-container>
        </v-sheet>
    </v-dialog>
</template>

<script>
    export default {
        name: 'UIScreenSpinner',

        props: {
            height: { type: Number, default: 300 },
            progress: { type: Number, default: null },
            text: { type: String, default: null },
            title: { type: String, default: null },
            value: { type: Boolean, default: true },
            width: { type: Number, default: 400 },
        },

        computed: {
            model() {
                return this.value
            },
        },
    }
</script>
