import Entity from '_entities/base/EntityNew'

import ServiceSection from '_entities/service/ServiceSection'

export const ServiceSubsectionSchema = {
    name: 'ServiceSubsection',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        order: { type: 'int' },
        section: { type: 'ServiceSection', entity: ServiceSection },
    },
}

class ServiceSubsection extends Entity {
    get schema() {
        return ServiceSubsectionSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
        order: 'Порядок',
        section: 'Раздел',
    }

    toServer() {
        return {
            name: this.name,
            order: this.order,
            section: this.section?.id,
        }
    }
}

export default ServiceSubsection
