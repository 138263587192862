import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const state = {
    items: {},
}

export default {
    namespaced: true,
    actions,
    state,
    mutations,
    getters,
}
