<template>
    <div>
        <CAttachment :class="{'error--text': isError}">
            <v-row align="center" justify="space-between" no-gutters>
                <v-col cols="auto" :class="{'required': isRequired}">
                    {{ label }}
                </v-col>
            </v-row>
            <v-row dense>
                <template v-for="(doc, docIndex) in docs">
                    <v-col :key="`docItem_${docIndex}`" cols="12">
                        <UIAttachmentDocItem
                            class="c-attachment-doc__item"
                            :disabled="disabled"
                            :value="doc"
                            @click-remove="removeDoc(docIndex)"
                        />
                    </v-col>
                </template>
                <v-col v-if="isShownAdd" cols="12">
                    <v-btn
                        color="primary"
                        :disabled="isUploading || disabled"
                        :loading="isUploading"
                        text
                        @click="addDoc()"
                    >
                        Добавить
                    </v-btn>
                </v-col>
            </v-row>
        </CAttachment>
        <div v-if="isError" class="v-messages theme--light error--text mt-2 pl-3" role="alert">
            <div class="v-messages__wrapper">
                <div class="v-messages__message">
                    Обязательное поле.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { forEach as _forEach } from 'lodash'

    import { API } from '_api'

    import Doc from '_entities/media/Doc'

    import CAttachment from '_common/components/Attachments/CAttachment'

    import UIAttachmentDocItem from '_ui/attachments/UIAttachmentDocItem'

    import { selectFiles } from '_utils/file'

    export default {
        name: 'UIAttachmentDocs',

        components: {
            CAttachment,
            UIAttachmentDocItem,
        },

        props: {
            // TODO: disabled
            disabled: { type: Boolean, default: false },
            hint: { type: String, default: null },
            isRequired: { type: Boolean, default: false },
            isDocumentFile: { type: Boolean, default: false },
            fileApi: { type: String, default: null }, // null || 'documents' || 'S3Doc'
            label: { type: String, default: null },
            limit: { type: Number, default: null },
            multiple: { type: Boolean },
            value: { type: [Array, Object], default: null },
            maxSize: { type: Number, default: null }, // Размер файла в мб
        },

        data: () => ({
            isResponse: false,
            isUploading: false,
            attachments: [],
        }),

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            isError() {
                return this.isRequired && this.isResponse
            },

            isMultiple() {
                return this.multiple && Array.isArray(this.model)
            },

            docs() {
                const docs = []

                if (this.model) {
                    if (this.isMultiple) {
                        _forEach(this.model, (o) => {
                            docs.push(o)
                        })
                    } else {
                        docs.push(this.model)
                    }
                }

                return docs
            },

            isShownAdd() {
                if (this.isMultiple) {
                    if (this.limit) {
                        return this.docs.length < this.limit
                    } else {
                        return true
                    }
                } else {
                    return this.docs.length < 1
                }
            },
        },

        watch: {
            attachments: {
                handler() {
                    this.uploadAttachments()
                },
                immediate: true,
            },
        },

        methods: {
            choiceAPI(docs) {
                switch (this.fileApi) {
                    case 'documents':
                        return API.v1.services.documentFile.uploadDocumentFile(docs)
                    case 'S3Doc':
                        return API.v1.services.file.uploadS3Doc(docs)
                    default:
                        return API.v1.services.file.uploadDoc(docs)
                }
            },

            async addDoc() {
                if (!this.isUploading) {
                    const files = await selectFiles({
                        multiple: this.multiple,
                    })
                    _forEach(files, (file) => {
                        if (this.maxSize) {
                            const fileMaxSize = this.maxSize * (1000 * 1000)
                            if (file.size > fileMaxSize) {
                                return
                            }
                        }
                        this.attachments.push({
                            file,
                        })
                    })
                }
            },

            removeDoc(attachmentIndex) {
                if (this.isMultiple) {
                    this.model = this.model.filter((item, index) => index !== attachmentIndex)
                } else {
                    this.model = null
                }
            },

            async uploadAttachments() {
                if (!this.isUploading && this.attachments.length) {
                    this.isUploading = true

                    if (this.fileApi !== 'S3Doc') {
                        const docs = new FormData()
                        _forEach(this.attachments, (attachment) => {
                            docs.append('files', attachment.file)
                        })

                        this.choiceAPI(docs)
                            .then((response) => {
                                if (this.multiple) {
                                    const docs = this.fileApi === 'documents' ? response : response?.results

                                    _forEach(docs, (doc) => {
                                        this.model.push(new Doc(doc))
                                    })
                                } else {
                                    this.model = new Doc(response[0])
                                }
                                this.attachments = []
                            })
                            .catch((e) => {
                                this.$root.$pushRequestNotifications('Не удалось загрузить документ', e)
                                this.$logger.logException(e)
                            })
                            .finally(() => {
                                this.isUploading = false
                            })
                    } else {
                        for (const attachment of this.attachments) {
                            const fileFormData = new FormData()
                            fileFormData.append('file', attachment.file)

                            let type = (attachment.file.type)?.split('/')[0]

                            // На бэке нет строгого соответствия MIME типам
                            if (type !== 'image' && type !== 'audio' && type !== 'video') {
                                type = 'document'
                            }

                            fileFormData.append('type', type)

                            try {
                                const response = await this.choiceAPI(fileFormData)

                                if (response?.file) {
                                    if (this.multiple) {
                                        this.model.push(new Doc(response.file))
                                    } else {
                                        this.model = new Doc(response.file)
                                    }
                                }
                            } catch (e) {
                                this.$root.$pushRequestNotifications('Не удалось загрузить документ', e)
                                this.$logger.logException(e)
                            }
                        }
                    }

                    this.attachments = []
                    this.isUploading = false
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/variables";

    .required {
        &::after {
            content: "*";
            color: #f44336;
        }
    }

    .error--text {
        border-color: #f44336;
    }
</style>
