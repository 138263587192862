/* eslint-disable camelcase */
import { chunk as _chunk, compact as _compact, map as _map } from 'lodash'

import { API } from '_api'
import { request } from '_api/v1/base/document/client'

export const getCommercialAppealDocuments = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    const response = await request('POST', 'documents/post/CommercialAppealDocument', filters, { params })
    const responseChunks = _chunk(response.results, 25)
    const results = []

    for (const responseChunk of responseChunks) {
        const companyRecipientIds = _map(responseChunk, ({ company_recipient_id }) => company_recipient_id)
        const userIds = _map(responseChunk, ({ created_by }) => created_by?.user_id)

        const companies = await API.v1.services.company.getCompanies({
            id__in: _compact(companyRecipientIds).join(','),
        })

        const users = await API.v1.services.user.getUsers({
            id__in: _compact(userIds),
        })

        for (const response of responseChunk) {
            response.company_recipient = companies.results.find((company) => company.id === response.company_recipient_id)
            response.user = users.results.find((user) => user.id === response.created_by?.user_id)

            results.push(response)
        }
    }

    response.results = results

    return response
}

export const getCommercialAppealDocumentById = async (commercialAppealDocumentId) => {
    const response = await request('GET', `documents/CommercialAppealDocument/${commercialAppealDocumentId}`)
    const errors = []

    if (response.company_recipient_id) {
        response.company_recipient = await API.v1.services.company.getCompanyById(response.company_recipient_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.created_by && response.created_by.user_id) {
        response.user = await API.v1.services.user.getUserById(response.created_by.user_id).catch((err) => {
            errors.push(...err)
        })
    }

    response.errors = errors

    return response
}

export const updateCommercialAppealDocument = async (commercialAppealDocumentId, data) => {
    return await request('PATCH', `documents/CommercialAppealDocument/${commercialAppealDocumentId}`, data)
}

export const createCommercialAppealDocument = async (data) => {
    return await request('POST', 'documents/CommercialAppealDocument/create', data)
}

export const deleteCommercialAppealDocument = async (commercialAppealDocumentId) => {
    return await request('DELETE', `documents/CommercialAppealDocument/${commercialAppealDocumentId}`)
}

export const getCommercialAppealDocumentTypes = async (params) => {
    return await request('POST', 'documents/post/CommercialAppealType', params)
}
