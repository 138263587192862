<template>
    <CContainer fluid>
        <CSection>
            <v-tabs class="tabs">
                <v-tab
                    v-for="item in navigated"
                    :key="item.route"
                    :to="item.route"
                    link
                    exact-active-class="link-active"
                >
                    {{ item.text }}
                </v-tab>
            </v-tabs>
        </CSection>

        <slot />
    </CContainer>
</template>

<script>
    import CContainer from '_common/components/CContainer'
    import CSection from '_common/components/CSection'

    export default {
        name: 'UIPageNav',

        components: {
            CContainer,
            CSection,
        },

        props: {
            navigated: {
                type: Array,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>
    .tabs {
        margin-bottom: 10px;
        border-radius: 8px;
        box-shadow:    0px 2px 6px rgba(0, 0, 0, 0.25);
    }

    .link-active {
        background-color: #f0d9ed;
    }
</style>
