import { request } from '_api/v1/base/behemoth/client'

export const getUnreadMessagesCount = async () => {
    return await request('GET', 'notification/unread_messages_count/')
}

export const sendSMS = async (params) => {
    return await request('POST', 'notification/send_sms/', params)
}

// Notification center

export const getNotificationsByUser = async (userId, params) => {
    return await request('GET', `v1/notification_center/user_notifications/${userId}/`, params)
}

// Методы для тестирования
export const sendHandleNotification = async (params) => {
    return await request('POST', 'v1/notification_center/send_handle_notification/', params)
}

export const sendNotification = async (params) => {
    return await request('POST', 'v1/notification_center/send_notification/', params)
}
