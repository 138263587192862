import ServiceElement from '_entities/service/ServiceElement'

export const ServiceElementTextInputSchema = {
    elementType: { type: 'string', default: 'textinput' },
    placeholder: { type: 'string' },
}

class ServiceElementTextInput extends ServiceElement {
    get schema() {
        return {
            ...super.schema.name,
            ...super.schema.primaryKey,
            properties: {
                ...super.schema.properties,
                ...ServiceElementTextInputSchema,
            },
        }
    }

    get dataMap() {
        return {
            ...super.dataMap,
            element_type: 'elementType',
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
        placeholder: 'Placeholder',
    }

    toServer(data) {
        return {
            ...super.toServer(data),
            placeholder: data?.placeholder,
        }
    }
}

export default ServiceElementTextInput
