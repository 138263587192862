<template>
    <v-card v-if="isInfoShown" flat class="pa-2">
        <template v-if="!isExpanded">
            <v-btn icon small @click="isExpanded = !isExpanded">
                <v-icon color="primary" v-text="'$caretLeftSolid'" />
            </v-btn>
            <v-icon v-text="'$toolsSolid'" />
        </template>
        <template v-else>
            <v-btn icon small @click="isExpanded = !isExpanded">
                <v-icon color="primary" v-text="'$caretRightSolid'" />
            </v-btn>
            <b>Планируются техработы с {{ formattedTime }}</b>
        </template>
    </v-card>
</template>

<script>
    import { parseDate } from '_utils/datetime'

    export default {
        name: 'FMaintenanceInfo',

        data: () => ({
            isExpanded: false,
        }),

        computed: {
            formattedTime() {
                const dateStart = this.$store.state.maintenanceWork?.status?.dateStart

                return dateStart ? parseDate(dateStart).locale('ru').format('LLL') : null
            },

            isInfoShown() {
                return this.$store.state.maintenanceWork?.isInfoShown
            },
        },
    }
</script>
