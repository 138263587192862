<template>
    <CAttachment class="c-attachment-photo" :class="{ required: required && !photos.length }">
        <v-row align="center" justify="space-between" no-gutters>
            <v-col cols="auto">
                {{ label }}
            </v-col>
        </v-row>
        <v-row dense>
            <template v-for="(photo, photoIndex) in photos">
                <v-col :key="`photoItem_${photoIndex}`" cols="auto">
                    <UIAttachmentImageItem
                        :aspect-ratio="aspectRatio"
                        class="c-attachment-photo__item"
                        :value="photo"
                        @click-remove="removePhoto(photoIndex)"
                    />
                    <div class="text-center text-caption grey--text">
                        {{ hint }}
                    </div>
                </v-col>
            </template>

            <v-col v-if="isShownAdd" cols="auto">
                <div class="c-attachment-photo__item">
                    <v-responsive :aspect-ratio="aspectRatio">
                        <div class="c-attachment-photo__placeholder" @click="addPhoto()">
                            <v-progress-circular v-if="isUploading" color="grey lighten-1" indeterminate />
                            <v-icon v-else color="grey lighten-1" x-large v-text="`$plus`" />
                        </div>
                    </v-responsive>
                </div>
            </v-col>
        </v-row>
    </CAttachment>
</template>

<script>
    import { forEach as _forEach } from 'lodash'

    import { API } from '_api'

    import FileImage from '_entities/media/file/FileImage'

    import CAttachment from '_common/components/Attachments/CAttachment'

    import UIAttachmentImageItem from '_ui/attachments/UIAttachmentImageItem'

    import { selectFiles } from '_utils/file'

    export default {
        name: 'UIAttachmentImages',

        components: {
            CAttachment,
            UIAttachmentImageItem,
        },

        props: {
            // TODO: disabled
            disabled: { type: Boolean, default: false },
            required: { type: Boolean },
            hint: { type: String, default: null },
            label: { type: String, default: null },
            limit: { type: Number, default: null },
            multiple: { type: Boolean },
            value: { type: [Array, Object], default: null },
        },

        data: () => ({
            aspectRatio: 1,
            isUploading: false,
            attachments: [],
        }),

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            isMultiple() {
                return this.multiple && Array.isArray(this.model)
            },

            photos() {
                const photos = []

                if (this.model) {
                    if (this.isMultiple) {
                        _forEach(this.model, (o) => {
                            photos.push(o)
                        })
                    } else {
                        photos.push(this.model)
                    }
                }

                return photos
            },

            isShownAdd() {
                if (this.isMultiple) {
                    if (this.limit) {
                        return this.photos.length < this.limit
                    } else {
                        return true
                    }
                } else {
                    return this.photos.length < 1
                }
            },
        },

        watch: {
            attachments: {
                handler() {
                    this.uploadAttachments()
                },
                immediate: true,
            },
        },

        methods: {
            async addPhoto() {
                if (!this.isUploading && !this.disabled) {
                    const files = await selectFiles({
                        multiple: this.multiple,
                    })

                    _forEach(files, (file) => {
                        this.attachments.push({
                            file,
                        })
                    })
                }
            },

            removePhoto(attachmentIndex) {
                if (this.disabled) {
                    return
                }

                if (this.isMultiple) {
                    this.model = this.model.filter((item, index) => index !== attachmentIndex)
                } else {
                    this.model = null
                }
            },

            async uploadAttachments() {
                if (!this.isUploading && this.attachments.length) {
                    this.isUploading = true
                    let images = new FormData()

                    // Загрузка по одному файлу, чтобы пропустить некорректные и успешно загрузить корректрые
                    for (const attachment of this.attachments) {
                        images = new FormData()
                        images.append('file', attachment.file)

                        let type = (attachment.file.type)?.split('/')[0]

                        // На бэке нет строгого соответствия MIME типам
                        if (type !== 'image' && type !== 'audio' && type !== 'video') {
                            type = 'document'
                        }

                        images.append('type', type)

                        try {
                            const response = await API.v1.services.file.uploadS3Doc(images)

                            if (response?.image) {
                                this.model = new FileImage(response.image)
                            }
                        } catch (e) {
                            this.$root.$pushRequestNotifications('Не удалось загрузить изображение', e)
                            this.$logger.logException(e)
                        }

                        this.attachments = []
                        this.isUploading = false
                    }
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    .c-attachment-photo {
        &__item {
            background-color: map-get($grey, lighten-4);
            border-radius:    $border-radius-root;

            width:            10rem;

            display:          flex;

            overflow:         hidden;
            position:         relative;
            z-index:          1;
        }

        &__placeholder {
            cursor:          pointer;

            width:           100%;
            height:          100%;

            display:         flex;
            align-items:     center;
            justify-content: center;
        }
    }
</style>
