import Entity from '_entities/base/EntityNew'

export const PushMessageTemplateLinkSchema = {
    name: 'PushMessageTemplateLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' }, // UUID
        title: { type: 'string' },
    },
}

class PushMessageTemplateLink extends Entity {
    get schema() {
        return PushMessageTemplateLinkSchema
    }
}

export default PushMessageTemplateLink
