<template>
    <v-card
        class="page-header"
        flat
        :style="{ top: $vuetify.application.top + 'px' }"
    >
        <UIHeader class="page-header__in">
            <v-row align="center" dense>
                <v-col cols="auto">
                    <v-card-title class="pa-0">
                        {{ headerTitle }}
                    </v-card-title>
                </v-col>
                <v-col cols="auto">
                    <slot name="subtitle" />
                </v-col>
            </v-row>

            <template v-if="headerBack" v-slot:back>
                <v-btn
                    icon
                    @click="goBack"
                >
                    <v-icon v-text="'$arrowLeft'" />
                </v-btn>
            </template>

            <template v-slot:actions>
                <slot name="actions" />
            </template>

            <template v-slot:description>
                <slot name="description" />
            </template>

            <template v-slot:bottom>
                <slot name="bottom" />
            </template>
        </UIHeader>
    </v-card>
</template>

<script>
    import UIHeader from '_ui/layout/UIHeader'

    export default {
        name: 'UIPageHeader',

        components: {
            UIHeader,
        },

        props: {
            title: { type: String, default: null },
        },

        computed: {
            headerTitle() {
                return this.title || this.$route.meta?.screenOptions?.title
            },

            headerBack() {
                return this.$route.meta?.showBack
            },
        },

        methods: {
            goBack() {
                if (this.$listeners && this.$listeners['click-back']) {
                    this.$emit('click-back')
                } else {
                    this.$router.back()
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/variables";

    .page-header {
        position: sticky;
        z-index: 4;
    }
    .page-header__in {
        border-bottom:  1px solid #cccccc;
        padding-bottom: map-get($spacers, 3);
        margin-bottom:  map-get($spacers, 3);
        background-color: white;
    }
</style>
