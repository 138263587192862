const mutations = {
    restoreAccessRequest(state) {
        state.isRestoringAccess = true
    },

    restoreAccessSuccess(state, { accessToken, userId }) {
        state.errors = []
        state.isRestoringAccess = false
        state.accessToken = accessToken
        state.userId = userId
    },

    restoreAccessError(state, errors) {
        state.errors = errors
        state.isRestoringAccess = false
        state.accessToken = null
        state.userId = null
    },

    saveCurrentWorkerRequest(state) {
        state.isSavingWorkerData = true
    },

    saveCurrentWorkerSuccsess(state) {
        state.errors = []
        state.isSavingWorkerData = false
    },

    saveWorCurrentkerError(state, errors) {
        state.errors = errors
        state.isSavingWorkerData = false
    },

    signInRequest(state) {
        state.isSigningIn = true
    },

    signInSuccess(state, { accessToken, userId }) {
        state.errors = []
        state.isRestoringAccess = false
        state.isSigningIn = false
        state.isSignedOut = false
        state.accessToken = accessToken
        state.userId = userId
    },

    signInError(state, errors) {
        state.errors = errors
        state.isSigningIn = false
        state.accessToken = null
        state.userId = null
    },

    signOutRequest(state) {
        state.isSigningOut = true
    },

    signOutSuccess(state) {
        state.errors = []
        state.isSigningOut = false
        state.isSignedOut = true
        state.accessToken = null
        state.userId = null
    },

    signOutError(state, errors) {
        state.errors = errors
        state.isSigningOut = false
        state.accessToken = null
        state.userId = null
    },

    fetchCurrentWorkerRequest(state) {
        state.isFetchingCurrentWorker = true
    },

    fetchCurrentWorkersSuccess(state, { currentWorker, currentUser }) {
        state.errors = []
        state.currentWorker = currentWorker
        state.currentUser = currentUser
        state.isFetchingCurrentWorker = false
    },

    fetchCurrentWorkersError(state, errors) {
        state.errors = errors
        state.isFetchingCurrentWorker = false
    },

    setIsAtWorkStatus(state, status) {
        state.isAtWork = status
    },
}

export default mutations
