import { request } from '_api/v1/base/behemoth/client'

import { getTicketDepartments } from '_api/v1/services/ticketDepartment'
import { getTicketKinds } from '_api/v1/services/ticketKind'
import { getTicketTypes } from '_api/v1/services/ticketType'

import ServiceElementCalendarEvent from '_entities/service/ServiceElementCalendarEvent'
import ServiceElementCatalog from '_entities/service/ServiceElementCatalog'
import ServiceElementCheckbox from '_entities/service/ServiceElementCheckbox'
import ServiceElementCheckboxTag from '_entities/service/ServiceElementCheckboxTag'
import ServiceElementDate from '_entities/service/ServiceElementDate'
import ServiceElementDateTime from '_entities/service/ServiceElementDateTime'
import ServiceElementDropdown from '_entities/service/ServiceElementDropdown'
import ServiceElementFile from '_entities/service/ServiceElementFile'
import ServiceElementPushMessage from '_entities/service/ServiceElementPushMessage'
import ServiceElementRadio from '_entities/service/ServiceElementRadio'
import ServiceElementRadioWithImage from '_entities/service/ServiceElementRadioWithImage'
import ServiceElementSendMessage from '_entities/service/ServiceElementSendMessage'
import ServiceElementText from '_entities/service/ServiceElementText'
import ServiceElementTextInput from '_entities/service/ServiceElementTextInput'
import ServiceElementTime from '_entities/service/ServiceElementTime'
import ServiceElementTitle1 from '_entities/service/ServiceElementTitle1'
import ServiceElementTitle2 from '_entities/service/ServiceElementTitle2'
import ServiceElementUploadImage from '_entities/service/ServiceElementUploadImage'
import ServiceElementUploadFiles from '_entities/service/ServiceElementUploadFiles'

import TicketDepartmentLink from '_entities/ticket/TicketDepartmentLink'
import TicketKindLink from '_entities/ticket/TicketKindLink'
import TicketTypeLink from '_entities/ticket/TicketTypeLink'

import { uniq as _uniq } from 'lodash'
// import moment from 'moment'
//
// Service
// -----------------------------------------------------------------------------

export const getServices = async (params) => {
    return await request('GET', 'service/service/', params)
}

export const getServiceById = async (serviceId) => {
    let ticketDepartmentIds = []
    let ticketKindIds = []
    let ticketTypeIds = []

    const ticketDepartments = {}
    const ticketKinds = {}
    const ticketTypes = {}

    // Загрузка самого сервиса
    const serviceResponse = await request('GET', `service/service/${serviceId}/`)

    // Сбор id элементов типизации
    if (serviceResponse.elements) {
        for (let el = 0; el < serviceResponse.elements.length; el++) {
            const element = serviceResponse.elements[el]

            if (element.variants) {
                for (let vr = 0; vr < element.variants.length; vr++) {
                    const variant = element.variants[vr]

                    if (variant.ticket_department) {
                        ticketDepartmentIds.push(variant.ticket_department)
                    }
                    if (variant.ticket_kind) {
                        ticketKindIds.push(variant.ticket_kind)
                    }
                    if (variant.ticket_type) {
                        ticketTypeIds.push(variant.ticket_type)
                    }
                }
            }
        }
    }

    ticketDepartmentIds = _uniq(ticketDepartmentIds)
    ticketKindIds = _uniq(ticketKindIds)
    ticketTypeIds = _uniq(ticketTypeIds)

    // Подгрузка справочников типизации
    let typeResponse

    if (ticketDepartmentIds.length) {
        typeResponse = await getTicketDepartments({
            page: 1,
            page_size: Number.MAX_SAFE_INTEGER,
            id__in: ticketDepartmentIds,
        })

        if (typeResponse?.results?.length) {
            typeResponse.results.forEach((ticketDepartment) => {
                ticketDepartments[ticketDepartment.id] = new TicketDepartmentLink(ticketDepartment)
            })
        }
    }

    if (ticketKindIds.length) {
        typeResponse = await getTicketKinds({
            page: 1,
            page_size: Number.MAX_SAFE_INTEGER,
            id__in: ticketKindIds,
        })

        if (typeResponse?.results?.length) {
            typeResponse.results.forEach((ticketKind) => {
                ticketKinds[ticketKind.id] = new TicketKindLink(ticketKind)
            })
        }
    }

    if (ticketTypeIds.length) {
        typeResponse = await getTicketTypes({
            page: 1,
            page_size: Number.MAX_SAFE_INTEGER,
            id__in: ticketTypeIds,
        })

        if (typeResponse?.results?.length) {
            typeResponse.results.forEach((ticketType) => {
                ticketTypes[ticketType.id] = new TicketTypeLink(ticketType)
            })
        }
    }

    // Заполнение сущностями
    if (serviceResponse.elements) {
        for (let el = 0; el < serviceResponse.elements.length; el++) {
            const element = serviceResponse.elements[el]

            if (element.variants) {
                for (let vr = 0; vr < element.variants.length; vr++) {
                    const variant = element.variants[vr]

                    variant.ticket_department = ticketDepartments[variant.ticket_department]
                    variant.ticket_kind = ticketKinds[variant.ticket_kind]
                    variant.ticket_type = ticketTypes[variant.ticket_type]
                }
            }

            switch (element.element_type) {
                case 'calendar_event':
                    serviceResponse.elements[el] = new ServiceElementCalendarEvent(element)
                    break
                case 'catalog':
                    serviceResponse.elements[el] = new ServiceElementCatalog(element)
                    break
                case 'checkbox':
                    serviceResponse.elements[el] = new ServiceElementCheckbox(element)
                    break
                case 'checkbox_tag':
                    serviceResponse.elements[el] = new ServiceElementCheckboxTag(element)
                    break
                case 'date':
                    serviceResponse.elements[el] = new ServiceElementDate(element)
                    break
                case 'datetime':
                    serviceResponse.elements[el] = new ServiceElementDateTime(element)
                    break
                case 'dropdown':
                    serviceResponse.elements[el] = new ServiceElementDropdown(element)
                    break
                case 'files':
                    serviceResponse.elements[el] = new ServiceElementFile(element)
                    break
                case 'radiobutton':
                    serviceResponse.elements[el] = new ServiceElementRadio(element)
                    break
                case 'radiobutton_with_image':
                    serviceResponse.elements[el] = new ServiceElementRadioWithImage(element)
                    break
                case 'text':
                    serviceResponse.elements[el] = new ServiceElementText(element)
                    break
                case 'textinput':
                    serviceResponse.elements[el] = new ServiceElementTextInput(element)
                    break
                case 'time':
                    serviceResponse.elements[el] = new ServiceElementTime(element)
                    break
                case 'title_1':
                    serviceResponse.elements[el] = new ServiceElementTitle1(element)
                    break
                case 'title_2':
                    serviceResponse.elements[el] = new ServiceElementTitle2(element)
                    break
                case 'upload_images':
                    serviceResponse.elements[el] = new ServiceElementUploadImage(element)
                    break
                case 'upload_files':
                    serviceResponse.elements[el] = new ServiceElementUploadFiles(element)
                    break
                case 'push_message':
                    serviceResponse.elements[el] = new ServiceElementPushMessage(element)
                    break
                case 'send_message':
                    serviceResponse.elements[el] = new ServiceElementSendMessage(element)
            }
        }
    }

    return serviceResponse
}

export const createService = async ({ data }) => {
    return await request('POST', 'service/service/', data)
}

export const updateService = async ({ serviceId, data }) => {
    return await request('PUT', `service/service/${serviceId}/`, data)
}

export const deleteService = async (serviceId) => {
    return await request('DELETE', `service/service/${serviceId}/`)
}

//
// Section
// -----------------------------------------------------------------------------

export const getServiceSections = async (params) => {
    return await request('GET', 'service/section/', params)
}

export const getServiceSectionById = async (serviceSectionId) => {
    return await request('GET', `service/section/${serviceSectionId}/`)
}

export const createServiceSection = async ({ data }) => {
    return await request('POST', 'service/section/', data)
}

export const updateServiceSection = async ({ serviceSectionId, data }) => {
    return await request('PUT', `service/section/${serviceSectionId}/`, data)
}

export const deleteServiceSection = async (serviceSectionId) => {
    return await request('DELETE', `service/section/${serviceSectionId}/`)
}

//
// Subsection
// -----------------------------------------------------------------------------

export const getServiceSubsections = async (params) => {
    return await request('GET', 'service/subsection/', params)
}

export const getServiceSubsectionById = async (serviceSubsectionId) => {
    return await request('GET', `service/subsection/${serviceSubsectionId}/`)
}

export const createServiceSubsection = async ({ data }) => {
    return await request('POST', 'service/subsection/', data)
}

export const updateServiceSubsection = async ({ serviceSubsectionId, data }) => {
    return await request('PUT', `service/subsection/${serviceSubsectionId}/`, data)
}

export const deleteServiceSubsection = async (serviceSubsectionId) => {
    return await request('DELETE', `service/subsection/${serviceSubsectionId}/`)
}

//
// Element
// -----------------------------------------------------------------------------

export const getServiceElementCalendarDates = async (serviceElementId) => {
    return await request('GET', `service/element/calendar_date/${serviceElementId}/`)
}

export const getServiceElementCalendarTimes = async (serviceElementId, date) => {
    return await request('GET', `service/element/calendar_date/${serviceElementId}/${date}/`)
}

//
// Discount card
// -----------------------------------------------------------------------------

export const getServiceDiscountCards = async (params) => {
    return await request('GET', 'service/bonus/card/', params)
}

export const getServiceDiscountCardById = async (serviceDiscountCardId) => {
    return await request('GET', `service/bonus/card/${serviceDiscountCardId}/`)
}

export const createServiceDiscountCard = async ({ data }) => {
    return await request('POST', 'service/bonus/card/', data)
}

export const updateServiceDiscountCard = async ({ serviceDiscountCardId, data }) => {
    return await request('PUT', `service/bonus/card/${serviceDiscountCardId}/`, data)
}

//
// Discount card category
// -----------------------------------------------------------------------------

export const getServiceDiscountCardCategories = async (params) => {
    return await request('GET', 'service/bonus/category/', params)
}

export const getServiceDiscountCardCategoryById = async (serviceDiscountCardCategoryId) => {
    return await request('GET', `service/bonus/category/${serviceDiscountCardCategoryId}/`)
}

export const createServiceDiscountCardCategory = async ({ data }) => {
    return await request('POST', 'service/bonus/category/', data)
}

export const updateServiceDiscountCardCategory = async ({ serviceDiscountCardCategoryId, data }) => {
    return await request('PUT', `service/bonus/category/${serviceDiscountCardCategoryId}/`, data)
}

//
// Partner company
// -----------------------------------------------------------------------------

export const getServicePartnerCompanies = async (params) => {
    return await request('GET', 'service/bonus/company/', params)
}

export const getServicePartnerCompanyById = async (servicePartnerCompanyId) => {
    return await request('GET', `service/bonus/company/${servicePartnerCompanyId}/`)
}

export const createServicePartnerCompany = async ({ data }) => {
    return await request('POST', 'service/bonus/company/', data)
}

export const updateServicePartnerCompany = async ({ servicePartnerCompanyId, data }) => {
    return await request('PUT', `service/bonus/company/${servicePartnerCompanyId}/`, data)
}

//
// Promotion
// -----------------------------------------------------------------------------

export const getServicePromotions = async (params) => {
    return await request('GET', 'service/bonus/promotion/', params)
}

export const getServicePromotionById = async (servicePromotionId) => {
    return await request('GET', `service/bonus/promotion/${servicePromotionId}/`)
}

export const createServicePromotion = async ({ data }) => {
    return await request('POST', 'service/bonus/promotion/', data)
}

export const updateServicePromotion = async ({ servicePromotionId, data }) => {
    return await request('PUT', `service/bonus/promotion/${servicePromotionId}/`, data)
}

//
// Partner bonus
// -----------------------------------------------------------------------------

export const getServicePartnerBonuses = async (params) => {
    return await request('POST', 'service/bonus/promotion/', params)
}

export const getServicePartnerBonusById = async (params) => {
    return await request('POST', 'service/partner_bonus/promotion/detail/', params)
}

export const createServicePartnerBonus = async (params) => {
    return await request('POST', 'service/partner_bonus/promotion/create/', params)
}

export const updateServicePartnerBonus = async (params) => {
    return await request('POST', 'service/partner_bonus/promotion/update/', params)
}

// Copmany partner

export const getServicePartnerBonusCompanies = async (params) => {
    return await request('POST', 'service/partner_bonus/company/', params)
}

export const getServicePartnerBonusCompanyById = async (params) => {
    const response = await request('POST', 'service/partner_bonus/company/detail/', params)

    response.logo = response.logo.image

    return response
}

export const createServicePartnerBonusCompany = async (params) => {
    return await request('POST', 'service/partner_bonus/company/create/', params)
}

export const updateServicePartnerBonusCompany = async (params) => {
    return await request('POST', 'service/partner_bonus/company/update/', params)
}

// Category

export const getServicePartnerBonusCategories = async (params) => {
    return await request('POST', 'service/partner_bonus/category/', params)
}

export const getServicePartnerBonusCategoryById = async (params) => {
    return await request('POST', 'service/partner_bonus/category/detail/', params)
}

export const createServicePartnerBonusCategory = async (params) => {
    return await request('POST', 'service/partner_bonus/category/create/', params)
}

export const updateServicePartnerBonusCategory = async (params) => {
    return await request('POST', 'service/partner_bonus/category/update/', params)
}

// Promotion

export const getServicePartnerBonusPromotions = async (params) => {
    return await request('POST', 'service/partner_bonus/promotion/', params)
}

export const getServicePartnerBonusPromotionById = async (params) => {
    return await request('POST', 'service/partner_bonus/promotion/detail/', params)
}

export const createServicePartnerBonusPromotion = async (params) => {
    return await request('POST', 'service/partner_bonus/promotion/create/', params)
}

export const updateServicePartnerBonusPromotion = async (params) => {
    return await request('POST', 'service/partner_bonus/promotion/update/', params)
}

//
// Tags
// -----------------------------------------------------------------------------

export const getServiceTags = async (params) => {
    return await request('GET', 'service/tags/', params)
}

export const getServiceTagTypes = async (params) => {
    return await request('GET', 'service/tag_types/', params)
}

export const createServiceTag = async (params) => {
    return await request('POST', 'service/tags/', params)
}
