const ProfileNavigator = () => import('_navigations/Home/Profile/ProfileNavigator')

const ProfileScreen = () => import('_screens/Home/Profile/ProfileScreen')

const profileRoutes = [
    {
        path: 'profile',
        component: ProfileNavigator,
        children: [
            {
                path: ':userId?',
                name: 'Profile',
                component: ProfileScreen,
                meta: {
                    screenOptions: {
                        title: 'Профиль пользователя',
                    },
                },
            },
        ],
    },
]

export default profileRoutes
