import { PERMISSIONS } from '_features/permissions/constants'

const CamerasNavigator = () => import('_navigations/Home/UserApp/CamerasNavigator')
const CameraGroupsNavigator = () => import('_navigations/Home/UserApp/Cameras/CameraGroupsNavigator')

const CamerasScreen = () => import('_screens/Home/UserApp/Cameras/CamerasScreen')
const CameraEditScreen = () => import('_screens/Home/UserApp/Cameras/CameraEditScreen')

const CameraGroupEditScreen = () => import('_screens/Home/UserApp/Cameras/Groups/CameraGroupEditScreen')

const camerasRoutes = [
    {
        path: 'cameras',
        component: CamerasNavigator,
        children: [
            {
                path: '',
                name: 'UserAppCameras',
                component: CamerasScreen,
                meta: {
                    screenOptions: {
                        title: 'Камеры',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_LOCATION_CAMERA],
                },
            },
            {
                path: 'edit/:cameraId?',
                name: 'UserAppCameraEdit',
                component: CameraEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Камера',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_LOCATION_CAMERA],
                },
            },
            {
                path: 'groups',
                component: CameraGroupsNavigator,
                children: [
                    {
                        path: '',
                        name: 'UserAppCameraGroups',
                        redirect: { name: 'UserAppCameraGroupEdit' },
                        meta: {
                            screenOptions: {
                                title: 'Группы камер',
                            },
                            permissions: [PERMISSIONS.CAN_VIEW_LOCATION_CAMERA_GROUP],
                        },
                    },
                    {
                        path: 'edit/:cameraGroupId?',
                        name: 'UserAppCameraGroupEdit',
                        component: CameraGroupEditScreen,
                        meta: {
                            showBack: true,
                            screenOptions: {
                                title: 'Группа камер',
                            },
                            permissions: [PERMISSIONS.CAN_CHANGE_LOCATION_CAMERA_GROUP],
                        },
                    },
                ],
            },
        ],
    },
]

export default camerasRoutes
