import { request } from '_api/v1/base/behemoth/client'

export const getChats = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'post/chat/', filters, { params })
}

export const getChatById = async (chatId) => {
    return await request('GET', `chat/${chatId}/`)
}

export const getChatMessages = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'post/chat/messages/', filters, { params })
}

export const getMessages = async (chatId, options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', `post/chat/${chatId}/message/`, filters, { params })
}

export const markAsReadMessages = async (chatId) => {
    return await request('DELETE', `chat/${chatId}/unread/`)
}

export const sendMessage = async (chatId, params) => {
    return await request('POST', `chat/${chatId}/message/`, params)
}

export const createChatForTicket = async (ticketId) => {
    return await request('POST', `chat/ticket/${ticketId}/`)
}

export const editMessage = async (chatId, messageId, params) => {
    return await request('PUT', `chat/${chatId}/message/${messageId}/`, params)
}

export const deleteMessage = async (chatId, messageId) => {
    return await request('DELETE', `chat/${chatId}/message/${messageId}/`)
}

export const getChanges = async (chatId) => {
    return await request('GET', `chat/${chatId}/change/`)
}

export const getTypicalAnswers = async () => {
    return await request('GET', 'chat/typical_answer/')
}

export const updateTypicalAnswer = async (typicalAnswerId, params) => {
    return await request('PUT', `chat/typical_answer/${typicalAnswerId}/`, params)
}

export const createTypicalAnswer = async (params) => {
    return await request('POST', 'chat/typical_answer/', params)
}

export const deleteTypicalAnswer = async (typicalAnswerId) => {
    return await request('DELETE', `chat/typical_answer/${typicalAnswerId}/`)
}

export const getChatReport = async (params) => {
    return await request('GETFILE', 'chat/report/', params)
}

// Регламентные сроки ответа
export const getChatAnswerTimeLimitSettings = async (params) => {
    return await request('GET', 'chat/chat_answer_time_limit_setting/', params)
}

export const getChatAnswerTimeLimitSettingById = async (chatAnswerTimeLimitSettingId) => {
    return await request('GET', `chat/chat_answer_time_limit_setting/${chatAnswerTimeLimitSettingId}/`)
}

export const createChatAnswerTimeLimitSettings = async (params) => {
    return await request('POST', 'chat/chat_answer_time_limit_setting/', params)
}

export const deleteChatAnswerTimeLimitSetting = async (chatAnswerTimeLimitSettingId) => {
    return await request('DELETE', `chat/chat_answer_time_limit_setting/${chatAnswerTimeLimitSettingId}/`)
}

export const updateChatAnswerTimeLimitSetting = async (chatAnswerTimeLimitSettingId, params) => {
    return await request('PUT', `chat/chat_answer_time_limit_setting/${chatAnswerTimeLimitSettingId}/`, params)
}
