<template>
    <UIPage>
        <UIPageHeader>
            <template v-slot:actions>
                <v-btn color="primary" :disabled="!canAddPushMessage" @click="addPushMessage()">
                    Добавить
                </v-btn>
            </template>
        </UIPageHeader>

        <CSection>
            <v-card>
                <CPushMessagesDataTable />
            </v-card>
        </CSection>
    </UIPage>
</template>

<script>
    import CPushMessagesDataTable from '_features/pushMessages/components/CPushMessagesDataTable'
    import { PERMISSIONS } from '_features/permissions/constants'

    import CSection from '_common/components/CSection'

    import { UIPage, UIPageHeader } from '_ui'

    export default {
        name: 'PushMessagesScreen',

        components: {
            CPushMessagesDataTable,
            CSection,
            UIPage,
            UIPageHeader,
        },

        computed: {
            canAddPushMessage() {
                return this.$hasPermission([
                    PERMISSIONS.CAN_ADD_NEWSLETTER_PUSH_MESSAGE,
                    PERMISSIONS.CAN_ADD_NEWSLETTER_TEMPLATE_PUSH_MESSAGE,
                ])
            },
        },

        methods: {
            addPushMessage() {
                this.$router.push({
                    name: 'NewslettersPushAdd',
                })
            },
        },
    }
</script>
