import Entity from '_entities/base/EntityNew'

import Address from '_entities/address/Address'
import BuildingLink from '_entities/building/BuildingLink'
import BuildingSectionLink from '_entities/buildingSection/BuildingSectionLink'
import Photo from '_entities/media/Photo'
import PipeLink from '_entities/pipe/PipeLink'
import PremiseTypeLink from '_entities/premise/PremiseTypeLink'
import ResidentialComplex from '_entities/residentialComplex/ResidentialComplex'
import EntranceLink from '_entities/entrance/EntranceLink'

export const PremiseSchema = {
    name: 'Premise',
    primaryKey: 'id',
    properties: {
        address: { type: 'Address', entity: Address },
        building: { type: 'BuildingLink', entity: BuildingLink },
        buildingSection: { type: 'BuildingSectionLink', entity: BuildingSectionLink },
        extId: { type: 'string' },
        finishingType: { type: 'string' },
        floor: { type: 'int' },
        fullArea: { type: 'float' },
        id: { type: 'string' },
        isCornerWindows: { type: 'bool' },
        isDuplex: { type: 'bool' },
        isFrontGarden: { type: 'bool' },
        isHighCeiling: { type: 'bool' },
        isPanoramic: { type: 'bool' },
        isView: { type: 'bool' },
        layout: { type: 'Photo', entity: Photo },
        number: { type: 'string' },
        order: { type: 'int' },
        pipes: { type: 'PipeLink[]', entity: PipeLink },
        premiseType: { type: 'PremiseTypeLink', entity: PremiseTypeLink },
        premiseTypeShort: { type: 'string' },
        residentialComplex: { type: 'ResidentialComplex', entity: ResidentialComplex },
        redecoratingType: { type: 'string' },
        roomsCount: { type: 'int' },
        entrance: { type: 'EntranceLink', entity: EntranceLink },
    },
}
class Premise extends Entity {
    get schema() {
        return PremiseSchema
    }

    get dataMap() {
        return {
            building_section: 'buildingSection',
            ext_id: 'extId',
            finishing_type: 'finishingType',
            full_area: 'fullArea',
            is_front_garden: 'isFrontGarden',
            is_corner_windows: 'isCornerWindows',
            is_duplex: 'isDuplex',
            is_high_ceiling: 'isHighCeiling',
            is_panoramic: 'isPanoramic',
            is_view: 'isView',
            rooms_count: 'roomsCount',
            premise_type: 'premiseType',
            premise_type_short: 'premiseTypeShort',
            redecorating_type: 'redecoratingType',
            residential_complex: 'residentialComplex',
        }
    }

    static fieldLabels = {
        address: 'Адрес',
        building: 'Здание',
        buildingSection: 'Секция',
        extId: 'Внешний ID',
        finishingType: 'Тип отделки',
        floor: 'Этаж',
        fullArea: 'Полная площадь',
        id: 'ID',
        isCornerWindows: 'Угловые окна',
        isDuplex: 'Двухуровневое помещение',
        isFrontGarden: 'Палисадник',
        isHighCeiling: 'Высокий потолок',
        isPanoramic: 'Панорамное помещение',
        isView: 'Видовое помещение',
        layout: 'Планировка',
        number: 'Номер',
        order: 'Порядок сортировки',
        pipes: 'Стояки',
        premiseType: 'Тип помещения',
        residentialComplex: 'ЖК',
        redecoratingType: 'Тип ремонта',
        roomsCount: 'Кол-во комнат',
        entrance: 'Подъезд',
    }

    get displayName() {
        return this.address?.short || this.address?.full || this.address || 'Адрес отсутствует'
    }
}

export default Premise
