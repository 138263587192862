import { request } from '_api/v1/base/behemoth/client'

export const getAppChangeLog = async (params) => {
    const _params = {
        ...params,
        app_id: process.env.VUE_APP_ID,
    }

    return await request('GET', 'app/app_changelog/', _params)
}
