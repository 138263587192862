import Entity from '_entities/base/EntityNew'

import CsiFeedbackPoint from '_entities/csiFeedback/CsiFeedbackPoint'
import PremiseLink from '_entities/premise/PremiseLink'
import TicketLink from '_entities/ticket/TicketLink'

export const CsiFeedbackSchema = {
    name: 'CsiFeedback',
    primaryKey: 'id',
    properties: {
        comment: { type: 'string' },
        csiPoll: { type: 'int' },
        date: { type: 'int' },
        feedbackPoints: { type: 'CsiFeedbackPoint[]', default: [], entity: CsiFeedbackPoint },
        feedbackStatus: { type: 'string' },
        id: { type: 'string' },
        premise: { type: 'PremiseLink', entity: PremiseLink },
        rating: { type: 'int' },
        ticket: { type: 'TicketLink', entity: TicketLink },
    },
}

class CsiFeedback extends Entity {
    get schema() {
        return CsiFeedbackSchema
    }

    get dataMap() {
        return {
            csi_poll: 'csiPoll',
            feedback_points: 'feedbackPoints',
            feedback_status: 'feedbackStatus',
            rating: 'rating',
        }
    }

    static fieldLabels = {
        comment: 'Текст',
        date: 'Дата',
        feedbackStatus: 'Статус',
        premise: 'Помещение',
        rating: 'Оценка',
    }

    toServer() {
        return {
            comment: this.comment,
            csi_poll: this.csiPoll,
            feedback_status: this.feedbackStatus,
            premise: this.premise?.id,
            rating: this.rating,
        }
    }
}

export default CsiFeedback
