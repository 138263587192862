import Entity from '_entities/base/EntityNew'

export const CompanyRequisiteSchema = {
    name: 'CompanyRequisite',
    primaryKey: 'id',
    properties: {
        bankName: { type: 'string' },
        bic: { type: 'string' },
        checkingAccount: { type: 'string' },
        correspondentAccount: { type: 'string' },
        fullName: { type: 'string' },
        id: { type: 'string' }, // UUID
        inn: { type: 'string' },
        kpp: { type: 'string' },
        legalAddress: { type: 'string' },
        nameAccount: { type: 'string' },
        shortName: { type: 'string' },
    },
}

class CompanyRequisite extends Entity {
    get schema() {
        return CompanyRequisiteSchema
    }

    get dataMap() {
        return {
            bank_name: 'bankName',
            checking_account: 'checkingAccount',
            correspondent_account: 'correspondentAccount',
            full_name: 'fullName',
            legal_address: 'legalAddress',
            name_account: 'nameAccount',
            short_name: 'shortName',
        }
    }

    static fieldLabels = {
        id: 'ID',
        inn: 'ИНН',
        kpp: 'КПП',
        bic: 'БИК',
        fullName: 'Полное наименование',
        shortName: 'Короткое наименование',
        nameAccount: 'Название счета',
        legalAddress: 'Юр. адрес',
        checkingAccount: 'Расчетный счет',
        correspondentAccount: 'к/с',
        bankName: 'Название банка',
    }

    toServer() {
        return {
            id: this?.id,
            inn: this?.inn,
            kpp: this?.kpp,
            bic: this?.bic,
            full_name: this?.fullName,
            short_name: this?.shortName,
            name_account: this?.nameAccount,
            legal_address: this?.legalAddress,
            checking_account: this?.checkingAccount,
            correspondent_account: this?.correspondentAccount,
            bank_name: this?.bankName,
        }
    }
}

export default CompanyRequisite
