const knowledgeBaseRoutes = []

knowledgeBaseRoutes.push({
    path: 'knowledge-base',
    children: [
        {
            path: '',
            name: 'KnowledgeBase',
            meta: {
                screenOptions: {
                    title: 'База знаний',
                },
            },
        },
    ],
    beforeEnter() {
        window.open('https://wiki.yandex.ru/', '_blank')
    },
})

export default knowledgeBaseRoutes
