import { PERMISSIONS } from '_features/permissions/constants'

const CitiesNavigator = () => import('_screens/Home/Cities/CitiesNavigator')

const CitiesScreen = () => import('_screens/Home/Cities/CitiesScreen')
const CityEditScreen = () => import('_screens/Home/Cities/CityEditScreen')
const CityViewScreen = () => import('_screens/Home/Cities/CityViewScreen')

const citiesRoutes = [
    {
        path: 'cities',
        component: CitiesNavigator,
        children: [
            {
                path: '',
                name: 'Cities',
                component: CitiesScreen,
                meta: {
                    screenOptions: {
                        title: 'Города',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_LOCATION_CITY],
                },
            },
            {
                path: 'edit/:cityId',
                name: 'CityEdit',
                component: CityEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Город',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_LOCATION_CITY],
                },
            },
            {
                path: 'view/:cityId',
                name: 'CityView',
                component: CityViewScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Информация о городе',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_LOCATION_CITY],
                },
            },
        ],
    },
]

export default citiesRoutes
