<template>
    <v-sheet flat width="500px" class="d-flex align-center">
        <v-container class="pa-5">
            <v-row justify="space-around" class="my-5">
                <v-icon large v-text="'$toolsSolid'" />
            </v-row>
            <v-row justify="center" class="my-5">
                <b class="text-h5">Уважаемый пользователь!</b>
            </v-row>
            <v-row class="text-center" dense>
                <p>Сервис временно недоступен по причине проведения технических работ. Приносим свои извинения в связи с доставленными неудобствами.</p>
            </v-row>
            <v-row v-if="formattedTime" justify="space-around" dense>
                <p>Плановое время завершения: {{ formattedTime }}</p>
            </v-row>
            <v-row justify="space-around">
                <v-btn
                    class="ma-5"
                    color="primary"
                    @click="refresh"
                >
                    Обновить статус
                </v-btn>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script>
    import { parseDate } from '_utils/datetime'

    export default {
        name: 'FMaintenancePanel',

        computed: {
            formattedTime() {
                const dateFinish = this.$store.state.maintenanceWork?.status?.dateFinish

                return dateFinish ? parseDate(dateFinish).locale('ru').format('LLL') : null
            },
        },

        methods: {
            refresh() {
                this.$store.dispatch('maintenanceWork/fetchMaintenanceStatus')
            },
        },
    }
</script>
