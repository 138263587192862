import Entity from '_entities/base/EntityNew'

export const PushMessageTemplateFieldSchema = {
    name: 'PushMessageTemplateField',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        type: { type: 'string' }, // list_relation || list || time || date
        code: { type: 'string' },
        isRequired: { type: 'bool' },
        order: { type: 'int' },
        values: { type: 'string[]', default: [] },
    },
}

class PushMessageTemplateField extends Entity {
    get schema() {
        return PushMessageTemplateFieldSchema
    }

    get dataMap() {
        return {
            is_required: 'isRequired',
        }
    }
}

export default PushMessageTemplateField
