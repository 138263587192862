import Entity from '_entities/base/EntityNew'

export const MeterHistorySchema = {
    name: 'MeterHistory',
    properties: {
        date: { type: 'int' }, // UNIX
        difference: { type: 'float' },
        name: { type: 'string' },
        value: { type: 'float' },
    },
}

class MeterHistory extends Entity {
    get schema() {
        return MeterHistorySchema
    }

    static fieldLabels = {
        date: 'Дата',
        difference: 'Расход (Разница показаний)',
        name: 'Название тарифа',
        value: 'Показание',
    }
}

export default MeterHistory
