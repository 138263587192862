import { API } from '_api'

import Company from '_entities/company/Company'

const actions = {
    async fetchCompanies({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchCompaniesRequest')

            try {
                const response = await API.v1.services.company.getCompanies(params)

                const { results } = response

                const items = []

                if (results) {
                    results?.forEach((result) => {
                        if (result.coordinates && typeof result.coordinates !== 'object') {
                            const coordinates = result.coordinates?.split(':')

                            result.coordinates = {
                                lat: coordinates[0],
                                lon: coordinates[1],
                            }
                        }

                        items.push(new Company(result))
                    })
                }

                commit('fetchCompaniesSuccess', { items })
            } catch (e) {
                commit('fetchCompaniesError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
