<template>
    <v-autocomplete
        v-model="model"
        v-bind="$attrs"
        clearable
        :disabled="$attrs.disabled || false"
        :hide-details="$attrs.hideDetails || false"
        :items="ticketDepartments"
        item-text="name"
        item-value="id"
        :label="label"
        :loading="isFetching"
        :multiple="multiple"
        outlined
        placeholder="Начните ввод.."
        :readonly="$attrs.readonly"
        return-object
        :rules="$attrs.rules || false"
        :search-input.sync="search"
        @focus.once="handleFetchData"
        @keydown="fetchData"
        @click:clear="fetchData"
    />
</template>

<script>
    import { debounce as _debounce, filter as _filter, forEach as _forEach } from 'lodash'
    import { mapGetters, mapState } from 'vuex'

    export default {
        name: 'FTicketDepartmentsSelect',

        props: {
            isAvailable: { type: Boolean, default: false },
            filter: { type: Object, default: null },
            label: { type: String, default: 'Отдел заявки' },
            multiple: { type: Boolean },
            value: { type: [Array, Object], default: null },
        },

        data: () => ({
            search: null,
        }),

        computed: {
            ...mapState('ticketDepartments', {
                isFetching: (state) => state.isFetching,
            }),

            ...mapGetters({
                getTicketDepartments: 'ticketDepartments/getTicketDepartments',
            }),

            model: {
                get() {
                    return this.value
                },
                set(value) {
                    if (value) {
                        if (this.multiple) {
                            const ticketDepartmentIds = []

                            _forEach(value, (item) => {
                                if (item.name) {
                                    this.$store.commit('ticketDepartments/addedTicketDepartment', item)
                                } else {
                                    ticketDepartmentIds.push(item.id)
                                }
                            })

                            if (ticketDepartmentIds.length) {
                                this.handleFetchData({ id__in: ticketDepartmentIds })
                            }
                        } else {
                            if (value.name) {
                                this.$store.commit('ticketDepartments/addedTicketDepartment', value)
                            } else {
                                this.handleFetchData({ id__in: [value.id] })
                            }
                        }
                    }

                    this.$emit('input', value)
                },
            },

            ticketDepartments() {
                let ticketDepartments = this.getTicketDepartments

                if (this.isAvailable) {
                    ticketDepartments = _filter(ticketDepartments, (ticketDepartment) => ticketDepartment.isAvailable)
                }

                if (this.filter) {
                    if (typeof this.filter === 'function') {
                        //
                    } else {
                        _forEach(this.filter, (filterItem) => {
                            ticketDepartments = _filter(ticketDepartments, filterItem)
                        })
                    }
                }

                if (this.multiple && this.value?.length) {
                    ticketDepartments = [...ticketDepartments, ...this.value]
                } else if (this.value) {
                    ticketDepartments = [...ticketDepartments, this.value]
                }

                return ticketDepartments
            },

            params() {
                const params = {}

                if (this.search) {
                    params.search = this.search
                }

                if (this.isAvailable) {
                    params.is_available = this.isAvailable
                }

                return params
            },
        },

        watch: {
            value: {
                handler() {
                    this.search = null
                },
            },

            params: {
                handler() {
                    this.handleFetchData()
                },
                deep: true,
            },
        },

        created() {
            this.fetchData = _debounce(() => {
                if (!this.isFetching) {
                    this.handleFetchData()
                }
            }, 800)
        },

        methods: {
            handleFetchData(filters) {
                const options = {
                    page: 1,
                    page_size: Number.MAX_SAFE_INTEGER,
                    ...filters,
                    ...this.params,
                }

                this.$store.dispatch('ticketDepartments/fetchTicketDepartments', options)
            },
        },
    }
</script>
