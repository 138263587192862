import Vue from 'vue'

const mutations = {
    setOnLineState(state, onLine) {
        state.onLine = !!onLine
    },

    setNavigationDrawer(state, payload) {
        if (payload) {
            const keys = Object.keys(payload)
            for (const key of keys) {
                const value = payload[key]

                Vue.set(state.navigationDrawer, key, value)
            }
        }
    },

    setShownNavigationDrawer(state, payload) {
        if (payload) {
            const keys = Object.keys(payload)
            for (const key of keys) {
                switch (key) {
                    case 'isShown': localStorage.setItem('isShownNavigationDrawer', payload[key])
                }
            }
        }
    },

    setAppActiveStatus(state, payload) {
        state.isAppActive = Boolean(payload)
    },
}

export default mutations
