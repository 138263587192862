import { request } from '_api/v1/base/behemoth/client'

export const createTicketKind = async (params) => {
    return await request('POST', 'ticket/kind/', params)
}

export const deleteTicketKind = async (ticketKindId) => {
    return await request('DELETE', `ticket/kind/${ticketKindId}/`)
}

export const getTicketKinds = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'post/ticket/kinds/', filters, { params })
}

export const getTicketKindById = async (ticketKindId) => {
    return await request('GET', `ticket/kind/${ticketKindId}/`)
}

export const updateTicketKind = async (ticketKindId, params) => {
    return await request('PUT', `ticket/kind/${ticketKindId}/`, params)
}
