import {
    compact as _compact,
    groupBy as _groupBy,
    uniq as _uniq,
} from 'lodash'
import moment from 'moment'

import { request } from '_api/v1/base/behemoth/client'

export const getCurrentTimeEntry = async (userId, params) => {
    return await request('GET', `time_management/current_time_entry/${userId}/`, params)
}

export const startWork = async (params) => {
    return await request('POST', 'time_management/start_work/', params)
}

export const finishWork = async (params) => {
    return await request('POST', 'time_management/finish_work/', params)
}

// Counters

export const getNeedAnswerTimeCounters = async (params) => {
    return await request('POST', 'time_management/time_counter/waiting_worker_response/', params)
}

// Очереди

export const getQueueEntries = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'time_management/on_queue_entry/', filters, { params })
}

export const getQueueEntriesSum = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'time_management/on_queue_entry_sum/', filters, { params })
}

// Смены сотрудников

export const getTimeEntries = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    const response = await request('POST', 'time_management/time_entry/', filters, { params })

    // Для каждой смены получаем данные очередей
    if (response?.results?.length) {
        // Если нет диапазона дат в параметрах фильтра, придется вычислять самостоятельно
        let dateStart = filters?.date_start || moment().unix()
        let dateEnd = filters?.date_end || moment().unix()

        const userIds = _uniq(_compact(response.results.map((timeEntry) => {
            const localDateStart = moment(timeEntry.date_start).unix()
            const localDateEnd = timeEntry.date_finish ? moment(timeEntry.date_finish).unix() : moment().unix()

            dateStart = Math.min(dateStart, localDateStart)
            dateEnd = Math.max(dateEnd, localDateEnd)

            return timeEntry.user?.id
        })))

        const queueEntriesResponse = await getQueueEntries({
            user__in: userIds,
            date_start: dateStart,
            date_end: dateEnd,
        })

        if (queueEntriesResponse.results?.length) {
            const groupedQueueEntries = _groupBy(queueEntriesResponse.results, 'time_entry')

            for (let index = 0; index < response.results.length; index++) {
                const timeEntryId = response.results[index].id
                response.results[index].queue_entries = groupedQueueEntries[timeEntryId] || []
            }
        }
    }

    return response
}

export const getTimeEntriesSum = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'time_management/time_entry_sum/', filters, { params })
}
