import Entity from '_entities/base/EntityNew'

export const ApprovalTypeSchema = {
    name: 'ApprovalType',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' }, // UUID
        name: { type: 'string' },
    },
}

class ApprovalType extends Entity {
    get schema() {
        return ApprovalTypeSchema
    }
}
export default ApprovalType
