import { request } from '_api/v1/base/behemoth/client'

export const getChatBots = async (params) => {
    return await request('GET', 'chatbot/', params)
}

export const getChatBotById = async (chatBotId) => {
    return await request('GET', `chatbot/${chatBotId}/`)
}

export const addChatBot = async ({ data }) => {
    return await request('POST', 'chatbot/', data)
}

export const updateChatBot = async ({ chatBotId, data }) => {
    return await request('PUT', `chatbot/${chatBotId}/`, data)
}

export const deleteChatBot = async (chatBotId) => {
    return await request('DELETE', `chatbot/${chatBotId}/`)
}

//
// Answers
// -----------------------------------------------------------------------------

export const getChatBotAnswers = async (params) => {
    return await request('GET', 'chatbot/answer/', params)
}

export const getChatBotAnswersNext = async (nextLink) => {
    return await request('GET', nextLink)
}

export const updateChatBotAnswer = async ({ chatBotAnswerId, data }) => {
    return await request('PUT', `chatbot/answer/${chatBotAnswerId}/`, data)
}

export const addChatBotAnswer = async ({ data }) => {
    return await request('POST', 'chatbot/answer/', data)
}

export const deleteChatBotAnswer = async (chatBotAnswerId) => {
    return await request('DELETE', `chatbot/answer/${chatBotAnswerId}/`)
}
