import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const state = {
    errors: [],
    isFetching: false,
    item: null,
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
