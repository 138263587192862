import Entity from '_entities/base/EntityNew'

export const CompanyEmailSchema = {
    name: 'CompanyEmail',
    primaryKey: 'id',
    properties: {
        email: { type: 'string' },
        id: { type: 'string' }, // UUID
    },
}

class CompanyEmail extends Entity {
    get schema() {
        return CompanyEmailSchema
    }

    static fieldLabels = {
        id: 'ID',
        email: 'Email',
    }

    getEmailText() {
        return this.email
    }

    toServer() {
        return {
            id: this.id,
            email: this.email,
        }
    }
}

export default CompanyEmail
