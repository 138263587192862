import { groupBy as _groupBy, orderBy as _orderBy } from 'lodash'
import moment from 'moment'

const getters = {
    getNotificationMessages: (state) => {
        return _orderBy(state.entities, ['deliveryTime'], ['asc'])
    },

    // Метод будет использован и оттестирован, когда появится больше сообщений
    getNotificationMessagesGroupedByDeliveryTime: (state) => {
        return _groupBy(state.entities, (item) => {
            return moment.unix(item.deliveryTime).startDay()
        })
    },
}

export default getters
