import { request } from '_api/v1/base/behemoth/client'

export const getMaintenanceWorkEvents = async (params) => {
    return await request('GET', 'maintenance_work_event/', params)
}

export const getMaintenanceWorkEventById = async (maintenanceWorkEventId) => {
    return await request('GET', `maintenance_work_event/${maintenanceWorkEventId}/`)
}

export const deleteMaintenanceWorkEvent = async (maintenanceWorkEventId) => {
    return await request('DELETE', `maintenance_work_event/${maintenanceWorkEventId}/`)
}

export const updateMaintenanceWorkEvent = async (maintenanceWorkEventId, params) => {
    return await request('PUT', `maintenance_work_event/${maintenanceWorkEventId}/`, params)
}

export const addMaintenanceWorkEvent = async (params) => {
    return await request('POST', 'maintenance_work_event/', params)
}

export const getServiceStatus = async (params) => {
    return await request('GET', 'service_status/', params)
}
