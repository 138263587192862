const mutations = {
    fetchPermissionsRequest(state) {
        state.isFetching = true
    },

    fetchPermissionsSuccess(state, { items }) {
        state.errors = []
        state.isFetching = false
        state.items = items
    },

    fetchPermissionsError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },
}

export default mutations
