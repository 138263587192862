
import Entity from '_entities/base/EntityNew'

export const AccidentCompanySchema = {
    name: 'AccidentCompany',
    primaryKey: 'id',
    properties: {
        id: { type: 'int' },
        code: { type: 'string' },
        name: { type: 'string' },
        phoneNumber: { type: 'string' },
    },
}

class AccidentCompany extends Entity {
    get schema() {
        return AccidentCompanySchema
    }

    get dataMap() {
        return {
            phone_number: 'phoneNumber',
        }
    }

    static fieldLabels = {
        id: 'ID',
        code: 'Код',
        name: 'Название',
        phoneNumber: 'Телефон',
    }
}

export default AccidentCompany
