import Entity from '_entities/base/EntityNew'

export const CompanyTypeSchema = {
    name: 'CompanyType',
    primaryKey: 'id',
    properties: {
        code: { type: 'string' },
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class CompanyType extends Entity {
    get schema() {
        return CompanyTypeSchema
    }

    static fieldLabels = {
        code: 'Код',
        id: 'ID',
        name: 'Название',
    }
}

export default CompanyType
