import { request } from '_api/v1/base/behemoth/client'

export const getTimeline = async (params) => {
    return await request('GET', 'entity/timeline/', params)
}

export const getNextTimeline = async (nextLink) => {
    return await request('GET', nextLink)
}

export const getComments = async (params) => {
    return await request('GET', 'entity/comment/', params)
}

export const addComment = async (params) => {
    return await request('POST', 'entity/comment/', params)
}

export const readComment = async (params) => {
    return await request('POST', 'entity/comment/update_reading_at/', params)
}
