import Entity from '_entities/base/EntityNew'

import CityLink from '_entities/city/CityLink'

export const ResidentialComplexSchema = {
    name: 'ResidentialComplex',
    primaryKey: 'id',
    properties: {
        city: { type: 'CityLink', entity: CityLink },
        id: { type: 'string' },
        name: { type: 'string' },
        order: { type: 'int' },
    },
}

class ResidentialComplex extends Entity {
    get schema() {
        return ResidentialComplexSchema
    }

    static fieldLabels = {
        city: 'Город',
        id: 'ID',
        name: 'Название',
        order: 'Порядок',
    }

    toArray() {
        return {
            name: this.name,
            city: this.city?.id,
        }
    }
}

export default ResidentialComplex
