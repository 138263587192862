const mutations = {
    setQueueStatus(state, { queueStatus }) {
        state.queueStatus = queueStatus
    },

    setCallQueue(state, callQueue) {
        state.callQueue = callQueue
    },
}

export default mutations
