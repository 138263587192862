const mutations = {
    fetchMantenanceStatusRequest(state) {
        state.isFetching = true
    },

    fetchMantenanceStatusSuccess(state, {
        maintenanceStatus,
        isInfoShown,
        isMaintenance,
        needRestartApplication,
    }) {
        state.errors = []
        state.isFetching = false
        state.status = maintenanceStatus
        state.isInfoShown = isInfoShown
        state.isMaintenance = isMaintenance
        state.needRestartApplication = needRestartApplication
    },

    fetchMantenanceStatusError(state, errors) {
        state.errors = errors
        state.isFetching = false

        state.status = null
        state.isMaintenance = true
    },
}

export default mutations
