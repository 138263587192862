import Entity from '_entities/base/EntityNew'

export const CallQueueSchema = {
    name: 'CallQueue',
    properties: {
        id: { type: 'int' },
        name: { type: 'string' },
        number: { type: 'int' },
        members: { type: 'string[]', default: [] }, // UUID
        public: { type: 'boolean' },
        recallTimeLimit: { type: 'int' },
    },
}

class CallQueue extends Entity {
    get schema() {
        return CallQueueSchema
    }

    get dataMap() {
        return {
            recall_time_limit: 'recallTimeLimit',
        }
    }
}

export default CallQueue
