import Entity from '_entities/base/EntityNew'

import CompanyLink from '_entities/company/CompanyLink'
import BuildingLink from '_entities/building/BuildingLink'
import CameraGroupLink from '_entities/camera/CameraGroupLink'
import Photo from '_entities/media/Photo'

export const CameraSchema = {
    name: 'Camera',
    primaryKey: 'id',
    properties: {
        building: { type: 'BuildingLink', entity: BuildingLink },
        company: { type: 'CompanyLink', entity: CompanyLink },
        group: { type: 'CameraGroupLink', entity: CameraGroupLink },
        id: { type: 'string' },
        link: { type: 'string' },
        name: { type: 'string' },
        photo: { type: 'Photo', entity: Photo },
    },
}

class Camera extends Entity {
    get schema() {
        return CameraSchema
    }

    static fieldLabels = {
        company: 'Компания',
        building: 'Дом',
        group: 'Группа',
        id: 'ID',
        link: 'Камера',
        name: 'Название',
        photo: 'Фото',
    }

    toServer() {
        return {
            building: this.building?.id,
            company: this.company?.id,
            group: this.group?.id || null,
            link: this.link,
            name: this.name,
            photo: this.photo?.id,
        }
    }
}

export default Camera
