import { forEach as _forEach } from 'lodash'

import Doc from '_entities/media/Doc'
import ServiceElement from '_entities/service/ServiceElement'

export const ServiceElementFileSchema = {
    elementType: { type: 'string', default: 'files' },
    files: { type: 'Doc[]', entity: Doc, default: () => [] },
}

class ServiceElementFile extends ServiceElement {
    get schema() {
        return {
            ...super.schema.name,
            ...super.schema.primaryKey,
            properties: {
                ...super.schema.properties,
                ...ServiceElementFileSchema,
            },
        }
    }

    get dataMap() {
        return {
            ...super.dataMap,
            element_type: 'elementType',
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
        files: 'Файлы',
    }

    toServer(data) {
        const fileIds = []

        _forEach(data?.files, (file) => {
            fileIds.push(file.id)
        })

        return {
            ...super.toServer(data),
            files: fileIds,
        }
    }
}

export default ServiceElementFile
