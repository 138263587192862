import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import ServicePartnerCompany from '_entities/service/ServicePartnerCompany'

const state = {
    item: new ServicePartnerCompany(),
    isFetching: false,
    isSaving: false,
    errors: [],
    // Form
    isValidForm: true,
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
