import ServiceDiscountCardCategory from '_entities/service/ServiceDiscountCardCategory'

const mutations = {
    clearServiceDiscountCardCategory(state) {
        state.item = new ServiceDiscountCardCategory()
        state.isFetching = false
        state.isSaving = false
        state.errors = []

        state.isValidForm = true
    },

    setValidFormState(state, isValidForm) {
        state.isValidForm = !!isValidForm
    },

    // -------------------------------------------------------------------------

    fetchServiceDiscountCardCategoryRequest(state) {
        state.item = new ServiceDiscountCardCategory()
        state.isFetching = true
    },

    fetchServiceDiscountCardCategorySuccess(state, { item }) {
        state.item = item
        state.isFetching = false
        state.errors = []
    },

    fetchServiceDiscountCardCategoryError(state, errors) {
        state.isFetching = false
        state.errors = errors
    },

    // -------------------------------------------------------------------------

    saveServiceDiscountCardCategoryRequest(state) {
        state.isSaving = true
    },

    saveServiceDiscountCardCategorySuccess(state) {
        state.isSaving = false
        state.errors = []
    },

    saveServiceDiscountCardCategoryError(state, errors) {
        state.isSaving = false
        state.errors = errors
    },
}

export default mutations
