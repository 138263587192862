import Entity from '_entities/base/EntityNew'

export const PushMessageTemplateValueSchema = {
    name: 'PushMessageTemplateValue',
    properties: {
        fieldId: { type: 'string' },
        fieldText: { type: 'string' },
        fieldValue: { type: 'string' },
    },
}

class PushMessageTemplateValue extends Entity {
    get schema() {
        return PushMessageTemplateValueSchema
    }

    get dataMap() {
        return {
            field_id: 'fieldId',
            field_text: 'fieldText',
            field_value: 'fieldValue',
        }
    }

    toArray() {
        return {
            field_id: this.fieldId,
            field_text: this.fieldText,
            field_value: this.fieldValue,
        }
    }
}

export default PushMessageTemplateValue
