<template>
    <v-menu
        ref="menu"
        v-model="showDialog"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="400"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                v-if="modeModel !== 'free'"
                v-model="model"
                v-mask="modes[modeModel].mask"
                v-bind="$attrs"
                :label="label"
                :rules="[...rules, modes[modeModel].rule]"
                masked
                outlined
                v-on="on"
            />
            <v-text-field
                v-else
                v-model="model"
                v-bind="$attrs"
                :label="label"
                outlined
                v-on="on"
            />
        </template>
        <v-sheet>
            <v-card-text>
                <v-radio-group
                    v-model="modeModel"
                    row
                >
                    <template
                        v-for="modeData in modes"
                    >
                        <v-radio
                            v-if="modeData.mode !== 'free'"
                            :key="modeData.mode"
                            :label="modeData.mask"
                            :value="modeData.mode"
                        />
                    </template>
                </v-radio-group>
            </v-card-text>
        </v-sheet>
    </v-menu>
</template>

<script>
    import { mask } from 'vue-the-mask'

    import { mobilePhoneRule, officePhoneRule } from '_utils/rules'

    export default {
        name: 'UIPhoneMaskedInput',

        directives: {
            mask,
        },

        props: {
            label: { type: String, default: 'Номер телефона' },
            mode: { type: String, default: 'mobile' },
            value: { type: String, default: null },
            rules: { type: Array, default: () => [] },
        },

        data: () => ({
            showDialog: false,
            modeModel: 'mobile',
            modes: {
                mobile: {
                    rule: mobilePhoneRule,
                    mask: '+7##########',
                    mode: 'mobile',
                },
                office: {
                    rule: officePhoneRule,
                    mask: '##-##-##',
                    mode: 'office',
                },
                free: {
                    rule: true,
                    mask: 'Свободно',
                    mode: 'free',
                },
            },
        }),

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },
        },

        watch: {
            mode: {
                handler(mode) {
                    this.modeModel = mode
                },
                immediate: true,
            },
        },
    }
</script>
