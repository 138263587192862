import { PERMISSIONS } from '_features/permissions/constants'

const ResidentialComplexesNavigator = () => import('_screens/Home/ResidentialComplexes/ResidentialComplexesNavigator')

const ResidentialComplexesScreen = () => import('_screens/Home/ResidentialComplexes/ResidentialComplexesScreen')
const ResidentialComplexEditScreen = () => import('_screens/Home/ResidentialComplexes/ResidentialComplexEditScreen')
const ResidentialComplexViewScreen = () => import('_screens/Home/ResidentialComplexes/ResidentialComplexViewScreen')

const residentialComplexesRoutes = [
    {
        path: 'residential-complexes',
        component: ResidentialComplexesNavigator,
        children: [
            {
                path: '',
                name: 'ResidentialComplexes',
                component: ResidentialComplexesScreen,
                meta: {
                    screenOptions: {
                        title: 'ЖК',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_LOCATION_RESIDENTIAL_COMPLEX],
                },
            },
            {
                path: 'edit/:residentialComplexId?',
                name: 'ResidentialComplexEdit',
                component: ResidentialComplexEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'ЖК',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_LOCATION_RESIDENTIAL_COMPLEX],
                },
            },
            {
                path: 'view/:residentialComplexId?',
                name: 'ResidentialComplexView',
                component: ResidentialComplexViewScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Информация о ЖК',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_LOCATION_RESIDENTIAL_COMPLEX],
                },
            },
        ],
    },
]

export default residentialComplexesRoutes
