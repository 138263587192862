import Entity from '_entities/base/EntityNew'

export const PlacementLinkSchema = {
    name: 'PlacementLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}
class PlacementLink extends Entity {
    get schema() {
        return PlacementLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        nanme: 'Наименование',
    }
}

export default PlacementLink
