/* eslint-disable camelcase */
import { chunk as _chunk, compact as _compact, map as _map } from 'lodash'
import moment from 'moment'

import { API } from '_api'
import { request } from '_api/v1/base/document/client'

import { parseDate } from '_utils/datetime'

export const getOutgoingDocuments = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    const response = await request('POST', 'documents/post/OutgoingDocument', filters, { params })
    const responseChunks = _chunk(response.results, 25)
    const results = []

    for (const responseChunk of responseChunks) {
        const companySenderIds = _map(responseChunk, ({ company_sender_id }) => company_sender_id)
        const ticketIds = _map(responseChunk, ({ ticket_id }) => ticket_id)

        const companySenders = await API.v1.services.company.getCompanies({
            id__in: _compact(companySenderIds).join(','),
        })

        const tickets = await API.v1.services.ticket.getTickets({
            id__in: _compact(ticketIds),
        })

        for (const response of responseChunk) {
            response.company_sender = companySenders.results.find((companySender) => companySender.id === response.company_sender_id)
            response.ticket = tickets.results.find((ticket) => ticket.id === response.ticket_id)

            response.date_of_document = parseDate(response.date_of_document)?.unix()

            results.push(response)
        }
    }

    response.results = results

    return response
}

export const getOutgoingDocumentById = async (outgoingDocumentId) => {
    const response = await request('GET', `documents/OutgoingDocument/${outgoingDocumentId}`)
    const errors = []

    if (response.company_sender_id) {
        response.companySender = await API.v1.services.company.getCompanyById(response.company_sender_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.compiler_user_id) {
        response.compilerUser = await API.v1.services.user.getUserById(response.compiler_user_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.signatory_user_id) {
        response.signatory_user = await API.v1.services.user.getUserById(response.signatory_user_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.ticket_id) {
        response.ticket = await API.v1.services.ticket.getTicketById(response.ticket_id).catch((err) => {
            errors.push(...err)
        })
    }

    response.date_of_document = parseDate(response.date_of_document)?.unix()

    response.errors = errors

    return response
}

export const updateOutgoingDocument = async (outgoingDocumentId, data) => {
    return await request('PATCH', `documents/OutgoingDocument/${outgoingDocumentId}`, data)
}

export const createOutgoingDocument = async (data) => {
    return await request('POST', 'documents/createOutgoingDocument', data)
}

export const deleteOutgoingDocument = async (outgoingDocumentId) => {
    return await request('DELETE', `documents/OutgoingDocument/${outgoingDocumentId}`)
}

export const getOutgoingDocumentBlank = async (companyId) => {
    const errors = []
    const response = await request(
        'POST',
        'documents/OutgoingDocument/getBlank',
        {
            company_sender_id: companyId,
        },
    )

    if (response.company_sender_id) {
        response.companySender = await API.v1.services.company.getCompanyById(response.company_sender_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.date_of_document) {
        response.date_of_document = moment(response.date_of_document).unix()
    }

    response.errors = errors

    return response
}
