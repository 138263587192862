import Entity from '_entities/base/EntityNew'

import PremiseLink from '_entities/premise/PremiseLink'

export const OfficeSchema = {
    name: 'Office',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        isCentral: { type: 'boolean' },
        name: { type: 'string' },
        premise: { type: 'PremiseLink', entity: PremiseLink },
    },
}
class Office extends Entity {
    get schema() {
        return OfficeSchema
    }

    get dataMap() {
        return {
            is_central: 'isCentral',
        }
    }

    static fieldLabels = {
        id: 'ID',
        isCentral: 'Центральный офис',
        name: 'Наименование',
        premise: 'Помещение',
    }
}

export default Office
