import Vue from 'vue'

const mutations = {
    fetchServiceDiscountCardCategoriesRequest(state) {
        state.isFetching = true
    },

    fetchServiceDiscountCardCategoriesSuccess(state, { items }) {
        state.errors = []
        state.isFetching = false

        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchServiceDiscountCardCategoriesError(state, errors) {
        state.errors = errors
        state.isFetching = false
    },

    addedServiceDiscountCardCategory(state, item) {
        Vue.set(state.items, item.id, item)
    },
}

export default mutations
