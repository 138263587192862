import Entity from '_entities/base/EntityNew'

import QuizStatQuestionAnswer from '_entities/quiz/QuizStatQuestionAnswer'

export const QuizStatQuestionSchema = {
    name: 'QuizStatQuestion',
    primaryKey: 'id',
    properties: {
        answers: { type: 'QuizStatQuestionAnswer[]', entity: QuizStatQuestionAnswer, default: [] },
        count: { type: 'int' },
        id: { type: 'int' }, // UUID
        text: { type: 'string' },
        type: { type: 'string' },
    },
}

class QuizStatQuestion extends Entity {
    get schema() {
        return QuizStatQuestionSchema
    }

    static fieldLabels = {
        answers: 'Ответы',
        count: 'Общее кол-во ответивших',
        id: 'ID',
        text: 'Текст вопроса',
        type: 'Тип вопроса',
    }
}

export default QuizStatQuestion
