import Entity from '_entities/base/EntityNew'

export const CsiFeedbackPointSchema = {
    name: 'CsiFeedbackPoint',
    primaryKey: 'id',
    properties: {
        id: { type: 'int' },
        name: { type: 'string' },
        rating: { type: 'string' },
    },
}

class CsiFeedbackPoint extends Entity {
    get schema() {
        return CsiFeedbackPointSchema
    }

    static fieldLabels = {
        name: 'Ответ',
        rating: 'Оценка',
    }
}

export default CsiFeedbackPoint
