<template>
    <div class="title-line">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'UITitleLine',
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/variables";

    .title-line {
        margin-top:       map-get($spacers, 2);
        padding-right:    map-get($spacers, 2);
        transform-origin: left 75%;
        transition:       transform 300ms ease;
    }
</style>
