import mutations from './mutations'
import actions from './actions'

const state = {
    errors: [],
    isMaintenance: false,
    isFetching: false,
    isInfoShown: false,
    needRestartApplication: false,
    status: null,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
