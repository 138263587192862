import personalAccountsRoutes from '@/router/Home/ResidentialFund/PersonalAccounts'

const ResidentialFundNavigator = () => import('_navigations/Home/ResidentialFund/ResidentialFundNavigator')

const residentialFundRoutes = [
    {
        path: 'residential-fund',
        name: 'ResidentialFundNavigator',
        component: ResidentialFundNavigator,
        redirect: { name: 'PersonalAccounts' },
        children: [
            ...personalAccountsRoutes,
        ],
    },
]

export default residentialFundRoutes
