import moment from 'moment'

import Entity from '_entities/base/EntityNew'

import Photo from '_entities/media/Photo'

export const UserProfileSchema = {
    name: 'UserProfile',
    properties: {
        email: { type: 'string' },
        firstName: { type: 'string' },
        isUsesMobileApp: { type: 'bool' },
        lastActivity: { type: 'int' }, // UNIX
        lastName: { type: 'string' },
        middleName: { type: 'string' },
        photo: { type: 'Photo', entity: Photo },
        phone: { type: 'string' },
    },
}

class UserProfile extends Entity {
    get schema() {
        return UserProfileSchema
    }

    get dataMap() {
        return {
            is_uses_mobile_app: 'isUsesMobileApp',
            first_name: 'firstName',
            last_activity: 'lastActivity',
            last_name: 'lastName',
            middle_name: 'middleName',
        }
    }

    static fieldLabels = {
        email: 'Email',
        firstName: 'Имя',
        lastActivity: 'Последняя активность в МПЖ',
        lastName: 'Фамилия',
        middleName: 'Отчество',
        photo: 'Аватар',
        phone: 'Телефон',
    }

    get fullName() {
        const names = []

        const lastName = this.lastName ? this.lastName.trim() : null
        const firstName = this.firstName ? this.firstName.trim() : null
        const middleName = this.middleName ? this.middleName.trim() : null

        if (lastName) {
            names.push(lastName)
        }
        if (firstName) {
            names.push(firstName)
        }
        if (middleName) {
            names.push(middleName)
        }

        return names.join(' ')
    }

    get shortName() {
        const names = []

        const lastName = this.lastName ? this.lastName.trim() : null
        const firstName = this.firstName ? this.firstName.trim() : null
        const middleName = this.middleName ? this.middleName.trim() : null

        // Из битрикс или 1С приходят пользователи где все ФИО лежит в firstname,
        // а так же есть богатая фантазия при заполнении пользователей-организаций
        if (lastName && firstName && middleName) {
            names.push(lastName)
            names.push(firstName[0] + '.')
            names.push(middleName[0] + '.')
        } else {
            if (lastName) {
                names.push(lastName)
            }
            if (firstName) {
                names.push(firstName)
            }
            if (middleName) {
                names.push(middleName)
            }
        }

        return names.join(' ')
    }

    get lastActivityCaption() {
        if (this.lastActivity) {
            const now = moment()
            const last = moment.unix(this.lastActivity)

            return moment.duration(-now.diff(last), 'milliseconds').locale('ru').humanize(true)
        }

        return ''
    }

    get toServer() {
        return {
            email: this.email,
            first_name: this.firstName,
            last_name: this.lastName,
            middle_name: this.middleName,
            photo: this.photo?.id,
            phone: this.phone,
        }
    }
}

export default UserProfile
