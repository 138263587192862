import { PERMISSIONS } from '_features/permissions/constants'

const PersonalAccountsNavigator = () => import('_navigations/Home/ResidentialFund/PersonalAccountsNavigator')

const PersonalAccountsScreen = () => import('_screens/Home/ResidentialFund/PersonalAccounts/PersonalAccountsScreen')
const PersonalAccountDetailScreen = () => import('_screens/Home/ResidentialFund/PersonalAccounts/PersonalAccountDetailScreen')
const MeterHistoryScreen = () => import('_screens/Home/ResidentialFund/PersonalAccounts/MeterHistoryScreen')

const personalAccountsRoutes = [
    {
        path: 'personal-accounts',
        component: PersonalAccountsNavigator,
        children: [
            {
                path: '',
                name: 'PersonalAccounts',
                component: PersonalAccountsScreen,
                meta: {
                    screenOptions: {
                        title: 'Лицевые счета',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_LOCATION_PERSONAL_ACCOUNT],
                },
            },
            {
                path: 'detail/:personalAccountId?',
                name: 'PersonalAccountDetail',
                component: PersonalAccountDetailScreen,
                meta: {
                    screenOptions: {
                        title: 'Лицевой счет',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_LOCATION_PERSONAL_ACCOUNT],
                },
            },
            {
                path: 'meter-history/:meterId?',
                name: 'MeterHistory',
                component: MeterHistoryScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'История показаний',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_LOCATION_PERSONAL_ACCOUNT],
                },
            },
        ],
    },
]

export default personalAccountsRoutes
