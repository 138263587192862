import Entity from '_entities/base/EntityNew'

export const ApplicantFeedbackSchema = {
    name: 'ApplicantFeedback',
    primaryKey: 'id',
    properties: {
        answerAuthor: { type: 'string' }, // UUID
        answerComment: { type: 'string' },
        feedbackAuthor: { type: 'string' }, // UUID
        feedbackRating: { type: 'int' },
        id: { type: 'string' }, // UUID
        isPositive: { type: 'bool', default: true },
        text: { type: 'string' },
        ticket: { type: 'string' }, // UUID
    },
}

class ApplicantFeedback extends Entity {
    get schema() {
        return ApplicantFeedbackSchema
    }

    get dataMap() {
        return {
            feedback_author: 'feedbackAuthor',
            feedback_rating: 'feedbackRating',
            answer_comment: 'answerComment',
            answer_author: 'answerAuthor',
            is_positive: 'isPositive',
        }
    }

    static fieldLabels = {
        answerAuthor: 'ID автора со стороны',
        answerComment: 'Оценка автора',
        feedbackAuthor: 'ID автора',
        feedbackRating: 'Оценка автора',
        id: 'ID',
        isPositive: 'Позитивный или отрицательный отзыв',
        text: 'Текст заявки',
        ticket: 'ID заявки',
    }
}

export default ApplicantFeedback
