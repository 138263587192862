<template>
    <v-container :class="{'fill-height': !!center}" :fluid="fluid">
        <template v-if="!!center">
            <v-row justify="center" no-gutters>
                <v-col cols="auto">
                    <slot />
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <slot />
        </template>
    </v-container>
</template>

<script>
    export default {
        name: 'CContainer',

        props: {
            center: { type: [Boolean, String], default: null },
            fluid: { type: [Boolean, String], default: null },
        },
    }
</script>
