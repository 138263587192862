import ServiceElement from '_entities/service/ServiceElement'

export const ServiceElementTimeSchema = {
    elementType: { type: 'string', default: 'time' },
}

class ServiceElementTime extends ServiceElement {
    get schema() {
        return {
            ...super.schema.name,
            ...super.schema.primaryKey,
            properties: {
                ...super.schema.properties,
                ...ServiceElementTimeSchema,
            },
        }
    }

    get dataMap() {
        return {
            ...super.dataMap,
            element_type: 'elementType',
        }
    }

    toServer(data) {
        return {
            ...super.toServer(data),
            timezone: this.timezone,
        }
    }
}

export default ServiceElementTime
