import Entity from '_entities/base/EntityNew'

export const NewsCategorySchema = {
    name: 'NewsCategory',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        isActive: { type: 'bool', default: false },
        name: { type: 'string' },
        order: { type: 'int' },
    },
}

class NewsCategory extends Entity {
    get schema() {
        return NewsCategorySchema
    }

    static fieldLabels = {
        id: 'ID',
        isActive: 'Активность',
        name: 'Название',
        order: 'Порядок',
    }
}

export default NewsCategory
