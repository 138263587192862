import Entity from '_entities/base/EntityNew'

export const TimeCounterSchema = {
    name: 'TimeCounter',
    primaryKey: 'id',
    properties: {
        duration: { type: 'int' },
        entityId: { type: 'string' },
        entityType: { type: 'string' },
        id: { type: 'string' },
        isActive: { type: 'boolean' },
        isOn: { type: 'boolean' },
        type: { type: 'string' }, // waiting_worker_response_in_ticket_chat
    },
}

class TimeCounter extends Entity {
    get schema() {
        return TimeCounterSchema
    }

    get dataMap() {
        return {
            entity_id: 'entityId',
            entity_type: 'entityType',
            is_active: 'isActive',
            is_on: 'isOn',
        }
    }
}

export default TimeCounter
