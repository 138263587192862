import { render, staticRenderFns } from "./UIColorPicker.vue?vue&type=template&id=db9a61b4&scoped=true&"
import script from "./UIColorPicker.vue?vue&type=script&lang=js&"
export * from "./UIColorPicker.vue?vue&type=script&lang=js&"
import style0 from "./UIColorPicker.vue?vue&type=style&index=0&id=db9a61b4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db9a61b4",
  null
  
)

export default component.exports