const AuthNavigator = () => import('_navigations/AuthNavigator')

const SignInScreen = () => import('_screens/Auth/SignInScreen')
const RestorePasswordScreen = () => import('_screens/Auth/RestorePasswordScreen')

const authRoutes = [
    {
        path: '/auth',
        name: 'Auth',
        redirect: { name: 'SignIn' },
        component: AuthNavigator,
        children: [
            {
                path: 'sign-in',
                name: 'SignIn',
                component: SignInScreen,
            },
            {
                path: 'restore-password',
                name: 'RestorePassword',
                component: RestorePasswordScreen,
            },
        ],
    },
]

export default authRoutes
