<template>
    <v-navigation-drawer
        v-model="model"
        absolute
        class="grey lighten-2"
        temporary
        right
        :width="windowWidth"
    >
        <v-toolbar color="primary" dark height="50px" class="position-sticky top">
            <v-btn icon @click="hide">
                <v-icon v-text="'$close'" />
            </v-btn>
            <v-toolbar-title>Центр уведомлений</v-toolbar-title>
        </v-toolbar>
        <v-sheet class="grey lighten-2 d-flex align-stretch">
            <v-sheet v-if="false" class="groups" />
            <div class="py-5" :style="{ width: (windowWidth) + 'px' }">
                <template
                    v-for="item, index of itemsModel"
                >
                    <v-card
                        v-if="item.notificationType !== 'info'"
                        :key="index"
                        class="ma-3"
                        @click="routeByNotice(item)"
                    >
                        <v-card-title>{{ item.title }}</v-card-title>
                        <v-card-text>
                            {{ item.text }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <span @click="isFullDate = !isFullDate">{{ showAsTime(item.deliveryTime) }}</span>
                        </v-card-actions>
                    </v-card>
                    <div v-if="item.notificationType === 'info'" :key="index" class="info-item">
                        <v-chip color="primary">
                            {{ item.text }}
                        </v-chip>
                    </div>
                </template>
            </div>
        </v-sheet>
    </v-navigation-drawer>
</template>

<script>
    import { dateDifferenceLabel } from '_utils/datetime'
    import moment from 'moment'

    import { forEach as _forEach } from 'lodash'

    export default {
        name: 'FNotificationCenter',

        props: {
            windowWidth: { type: Number, default: 600 },
        },

        data: () => ({
            isFullDate: false,
        }),

        computed: {
            model: {
                get() {
                    return this.$store.state.notificationCenter.isShown
                },
                set(value) {
                    if (value) {
                        this.$store.commit('notificationCenter/show')
                    } else {
                        this.$store.commit('notificationCenter/hide')
                    }
                },
            },

            currentWorker() {
                return this.$store.state.auth.currentWorker
            },

            items() {
                return this.$store.getters['notificationMessages/getNotificationMessages']
            },

            itemsModel() {
                const messages = this.items
                let result = []

                if (messages.length) {
                    // Вставляем информационные плашки смены дат
                    _forEach(messages, (message) => {
                        if (result.length > 0) {
                            const lowDay = moment.unix(message.deliveryTime).format('DD')
                            const highDay = moment.unix(result[0].deliveryTime).format('DD')

                            // Определяем момент смены дня
                            if (lowDay !== highDay) {
                                const dateLabel = dateDifferenceLabel(result[0].deliveryTime)

                                if (dateLabel !== 'Сегодня') {
                                    result.unshift({
                                        notificationType: 'info',
                                        text: dateLabel,
                                    })
                                }
                            }
                        }

                        result = [message, ...result]
                    })

                    // Вставка верхней плашки (относительно самого свежего сообщения)
                    result.unshift({
                        notificationType: 'info',
                        text: dateDifferenceLabel(result[0].deliveryTime),
                    })
                }

                return result
            },
        },

        watch: {
            currentWorker: {
                handler() {
                    if (this.currentWorker) {
                        this.fetchData()
                    }
                },
                immediate: true,
                deep: true,
            },
        },

        methods: {
            routeByNotice(item) {
                // TODO: Внедрить eventType. Пока любое уведомление по заявке ведет на детальную заявки.
                if (item.entityType === 'Ticket' && item.entityId) {
                    this.$router.push({ name: 'TicketView', params: { ticketId: item.entityId } })
                }
            },

            count(length) {
                return Math.ceil(Math.random() * (length - 1))
            },

            async fetchData() {
                await this.$store.dispatch('notificationMessages/fetchNotificationMessages', { userId: this.currentWorker?.id })
            },

            hide() {
                this.$store.commit('notificationCenter/hide')
            },

            showAsTime(time) {
                if (this.isFullDate) {
                    return moment.unix(time).locale('ru').format('LLL')
                }

                return moment.unix(time).format('HH:mm')
            },
        },
    }
</script>

<style scoped>
.groups{
    width: 80px;
    background-color: lightgrey;
}
.info-item{
    width: 100%;
    text-align: center;
}
.top {
    top: 0;
    z-index: 10;
}
</style>
