import { PERMISSIONS } from '_features/permissions/constants'

const ChatsNavigator = () => import('_screens/Home/Chats/ChatsNavigator')

const ChatNeedAnswerScreen = () => import('_screens/Home/Chats/ChatNeedAnswerScreen')
const ChatTimeLimitsScreen = () => import('_screens/Home/Chats/ChatTimeLimitsScreen')
const ChatTimeLimitEditScreen = () => import('_screens/Home/Chats/ChatTimeLimitEditScreen')

const chatRoutes = [
    {
        path: '',
        component: ChatsNavigator,
        children: [
            {
                path: 'need-answer',
                name: 'Chats',
                component: ChatNeedAnswerScreen,
                meta: {
                    screenOptions: {
                        title: 'Надо ответить',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_NEED_ANSWER_SECTION],
                },
            },
            {
                path: 'time-limits',
                name: 'ChatTimeLimits',
                component: ChatTimeLimitsScreen,
                meta: {
                    screenOptions: {
                        title: 'Установка регламентных сроков ответа',
                    },
                    permissions: [
                        PERMISSIONS.CAN_VIEW_CHAT_TIME_SETTING,
                    ],
                },
            },
            {
                path: 'time-limits-edit',
                name: 'ChatTimeLimitEdit',
                component: ChatTimeLimitEditScreen,
                meta: {
                    screenOptions: {
                        title: 'Редактирование регламентных сроков ответа',
                    },
                    permissions: [
                        PERMISSIONS.CAN_ADD_CHAT_TIME_SETTING,
                        PERMISSIONS.CAN_CHANGE_CHAT_TIME_SETTING,
                    ],
                },
            },
        ],
    },
]

export default chatRoutes
