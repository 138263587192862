import Vue from 'vue'
import Vuex from 'vuex'

import filter from '_features/filter/store'
import global from '_features/global/store'
import notifications from '_features/notifications/store'
import notificationCenter from '_features/notificationCenter/store'
import notificationMessages from '_features/notificationMessages/store'
import softwareUpdate from '_features/system/softwareUpdate/store'

import auth from '_features/auth/store'

import companies from '_features/companies/store'
import departments from '_features/departments/store'
import workers from '_features/workers/store'

import im from '_features/im/store'

import callQueue from '_features/callQueue/store'
import incomingCall from '_features/incomingCall/store'
import queue from '_features/queue/store'
import sipCall from '_features/sipCall/store'

import buildings from '_features/housingStock/buildings/store'
import cameraGroups from '_features/cameraGroups/store'
import cameras from '_features/cameras/store'
import sectors from '_features/housingStock/sectors/store'

import newsCategories from '_features/newsCategories/store'
import newsList from '_features/newsList/store'

import quiz from '_features/quiz/store'

import permissions from '_features/permissions/store'

import pushMessages from '_features/pushMessages/store'

import services from '_features/service/services/store'
import serviceDiscountCard from '_features/service/serviceDiscountCard/store'
import serviceDiscountCardCategories from '_features/service/serviceDiscountCardCategories/store'
import serviceDiscountCardCategory from '_features/service/serviceDiscountCardCategory/store'
import serviceDiscountCards from '_features/service/serviceDiscountCards/store'
import serviceSections from '_features/service/serviceSections/store'
import serviceSubsections from '_features/service/serviceSubsections/store'
import servicePartnerCompanies from '_features/service/servicePartnerCompanies/store'
import servicePartnerCompany from '_features/service/servicePartnerCompany/store'
import servicePromotion from '_features/service/servicePromotion/store'

import ticket from '_features/ticket/store'
import tickets from '_features/tickets/store'
import ticketDepartments from '_features/ticketDepartments/store'
import ticketKinds from '_features/ticketKinds/store'
import ticketRoute from '_features/ticketRoute/store'
import ticketRoutes from '_features/ticketRoutes/store'
import ticketStages from '_features/ticketStages/store'

import needAnswerTimeCounters from '_features/needAnswerTimeCounters/store'
import timeManagement from '_features/timeManagement/store'

import stories from '_features/story/stories/store'

import maintenanceWork from '_features/maintenanceWork/store'

import userCounters from '_features/user/userCounters/store'

import sync from '_features/sync/store'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        filter,
        global,
        notifications,
        notificationCenter,
        notificationMessages,
        softwareUpdate,
        // Auth
        auth,
        // Department
        companies,
        departments,
        workers,
        // IM
        im,
        // incomingCall
        callQueue,
        incomingCall,
        queue,
        sipCall,

        // Location
        buildings,
        cameraGroups,
        cameras,
        sectors,
        // News
        newsCategories,
        newsList,
        // Quizzes
        quiz,
        // Permissions
        permissions,
        // Newsletter
        pushMessages,
        // Service
        services,
        serviceDiscountCard,
        serviceDiscountCardCategories,
        serviceDiscountCardCategory,
        serviceDiscountCards,
        serviceSections,
        serviceSubsections,
        servicePartnerCompanies,
        servicePartnerCompany,
        servicePromotion,
        // Ticket
        ticket,
        tickets,
        ticketDepartments,
        ticketKinds,
        ticketRoute,
        ticketRoutes,
        ticketStages,

        needAnswerTimeCounters,
        timeManagement,

        stories,

        maintenanceWork,

        userCounters,
        sync,
    },
})
