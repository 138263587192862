<template>
    <v-btn
        icon
        @click="makeCall"
    >
        <v-icon v-text="'$phoneAlt'" />
    </v-btn>
</template>

<script>
    export default {
        name: 'UICallButton',

        props: {
            phoneNumber: { type: String, default: null },
        },

        methods: {
            makeCall() {
                if (this.phoneNumber) {
                    window.open(`sip:${this.phoneNumber}`, '_self')
                }
            },
        },
    }
</script>
