import { filter as _filter, orderBy as _orderBy } from 'lodash'

const getters = {
    getMessages: (state) => (chatId) => {
        const messages = _filter(state.messages, (item) => item.chatId === chatId)

        return _orderBy(messages, ['date'], ['asc'])
    },
}

export default getters
