<template>
    <div class="card-body">
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'UICardBody',
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    .card-body {
        padding: map-get($spacers, 3);
    }
</style>
