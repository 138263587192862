import Entity from '_entities/base/EntityNew'

export const FileBaseSchema = {
    name: 'FileBase',
    primaryKey: 'id',
    properties: {
        extension: { type: 'string' },
        fileName: { type: 'string' },
        fileSize: { type: 'int' },
        id: { type: 'string' },
        mimeType: { type: 'string' },
        name: { type: 'string' },
        originalName: { type: 'string' },
        semanticType: { type: 'string' },
        type: { type: 'string' },
        url: { type: 'string' },
    },
}

class FileBase extends Entity {
    get schema() {
        return FileBaseSchema
    }

    get dataMap() {
        return {
            file_name: 'fileName',
            file_size: 'fileSize',
            mime_type: 'mimeType',
            original_name: 'originalName',
            semantic_type: 'semanticType',
        }
    }

    get isAudio() {
        return this.semanticType === 'audio'
    }

    get isCsv() {
        return this.semanticType === 'csv'
    }

    get isImage() {
        return this.semanticType === 'image'
    }

    get isMsExcel() {
        return this.semanticType === 'ms_excel'
    }

    get isMsPowerpoint() {
        return this.semanticType === 'ms_powerpoint'
    }

    get isMsWord() {
        return this.semanticType === 'ms_word'
    }

    get isPDF() {
        return this.semanticType === 'pdf'
    }

    get isVideo() {
        return this.semanticType === 'video'
    }

    get isZip() {
        return this.semanticType === 'zip'
    }

    get iconName() {
        if (this.isAudio) {
            return 'file-music'
        }
        if (this.isCsv) {
            return 'file-csv'
        }
        if (this.isImage) {
            return 'file-image'
        }
        if (this.isMsExcel) {
            return 'file-excel'
        }
        if (this.isMsPowerpoint) {
            return 'file-powerpoint'
        }
        if (this.isMsWord) {
            return 'file-word'
        }
        if (this.isPDF) {
            return 'file-pdf'
        }
        if (this.isVideo) {
            return 'file-video'
        }
        if (this.isZip) {
            return 'file-zip'
        }

        return 'file'
    }
}

export default FileBase
