import { request } from '_api/v1/base/behemoth/client'

export const getInvoices = async (params) => {
    return await request('GET', 'payment/invoice/', params)
}

export const createInvoice = async ({ data }) => {
    return await request('POST', 'payment/invoice/', data)
}

export const deleteInvoice = async (invoceId) => {
    return await request('DELETE', `payment/invoice/${invoceId}/`)
}

export const sendSmsForPayment = async (invoceId, params) => {
    return await request('POST', `payment/invoice/${invoceId}/send_sms_payment_link/`, params)
}

export const sendSmsAccountPaymentLink = async (params) => {
    return await request('POST', 'v2/payment/send_sms_account_payment_link/', params)
}

export const sendEmailAccountPaymentLink = async (params) => {
    return await request('POST', 'v2/payment/send_email_account_payment_link/', params)
}

export const getAccountPaymentLink = async (params) => {
    return await request('POST', 'v2/payment/get_account_payment_link/', params)
}

export const getPaymentReport = async (params) => {
    return await request('GETFILE', 'v2/payment/get_payment_report/', params)
}
