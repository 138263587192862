import Entity from '_entities/base/EntityNew'

export const NewsCategoryLinkSchema = {
    name: 'NewsCategoryLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class NewsCategoryLink extends Entity {
    get schema() {
        return NewsCategoryLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
    }
}

export default NewsCategoryLink
