<template>
    <v-btn
        color="primary"
        :disabled="disabled || isFetching"
        :loading="isFetching"
        @click="saveExcel"
    >
        Выгрузить в Excel
    </v-btn>
</template>

<script>
    export default {
        name: 'UIButtonSaveToExcel',

        props: {
            disabled: { type: Boolean },
            filename: { type: String, default: null },
            method: { type: Function, default: null },
            params: { type: Object, default: null },
        },

        data: () => ({
            isFetching: false,
        }),

        methods: {
            async saveExcel() {
                try {
                    this.isFetching = true

                    const link = document.createElement('a')

                    const response = await this.method(this.params)

                    link.href = URL.createObjectURL(response)
                    link.download = this.filename + '.xlsx'

                    link.click()
                    URL.revokeObjectURL(link.href)
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить данные отчета', e)
                    this.$logger.logException(e)
                }
                this.isFetching = false
            },
        },
    }
</script>
