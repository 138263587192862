import { PERMISSIONS } from '_features/permissions/constants'

const CompaniesNavigator = () => import('_screens/Home/Companies/CompaniesNavigator')

const CompaniesScreen = () => import('_screens/Home/Companies/CompaniesScreen')
const CompanyEditScreen = () => import('_screens/Home/Companies/CompanyEditScreen')

const companiesRoutes = [
    {
        path: 'companies',
        component: CompaniesNavigator,
        children: [
            {
                path: '',
                name: 'Companies',
                component: CompaniesScreen,
                meta: {
                    screenOptions: {
                        title: 'Компании',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_DEPARTMENT_COMPANY],
                },
            },
            {
                path: 'edit/:companyId',
                name: 'CompanyEdit',
                component: CompanyEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Компания',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_DEPARTMENT_COMPANY],
                },
            },
        ],
    },
]

export default companiesRoutes
