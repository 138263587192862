import {
    map as _map,
    orderBy as _orderBy,
    padStart as _padStart,
    toLower as _toLower,
} from 'lodash'

export const orderByString = (collection, field = 'name', targetLength = 10) => {
    return _orderBy(collection, [(o) => getOrderString(o[field], targetLength)], ['asc'])
}

export const getOrderString = (string, targetLength = 10) => {
    if (!string) {
        return string
    }

    string = _toLower(string)

    // Разделение строки на группы цифр и букв
    const parts = string.match(/(\d+|[a-zа-я.]+)/g)

    // Создание комбинированной строки с добивкой нолями до targetLength разрядов числовых элементов
    const resolvedParts = _map(parts, (part) => {
        if (isNaN(parseInt(part))) {
            // НЕ число
            return part
        } else {
            // Число
            return _padStart(part, targetLength, '0')
        }
    })

    return resolvedParts.join('')
}
