import { forEach as _forEach } from 'lodash'

import ServiceElement from '_entities/service/ServiceElement'
import ServiceElementCalendarEventItem from '_entities/service/ServiceElementCalendarEventItem'

export const ServiceElementCalendarEventSchema = {
    elementType: { type: 'string', default: 'calendar_event' },
    events: { type: 'ServiceElementCalendarEventItem[]', entity: ServiceElementCalendarEventItem, default: () => [] },
    timezone: { type: 'string', default: 'Asia/Yekaterinburg' },
    accountStatus: { type: 'string' },
    sendText: { type: 'string' },
    isSend: { type: 'bool' },
}

class ServiceElementCalendarEvent extends ServiceElement {
    get schema() {
        return {
            ...super.schema.name,
            ...super.schema.primaryKey,
            properties: {
                ...super.schema.properties,
                ...ServiceElementCalendarEventSchema,
            },
        }
    }

    get dataMap() {
        return {
            ...super.dataMap,
            element_type: 'elementType',
            account_status: 'accountStatus',
            send_text: 'sendText',
            is_send: 'isSend',
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
        sendText: 'Текст сообщения',
        isSend: 'Отправлять сообщение по СМС',
    }

    toServer(data) {
        const events = []

        _forEach(data?.events, (event) => {
            events.push(event.toServer(event))
        })

        return {
            ...super.toServer(data),
            events: events,
            timezone: this.timezone,
            account_status: this.accountStatus,
            send_text: this.sendText,
            is_send: this.isSend,
        }
    }
}

export default ServiceElementCalendarEvent
