import { PERMISSIONS } from '_features/permissions/constants'

const StackNavigator = () => import('_screens/StackNavigator')

const ServicesNavigator = () => import('_screens/Home/UserApp/Services/ServicesNavigator')
const ServicesScreen = () => import('_screens/Home/UserApp/Services/ServicesScreen')
const ServiceSectionEditScreen = () => import('_screens/Home/UserApp/Services/ServiceSectionEditScreen')
const ServiceSubsectionEditScreen = () => import('_screens/Home/UserApp/Services/ServiceSubsectionEditScreen')
const ServiceEditScreen = () => import('_screens/Home/UserApp/Services/ServiceEditScreen')

const ServiceDiscountCardsScreen = () => import('_screens/Home/UserApp/Services/DiscountCards/ServiceDiscountCardsScreen')
const ServiceDiscountCardEditScreen = () => import('_screens/Home/UserApp/Services/DiscountCards/ServiceDiscountCardEditScreen')

const ServiceDiscountCardCategoriesScreen = () => import('_screens/Home/UserApp/Services/DiscountCards/Categories/ServiceDiscountCardCategoriesScreen')
const ServiceDiscountCardCategoryEditScreen = () => import('_screens/Home/UserApp/Services/DiscountCards/Categories/ServiceDiscountCardCategoryEditScreen')

const ServicePartnerCompaniesScreen = () => import('_screens/Home/UserApp/Services/PartnerCompanies/ServicePartnerCompaniesScreen')
const ServicePartnerCompanyEditScreen = () => import('_screens/Home/UserApp/Services/PartnerCompanies/ServicePartnerCompanyEditScreen')

const ServicePromotionsScreen = () => import('_screens/Home/UserApp/Services/Promotions/ServicePromotionsScreen')
const ServicePromotionEditScreen = () => import('_screens/Home/UserApp/Services/Promotions/ServicePromotionEditScreen')

const servicesRoutes = [
    {
        path: 'services',
        component: ServicesNavigator,
        children: [
            {
                path: '',
                name: 'UserAppServices',
                component: ServicesScreen,
                meta: {
                    screenOptions: {
                        title: 'Сервисы',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_SERVICE],
                },
            },
            {
                path: 'edit/:serviceId?',
                name: 'UserAppServiceEdit',
                component: ServiceEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Сервис',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_SERVICE],
                },
            },
            {
                path: 'section',
                component: StackNavigator,
                children: [
                    {
                        path: '',
                        name: 'UserAppServiceSection',
                        redirect: { name: 'UserAppServiceSectionEdit' },
                    },
                    {
                        path: 'edit/:serviceSectionId?',
                        name: 'UserAppServiceSectionEdit',
                        component: ServiceSectionEditScreen,
                        meta: {
                            showBack: true,
                            screenOptions: {
                                title: 'Раздел сервиса',
                            },
                            permissions: [PERMISSIONS.CAN_CHANGE_SERVICE_SECTION],
                        },
                    },
                ],
            },
            {
                path: 'subsection',
                component: StackNavigator,
                children: [
                    {
                        path: '',
                        name: 'UserAppServiceSubsection',
                        redirect: { name: 'UserAppServiceSubsectionEdit' },
                    },
                    {
                        path: 'edit/:serviceSubsectionId?',
                        name: 'UserAppServiceSubsectionEdit',
                        component: ServiceSubsectionEditScreen,
                        meta: {
                            showBack: true,
                            screenOptions: {
                                title: 'Подраздел сервиса',
                            },
                            permissions: [PERMISSIONS.CAN_CHANGE_SERVICE_SUBSECTION],
                        },
                    },
                ],
            },
            {
                path: 'discount-card',
                component: StackNavigator,
                children: [
                    {
                        path: '',
                        name: 'UserAppServiceDiscountCard',
                        component: ServiceDiscountCardsScreen,
                        meta: {
                            showBack: true,
                            screenOptions: {
                                title: 'Дисконтные карты',
                            },
                            permissions: [PERMISSIONS.CAN_VIEW_SERVICE_DISCOUNT_CARD],
                        },
                    },
                    {
                        path: 'edit/:serviceDiscountCardId?',
                        name: 'UserAppServiceDiscountCardEdit',
                        component: ServiceDiscountCardEditScreen,
                        meta: {
                            showBack: true,
                            screenOptions: {
                                title: 'Дисконтная карта',
                            },
                            permissions: [PERMISSIONS.CAN_CHANGE_SERVICE_DISCOUNT_CARD],
                        },
                    },
                    {
                        path: 'category',
                        component: StackNavigator,
                        children: [
                            {
                                path: '',
                                name: 'UserAppServiceDiscountCardCategory',
                                component: ServiceDiscountCardCategoriesScreen,
                                meta: {
                                    showBack: true,
                                    screenOptions: {
                                        title: 'Категории',
                                    },
                                    permissions: [PERMISSIONS.CAN_VIEW_SERVICE_DISCOUNT_CARD],
                                },
                            },
                            {
                                path: 'edit/:serviceDiscountCardCategoryId?',
                                name: 'UserAppServiceDiscountCardCategoryEdit',
                                component: ServiceDiscountCardCategoryEditScreen,
                                meta: {
                                    showBack: true,
                                    screenOptions: {
                                        title: 'Категория',
                                    },
                                    permissions: [PERMISSIONS.CAN_CHANGE_SERVICE_DISCOUNT_CARD],
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'partner-company',
                component: StackNavigator,
                children: [
                    {
                        path: '',
                        name: 'UserAppServicePartnerCompany',
                        component: ServicePartnerCompaniesScreen,
                        meta: {
                            showBack: true,
                            screenOptions: {
                                title: 'Компании-партнеры',
                            },
                            permissions: [PERMISSIONS.CAN_VIEW_SERVICE_BONUS_COMPANY],
                        },
                    },
                    {
                        path: 'edit/:servicePartnerCompanyId?',
                        name: 'UserAppServicePartnerCompanyEdit',
                        component: ServicePartnerCompanyEditScreen,
                        meta: {
                            showBack: true,
                            screenOptions: {
                                title: 'Компания-партнер',
                            },
                            permissions: [PERMISSIONS.CAN_CHANGE_SERVICE_BONUS_COMPANY],
                        },
                    },
                ],
            },
            {
                path: 'promotion',
                component: StackNavigator,
                children: [
                    {
                        path: '',
                        name: 'UserAppServicePromotion',
                        component: ServicePromotionsScreen,
                        meta: {
                            showBack: true,
                            screenOptions: {
                                title: 'Акции',
                            },
                            permissions: [PERMISSIONS.CAN_VIEW_SERVICE_PROMOTION],
                        },
                    },
                    {
                        path: 'edit/:servicePromotionId?',
                        name: 'UserAppServicePromotionEdit',
                        component: ServicePromotionEditScreen,
                        meta: {
                            showBack: true,
                            screenOptions: {
                                title: 'Акция',
                            },
                            permissions: [PERMISSIONS.CAN_CHANGE_SERVICE_PROMOTION],
                        },
                    },
                ],
            },
        ],
    },
]

export default servicesRoutes
