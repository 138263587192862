import Entity from '_entities/base/EntityNew'

import Doc from '_entities/media/Doc'
import Photo from '_entities/media/Photo'

export const StoryElementSchema = {
    name: 'StoryElement',
    primaryKey: 'id',
    properties: {
        buttonName: { type: 'string' },
        entity: { type: 'string' },
        entityId: { type: 'string' },
        id: { type: 'string' },
        image: { type: 'Photo', entity: Photo },
        link: { type: 'string' },
        order: { type: 'int' },
        storyType: { type: 'string' },
        text: { type: 'string' },
        title: { type: 'string' },
        video: { type: 'Doc', entity: Doc },
    },
}

class StoryElement extends Entity {
    get schema() {
        return StoryElementSchema
    }

    get dataMap() {
        return {
            button_name: 'buttonName',
            entity_id: 'entityId',
            story_type: 'storyType',
        }
    }

    static fieldLabels = {
        id: 'ID',
        title: 'Заголовок',
        text: 'Текст',
        buttonName: 'Наименование кнопки',
        entity: 'Наименование сущности',
        entityId: 'ID сущности',
        link: 'Ссылка',
        order: 'Сортировка',
        storyType: 'Тип',
        image: 'Изображение',
        video: 'Видео',
    }

    static storyTypes = [
        {
            code: 'informational',
            text: 'Информационный',
        },
        {
            code: 'service',
            text: 'Сервис',
        },
        {
            code: 'news',
            text: 'Новость',
        },
        {
            code: 'link',
            text: 'Ссылка',
        },
        {
            code: 'video',
            text: 'Видео',
        },
        {
            code: 'poll',
            text: 'NPS опрос',
        },
    ]

    toServer() {
        return {
            title: this.title,
            text: this.text,
            button_name: this.buttonName,
            entity_id: this.entityId,
            entity: this.storyType === 'service' || this.storyType === 'news' ? this.storyType : null,
            link: this.link,
            order: this.order,
            story_type: this.storyType,
            image: this.image?.id,
            video: this.video?.id,
        }
    }
}

export default StoryElement
