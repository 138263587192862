import { forEach as _forEach } from 'lodash'

import { request } from '_api/v1/base/behemoth/client'

import MeterHistory from '_entities/meter/MeterHistory'
import MeterType from '_entities/meter/MeterType'

export const getMeterTypes = async (params) => {
    const _params = {
        page: params?.page,
        page_size: params?.itemsPerPage,
    }

    try {
        const response = await request('GET', 'meter/meter_types/', _params)

        const { count, results } = response

        const entities = []
        _forEach(results, (result) => {
            entities.push(new MeterType(result))
        })

        return { count, results: entities }
    } catch (errors) {
        return Promise.reject(errors)
    }
}

export const getMeterHistory = async (meterId) => {
    const meters = []

    const response = await request('GET', `meter/history/${meterId}/`)

    if (response) {
        _forEach(response, (meterHistory) => {
            _forEach(meterHistory.metering, (meter) => {
                meters.push(new MeterHistory({
                    date: meterHistory.date,
                    difference: meter.difference,
                    name: meter.name,
                    value: meter.value,
                }))
            })
        })
    }

    return meters
}
