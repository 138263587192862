<template>
    <v-menu
        ref="menu"
        v-model="showDialog"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                v-model="timeFormatted"
                :disabled="disabled"
                :label="label"
                outlined
                prepend-inner-icon="$calendarDay"
                readonly
                v-bind="$attrs"
                v-on="on"
            />
        </template>
        <v-time-picker
            v-model="model"
            v-bind="$attrs"
            format="24hr"
            full-width
        />
    </v-menu>
</template>

<script>
    export default {
        name: 'UITimePicker',

        props: {
            label: { type: String, default: null },
            disabled: { type: Boolean, default: false },
            value: { type: Number, default: null },
        },

        data: () => ({
            showDialog: false,
        }),

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            timeFormatted() {
                return this.value
            },
        },
    }
</script>

<style scoped lang="scss">

</style>
