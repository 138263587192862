import Entity from '_entities/base/EntityNew'

export const CompanyLinkSchema = {
    name: 'CompanyLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class CompanyLink extends Entity {
    get schema() {
        return CompanyLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
    }
}

export default CompanyLink
