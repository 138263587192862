const UsersNavigator = () => import('_screens/Home/Users/UsersNavigator')

const UserGroupsScreen = () => import('_screens/Home/Users/UserGroupsScreen')

const companiesRoutes = [
    {
        path: 'users',
        component: UsersNavigator,
        children: [
            {
                path: '',
                name: 'UserGroups',
                component: UserGroupsScreen,
                meta: {
                    screenOptions: {
                        title: 'Группы пользователей',
                    },
                },
            },
        ],
    },
]

export default companiesRoutes
