import { request } from '_api/v1/base/behemoth/client'

export const getBuildings = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    return await request('POST', 'post/buildings/', filters, { params })
}

export const getBuildingById = async (buildingId) => {
    return await request('GET', `building/${buildingId}/`)
}

export const updateBuilding = async ({ buildingId, data }) => {
    return await request('PUT', `building/${buildingId}/`, data)
}
