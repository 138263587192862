<template>
    <div class="main">
        <template v-if="isPhoneConnected">
            <v-badge
                bordered
                :color="isPhoneStopping ? 'grey' : 'red'"
                class="mr-3"
                dot
                overlap
            >
                <v-icon
                    small
                    @click="switchOnLine"
                    v-text="'$phoneOffice'"
                />
            </v-badge>
            <span @click="switchOnLine">
                {{ isPhoneStopping ? 'Отключение...' : timeOnLineLabel }}
            </span>
            <v-btn
                v-if="showCallButton"
                small
                class="ml-2"
                icon
                color="primary"
                @click="makeCall"
            >
                <v-icon small v-text="'$phoneSolid'" />
            </v-btn>
        </template>
        <template v-else>
            <v-icon
                small
                @click="switchOnLine"
                v-text="'$phoneOffice'"
            />
        </template>

        <FMakeCallDialog ref="makeCallDialog" />
    </div>
</template>

<script>
    import moment from 'moment'

    import FMakeCallDialog from '_features/sipCall/components/FMakeCallDialog'
    import { sipUsers } from '_features/sipCall/constants/users'

    import PhoneSettings from '_entities/call/PhoneSettings'

    import { decodeFromSeconds } from '_utils/datetime'

    export default {
        name: 'FPhoneOnLineSwitcher',

        components: {
            FMakeCallDialog,
        },

        props: {
            isTestingMode: { type: Boolean, default: false },
            showCallButton: { type: Boolean, default: true },
        },

        data: () => ({
            timeOnLineLabel: null,
            timeOnLineUandler: null,
            phoneSettings: null,
        }),

        computed: {
            isPhoneConnected() {
                return this.$store.state.sipCall.isPhoneConnected
            },

            isPhoneStopping() {
                return this.$store.state.sipCall.isPhoneStopping
            },

            connectionStartTime() {
                return this.$store.state.sipCall.connectionStartTime
            },

            currentWorker() {
                return this.$store.state.auth.currentWorker
            },

            phoneStatus() {
                return this.$store.state.sipCall.phoneStatus
            },

            phoneError() {
                return this.$store.state.sipCall.phoneError
            },

            sipPhoneSettings() {
                return this.$store.state.sipCall.phoneSettings
            },

            isPollUser() {
                return this.currentWorker?.internalPhone === '9998'
            },
        },

        watch: {
            phoneStatus: {
                handler(phoneStatus) {
                    const time = moment().format('HH:mm:ss')

                    // eslint-disable-next-line no-console
                    console.log(`STATUS: ${phoneStatus} (${time})`)
                },
            },

            phoneError: {
                handler(phoneError) {
                    // eslint-disable-next-line no-console
                    console.log('ERROR: ' + phoneError)
                },
            },
        },

        mounted() {
            const phoneSettings = localStorage.getItem('test_phone_settings')
            if (phoneSettings) {
                this.phoneSettings = new PhoneSettings(JSON.parse(phoneSettings))
            } else {
                this.phoneSettings = this.sipPhoneSettings
            }

            const phoneConnectedTime = localStorage.getItem('phoneConnectedTime')
            if (phoneConnectedTime) {
                this.$store.commit('sipCall/setConnectionStartTime', Number(phoneConnectedTime))
                this.connectPhone()
            }

            this.$store.commit('sipCall/setRef', this)
        },

        destroyed() {
            this.clearTimer()
        },

        methods: {
            switchOnLine() {
                if (this.isPhoneStopping) {
                    return
                }

                if (this.isPhoneConnected) {
                    this.disconnectPhone()
                } else {
                    this.connectPhone()
                }
            },

            connectPhone() {
                let internalPhone = this.currentWorker?.internalPhone

                if (this.isTestingMode) {
                    internalPhone = this.phoneSettings.displayName
                }

                const pollCallLine = localStorage.getItem('callLine')

                if (this.isPollUser) {
                    if (pollCallLine) {
                        internalPhone = pollCallLine
                    } else {
                        this.disconnectPhone()
                        return
                    }
                }

                const password = sipUsers[internalPhone]

                if (!password) {
                    this.$root.pushNotification({
                        text: `Внутренний номер ${internalPhone} не определен`,
                        color: 'red',
                    })

                    return
                }

                this.phoneSettings.displayName = internalPhone
                this.phoneSettings.privateIdentity = internalPhone

                this.phoneSettings.password = password
                this.phoneSettings.publicIdentity = 'sip:' + internalPhone + '@pbx.service-company.biz'

                this.$store.commit('sipCall/connectPhone', {
                    settings: this.phoneSettings,
                    remoteAudio: document.getElementById('audio_remote'),
                })

                if (!this.connectionStartTime) {
                    localStorage.setItem('phoneConnectedTime', moment().unix())
                    this.$store.commit('sipCall/setConnectionStartTime', moment().unix())
                }

                this.getTimeOnLine()
                this.timeOnLineUandler = setInterval(this.getTimeOnLine, 1000)
            },

            disconnectPhone() {
                this.$store.commit('sipCall/disconnectPhone')

                localStorage.removeItem('phoneConnectedTime')
                this.clearTimer()
            },

            getTimeOnLine() {
                const timeAtLine = decodeFromSeconds(moment().unix() - this.connectionStartTime)

                const hours = String(timeAtLine.hours).padStart(2, '0')
                const minutes = String(timeAtLine.minutes).padStart(2, '0')
                const seconds = String(timeAtLine.seconds).padStart(2, '0')

                this.timeOnLineLabel = `${hours}:${minutes}:${seconds}`
            },

            clearTimer() {
                clearInterval(this.timeOnLineUandler)
                this.timeOnLineUandler = null
            },

            async makeCall() {
                const { resident, phone } = await this.$refs.makeCallDialog.open()

                const phoneNumber = resident?.phoneNumber || resident?.phone || phone

                if (phoneNumber) {
                    this.$store.dispatch('sipCall/fetchCallInfo', phoneNumber)
                    this.$store.commit('sipCall/callPhone', phoneNumber)
                }
            },
        },
    }
</script>

<style scoped>
    .main {
        background-color: white;
        border-radius: 9999px;
        cursor: pointer;
        display: inline-block;
        padding: 5px 10px;
        font-size: 14;
        font-weight: 600;
    }
</style>
