import { v4 as uuidV4 } from 'uuid'

import Entity from '_entities/base/EntityNew'

import Photo from '_entities/media/Photo'
import TicketDepartment from '_entities/ticket/TicketDepartment'
import TicketKind from '_entities/ticket/TicketKind'
import TicketType from '_entities/ticket/TicketType'

export const ServiceElementVariantSchema = {
    name: 'ServiceElementVariant',
    primaryKey: 'id',
    properties: {
        id: { type: 'string', default: () => uuidV4() },
        image: { type: 'Photo', entity: Photo },
        order: { type: 'int' },
        ticketDepartment: { type: 'TicketDepartmentLink', entity: TicketDepartment },
        ticketKind: { type: 'TicketKindLink', entity: TicketKind },
        ticketType: { type: 'TicketTypeLink', entity: TicketType },
        title: { type: 'string' },
    },
}

class ServiceElementVariant extends Entity {
    get schema() {
        return ServiceElementVariantSchema
    }

    get dataMap() {
        return {
            ticket_department: 'ticketDepartment',
            ticket_kind: 'ticketKind',
            ticket_type: 'ticketType',
        }
    }

    static fieldLabels = {
        id: 'ID',
        image: 'Изображение',
        order: 'Порядок',
        ticketDepartment: 'Отдел',
        ticketKind: 'Вид',
        ticketType: 'Тип',
        title: 'Заголовок',
    }

    toServer() {
        return {
            id: this.id,
            title: this.title,
            order: this.order,
            image: this.image?.id,
            ticket_department: this.ticketDepartment?.id || null,
            ticket_kind: this.ticketKind?.id || null,
            ticket_type: this.ticketType?.id || null,
        }
    }
}

export default ServiceElementVariant
