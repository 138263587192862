import { API } from '_api'

import Department from '_entities/department/Department'

const actions = {
    async fetchDepartments({ commit, state }, options) {
        if (!state.isFetching) {
            commit('fetchDepartmentsRequest')

            try {
                const response = await API.v1.services.department.getDepartments(options)

                const { results } = response

                const items = []

                if (results) {
                    results?.forEach((result) => {
                        items.push(new Department(result))
                    })
                }

                commit('fetchDepartmentsSuccess', { items })
            } catch (e) {
                commit('fetchDepartmentsError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
