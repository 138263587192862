<template>
    <div class="c-attachment-doc-item">
        <div class="c-attachment-doc-item__content">
            <div class="c-attachment-doc-item__avatar">
                <v-icon small v-text="'$file__Light'" />
            </div>
            <span class="c-attachment-doc-item__content-name">
                {{ attachment.name }}
            </span>
        </div>
        <v-icon
            small
            class="c-attachment-doc-item__close"
            :disabled="disabled"
            @click="remove"
            v-text="'$delete'"
        />
    </div>
</template>

<script>
    import Doc from '_entities/media/Doc'

    export default {
        name: 'UIAttachmentDocItem',

        props: {
            disabled: { type: Boolean, default: false },
            value: { type: Doc, default: null },
        },

        computed: {
            attachment() {
                if (this.value) {
                    return {
                        id: this.value.id,
                        name: this.value.name,
                    }
                }

                return null
            },
        },

        methods: {
            remove() {
                this.$emit('click-remove')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/variables";

    .c-attachment-doc-item {
        background-color: map_get($grey, 'lighten-2');
        border-radius:    50rem;
        font-size:        .9rem;

        padding:          map_get($spacers, 2) map_get($spacers, 3);

        display:          flex;
        align-items:      center;
        justify-content:  space-between;

        &__content {
            width:         90%;
            height:        100%;

            display:       flex;
            align-items:   center;

            &-name {
                overflow:      hidden;
                text-overflow: ellipsis;
                white-space:   nowrap;
            }
        }

        &__avatar {
            margin-right: map_get($spacers, 2);
        }

        &__close {
            margin-left:  map_get($spacers, 2);
            margin-right: -1 * map_get($spacers, 1);
        }
    }

</style>
