import { forEach as _forEach, map as _map } from 'lodash'

import Entity from '_entities/base/EntityNew'

import CompanyEmail from '_entities/company/CompanyEmail'
import CompanyPhone from '_entities/company/CompanyPhone'
import CompanyRequisite from '_entities/company/CompanyRequisite'
import CompanyType from '_entities/company/CompanyType'
import Coordinates from '_entities/coordinates/Coordinates'
import Photo from '_entities/media/Photo'
import ServiceLink from '_entities/service/ServiceLink'
import NewsLink from '_entities/news/NewsLink'

export const CompanySchema = {
    name: 'Company',
    primaryKey: 'id',
    properties: {
        address: { type: 'string' },
        companyType: { type: 'CompanyType', entity: CompanyType },
        coordinates: { type: 'Сoordinates', entity: Coordinates },
        emails: { type: 'CompanyEmail[]', entity: CompanyEmail, default: [] },
        greetingNews: { type: 'NewsLink', entity: NewsLink },
        id: { type: 'string' }, // UUID
        instagramLink: { type: 'string' },
        logo: { type: 'Photo', entity: Photo },
        name: { type: 'string' },
        phones: { type: 'CompanyPhone[]', entity: CompanyPhone, default: [] },
        requisite: { type: 'CompanyRequisite', entity: CompanyRequisite },
        serviceForMessage: { type: 'ServiceLink', entity: ServiceLink },
        siteLink: { type: 'string' },
        vkLink: { type: 'string' },
        workTime: { type: 'mixed' },
    },
}

class Company extends Entity {
    get schema() {
        return CompanySchema
    }

    get dataMap() {
        return {
            company_type: 'companyType',
            greeting_news: 'greeting_news',
            instagram_link: 'instagramLink',
            service_for_message: 'service_for_message',
            site_link: 'site_link',
            vk_link: 'vkLink',
            work_time: 'workTime',
        }
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
        logo: 'Логотип',
        address: 'Адрес',
        companyType: 'Тип компании',
        coordinates: 'Координаты',
        lat: 'Широта',
        lon: 'Долгота',
        workTime: 'Время работы',
        instagramLink: 'Instagram',
        vkLink: 'ВКонтакте',
        siteLink: 'Сайт',
        phones: 'Телефоны',
        emails: 'Email',
        serviceForMessage: 'Сервис - Написать в УК/застройщику',
        greetingNews: 'Приветственная новость',
    }

    toServer() {
        let coordinates
        if (this.coordinates?.lat && this.coordinates?.lon) {
            coordinates = `${this.coordinates.lat}:${this.coordinates.lon}`
        }

        const workTime = {}
        _forEach(this.workTime, (item, key) => {
            if (item.start && item.end) {
                workTime[key] = {
                    start: item.start,
                    end: item.end,
                }
            } else {
                workTime[key] = null
            }
        })

        return {
            name: this.name,
            address: this.address,
            company_type: this.companyType?.id || null,
            logo: this.logo?.id,
            coordinates,
            work_time: workTime,
            instagram_link: this.instagramLink,
            vk_link: this.vkLink,
            site_link: this.siteLink,
            // TODO: this.greetingNews должен быть Entity: Service. Сейчас там ID.
            service_for_message: this.serviceForMessage,
            // TODO: this.greetingNews должен быть Entity: News. Сейчас там ID.
            greeting_news: this.greetingNews,
            phones: _map(this.phones, (item) => item.toServer()),
            emails: _map(this.emails, (item) => item.toServer()),
            requisite: this.requisite?.toServer(this.requisite),
        }
    }
}

export default Company
