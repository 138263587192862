import { API } from '_api'

import ServiceSection from '_entities/service/ServiceSection'

const actions = {
    async fetchServiceSections({ commit }) {
        commit('fetchServiceSectionsRequest')

        try {
            const response = await API.v1.services.service.getServiceSections()

            const { results } = response

            const items = []

            if (results) {
                results?.forEach((result) => {
                    items.push(new ServiceSection(result))
                })
            }

            commit('fetchServiceSectionsSuccess', { items })
        } catch (e) {
            commit('fetchServiceSectionsError', e)

            return Promise.reject(e)
        }
    },

    async deleteServiceSection({ commit }, params) {
        commit('deleteServiceSectionRequest')

        try {
            const serviceSectionId = params?.serviceSectionId || null
            if (serviceSectionId) {
                await API.v1.services.service.deleteServiceSection(serviceSectionId)
            }

            commit('deleteServiceSectionSuccess', { serviceSectionId })
        } catch (e) {
            commit('deleteServiceSectionError', e)
        }
    },
}

export default actions
