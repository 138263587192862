import { PERMISSIONS } from '_features/permissions/constants'

const DutyScheduleNavigator = () => import('_navigations/Home/DutySchedule/DutyScheduleNavigator')

const DutyScheduleEditScreen = () => import('_screens/Home/DutySchedule/DutyScheduleEditScreen')
const DutyScheduleScreen = () => import('_screens/Home/DutySchedule/DutyScheduleScreen')

const dutyScheduleRoutes = [
    {
        path: 'duty-schedule',
        component: DutyScheduleNavigator,
        children: [
            {
                path: 'list',
                name: 'DutySchedule',
                component: DutyScheduleScreen,
                meta: {
                    screenOptions: {
                        title: 'График дежурств',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_DUTY_SCHEDULE],
                },
            },
            {
                path: 'edit',
                name: 'DutyScheduleEdit',
                component: DutyScheduleEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Редактирование дежурства',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_DUTY_SCHEDULE],
                },
            },
            {
                path: 'create',
                name: 'DutyScheduleCreate',
                component: DutyScheduleEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'Добавление дежурства',
                    },
                    permissions: [PERMISSIONS.CAN_ADD_DUTY_SCHEDULE],
                },
            },
        ],
    },
]

export default dutyScheduleRoutes
