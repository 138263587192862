import Entity from '_entities/base/EntityNew'

import ChecklistField from '_entities/checklist/ChecklistField'

export const ChecklistTemplateSchema = {
    name: 'ChecklistTemplate',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        template: { type: 'ChecklistField[]', entity: ChecklistField, defaut: () => [] },
    },
}

class ChecklistTemplate extends Entity {
    get schema() {
        return ChecklistTemplateSchema
    }

    static fieldLabels = {
        fields: 'Поля',
    }
}

export default ChecklistTemplate
