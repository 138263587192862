import { v4 as uuidV4 } from 'uuid'

import Entity from '_entities/base/EntityNew'

import Photo from '_entities/media/Photo'

export const ProductSchema = {
    name: 'Product',
    primaryKey: 'id',
    properties: {
        id: { type: 'string', default: () => uuidV4() },
        image: { type: 'Photo', entity: Photo },
        price: { type: 'float' },
        title: { type: 'string' },
        workTime: { type: 'int', default: 0 },
    },
}

class Product extends Entity {
    get schema() {
        return ProductSchema
    }

    get dataMap() {
        return {
            work_time: 'workTime',
        }
    }

    static fieldLabels = {
        title: 'Заголовок',
        price: 'Цена',
        image: 'Изображение',
        workTime: 'Длительность услуги, минут',
    }

    toServer(data) {
        return {
            title: data?.title,
            price: data?.price,
            image: data?.image?.id,
            work_time: data?.workTime || 0,
        }
    }
}

export default Product
