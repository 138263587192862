import { compact as _compact, includes as _includes } from 'lodash'

export const checkConditions = (type, conditions = []) => {
    if (!conditions.length) {
        return true
    }

    if (type === 'and') {
        return _compact(conditions).length === conditions.length
    } else if (type === 'or') {
        return _includes(conditions, true)
    } else {
        return false
    }
}
