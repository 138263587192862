import Entity from '_entities/base/EntityNew'
import Photo from '_entities/media/Photo'
import ServiceDiscountCard from '_entities/service/ServiceDiscountCard'

export const ServicePromotionSchema = {
    name: 'ServicePromotion',
    primaryKey: 'id',
    properties: {
        discountCard: { type: 'ServiceDiscountCard', entity: ServiceDiscountCard },
        id: { type: 'string' },
        originalImage: { type: 'Photo', entity: Photo },
        previewImage: { type: 'Photo', entity: Photo },
    },
}

class ServicePromotion extends Entity {
    get schema() {
        return ServicePromotionSchema
    }

    get dataMap() {
        return {
            discount_card: 'discountCard',
            original_image: 'originalImage',
            preview_image: 'previewImage',
        }
    }

    static fieldLabels = {
        discountCard: 'Дисконтная карта',
        id: 'ID',
        originalImage: 'Оригинал',
        previewImage: 'Анонс',
    }

    toServer() {
        return {
            discount_card: this.discountCard?.id,
            original_image: this.originalImage?.id,
            preview_image: this.previewImage?.id,
        }
    }
}

export default ServicePromotion
