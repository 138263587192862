import { filter as _filter, find as _find, includes as _includes, orderBy as _orderBy } from 'lodash'

const getters = {
    getTicketStages: (state) => {
        const items = _filter(state.items, (item) => {
            return !_includes([
                'new_from_external', // Новая из внешней системы
                'stuff_waiting', // Ожидает ТМЦ
                'not_taken_by_applicant', // Работа не принята заявителем
                'waiting_builder_response', // Ожидаем ответ застройщика
            ], item.code)
        })

        return _orderBy(items, ['order', 'name'], ['asc', 'asc'])
    },

    getTicketStageById: (state, getters) => (itemId) => {
        return _find(getters.getTicketStages, (item) => item.id === itemId) || null
    },

    getTicketStageByCode: (state) => (itemCode) => {
        return _find(state.items, (item) => item.code === itemCode)
    },
}

export default getters
