import { render, staticRenderFns } from "./FPhoneOnLineSwitcher.vue?vue&type=template&id=37a19aba&scoped=true&"
import script from "./FPhoneOnLineSwitcher.vue?vue&type=script&lang=js&"
export * from "./FPhoneOnLineSwitcher.vue?vue&type=script&lang=js&"
import style0 from "./FPhoneOnLineSwitcher.vue?vue&type=style&index=0&id=37a19aba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a19aba",
  null
  
)

export default component.exports